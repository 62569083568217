import React from 'react';

import RootNavigator from './navigators/RootNavigator';
import { useApolloClient } from './api/config';
import { useSessionTracker } from './lib/common/sessions';
import { initializeGoogleTagManager } from './lib/common/tags';

initializeGoogleTagManager();

function App() {
    const apolloClient = useApolloClient();
    useSessionTracker(apolloClient);
    return <RootNavigator {...{ apolloClient }} />;
}

export default App;
