import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../Locales';
import { containerStyles } from '../../style/container';
import color from '../../style/color';
import { font } from '../../style/text';
import { HOME_SCREEN_WIDTH } from '../../style/size';
import { EnrichedUserOffer } from '../../api/graphql/fragments/enrichedOffers';
import {
    FavoriteOffersQueryResponse,
    FavoriteOffersQueryVariables,
    favoriteOffersQuery,
} from '../../api/graphql/queries/enrichedOffers';
import { isOfferDisplayed } from '../../lib/offers/offers';
import SmallOfferComponent from '../../components/home/offers/SmallOfferComponent';
import LargeOfferComponent from '../../components/home/offers/LargeOfferComponent';
import { ProgressiveFlatList } from '../../components/common/ProgressiveList';
import Footer from '../../components/common/Footer';

const heartShapeIconWhite = { uri: '/assets/images/icons/heart-shape-white.svg' };

function FavoriteOffersPage() {
    const favoriteOffers: EnrichedUserOffer[] | undefined = useFavoriteOffers();
    if (!favoriteOffers)
        return (
            <View style={containerStyles.containerCenter}>
                <ActivityIndicator size="large" color={color.frenchGray} />
            </View>
        );
    if (favoriteOffers.length === 0) return <FavoriteOfferPlaceholder />;
    return <FavoriteOffersList {...{ favoriteOffers }} />;
}

export default FavoriteOffersPage;

export function useFavoriteOffers(): EnrichedUserOffer[] | undefined {
    const queryResponse = useQuery<FavoriteOffersQueryResponse, FavoriteOffersQueryVariables>(favoriteOffersQuery, {
        fetchPolicy: 'cache-and-network',
    });
    const offers: EnrichedUserOffer[] | undefined = React.useMemo(
        () => queryResponse.data?.user?.enrichedOffers?.filter(isOfferDisplayed),
        [queryResponse]
    );
    return offers;
}

function FavoriteOfferPlaceholder() {
    const texts = getLocalizedTexts().home.offers.favoriteOffers.placeholder;
    const history = useHistory();
    return (
        <View style={styles.containerFavoriteOfferPlaceholder}>
            <View style={styles.containerFavoriteOfferPlaceholderImage}>
                <View style={styles.containerHeartShapeIconWhite}>
                    <Image source={heartShapeIconWhite} style={{ height: 35, width: 40, top: 2 }} />
                </View>
            </View>
            <View style={styles.containerFavoriteOfferPlaceholderText}>
                <Text style={styles.textPlaceholderTitle}>{texts.title}</Text>
                <Text style={styles.textPlaceholderSubtitle}>{texts.subtitle}</Text>
            </View>
            <TouchableOpacity style={styles.containerButton} onPress={() => history.push('/home/search')}>
                <Text style={styles.textButton}>{texts.exploreButton}</Text>
            </TouchableOpacity>
        </View>
    );
}

function FavoriteOffersList({ favoriteOffers }: { favoriteOffers: EnrichedUserOffer[] }) {
    const texts = getLocalizedTexts().home.offers.favoriteOffers;
    return (
        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, flex: 1 }}>
            <ProgressiveFlatList<EnrichedUserOffer>
                contentContainerStyle={{ flex: 1 }}
                ListFooterComponentStyle={{ flex: 1, justifyContent: 'flex-end' }}
                data={favoriteOffers}
                renderItem={({ item, index }) => {
                    if (index < 6)
                        return (
                            <View style={styles.containerFavoriteOffer}>
                                <LargeOfferComponent offerId={item.offerId} />
                            </View>
                        );
                    return (
                        <View style={styles.containerFavoriteOffer}>
                            <SmallOfferComponent offerId={item.offerId} />
                        </View>
                    );
                }}
                keyExtractor={(item) => item.offerId}
                initialNumberOfItemsToDisplay={15}
                numColumns={3}
                ListHeaderComponent={
                    <View style={styles.containerHeader}>
                        <Text style={styles.textPageTitle}>{texts.pageTitle}</Text>
                    </View>
                }
                ListFooterComponent={
                    <View>
                        <Footer />
                    </View>
                }
            />
        </View>
    );
}

const styles = StyleSheet.create({
    containerHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 20,
        height: 66,
    },
    containerFavoriteOffer: {
        paddingHorizontal: 20,
        paddingBottom: 20,
    },
    containerFavoriteOfferPlaceholder: {
        marginTop: 70,
        paddingHorizontal: 20,
        paddingBottom: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerFavoriteOfferPlaceholderImage: {
        height: 173,
        width: Math.floor((HOME_SCREEN_WIDTH - 20) / 2),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: color.linkWaterLight,
    },
    containerHeartShapeIconWhite: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 63,
        width: 63,
        borderRadius: 63,
        backgroundColor: color.athensGrayDarker,
    },
    containerFavoriteOfferPlaceholderText: {
        marginTop: 45,
        paddingHorizontal: 20,
        maxWidth: HOME_SCREEN_WIDTH / 1.5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerButton: {
        marginTop: 40,
        height: 50,
        borderRadius: 80,
        paddingHorizontal: 16,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: color.caribbeanGreen,
    },
    textPageTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.tundora,
    },
    textPlaceholderTitle: {
        textAlign: 'center',
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.tundora,
    },
    textPlaceholderSubtitle: {
        marginTop: 10,
        paddingHorizontal: 10,
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.manatee,
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.white,
    },
});
