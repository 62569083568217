import { Region } from '../auth/region';

export enum Browser {
    chrome = 'chrome',
    firefox = 'firefox',
    safari = 'safari',
    edge = 'edge',
    opera = 'opera',
    other = 'other',
}

function detectBrowser(): Browser {
    const userAgent = navigator.userAgent.toLowerCase();
    // Chrome is detected by the presence of 'chrome', or 'crios' in iOS
    if (userAgent.indexOf('chrome') !== -1 || userAgent.indexOf('crios') !== -1) return Browser.chrome;
    // Edge is detected by the presence of 'edg', or 'edgiOS' in iOS, and its user agent contains Safari elements
    else if (userAgent.indexOf('edg') !== -1) return Browser.edge;
    // Opera is detected by the presence of 'opr', or 'opt' in iOS, and its user agent contains Safari elements
    else if (userAgent.indexOf('opr') !== -1 || userAgent.indexOf('opt') !== -1) return Browser.opera;
    // Firefox is detected by the presence of 'firefox', or 'fxios' in iOS
    else if (userAgent.indexOf('firefox') !== -1 || userAgent.indexOf('fxios') !== -1) return Browser.firefox;
    // Safari is detected by the presence of 'safari' and 'version/'
    else if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('version/') !== -1) return Browser.safari;
    else return Browser.other;
}

export const browserKind = detectBrowser();

export enum BrowserExtensionStoreUrl {
    chrome = 'https://chrome.google.com/webstore/detail/joko-pour-chrome/jigflhhckdjdefdjmodlkomnmdonfbbn?hl=fr',
    firefox = 'https://addons.mozilla.org/addon/joko/',
    safari = 'https://apps.apple.com/fr/app/joko-pour-safari/id1559239914?mt=12',
}

enum BrowserExtensionStoreUrlWithRegionTemplate {
    chrome = 'https://chrome.google.com/webstore/detail/joko-pour-chrome/jigflhhckdjdefdjmodlkomnmdonfbbn?hl={{region}}',
    firefox = 'https://addons.mozilla.org/{{region}}/firefox/addon/joko/',
    safari = 'https://apps.apple.com/{{region}}/app/joko-pour-safari/id1559239914?mt=12',
}

/** @deprecated replaced by getBrowserExtensionStoreUrl()  */
export const browserExtensionStoreUrl =
    browserKind === Browser.safari
        ? BrowserExtensionStoreUrl.safari
        : browserKind === Browser.firefox
        ? BrowserExtensionStoreUrl.firefox
        : BrowserExtensionStoreUrl.chrome;

export function getBrowserExtensionStoreUrl(region: Region): string {
    const extensionStoreUrl =
        browserKind === Browser.safari
            ? BrowserExtensionStoreUrlWithRegionTemplate.safari
            : browserKind === Browser.firefox
            ? BrowserExtensionStoreUrlWithRegionTemplate.firefox
            : BrowserExtensionStoreUrlWithRegionTemplate.chrome;
    return extensionStoreUrl.replaceAll('{{region}}', region === Region.FR ? 'fr' : 'en');
}

export enum MobileOperatingSystem {
    iOS = 'iOS',
    android = 'android',
}

export function getMobileOperatingSystem(): MobileOperatingSystem | undefined {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) return MobileOperatingSystem.android;
    else if (/iPad|iPhone|iPod/.test(userAgent)) return MobileOperatingSystem.iOS;
    else return;
}

export const mobileOperatingSystem = getMobileOperatingSystem();
