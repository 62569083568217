import * as React from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';

import { MerchantOffer } from '../../../api/graphql/fragments/offers';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { HOME_SCREEN_WIDTH } from '../../../style/size';
import { containerStyles } from '../../../style/container';
import { useMerchantOffer } from '../../../lib/offers/offers';
import { AffiliateLinkLoadingTriggeringEventType } from '../../../lib/offers/affiliateLinkLoading';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import OfferButton from './OfferButton';
import FavoriteOfferButton from './FavoriteOfferButton';
import OfferCashback from './OfferCashback';
import ExpirationDate from './ExpirationDate';
import { HoverableView } from '../../common/HoverableComponents';

export default function MediumOfferComponent({ offerId }: { offerId: string }) {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const offer: MerchantOffer | undefined = useMerchantOffer(offerId);
    return (
        <TouchableOpacity
            onPress={() => {
                if (offer) {
                    logAmplitudeEvent({ name: 'Offers - Clicked Offer', properties: { offerId } });
                    logUserEventUtil(apolloClient, {
                        type: 'clickedOffer',
                        payload: { offerId, type: offer.type, from: 'webApp' },
                    });
                    history.push(`/home/offer/${offer.offerId}`);
                }
            }}>
            <OfferInfo {...{ offer }} />
        </TouchableOpacity>
    );
}

function OfferInfo({ offer }: { offer: MerchantOffer | undefined }) {
    return (
        <HoverableView style={styles.container} hoveredStyle={styles.containerHovered}>
            <View>
                {offer?.card?.bannerURL ? (
                    <Image source={{ uri: offer.card.bannerURL }} style={styles.image} />
                ) : (
                    <View style={styles.image} />
                )}
                <FavoriteOfferButton {...{ offer }} style={styles.containerFavoriteOfferButton} />
                <View style={styles.containerExpirationDate}>{offer ? <ExpirationDate offer={offer} /> : null}</View>
            </View>
            <View style={containerStyles.containerRow}>
                <View style={styles.containerTexts}>
                    {offer?.card?.title ? (
                        <>
                            <Text style={styles.textTitle} numberOfLines={1}>
                                {offer.card.title}
                            </Text>
                            <OfferCashback {...{ offer }} />
                        </>
                    ) : (
                        <>
                            <View style={[styles.containerPlaceholder, { width: 60 }]} />
                            <View style={[styles.containerPlaceholder, { width: 120 }]} />
                        </>
                    )}
                </View>
                <View style={styles.containerButton}>
                    <OfferButton
                        {...{ offer }}
                        triggeringEventType={AffiliateLinkLoadingTriggeringEventType.offerComponent}
                    />
                </View>
            </View>
        </HoverableView>
    );
}

const styles = StyleSheet.create({
    container: {
        width: Math.floor((HOME_SCREEN_WIDTH - 2 * 20) / 3),
    },
    containerHovered: {
        opacity: 0.8,
    },
    containerTexts: {
        flex: 1,
        marginTop: 15,
        marginRight: 10,
    },
    containerButton: {
        marginTop: 15,
        marginBottom: 15,
    },
    containerFavoriteOfferButton: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    containerExpirationDate: {
        position: 'absolute',
        bottom: 4,
        left: 4,
    },
    containerPlaceholder: {
        height: 14,
        marginBottom: 6,
        borderRadius: 5,
        backgroundColor: color.linkWaterLight,
    },
    image: {
        height: 150,
        borderRadius: 8,
        backgroundColor: color.linkWaterLight,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.black,
    },
});
