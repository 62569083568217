import { useQuery } from '@apollo/client';

import { PhoneNumberStatus, UserBankDetails } from '../../api/graphql/fragments/user';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';

interface UserInfo {
    email: string | null;
    firstName: string | null;
    gender: string | null;
    age: number | null;
    birthDate: string | null;
    phoneNumber: string | null;
    phoneNumberStatus: PhoneNumberStatus | null;
    userBankDetails: UserBankDetails | null;
}

export function useUserInfo(): [UserInfo] {
    const { data } = useQuery<UserQueryResponse, {}>(userQuery, { fetchPolicy: 'cache-and-network' });
    const userInfo = computeUserInfo(data);
    return [userInfo];
}

function computeUserInfo(data: UserQueryResponse | undefined): UserInfo {
    return {
        email: data?.user?.email || null,
        firstName: data?.user?.userInfo?.firstName || null,
        gender: data?.user?.userInfo?.gender || null,
        age: data?.user?.userInfo?.age || null,
        birthDate: data?.user?.userInfo?.birthDate || null,
        phoneNumber: data?.user?.phoneNumber || null,
        phoneNumberStatus: data?.user?.phoneNumberStatus || null,
        userBankDetails: data?.user?.userBankDetails || null,
    };
}
