import * as React from 'react';
import { Modal, StyleSheet, View, Text, Image, Dimensions, TouchableWithoutFeedback } from 'react-native';
import { useParams } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { logUserEventWithoutAuthentication } from '../../api/rest/events';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import Button from '../../components/common/Button';
import JokoLogoHeader from '../../components/public/JokoLogoHeader';

export default function IosAutoDonationConfirmationPage() {
    const { userId, rewardId, rewardTitle, rewardLogoUrl, stackMode } = useParams<{
        userId: string;
        rewardId: string;
        rewardTitle: string;
        rewardLogoUrl: string;
        stackMode?: string;
    }>();
    const [confirmationStatus, setConfirmationStatus] = React.useState<ConfirmationStatus>('pending');
    const shouldUseDevStack: boolean = stackMode === 'dev';
    return (
        <Modal transparent={true}>
            <TouchableWithoutFeedback>
                <View style={styles.container}>
                    <JokoLogoHeader />
                    {confirmationStatus === 'cancelled' ? (
                        <CancelledMessage rewardTitle={rewardTitle} />
                    ) : confirmationStatus === 'confirmed' ? (
                        <ConfirmedMessage rewardTitle={rewardTitle} />
                    ) : (
                        <ConfirmationForm
                            {...{ userId, rewardId, rewardLogoUrl, rewardTitle, shouldUseDevStack, setConfirmationStatus }}
                        />
                    )}
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

type ConfirmationStatus = 'pending' | 'confirmed' | 'cancelled';

function ConfirmationForm({
    userId,
    rewardId,
    rewardLogoUrl,
    rewardTitle,
    shouldUseDevStack,
    setConfirmationStatus,
}: {
    userId: string;
    rewardId: string;
    rewardLogoUrl: string;
    rewardTitle: string;
    shouldUseDevStack: boolean;
    setConfirmationStatus: (value: ConfirmationStatus) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.iosAutoDonationConfirmation.confirmationForm;
    const onConfirm = async () => {
        await logUserEventWithoutAuthentication(
            userId,
            { type: 'confirmedAutoDonationSettings', payload: { rewardId, rewardTitle } },
            shouldUseDevStack
        );
        await logAmplitudeEvent({
            name: 'Rewards - Confirmed Auto Donation Settings',
            properties: { rewardId, rewardTitle },
        });
        setConfirmationStatus('confirmed');
    };
    const onCancel = async () => {
        await logUserEventWithoutAuthentication(userId, { type: 'cancelledAutoDonationSettings' }, shouldUseDevStack);
        await logAmplitudeEvent({ name: 'Rewards - Cancelled Auto Donation Settings' });
        setConfirmationStatus('cancelled');
    };
    return (
        <View style={styles.containerConfirmationForm}>
            <View style={styles.containerConfirmationFormContent}>
                <View style={styles.containerConfirmationFormTitle}>
                    <View style={styles.containerConfirmationFormLogo}>
                        <Image
                            source={{ uri: decodeURIComponent(rewardLogoUrl) }}
                            style={styles.imageConfirmationFormLogo}
                        />
                    </View>
                    <Text style={styles.textConfirmationFormTitle}>{rewardTitle}</Text>
                </View>
                <View style={styles.containerConfirmationFormSeparator}>
                    <View style={styles.containerConfirmationFormSeparatorLine} />
                </View>
                <Text style={styles.textConfirmationForm}>{texts.text(rewardTitle)}</Text>
            </View>
            <View style={styles.containerConfirmationFormButtons}>
                <Button
                    onPress={onCancel}
                    style={styles.containerConfirmationFormCancelButton}
                    textStyle={styles.textConfirmationFormCancelButton}
                    useSecondaryColor
                    disabled={false}>
                    {texts.cancelButton}
                </Button>
                <Button
                    onPress={onConfirm}
                    style={styles.containerConfirmationFormConfirmButton}
                    textStyle={styles.textConfirmationFormConfirmButton}
                    useSecondaryColor
                    disabled={false}>
                    {texts.submitButton}
                </Button>
            </View>
        </View>
    );
}

function CancelledMessage({ rewardTitle }: { rewardTitle: string }) {
    const texts = getLocalizedTexts().home.rewards.iosAutoDonationConfirmation.cancelledMessage;
    return (
        <View style={styles.containerMessage}>
            <Text style={styles.textMessageTitle}>{texts.title(rewardTitle)}</Text>
            <Text style={styles.textMessage}>{texts.subtitle}</Text>
        </View>
    );
}

function ConfirmedMessage({ rewardTitle }: { rewardTitle: string }) {
    const texts = getLocalizedTexts().home.rewards.iosAutoDonationConfirmation.confirmedMessage;
    return (
        <View style={styles.containerMessage}>
            <Text style={styles.textMessageTitle}>{texts.title}</Text>
            <Text style={styles.textMessage}>{texts.subtitle(rewardTitle)}</Text>
        </View>
    );
}

const { height, width } = Dimensions.get('window');

const BUTTON_HEIGHT = 53;

const styles = StyleSheet.create({
    container: {
        height: height,
        width: width,
    },
    containerConfirmationForm: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    containerConfirmationFormContent: {
        position: 'absolute',
        top: 0,
        paddingHorizontal: 20,
    },
    containerConfirmationFormTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerConfirmationFormLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 46,
        height: 46,
        marginRight: 20,
    },
    containerConfirmationFormSeparator: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    containerConfirmationFormSeparatorLine: {
        justifyContent: 'center',
        height: 1,
        width: '100%',
        backgroundColor: color.athensGrayDark,
    },
    containerConfirmationFormButtons: {
        position: 'absolute',
        bottom: 2 * BUTTON_HEIGHT,
        width: '100%',
        paddingHorizontal: 20,
    },
    containerConfirmationFormCancelButton: {
        height: BUTTON_HEIGHT,
        marginBottom: 20,
    },
    containerConfirmationFormConfirmButton: {
        height: BUTTON_HEIGHT,
        backgroundColor: color.black,
    },
    containerMessage: {
        padding: 20,
    },
    imageConfirmationFormLogo: {
        width: 30,
        height: 30,
    },
    textConfirmationFormTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
    },
    textConfirmationForm: {
        marginBottom: 20,
        fontSize: 14,
    },
    textConfirmationFormCancelButton: {
        fontSize: 16,
        fontFamily: font.ambitBold,
    },
    textConfirmationFormConfirmButton: {
        fontSize: 16,
        fontFamily: font.ambitBold,
        color: color.white,
    },
    textMessageTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
        marginBottom: 20,
    },
    textMessage: {
        fontSize: 14,
    },
});
