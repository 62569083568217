const { v1: uuidv1 } = require('uuid');

export const DEVICE_ID_LOCAL_STORAGE_KEY = '@AuthenticationStore:deviceId';

export function updateStoredDeviceId(deviceId: string | undefined) {
    if (!!deviceId && deviceId !== getDeviceId()) localStorage.setItem(DEVICE_ID_LOCAL_STORAGE_KEY, deviceId);
}

export function getDeviceId(): string {
    const deviceId = localStorage.getItem(DEVICE_ID_LOCAL_STORAGE_KEY);
    if (!deviceId) {
        const newDeviceId = uuidv1();
        localStorage.setItem(DEVICE_ID_LOCAL_STORAGE_KEY, newDeviceId);
        return newDeviceId;
    } else return deviceId;
}
