import * as _ from 'lodash';

import { ApolloClient } from '../../api/graphql/client';
import { logAmplitudeEvent, setAmplitudeMarketingAttributionUserProperties } from '../events/amplitudeEvents';
import { logUserEventUtil } from '../events/userEvents';

interface AttributionParameters {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    googleClickId?: string;
    facebookClickId?: string;
    referrer?: string;
    referringDomain?: string;
}

export async function captureAttributionParameters(client: ApolloClient) {
    const utmParameters = captureUTMParameters();
    const googleClickIdentifier = captureGoogleClickIdentifier();
    const facebookClickIdentifier = captureFacebookClickIdentifier();
    const referrer = captureReferrer();
    const attributionParameters: AttributionParameters = {
        ...utmParameters,
        ...googleClickIdentifier,
        ...facebookClickIdentifier,
        ...referrer,
    };
    if (_.values(attributionParameters).some((value) => value && value.length > 0)) {
        await setAttributionAmplitudeUserProperties(attributionParameters);
        await logAttributionEvents(client, attributionParameters);
    }
}

function captureUTMParameters(): {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
} {
    const queryString = window.location.search;
    const utmSource = getQueryParameter('utm_source', queryString);
    const utmMedium = getQueryParameter('utm_medium', queryString);
    const utmCampaign = getQueryParameter('utm_campaign', queryString);
    const utmTerm = getQueryParameter('utm_term', queryString);
    const utmContent = getQueryParameter('utm_content', queryString);
    return { utmSource, utmMedium, utmCampaign, utmTerm, utmContent };
}

function captureGoogleClickIdentifier(): {
    googleClickId?: string;
} {
    const queryString = window.location.search;
    const googleClickId = getQueryParameter('gclid', queryString);
    return { googleClickId };
}

function captureFacebookClickIdentifier(): {
    facebookClickId?: string;
} {
    const queryString = window.location.search;
    const facebookClickId = getQueryParameter('fbclid', queryString);
    return { facebookClickId };
}

function getQueryParameter(parameterName: string, queryString: string): string | undefined {
    parameterName = parameterName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + parameterName + '=([^&#]*)');
    const regexResults = regex.exec(queryString);
    if (regexResults === null || !regexResults[1]) return undefined;
    const parameterValue = decodeURIComponent(regexResults[1].replace(/\+/g, ' '));
    if (parameterValue.length > 1) return parameterValue;
    return undefined;
}

function captureReferrer(): {
    referrer?: string;
    referringDomain?: string;
} {
    const referrer = document.referrer;
    if (referrer && referrer.length > 0) {
        let referringDomain: string | undefined = undefined;
        const parts = referrer.split('/');
        if (parts.length >= 3) referringDomain = parts[2];
        return { referrer, referringDomain };
    }
    return {};
}

async function setAttributionAmplitudeUserProperties(attributionParameters: AttributionParameters) {
    const allProperties: { [key: string]: string | undefined } = {
        utm_source: attributionParameters.utmSource,
        utm_medium: attributionParameters.utmMedium,
        utm_campaign: attributionParameters.utmCampaign,
        utm_term: attributionParameters.utmTerm,
        utm_content: attributionParameters.utmContent,
        gclid: attributionParameters.googleClickId,
        fbclid: attributionParameters.facebookClickId,
        referrer: attributionParameters.referrer,
        referring_domain: attributionParameters.referringDomain,
    };
    const filteredProperties: { [key: string]: string } = {};
    const initialProperties: { [key: string]: string } = {};
    for (const key of Object.keys(allProperties)) {
        const value = allProperties[key];
        if (value) {
            filteredProperties[key] = value;
            initialProperties['initial_' + key] = value;
        }
    }
    await setAmplitudeMarketingAttributionUserProperties({
        $set: filteredProperties,
        $setOnce: initialProperties,
    });
}

async function logAttributionEvents(client: ApolloClient, attributionParameters: AttributionParameters) {
    await logUserEventUtil(client, { type: 'attributionCaptured', payload: { ...attributionParameters } });
    await logAmplitudeEvent({
        name: '[Amplitude] Attribution Captured',
        properties: {
            utm_source: attributionParameters.utmSource,
            utm_medium: attributionParameters.utmMedium,
            utm_campaign: attributionParameters.utmCampaign,
            utm_term: attributionParameters.utmTerm,
            utm_content: attributionParameters.utmContent,
            gclid: attributionParameters.googleClickId,
            fbclid: attributionParameters.facebookClickId,
            referrer: attributionParameters.referrer,
            referring_domain: attributionParameters.referringDomain,
        },
    });
}
