import { InMemoryCacheConfig, defaultDataIdFromObject, InMemoryCache } from '@apollo/react-hooks';
import { CachePersistor } from 'apollo-cache-persist';

import { dataIdFromUser } from './fragments/user';
import { dataIdFromUserInAppContent } from './fragments/inAppContents';
import { dataIdFromUserOffer, dataIdFromMerchantOffer, dataIdFromOfferCondition } from './fragments/offers';
import { dataIdFromUserPoints } from './fragments/points';
import { dataIdFromUserTrackingConsent } from './fragments/trackingConsents';
import { dataIdFromUserBonus } from './fragments/userBonuses';
import { dataIdFromReward, dataIdFromUserReward } from './fragments/rewards';
import { dataIdFromUserState } from './fragments/states';
import { dataIdFromSetting } from './fragments/settings';
import { dataIdFromAppFeature } from './fragments/featuresAndStates';
import { dataIdFromEnrichedMerchantOffer, dataIdFromEnrichedUserOffer } from './fragments/enrichedOffers';
import { dataIdFromOfferBrowsingTag } from './fragments/offerBrowsingTags';
import { dataIdFromHistoryRecord } from './fragments/history';
import { dataIdFromLegalUpdate, dataIdFromLegalUpdatesToBeReviewed } from './fragments/legalUpdates';

const cacheOptions: InMemoryCacheConfig = {
    dataIdFromObject: (object: any) => {
        switch (object.__typename) {
            case 'User':
                return dataIdFromUser(object);
            case 'UserInAppContent':
                return dataIdFromUserInAppContent(object);
            case 'UserOffer':
                return dataIdFromUserOffer(object);
            case 'UserPoints':
                return dataIdFromUserPoints(object);
            case 'UserReward':
                return dataIdFromUserReward(object);
            case 'UserState':
                return dataIdFromUserState(object);
            case 'UserTrackingConsent':
                return dataIdFromUserTrackingConsent(object);
            case 'UserBonus':
                return dataIdFromUserBonus(object);
            case 'AppFeature':
                return dataIdFromAppFeature(object);
            case 'Setting':
                return dataIdFromSetting(object);
            case 'MerchantOffer':
                return dataIdFromMerchantOffer(object);
            case 'EnrichedMerchantOffer':
                return dataIdFromEnrichedMerchantOffer(object);
            case 'EnrichedUserOffer':
                return dataIdFromEnrichedUserOffer(object);
            case 'OfferCondition':
                return dataIdFromOfferCondition(object);
            case 'OfferBrowsingTag':
                return dataIdFromOfferBrowsingTag(object);
            case 'HistoryRecord':
                return dataIdFromHistoryRecord(object);
            case 'Reward':
                return dataIdFromReward(object);
            case 'LegalUpdate':
                return dataIdFromLegalUpdate(object);
            case 'LegalUpdatesToBeReviewed':
                return dataIdFromLegalUpdatesToBeReviewed(object);
            default:
                return defaultDataIdFromObject(object);
        }
    },
};

export const cache: InMemoryCache = new InMemoryCache(cacheOptions);

export async function setupCachePersistor() {
    const persistor = new CachePersistor({
        cache: cache as any,
        storage: localStorage as any,
    });
    await persistor.restore();
    const cacheSize: number | null = await persistor.getSize();
    console.log(`Cache restored (cache size: ${(cacheSize || 0) / 1000}kB)`);
}
