import * as React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import color from '../../style/color';
import { font } from '../../style/text';
import FloatingIcon from '../common/FloatingIcon';
import GradientBackground from '../common/GradientBackground';

const currencySymbol = { uri: '/assets/images/visualIdentity/currency-symbol-green.svg' };
const ticketIcon = { uri: '/assets/images/icons/ticket.svg' };
const giftIcon = { uri: '/assets/images/icons/gift-pink-tilted.svg' };

function LoadingFrame({
    text,
    height,
    width,
    isError,
}: {
    text: string;
    height?: number;
    width?: number;
    isError: boolean;
}) {
    return (
        <>
            <GradientBackground {...{ height, width }} />
            {!isError ? (
                <View style={[styles.containerFloatingIcons, { top: (height || 0) / 2 - 70 }]}>
                    <FloatingIcon icon={currencySymbol} />
                    <FloatingIcon icon={ticketIcon} offsetY={-10} />
                    <FloatingIcon icon={giftIcon} />
                </View>
            ) : null}
            <View style={[styles.containerText, { top: (height || 0) / 2 }, isError ? styles.containerTextError : {}]}>
                <Text style={[styles.text, isError ? styles.textError : {}]}>{text}</Text>
            </View>
        </>
    );
}

export default LoadingFrame;

const styles = StyleSheet.create({
    containerFloatingIcons: {
        position: 'absolute',
        flexDirection: 'row',
    },
    containerText: {
        position: 'absolute',
        alignItems: 'center',
        backgroundColor: color.whisper,
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderRadius: 8,
    },
    containerTextError: {
        backgroundColor: color.sauvignon,
    },
    text: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.black,
    },
    textError: {
        color: color.flamingo,
    },
});
