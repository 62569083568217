import * as React from 'react';
import { View, ViewStyle } from 'react-native';

export default function StrikeThroughContainer({
    children,
    style,
    lineWidth,
    lineColor,
    adjustLineHeight,
}: {
    children: null | JSX.Element | (JSX.Element | null)[];
    style: ViewStyle;
    lineWidth: number;
    lineColor: string;
    adjustLineHeight?: number;
}) {
    const [dimensions, setDimensions] = React.useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });
    return (
        <View
            {...{ style }}
            onLayout={(event) =>
                setDimensions({ width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height })
            }>
            {children}
            <View
                style={{
                    position: 'absolute',
                    height: lineWidth,
                    backgroundColor: lineColor,
                    width: dimensions.width,
                    top: dimensions.height / 2 - lineWidth + (adjustLineHeight ?? 0),
                }}
            />
        </View>
    );
}
