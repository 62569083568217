import * as React from 'react';
import { StyleSheet, Text, Linking } from 'react-native';

import { getLocalizedTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';

export function FloaTermsOfServices({
    buttonName,
    children,
}: {
    buttonName: string;
    children?: null | JSX.Element | JSX.Element[];
}) {
    const texts = getLocalizedTexts().home.bnplTermsOfService;
    return (
        <Text style={styles.textTerms}>
            {texts.terms({ buttonName })}
            <TermsTextWithLink text={` ${texts.floaTermsOfServices}`} link={'https://www.joko.com/fr/floa-tos/'} />
            <Text style={styles.textTerms}>, </Text>
            <TermsTextWithLink
                text={texts.floaConfidentialityPolicy}
                link={'https://www.joko.com/fr/floa-confidentiality-policy/'}
            />
            <Text style={styles.textTerms}>{` ${texts.and} `}</Text>
            <TermsTextWithLink text={texts.jokoTermsOfServices} link={'https://www.joko.com/fr/tos/'} />
            <Text style={styles.textTerms}>{` ${texts.and} `}</Text>
            <TermsTextWithLink
                text={`${texts.jokoConfidentialityPolicy}.`}
                link={'https://www.joko.com/fr/privacy-policy/'}
            />
            {children}
        </Text>
    );
}

function TermsTextWithLink({ text, link }: { text: String; link: string }) {
    return (
        <Text style={[styles.textTerms, { textDecorationLine: 'underline' }]} onPress={() => Linking.openURL(link)}>
            {text}
        </Text>
    );
}

const styles = StyleSheet.create({
    textTerms: {
        fontFamily: font.ambitRegular,
        fontWeight: '600',
        fontSize: 14,
        lineHeight: 16,
        color: color.black,
        textAlign: 'center',
        marginBottom: 20, // Ample space for readability
    },
});
