import * as React from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import * as _ from 'lodash';
import { useHistory } from 'react-router';

import { Reward, RewardType } from '../../api/graphql/fragments/rewards';
import { allRewardsQuery, AllRewardsQueryResponse } from '../../api/graphql/queries/rewards';
import { logUserEventUtil } from '../events/userEvents';

function useRewards(): Reward[] | undefined {
    const queryResponse = useQuery<AllRewardsQueryResponse, {}>(allRewardsQuery, {
        fetchPolicy: 'cache-and-network',
    });
    const rewards: Reward[] | undefined = React.useMemo(() => queryResponse.data?.rewards?.items, [queryResponse]);
    return rewards;
}

export function useRewardsByType(): { [type: string]: Reward[] | undefined } {
    const rewards: Reward[] | undefined = useRewards();
    const rewardsByType = React.useMemo(() => {
        const rewardsByType: { [type: string]: Reward[] | undefined } = {};
        if (rewards) {
            const sortedRewards = _.orderBy(rewards, ['points', 'displayOrder']);
            for (const reward of sortedRewards) {
                if (!rewardsByType[reward.type]) rewardsByType[reward.type] = [];
                rewardsByType[reward.type]?.push(reward);
            }
        }
        return rewardsByType;
    }, [rewards]);
    return rewardsByType;
}

export function useViewRewardDetails() {
    const apolloClient = useApolloClient();
    const history = useHistory();
    const viewRewardDetails = async (reward: Reward) => {
        const { rewardId, type } = reward;
        logUserEventUtil(apolloClient, { type: 'clickedReward', payload: { type, rewardId } });
        if (type === RewardType.bankTransfer) history.push(`/home/rewards/bank-transfer/${rewardId}`);
        else if (type === RewardType.giftCard) history.push(`/home/rewards/gift-card/${rewardId}`);
        else if (type === RewardType.donation) history.push(`/home/rewards/donation/${rewardId}`);
    };
    return viewRewardDetails;
}
