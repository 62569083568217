const gql = require('graphql-tag');

export const deleteUserMutation = gql`
    mutation {
        deleteUser
    }
`;

export interface DeleteUserMutationResponse {
    __typename: 'Mutation';
    userId: string | null;
}
