import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import { useHistory } from 'react-router';

import { getLocalizedTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { Reward } from '../../../api/graphql/fragments/rewards';
import { useReward } from './RewardDetailPage';

const cashMachineIllustration = { uri: '/assets/images/illustrations/cash-machine.svg' };

function BankTransferSuccessPage() {
    const texts = getLocalizedTexts().home.rewards.bankTransfer.success;
    const history = useHistory();
    const reward: Reward | undefined = useReward();
    return (
        <View style={styles.container}>
            <View style={styles.containerIllustration}>
                <Image source={cashMachineIllustration} style={styles.imageIllustration} />
            </View>
            <Text style={styles.textTitle}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.subtitle({ terms: reward?.bankTransferProperties?.terms || '' })}</Text>
            <TouchableOpacity onPress={() => history.push('/home/rewards')} style={{ flexDirection: 'row' }}>
                <View style={[styles.containerButton]}>
                    <Text style={styles.textButton}>{texts.button}</Text>
                </View>
            </TouchableOpacity>
        </View>
    );
}

export default BankTransferSuccessPage;

const styles = StyleSheet.create({
    container: {
        width: 450,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        justifyContent: 'center',
    },
    containerIllustration: {
        alignSelf: 'center',
    },
    containerButton: {
        marginTop: 24,
        flex: 1,
        height: 50,
        borderRadius: 60,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.caribbeanGreen,
    },
    textTitle: {
        marginHorizontal: 25,
        marginVertical: 16,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.tundora,
        textAlign: 'center',
    },
    textSubtitle: {
        marginHorizontal: 15,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.tundora,
        textAlign: 'center',
    },
    textButton: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.white,
    },
    imageIllustration: {
        width: 150,
        height: 150,
        resizeMode: 'contain',
    },
});
