import { makeVar } from '@apollo/react-hooks';
import type { Region } from '../../lib/auth/region';

export const sessionIdVar = makeVar<number | null>(null);
export const lastPointsCollectedAtVar = makeVar<number | null>(null);
export const hasPressedRedeemRewardVar = makeVar<boolean>(false);
export const lastUserAttributeVerificationActionAtVar = makeVar<number | null>(null);
export const lastLegalUpdateConsentedAtVar = makeVar<number | null>(null);
// The two variables below allow to properly handle query refetch
export const lastMinimalNonOngoingUserOffersQueryAtVar = makeVar<number | null>(null);
export const lastUserInAppContentsQueryQueryAtVar = makeVar<number | null>(null);
export const userRegionVar = makeVar<Region | undefined>(undefined);
