import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router';

import { User } from '../../api/graphql/fragments/user';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';

export function useOnboardingRedirection() {
    const history = useHistory();
    const user = useUser();
    useEffect(() => {
        if (user && !user.onboardedAt) {
            history.push('/onboarding/');
        }
    }, [user, history]);
}

export function useHomeRedirection() {
    const history = useHistory();
    const location = useLocation();
    const user = useUser();
    useEffect(() => {
        if (user && user.onboardedAt && location.pathname !== '/onboarding/end') history.push('/home');
    }, [user, history, location]);
}

function useUser(): User | undefined {
    const queryResponse = useQuery<UserQueryResponse>(userQuery, { fetchPolicy: 'network-only' });
    const user = React.useMemo(() => queryResponse?.data?.user, [queryResponse]);
    return user;
}
