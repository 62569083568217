import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Switch, Redirect, Route, useRouteMatch, useLocation } from 'react-router-dom';

import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { useWindowSize } from '../../style/utils';
import ModalContainer, { useModalLocationManager } from '../../components/common/ModalContainer';
import ProfilePanel from '../../pages/home/profile/ProfilePanel';
import UserInfoPage from '../../pages/home/profile/UserInfoPage';
import UserEmailPage from '../../pages/home/profile/UserEmailPage';
import UserEmailVerificationPage from '../../pages/home/profile/UserEmailVerificationPage';
import UserPhoneNumberPage from '../../pages/home/profile/UserPhoneNumberPage';
import UserPhoneNumberVerificationPage from '../../pages/home/profile/UserPhoneNumberVerificationPage';

const modalPathPrefixes = ['/user-info/email', '/user-info/phone-number'];

function ProfileNavigator() {
    const size = useWindowSize();
    const location = useLocation();
    let { path } = useRouteMatch();
    const [, lastNonModalLocation] = useModalLocationManager(modalPathPrefixes);
    return (
        <>
            <View style={styles.container}>
                <View style={[styles.containerPanel, { height: (size.height || 0) - NAVIGATION_BAR_HEIGHT }]}>
                    <ProfilePanel />
                </View>
                <View style={styles.containerContent}>
                    <Switch location={lastNonModalLocation}>
                        <Route exact path={path}>
                            <Redirect to={{ pathname: `${path}/user-info`, search: window.location.search }} />
                        </Route>
                        <Route exact path={`${path}/user-info`}>
                            <UserInfoPage />
                        </Route>
                    </Switch>
                </View>
            </View>
            <Switch location={location}>
                <Route exact path={`${path}/user-info/email`}>
                    <ModalContainer cannotClose>
                        <UserEmailPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/user-info/email/verification`}>
                    <ModalContainer cannotClose>
                        <UserEmailVerificationPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/user-info/phone-number`}>
                    <ModalContainer cannotClose>
                        <UserPhoneNumberPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/user-info/phone-number/verification`}>
                    <ModalContainer cannotClose>
                        <UserPhoneNumberVerificationPage />
                    </ModalContainer>
                </Route>
            </Switch>
        </>
    );
}

export default ProfileNavigator;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    containerPanel: {
        flex: 0.4,
    },
    containerContent: {
        flex: 0.6,
    },
});
