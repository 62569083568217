import * as React from 'react';
import { StyleSheet, TouchableOpacity, Image, StyleProp, ViewStyle, ImageStyle } from 'react-native';

import { MerchantOffer, OfferType } from '../../../api/graphql/fragments/offers';
import { EnrichedUserOffer } from '../../../api/graphql/fragments/enrichedOffers';
import { useUserOffer, useOnPressFavoriteOfferButton } from '../../../lib/offers/offers';

const heartIconPink = { uri: '/assets/images/icons/heart-pink-white-border.svg' };
const heartShapeIconWhite = { uri: '/assets/images/icons/heart-shape-white.svg' };

export default function FavoriteOfferButton({
    offer,
    style,
    iconStyle,
}: {
    offer: MerchantOffer | undefined;
    style?: StyleProp<ViewStyle>;
    iconStyle?: StyleProp<ImageStyle>;
}) {
    const userOffer: EnrichedUserOffer | undefined = useUserOffer(offer?.offerId, 'cache-first');
    const onPressFavoriteOfferButton = useOnPressFavoriteOfferButton(userOffer);
    if (!userOffer || offer?.type === OfferType.Ongoing) return null;
    return (
        <TouchableOpacity style={style} activeOpacity={0.8} onPress={onPressFavoriteOfferButton}>
            {userOffer.favorited ? (
                <Image source={heartIconPink} style={[styles.image, iconStyle]} />
            ) : (
                <Image source={heartShapeIconWhite} style={[styles.image, iconStyle]} />
            )}
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    image: {
        height: 22,
        width: 26,
    },
});
