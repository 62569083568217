import * as React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import Button from '../../../components/common/Button';

function UserEmailVerificationPage() {
    const texts = getLocalizedTexts().home.profile.userInfo.emailVerificationPage;
    const history = useHistory();
    React.useEffect(() => {
        Auth.verifyCurrentUserAttribute('email');
    }, []);
    return (
        <View style={styles.container}>
            <Text style={styles.textTitle}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.text}</Text>
            <Button
                onPress={() => history.push('/home/profile/user-info')}
                style={styles.containerButton}
                textStyle={styles.textButton}
                height={40}>
                {texts.button}
            </Button>
        </View>
    );
}

export default UserEmailVerificationPage;

const styles = StyleSheet.create({
    container: {
        width: 450,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        justifyContent: 'center',
    },
    containerButton: {
        marginTop: 24,
    },
    textTitle: {
        marginHorizontal: 25,
        marginBottom: 16,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textSubtitle: {
        marginHorizontal: 15,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.black,
        textAlign: 'center',
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
    },
});
