import * as React from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../Locales';
import { MarketingWebsitePath, getMarketingWebsiteUrl } from '../../lib/auth/region';
import color from '../../style/color';
import { font } from '../../style/text';
import {
    useSyncLocalAndRemoteTrackingConsents,
    getUserTrackingConsents,
    shouldDisplayTrackingModal,
    acceptAllTracking,
    rejectAllTracking,
} from '../../lib/common/trackingConsents';
import ModalContainer from './ModalContainer';
import TrackingPersonalizationModal from './TrackingPersonalizationModal';
import Button from './Button';

export function TrackingModal() {
    const apolloClient = useApolloClient();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isPersonalizationModalVisible, setIsPersonalizationModalVisible] = React.useState(false);
    const lastSyncingTimestamp: number | undefined = useSyncLocalAndRemoteTrackingConsents();
    const trackingConsents = React.useMemo(() => getUserTrackingConsents(), [lastSyncingTimestamp]); // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (lastSyncingTimestamp) setIsModalVisible(shouldDisplayTrackingModal(trackingConsents));
    }, [trackingConsents, lastSyncingTimestamp]);
    const onPersonalize = () => {
        setIsModalVisible(false);
        setIsPersonalizationModalVisible(true);
    };
    const onAccept = () => {
        setIsModalVisible(false);
        acceptAllTracking(apolloClient);
    };
    const onReject = () => {
        setIsModalVisible(false);
        rejectAllTracking(apolloClient);
    };
    return isModalVisible ? (
        <MainTrackingModal {...{ onPersonalize, onAccept, onReject }} />
    ) : isPersonalizationModalVisible ? (
        <TrackingPersonalizationModal {...{ trackingConsents, setIsModalVisible: setIsPersonalizationModalVisible }} />
    ) : null;
}

function MainTrackingModal({
    onPersonalize,
    onAccept,
    onReject,
}: {
    onPersonalize: () => void;
    onAccept: () => void;
    onReject: () => void;
}) {
    const texts = getLocalizedTexts().trackingConsents.trackingModal;
    return (
        <ModalContainer cannotClose={false} onClose={onReject}>
            <View style={styles.containerModal}>
                <View style={[styles.containerMessage, { paddingHorizontal: 40 }]}>
                    <Text style={styles.textMessage}>{texts.message}</Text>
                    <TouchableOpacity
                        onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy }))}>
                        <Text style={[styles.textMessage, { textDecorationLine: 'underline' }]}>
                            {texts.privacyPolicyLink}
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.containerDivider}></View>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 30 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <PersonalizeButton onPress={onPersonalize} />
                        <AcceptButton onPress={onAccept} />
                    </View>
                </View>
            </View>
        </ModalContainer>
    );
}

function PersonalizeButton({ onPress }: { onPress: () => void }) {
    const texts = getLocalizedTexts().trackingConsents.trackingModal;
    return (
        <Button
            onPress={onPress}
            textStyle={styles.textButton}
            height={40}
            useSecondaryColor
            style={{ paddingHorizontal: 20 }}>
            {texts.personalizeButton}
        </Button>
    );
}

function AcceptButton({ onPress }: { onPress: () => void }) {
    const texts = getLocalizedTexts().trackingConsents.trackingModal;
    return (
        <Button
            onPress={onPress}
            textStyle={styles.textButton}
            height={40}
            style={{
                backgroundColor: color.black,
                borderColor: color.black,
                paddingHorizontal: 20,
                marginLeft: 10,
            }}>
            {texts.acceptButton}
        </Button>
    );
}

export default TrackingModal;

const styles = StyleSheet.create({
    containerModal: {
        width: 600,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingVertical: 30,
        justifyContent: 'center',
    },
    containerDivider: {
        marginVertical: 15,
        borderBottomWidth: 1,
        borderBottomColor: color.linkWaterLight,
    },
    containerMessage: {
        flex: 1,
    },
    containerButton: {
        marginLeft: 15,
        paddingVertical: 6,
        paddingHorizontal: 14,
        borderRadius: 100,
    },
    containerButtonHovered: {
        opacity: 0.7,
    },
    textMessage: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
    },
    textButton: {
        fontSize: 14,
    },
});
