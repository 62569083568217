import * as React from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageURISource,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Modal,
} from 'react-native';
import { useLocation } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { logUserEventWithoutAuthentication } from '../../api/rest/events';
import { localizeAsset, LocalizedAsset, Region } from '../../lib/auth/region';
import { logAmplitudeEventWithoutAuthentication } from '../../lib/events/amplitudeEvents';
import {
    useShouldSeeInstallationFlowVersionB,
    UrlStep,
    useParseUrlParameters,
    setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage,
    InstallationStep,
    useLogEvents,
    useInstallationStep,
    NativeAuthorizationPopupState,
    useNativeAuthorizationPopupBackgroundDisplay,
    useRedirectToAppAtConclusionStepAfterTimeout,
    getCallbacks,
    useCloseObsoletePagesWhenSecondWaitingPageIsOpened,
    useActivationPreamblePageTestGroup,
} from '../../lib/mobileSafariExtension/mobileSafariExtension';
import { AbcTestGroup } from '../../lib/mobileSafariExtension/abTest';
import { browserKind, Browser } from '../../lib/common/browser';
import Button from '../../components/common/Button';
import ModalContainer from '../../components/common/ModalContainer';
import HighlightedText from '../../components/common/HighlightedText';

const logo = { uri: '/assets/images/logos/logo-with-title.svg' };
const widgetIllustrationLightGreenBackground = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-light-green.png',
};
const widgetIllustrationGreenBackground: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-green.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-green-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-green-us.png' },
};
const widgetIllustrationOrangeBackground: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-orange.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-orange-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-orange-us.png' },
};
const progressBar1 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-1.svg' };
const progressBar2 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-2.svg' };
const progressBar3 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-3.svg' };
const progressBar4 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-4.svg' };
const progressBar5 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-5.svg' };
const instructionScreenshot1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-1.png',
};
const instructionScreenshot2: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-2.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-2-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-2-us.png' },
};
const instructionScreenshot3: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-3.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-3-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-3-us.png' },
};
const instructionScreenshot4: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-4.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-4-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-4-us.png' },
};
const instructionScreenshot5: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-5.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-5-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-5-us.png' },
};
const instructionScreenshot6: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-6.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-6-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-6-us.png' },
};
const instructionSettingsScreenshot1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-settings-1.png',
};
const instructionSettingsScreenshot2 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-settings-2.png',
};
const instructionBannerIllustration1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-banner-1.png',
};
const instructionBannerIllustration2 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-banner-2.png',
};
const instructionBannerIllustration3 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-banner-3.png',
};
/** This is the first step illustration, for both the activation and authorization instructions */
const instructionIllustrationVersionBActivationStep1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-instruction-activation-1.svg',
};
const instructionIllustrationVersionBActivationStep2 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-instruction-activation-2.svg',
};
const instructionIllustrationVersionBActivationStep3 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-instruction-activation-3.svg',
};
const instructionIllustrationVersionBAuthorizationStep2 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-instruction-authorization-2.svg',
};
const instructionIllustrationVersionBAuthorizationStep3 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-instruction-authorization-3.svg',
};
const instructionIllustrationVersionBAuthorizationStep4 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-instruction-authorization-4.svg',
};
const warningIllustration: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/warning.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/warning-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/warning-us.png' },
};
const greenChevronWithRoundBackground = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/chevron-up-green-with-background.png',
};
const confettiAnimation = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/confettiAnimation.gif' };

const checkMarkIcon = { uri: '/assets/images/icons/check-mark.svg' };
const puzzlePieceIcon = { uri: '/assets/images/icons/puzzle-piece.svg' };
const safariJokoIcon = { uri: '/assets/images/icons/safari-joko-icon-gray.svg' };
const arrowRightIcon = { uri: '/assets/images/icons/arrow-right-green.svg' };
const extensionIcon = { uri: '/assets/images/icons/mobile-safari-extension-icon.svg' };
const clockIcon = { uri: '/assets/images/icons/clock-green.svg' };

function MobileSafariExtensionInstallationPage() {
    const location = useLocation();
    const urlParameters = useParseUrlParameters(location);
    useRedirectToAppAtConclusionStepAfterTimeout(urlParameters);
    const shouldSeeInstallationFlowVersionB = useShouldSeeInstallationFlowVersionB(urlParameters);
    if (browserKind !== Browser.safari && !urlParameters.isDevStackMode)
        return <WrongBrowserScreen {...{ ...urlParameters, browserKind }} />;
    if (shouldSeeInstallationFlowVersionB === undefined) return null;
    return <MobileSafariExtensionInstallationPageContent {...{ ...urlParameters, shouldSeeInstallationFlowVersionB }} />;
}

export default MobileSafariExtensionInstallationPage;

function MobileSafariExtensionInstallationPageContent({
    urlStep,
    isDevStackMode,
    userId,
    deviceId,
    isOnboarding,
    isBeforeOnboarding,
    isFlowWithSystemSettings,
    shouldSeeInstallationFlowVersionB,
    region,
}: {
    urlStep: UrlStep;
    isDevStackMode: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    isFlowWithSystemSettings: boolean;
    shouldSeeInstallationFlowVersionB: boolean;
    region: Region;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    const activationPreamblePageTestGroup = useActivationPreamblePageTestGroup({
        isBeforeOnboarding,
        userId,
        deviceId,
        region,
    });
    const [step, setStep] = useInstallationStep(urlStep, userId, isDevStackMode, activationPreamblePageTestGroup);
    const [isTosConsentNeeded, setIsTosConsentNeeded] = React.useState<boolean>(isBeforeOnboarding);
    useLogEvents(urlStep, userId, deviceId, isDevStackMode, isOnboarding, isBeforeOnboarding, isFlowWithSystemSettings);
    const {
        onPressStartActivation,
        onPressStartAuthorization,
        onPressCloseMissingAllWebsitesAuthorizationModal,
        onPressModifyAuthorization,
        onPressReturnToApp,
    } = getCallbacks({
        step,
        setStep,
        userId,
        deviceId,
        isDevStackMode,
        isOnboarding,
        isBeforeOnboarding,
        isFlowWithSystemSettings,
    });
    useCloseObsoletePagesWhenSecondWaitingPageIsOpened(step);
    const { nativeAuthorizationPopupState, shouldShowNativeAuthorizationPopupBackgroundModal } =
        useNativeAuthorizationPopupBackgroundDisplay({
            userId,
            deviceId,
            region,
            step,
            isFlowWithSystemSettings,
            isDevStackMode,
        });
    const isActivationPreamblePageTestGroupB = activationPreamblePageTestGroup === AbcTestGroup.B;
    if (activationPreamblePageTestGroup === undefined) return null;
    if (
        step === InstallationStep.firstWaitingStep ||
        step === InstallationStep.secondWaitingStep ||
        step === InstallationStep.genericWaitingPage
    )
        return <WaitingScreen />;
    return (
        <View style={styles.container}>
            <Header {...{ step }} />
            {step === InstallationStep.activateStepPreamble && isActivationPreamblePageTestGroupB ? (
                <ActivationPreambleBanner />
            ) : null}
            <Illustration {...{ step, isActivationPreamblePageTestGroupB }} />
            <Title {...{ step, isBeforeOnboarding }} />
            {step === InstallationStep.activateStepPreamble ? (
                <View style={isActivationPreamblePageTestGroupB ? styles.containerButtonsAnchored : styles.containerButtons}>
                    <TosConsentButton
                        {...{
                            isBeforeOnboarding,
                            isTosConsentNeeded,
                            setIsTosConsentNeeded,
                            isDevStackMode,
                            userId,
                            deviceId,
                        }}
                    />
                    <Button onPress={onPressStartActivation} disabled={isTosConsentNeeded} textStyle={styles.textButton}>
                        {texts.activateStep.preamble.startButton}
                    </Button>
                </View>
            ) : step === InstallationStep.authorizeStepPreamble ? (
                <View style={styles.containerButtons}>
                    <Button onPress={onPressStartAuthorization} textStyle={styles.textButton}>
                        {texts.authorizeStep.preamble.startButton}
                    </Button>
                </View>
            ) : step === InstallationStep.activateStep ? (
                <ActivationInstructions {...{ shouldSeeInstallationFlowVersionB }} />
            ) : step === InstallationStep.authorizeStep ||
              step === InstallationStep.missingAllWebsitesAuthorization ||
              step === InstallationStep.missingAllWebsitesAuthorizationAfterRetry ||
              step === InstallationStep.authorizeWithBannerStep ? (
                <AuthorizationInstructions
                    {...{
                        step,
                        setStep,
                        userId,
                        deviceId,
                        isDevStackMode,
                        isFlowWithSystemSettings,
                        shouldSeeInstallationFlowVersionB,
                    }}
                />
            ) : null}
            {step === InstallationStep.missingAllWebsitesAuthorization ||
            step === InstallationStep.missingAllWebsitesAuthorizationAfterRetry ? (
                <MissingAllWebsitesAuthorizationModal
                    {...{
                        hasAlreadyRetried: step === InstallationStep.missingAllWebsitesAuthorizationAfterRetry,
                        onPressCloseMissingAllWebsitesAuthorizationModal,
                    }}
                />
            ) : step === InstallationStep.missingAuthorization ? (
                <View style={styles.containerButtons}>
                    <Button onPress={onPressModifyAuthorization} textStyle={styles.textButton}>
                        {texts.missingAuthorization.default.button}
                    </Button>
                </View>
            ) : null}
            {step === InstallationStep.conclusionStep ? (
                <View style={styles.containerButtons}>
                    <Button onPress={onPressReturnToApp} textStyle={styles.textButton}>
                        {isBeforeOnboarding ? texts.conclusion.connectInAppButton : texts.backToAppButton}
                    </Button>
                </View>
            ) : null}
            {nativeAuthorizationPopupState !== NativeAuthorizationPopupState.noPopup &&
            shouldShowNativeAuthorizationPopupBackgroundModal ? (
                <NativeAuthorizationPopupBackgroundModal {...{ nativeAuthorizationPopupState }} />
            ) : null}
        </View>
    );
}

function Header({ step }: { step: InstallationStep }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    return (
        <View style={styles.containerHeader}>
            <Image source={logo} style={styles.imageLogo} />
            {step !== InstallationStep.conclusionStep ? (
                <>
                    <Text style={styles.textTitle}>{texts.header.title}</Text>
                    <ProgressBar {...{ step }} />
                </>
            ) : null}
        </View>
    );
}

function ProgressBar({ step }: { step: InstallationStep }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    const progressBar = { uri: '' };
    switch (step) {
        case InstallationStep.activateStepPreamble:
            progressBar.uri = progressBar1.uri;
            break;
        case InstallationStep.activateStep:
            progressBar.uri = progressBar2.uri;
            break;
        case InstallationStep.authorizeStepPreamble:
            progressBar.uri = progressBar3.uri;
            break;
        case InstallationStep.authorizeStep:
        case InstallationStep.authorizeWithBannerStep:
            progressBar.uri = progressBar4.uri;
            break;
        case InstallationStep.missingAllWebsitesAuthorization:
        case InstallationStep.missingAllWebsitesAuthorizationAfterRetry:
        case InstallationStep.missingAuthorization:
            progressBar.uri = progressBar5.uri;
            break;
    }
    const leftTextStyle =
        step !== InstallationStep.activateStepPreamble ? styles.textProgressBar : styles.textProgressBarGray;
    const rightTextStyle =
        step === InstallationStep.authorizeStep ||
        step === InstallationStep.authorizeWithBannerStep ||
        step === InstallationStep.conclusionStep
            ? styles.textProgressBar
            : styles.textProgressBarGray;
    if (!progressBar.uri) return null;
    return (
        <View style={{ alignItems: 'center' }}>
            <Image source={progressBar} style={styles.imageProgressBar} />
            <View style={{ flexDirection: 'row', width: normalizeWidth(210) }}>
                <View style={{ width: normalizeWidth(105) }}>
                    <Text style={leftTextStyle}>{texts.header.left}</Text>
                </View>
                <View style={{ width: normalizeWidth(105), paddingLeft: 5 }}>
                    <Text style={rightTextStyle}>{texts.header.right}</Text>
                </View>
            </View>
        </View>
    );
}

function ActivationPreambleBanner() {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.activateStep.preamble;
    return (
        <View style={styles.containerActivationPreambleBanner}>
            <Image style={styles.imageActivationPreambleBanner} source={clockIcon} />
            <Text style={styles.textActivationPreambleBanner}>{texts.banner}</Text>
        </View>
    );
}

function Illustration({
    step,
    isActivationPreamblePageTestGroupB,
}: {
    step: InstallationStep;
    isActivationPreamblePageTestGroupB: boolean;
}) {
    const illustration: ImageURISource = { uri: '' };
    switch (step) {
        case InstallationStep.activateStepPreamble:
            illustration.uri = isActivationPreamblePageTestGroupB
                ? widgetIllustrationLightGreenBackground.uri
                : localizeAsset(widgetIllustrationGreenBackground).uri;
            break;
        case InstallationStep.authorizeStepPreamble:
            illustration.uri = localizeAsset(widgetIllustrationGreenBackground).uri;
            break;
        case InstallationStep.conclusionStep:
            illustration.uri = confettiAnimation.uri;
            break;
        case InstallationStep.missingAuthorization:
            illustration.uri = localizeAsset(widgetIllustrationOrangeBackground).uri;
    }
    if (!illustration.uri) return null;
    const imageStyle =
        step === InstallationStep.activateStepPreamble && isActivationPreamblePageTestGroupB
            ? styles.imageIllustrationPreambleVersionB
            : styles.imageIllustration;
    const containerStyle =
        step === InstallationStep.activateStepPreamble && isActivationPreamblePageTestGroupB
            ? styles.containerIllustrationPreambleVersionB
            : styles.containerIllustration;
    return (
        <View style={containerStyle}>
            <Image source={illustration} style={imageStyle} />
        </View>
    );
}

function Title({ step, isBeforeOnboarding }: { step: InstallationStep; isBeforeOnboarding: boolean }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    if (
        step !== InstallationStep.authorizeStepPreamble &&
        step !== InstallationStep.conclusionStep &&
        step !== InstallationStep.missingAuthorization
    )
        return null;
    const title =
        step === InstallationStep.authorizeStepPreamble
            ? texts.authorizeStep.preamble.title
            : step === InstallationStep.conclusionStep
            ? texts.conclusion.title
            : texts.missingAuthorization.default.title;
    const subtitle =
        step === InstallationStep.authorizeStepPreamble
            ? texts.authorizeStep.preamble.subtitle
            : step === InstallationStep.conclusionStep
            ? isBeforeOnboarding
                ? texts.conclusion.connectInAppSubtitle
                : texts.conclusion.subtitle
            : texts.missingAuthorization.default.subtitle;
    return (
        <View style={styles.containerTitle}>
            <Text style={styles.textTitle}>{title}</Text>
            <Text style={styles.textSubtitle}>{subtitle} </Text>
        </View>
    );
}

function TosConsentButton({
    isBeforeOnboarding,
    isTosConsentNeeded,
    setIsTosConsentNeeded,
    isDevStackMode,
    userId,
    deviceId,
}: {
    isBeforeOnboarding: boolean;
    isTosConsentNeeded: boolean;
    setIsTosConsentNeeded: React.Dispatch<React.SetStateAction<boolean>>;
    isDevStackMode: boolean;
    userId?: string;
    deviceId?: string;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.activateStep.preamble;
    const termsOfServiceUrl = 'joko://TermsOfService';
    const privacyPolicyUrl = 'joko://PrivacyPolicy';
    const onPressConsent = () => {
        // We store the consent in the local storage so that it can be accessed by the mobile Safari extension's content script
        // We also store the associated 'isDevStackMode' flag so that it can be accessed and used by the mobile Safari extension
        setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage(isDevStackMode);
        setIsTosConsentNeeded(false);
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Consent ToS And PP',
            },
            { userId, deviceId }
        );
    };
    const viewTermsOfService = () => {
        window.location.href = termsOfServiceUrl;
    };
    const viewPrivacyPolicy = () => {
        window.location.href = privacyPolicyUrl;
    };
    if (!isBeforeOnboarding) return null;
    return (
        <TouchableOpacity style={styles.containerTosConsentButton} onPress={onPressConsent} activeOpacity={0.7}>
            <View
                style={[
                    styles.containerCheckMark,
                    isTosConsentNeeded ? styles.containerCheckMarkUnselected : styles.containerCheckMarkSelected,
                ]}>
                <Image source={checkMarkIcon} style={styles.imageCheckMarkIcon} />
            </View>
            <HighlightedText
                style={styles.textTosConsentButton}
                highlightedStyle={styles.textTosConsentButtonHighlighted}
                onPressHighlightedTexts={[viewTermsOfService, viewPrivacyPolicy]}>
                {texts.tosConsent}
            </HighlightedText>
        </TouchableOpacity>
    );
}

function ActivationInstructions({ shouldSeeInstallationFlowVersionB }: { shouldSeeInstallationFlowVersionB: boolean }) {
    if (shouldSeeInstallationFlowVersionB) return <ActivationInstructionsVersionB />;
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.activateStep.instructions.versionA;
    return (
        <View style={styles.containerInstructions}>
            <Text style={styles.textInstructionTitle}>{texts.title}</Text>
            <View style={styles.containerSeparator} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={{ textAlign: 'center' }}>
                    <Text style={styles.textInstruction}>{texts.firstStep.title1}</Text>
                    <Image style={styles.imageInstructionIcon} source={extensionIcon} />
                    <Text style={styles.textInstruction}>{texts.firstStep.title2}</Text>
                </Text>
                <InstructionImage {...instructionScreenshot1} />
                <View style={{ marginHorizontal: 20, alignItems: 'center' }}>
                    <Text style={styles.textInstructionGray}>{texts.firstStep.subtitle}</Text>
                </View>
                <Text style={styles.textInstruction}>{texts.secondStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot2)} />
                <Text style={styles.textInstruction}>{texts.thirdStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot3)} />
            </View>
        </View>
    );
}

function ActivationInstructionsVersionB() {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.activateStep.instructions.versionB;
    const contentArray = [
        { text: texts.firstStep, icon: extensionIcon, illustration: instructionIllustrationVersionBActivationStep1 },
        { text: texts.secondStep, icon: puzzlePieceIcon, illustration: instructionIllustrationVersionBActivationStep2 },
        { text: texts.thirdStep, icon: undefined, illustration: instructionIllustrationVersionBActivationStep3 },
    ];
    return (
        <View style={styles.containerInstructions}>
            <View style={styles.containerInstructionsDetails}>
                {contentArray.map((stepContent, index) => {
                    return (
                        <View key={index} style={{ marginBottom: normalizeHeight(5) }}>
                            <Text style={styles.textInstructionBoldVersionB}>
                                {stepContent.text}{' '}
                                {stepContent.icon ? (
                                    <Image style={styles.imageInstructionIcon} source={stepContent.icon} />
                                ) : null}
                            </Text>
                            <Image source={stepContent.illustration} style={styles.imageInstructionVersionB} />
                        </View>
                    );
                })}
            </View>
        </View>
    );
}

function AuthorizationInstructions({
    step,
    setStep,
    userId,
    deviceId,
    isDevStackMode,
    isFlowWithSystemSettings,
    shouldSeeInstallationFlowVersionB,
}: {
    step: InstallationStep;
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
    isFlowWithSystemSettings: boolean;
    shouldSeeInstallationFlowVersionB: boolean;
}) {
    if (step === InstallationStep.authorizeWithBannerStep)
        return <BannerAuthorizationInstructions {...{ setStep, userId, deviceId, isDevStackMode }} />;
    if (isFlowWithSystemSettings)
        return <SystemSettingsAuthorizationInstructions {...{ userId, deviceId, isDevStackMode }} />;
    if (shouldSeeInstallationFlowVersionB) return <AuthorizationInstructionsVersionB />;
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructions.versionA;
    return (
        <View style={styles.containerAuthorizationInstructions}>
            <Text style={styles.textInstructionTitle}>{texts.title}</Text>
            <View style={styles.containerSeparator} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={{ textAlign: 'center' }}>
                    <Text style={styles.textInstruction}>{texts.firstStep.title1}</Text>
                    <Image style={styles.imageInstructionIcon} source={extensionIcon} />
                    <Text style={styles.textInstruction}>{texts.firstStep.title2}</Text>
                </Text>
                <InstructionImage {...instructionScreenshot1} />
                <View style={{ marginHorizontal: 20, alignItems: 'center' }}>
                    <Text style={styles.textInstructionGray}>{texts.firstStep.subtitle}</Text>
                </View>
                <Text style={styles.textInstruction}>{texts.secondStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot4)} />
                <div id="alwaysAllowPopupInstruction" /> {/* This empty div is used as a scrolling tag */}
                <Text style={styles.textInstruction}>{texts.thirdStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot5)} />
                <div id="allowOnAllWebsitesPopupInstruction" /> {/* This empty div is used as a scrolling tag */}
                <Text style={styles.textInstruction}>{texts.fourthStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot6)} />
            </View>
        </View>
    );
}

function AuthorizationInstructionsVersionB() {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructions.versionB;
    const contentArray = [
        { text: texts.firstStep, icon: extensionIcon, illustration: instructionIllustrationVersionBActivationStep1 },
        { text: texts.secondStep, icon: safariJokoIcon, illustration: instructionIllustrationVersionBAuthorizationStep2 },
        { text: texts.thirdStep, icon: undefined, illustration: instructionIllustrationVersionBAuthorizationStep3 },
        { text: texts.fourthStep, icon: undefined, illustration: instructionIllustrationVersionBAuthorizationStep4 },
    ];
    return (
        <View style={styles.containerAuthorizationInstructions}>
            <View style={styles.containerInstructionsDetails}>
                {contentArray.map((stepContent, index) => {
                    return (
                        <View key={index} style={{ marginBottom: normalizeHeight(5) }}>
                            <Text style={styles.textInstructionBoldVersionB}>
                                {stepContent.text}{' '}
                                {stepContent.icon ? (
                                    <Image style={styles.imageInstructionIcon} source={stepContent.icon} />
                                ) : null}
                            </Text>
                            <Image source={stepContent.illustration} style={styles.imageInstructionVersionB} />
                        </View>
                    );
                })}
            </View>
        </View>
    );
}

function BannerAuthorizationInstructions({
    setStep,
    userId,
    deviceId,
    isDevStackMode,
}: {
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
}) {
    const onPressMissingAuthorizationBanner = () => {
        setStep(InstallationStep.authorizeStep);
        if (userId)
            logUserEventWithoutAuthentication(
                userId,
                { type: 'clickedMissingMobileSafariExtensionAuthorizationBannerButton' },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Missing Authorization Banner Button',
            },
            { userId, deviceId }
        );
    };
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructionsWithBanner;
    return (
        <View style={styles.containerAuthorizationInstructions}>
            <View style={{ height: normalizeHeight(10) }} />
            <Text style={styles.textBannerInstruction}>{texts.firstStep}</Text>
            <Image source={instructionBannerIllustration1} style={[styles.imageBannerInstruction1, { marginBottom: 0 }]} />
            <div id="missingBannerButton" /> {/* This empty div is used as a tag */}
            <TouchableOpacity
                onPress={onPressMissingAuthorizationBanner}
                activeOpacity={0.7}
                style={styles.containerMissingBannerButton}>
                <Text style={styles.textMissingAuthorizationBannerButton}>{texts.missingBannerButton}</Text>
                <Image source={arrowRightIcon} style={styles.imageArrowRightIcon} />
            </TouchableOpacity>
            <Text style={styles.textBannerInstruction}>{texts.secondStep}</Text>
            <Image source={instructionBannerIllustration2} style={styles.imageBannerInstruction2} />
            <Text style={styles.textBannerInstruction}>{texts.thirdStep}</Text>
            <Image source={instructionBannerIllustration3} style={styles.imageBannerInstruction2} />
            <WigglingArrowModal {...{ onPressMissingAuthorizationBanner }} />
        </View>
    );
}

// This modal displays a wiggling arrow animation, at a fixed position close to the top-right corner of the screen
// Since the modal covers the underlying screen, we duplicate the touchable CTA in the modal, at the appropriate position
function WigglingArrowModal({ onPressMissingAuthorizationBanner }: { onPressMissingAuthorizationBanner: () => void }) {
    const [animationTarget, setAnimationTarget] = React.useState(0); // This variable switches between 0 and 1
    const [animation, setAnimation] = React.useState(new Animated.Value(0)); // This animated variable is translated from the previous target to the new target
    const arrowTopPosition = animation.interpolate({ inputRange: [0, 1], outputRange: [5, 20] });
    const moveArrowTo = (newAnimationTarget: number) => {
        Animated.timing(animation, { toValue: newAnimationTarget, duration: 600, useNativeDriver: true }).start(() => {
            setAnimationTarget(1 - animationTarget); // At the end of a translation, we start a new translation in the opposite direction
        });
    };
    React.useEffect(() => {
        moveArrowTo(animationTarget);
    }, [animationTarget]);
    const missingBannerButtonTop = document.getElementById('missingBannerButton')?.getBoundingClientRect()?.top;
    return (
        <Modal transparent={true}>
            <Animated.View style={{ top: arrowTopPosition, right: normalizeWidth(25), position: 'absolute' }}>
                <View>
                    <Image source={greenChevronWithRoundBackground} style={styles.imageChevronIcon} />
                </View>
            </Animated.View>
            {!!missingBannerButtonTop ? (
                <TouchableOpacity
                    style={[styles.containerClickablePlaceholder, { top: missingBannerButtonTop }]}
                    onPress={onPressMissingAuthorizationBanner}
                />
            ) : null}
        </Modal>
    );
}

function NativeAuthorizationPopupBackgroundModal({
    nativeAuthorizationPopupState,
}: {
    nativeAuthorizationPopupState: NativeAuthorizationPopupState;
}) {
    if (nativeAuthorizationPopupState === NativeAuthorizationPopupState.noPopup) return null;
    // The modal is embedded in an empty view, which adds white space at the bottom of the screen to allow the scrolling mechanism to work
    // In order to limit this white space to the minimum, its height is equal to the window height minus the size of the last instruction element
    const bottomPaddingHeight = height - (40 + normalizeWidth(120) + normalizeHeight(50));
    const isAlwaysAllowPopup = nativeAuthorizationPopupState === NativeAuthorizationPopupState.alwaysAllowPopup;
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructionsNativePopupBackground;
    return (
        <View style={{ height: bottomPaddingHeight }}>
            {nativeAuthorizationPopupState !== NativeAuthorizationPopupState.popupClosed ? (
                <Modal transparent={true}>
                    <View
                        style={[
                            styles.containerNativeAuthorizationPopupBackgroundModal,
                            { height: isAlwaysAllowPopup ? normalizeHeight(40) : normalizeHeight(52) },
                        ]}>
                        <Text style={styles.textNativePopupBackgroundEmoji}>{texts.handEmoji}</Text>
                        <Text style={styles.textNativePopupBackground}>
                            {isAlwaysAllowPopup ? texts.alwaysAllowPopup : texts.allowOnAllWebsitesPopup}
                        </Text>
                    </View>
                </Modal>
            ) : null}
        </View>
    );
}

function SystemSettingsAuthorizationInstructions({
    userId,
    deviceId,
    isDevStackMode,
}: {
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
}) {
    const onPressOpenSystemSettings = () => {
        if (userId)
            logUserEventWithoutAuthentication(
                userId,
                { type: 'clickedOpenSystemSettingsMobileSafariExtensionInstallationFlow' },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Open System Settings',
            },
            { userId, deviceId }
        );
        window.location.replace('joko://MobileSafariExtensionSystemSettingsRedirection/');
    };
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructionsWithSystemSettings;
    return (
        <View style={styles.containerAuthorizationInstructions}>
            <Text style={styles.textInstructionTitleFlowWithSystemSettings}>{texts.title}</Text>
            <View style={styles.containerSeparator} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={styles.textInstruction}>{texts.firstStep}</Text>
                <Text style={styles.textInstruction}>{texts.secondStep}</Text>
                <InstructionImage {...instructionSettingsScreenshot1} />
                <Text style={styles.textInstruction}>{texts.thirdStep}</Text>
                <InstructionImage {...instructionSettingsScreenshot2} />
            </View>
            <View style={[styles.containerButtons, styles.containerButtonFlowWithSystemSettings]}>
                <Button onPress={onPressOpenSystemSettings} textStyle={styles.textButton}>
                    {texts.openSystemSettingsButton}
                </Button>
            </View>
        </View>
    );
}

function InstructionImage(source: ImageURISource) {
    return (
        <View style={styles.containerImageInstruction}>
            <Image source={source} style={styles.imageInstruction} />
        </View>
    );
}

function MissingAllWebsitesAuthorizationModal({
    hasAlreadyRetried,
    onPressCloseMissingAllWebsitesAuthorizationModal,
}: {
    hasAlreadyRetried: boolean;
    onPressCloseMissingAllWebsitesAuthorizationModal: (hasUserClickedModifyAuthorization: boolean) => void;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.missingAuthorization;
    const onPressCloseModal = () => onPressCloseMissingAllWebsitesAuthorizationModal(false);
    const onPressModifyAuthorizationModal = () => onPressCloseMissingAllWebsitesAuthorizationModal(true);
    return (
        <ModalContainer cannotClose={false} onClose={onPressCloseModal} shouldCrossBeDiscrete>
            <View style={styles.containerMissingAllWebsitesAuthorizationModal}>
                <Image source={localizeAsset(warningIllustration)} style={styles.imageModal} />
                <Text style={styles.textMissingAllWebsitesAuthorizationModalTitle}>
                    {hasAlreadyRetried ? texts.allWebsitesAfterRetry.title : texts.allWebsitesBeforeRetry.title}
                </Text>
                <Text style={styles.textMissingAllWebsitesAuthorizationModalSubtitle}>
                    {hasAlreadyRetried ? texts.allWebsitesAfterRetry.subtitle : texts.allWebsitesBeforeRetry.subtitle}
                </Text>
                {!hasAlreadyRetried ? (
                    <View style={styles.containerMissingAllWebsitesAuthorizationModalButton}>
                        <Button
                            onPress={onPressModifyAuthorizationModal}
                            textStyle={styles.textButton}
                            height={normalizeHeight(40)}>
                            {texts.allWebsitesBeforeRetry.button}
                        </Button>
                    </View>
                ) : null}
            </View>
        </ModalContainer>
    );
}

function WrongBrowserScreen({
    userId,
    deviceId,
    isDevStackMode,
    browserKind,
    isOnboarding,
    isBeforeOnboarding,
}: {
    userId?: string;
    deviceId?: string;
    isDevStackMode: boolean;
    browserKind: Browser;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
}) {
    React.useEffect(() => {
        if (userId)
            logUserEventWithoutAuthentication(
                userId,
                {
                    type: 'viewedWrongBrowserPageMobileSafariExtension',
                    payload: { browserKind, isOnboarding, isBeforeOnboarding },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Viewed Wrong Browser Page',
                properties: { browserKind, isOnboarding, isBeforeOnboarding },
            },
            { userId, deviceId }
        );
    }, []);
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.wrongBrowser;
    return (
        <View style={styles.container}>
            <Image source={logo} style={[styles.imageLogo, { marginBottom: normalizeHeight(40) }]} />
            <View style={styles.containerIllustration}>
                <Image source={localizeAsset(widgetIllustrationOrangeBackground)} style={styles.imageIllustration} />
            </View>
            <View style={[styles.containerTitle, { marginTop: normalizeHeight(25) }]}>
                <Text style={[styles.textTitle, { marginBottom: normalizeHeight(20) }]}>{texts.title}</Text>
                <Text style={styles.textSubtitle}>{texts.subtitle} </Text>
            </View>
        </View>
    );
}

function WaitingScreen() {
    return (
        <View style={styles.containerWaitingScreen}>
            <ActivityIndicator size="large" color={color.alto} />
        </View>
    );
}

const { height } = Dimensions.get('window');
const DEVICE_HEIGHT_RATIO = Dimensions.get('window').height / 548;
const DEVICE_WIDTH_RATIO = Dimensions.get('window').width / 300;
const IS_SMALL_SCREEN = height < 800;

function normalizeHeight(size: number, ratioEffect: number = 1, maxSize?: number): number {
    const normalizedSize = Math.round(((DEVICE_HEIGHT_RATIO - 1) * ratioEffect + 1) * size);
    if (maxSize) return Math.min(normalizedSize, maxSize);
    else return normalizedSize;
}

function normalizeWidth(size: number, ratioEffect: number = 1, maxSize?: number): number {
    const normalizedSize = Math.round(((DEVICE_WIDTH_RATIO - 1) * ratioEffect + 1) * size);
    if (maxSize) return Math.min(normalizedSize, maxSize);
    else return normalizedSize;
}

const styles = StyleSheet.create({
    container: {
        height,
        alignItems: 'center',
        paddingTop: 40,
    },
    containerTitle: {
        marginTop: normalizeHeight(15),
        marginHorizontal: 25,
    },
    containerActivationPreambleBanner: {
        backgroundColor: color.hintOfGreen,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 10,
        marginTop: normalizeHeight(25),
        marginHorizontal: normalizeWidth(20),
        paddingHorizontal: normalizeWidth(10),
        paddingVertical: normalizeWidth(10),
    },
    containerIllustration: {
        marginVertical: IS_SMALL_SCREEN ? normalizeHeight(8) : normalizeHeight(16),
    },
    containerIllustrationPreambleVersionB: {
        marginVertical: normalizeHeight(25),
    },
    containerButtons: {
        marginTop: IS_SMALL_SCREEN ? 10 : 20,
        width: '100%',
        paddingHorizontal: 20,
        rowGap: IS_SMALL_SCREEN ? 10 : 15,
    },
    containerButtonsAnchored: {
        width: '100%',
        paddingHorizontal: normalizeWidth(20),
        rowGap: IS_SMALL_SCREEN ? 10 : 15,
        position: 'absolute',
        bottom: 20,
    },
    containerButtonFlowWithSystemSettings: {
        paddingBottom: '10px',
    },
    containerHeader: {
        alignItems: 'center',
        marginBottom: 10,
    },
    containerSeparator: {
        width: '80%',
        borderBottomColor: color.frenchGray,
        borderBottomWidth: 0.2,
        marginVertical: 5,
    },
    containerInstructions: {
        flex: 1,
        marginHorizontal: 40,
        alignItems: 'center',
    },
    containerAuthorizationInstructions: {
        flex: 1,
        marginHorizontal: 40,
        alignItems: 'center',
        marginBottom: normalizeHeight(50),
    },
    containerClickablePlaceholder: {
        position: 'absolute',
        height: normalizeWidth(15),
        width: '100%',
        outlineStyle: 'none',
    },
    containerInstructionsDetails: {
        alignItems: 'center',
        paddingVertical: 20,
    },
    containerNativeAuthorizationPopupBackgroundModal: {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: color.hintOfGreen,
        width: '100%',
        shadowRadius: normalizeHeight(15),
        shadowColor: color.black,
        shadowOpacity: 0.2,
    },
    containerImageInstruction: {
        width: normalizeWidth(240),
        height: normalizeWidth(120),
        borderColor: color.frenchGray,
        borderWidth: 0.2,
        borderRadius: 5,
        marginTop: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerMissingBannerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        outlineStyle: 'none',
        flexDirection: 'row',
        marginBottom: normalizeHeight(15),
    },
    containerMissingAllWebsitesAuthorizationModal: {
        width: normalizeWidth(250),
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        alignItems: 'center',
        paddingVertical: 25,
        paddingHorizontal: 15,
    },
    containerMissingAllWebsitesAuthorizationModalButton: {
        width: '90%',
        marginTop: 5,
    },
    containerCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        height: normalizeHeight(16),
        width: normalizeHeight(16),
        borderRadius: normalizeHeight(16),
        marginRight: 10,
    },
    containerCheckMarkSelected: {
        backgroundColor: color.emerald,
    },
    containerCheckMarkUnselected: {
        borderWidth: 2,
        borderColor: color.silverChalice,
    },
    containerTosConsentButton: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: IS_SMALL_SCREEN ? normalizeHeight(16) : normalizeHeight(25),
        outlineStyle: 'none',
    },
    containerWaitingScreen: {
        flex: 1,
        minHeight: '100lvh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textTitle: {
        fontFamily: font.ambitBold,
        fontSize: normalizeHeight(18),
        lineHeight: normalizeHeight(24),
        color: color.black,
        textAlign: 'center',
    },
    textSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(16),
        lineHeight: normalizeHeight(24),
        color: color.gray,
        textAlign: 'center',
        marginTop: normalizeHeight(10),
    },
    textButton: {
        fontSize: normalizeHeight(13),
        fontFamily: font.ambitBlack,
        textTransform: 'uppercase',
    },
    textProgressBar: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(10),
        color: color.black,
    },
    textProgressBarGray: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(10),
        color: color.gray,
    },
    textActivationPreambleBanner: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(12),
        color: color.black,
        textAlign: 'left',
    },
    textInstructionTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(18),
        color: color.black,
        textAlign: 'center',
        marginVertical: 15,
    },
    textInstructionTitleFlowWithSystemSettings: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(16),
        color: color.black,
        textAlign: 'center',
        marginVertical: 15,
    },
    textInstruction: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(13),
        color: color.black,
        textAlign: 'center',
        marginTop: 25,
    },
    textInstructionBoldVersionB: {
        fontFamily: font.ambitBold,
        fontSize: normalizeWidth(13),
        color: color.black,
        textAlign: 'center',
        marginTop: 25,
    },
    textInstructionGray: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(13),
        color: color.gray,
        textAlign: 'center',
        marginTop: 5,
    },
    textNativePopupBackground: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(13),
        color: color.malachite,
        textAlign: 'left',
    },
    textNativePopupBackgroundEmoji: {
        marginLeft: normalizeWidth(15),
        marginRight: normalizeWidth(13),
        fontSize: normalizeWidth(16),
    },
    textBannerInstruction: {
        fontFamily: font.ambitBold,
        fontSize: normalizeWidth(14),
        color: color.black,
        textAlign: 'center',
        marginTop: normalizeHeight(35),
    },
    textMissingAuthorizationBannerButton: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(10),
        color: color.emerald,
        textAlign: 'center',
    },
    textMissingAllWebsitesAuthorizationModalTitle: {
        fontFamily: font.ambitBold,
        fontSize: normalizeHeight(18),
        lineHeight: normalizeHeight(24),
        color: color.black,
        textAlign: 'center',
        marginVertical: normalizeHeight(5),
    },
    textMissingAllWebsitesAuthorizationModalSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(14),
        lineHeight: normalizeHeight(19),
        color: color.gray,
        textAlign: 'center',
        marginBottom: 15,
    },
    textTosConsentButton: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(13),
        color: color.black,
        textAlign: 'left',
        paddingTop: normalizeHeight(1),
    },
    textTosConsentButtonHighlighted: {
        fontFamily: font.ambitSemiBold,
        color: color.emerald,
    },
    imageLogo: {
        width: normalizeHeight(90),
        height: normalizeHeight(30),
        resizeMode: 'contain',
        alignSelf: 'center',
        marginBottom: 20,
    },
    imageIllustration: {
        width: normalizeHeight(175),
        height: normalizeHeight(175),
        resizeMode: 'contain',
        borderRadius: 175,
    },
    imageIllustrationPreambleVersionB: {
        width: normalizeHeight(225),
        height: normalizeHeight(175),
        resizeMode: 'contain',
        borderRadius: 175,
    },
    imageProgressBar: {
        width: normalizeWidth(220),
        height: normalizeWidth(20),
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    imageInstruction: {
        width: normalizeWidth(210),
        height: normalizeWidth(100),
        resizeMode: 'contain',
    },
    imageBannerInstruction1: {
        width: normalizeWidth(250),
        height: normalizeWidth(50),
        resizeMode: 'contain',
        marginVertical: normalizeWidth(10),
    },
    imageBannerInstruction2: {
        width: normalizeWidth(160),
        height: normalizeWidth(80),
        resizeMode: 'contain',
        marginVertical: normalizeWidth(10),
    },
    imageInstructionVersionB: {
        width: normalizeWidth(230),
        height: normalizeWidth(70),
        resizeMode: 'contain',
    },
    imageInstructionIcon: {
        marginHorizontal: 3,
        width: normalizeHeight(14),
        height: normalizeHeight(14),
        resizeMode: 'contain',
        bottom: -normalizeHeight(3),
    },
    imageActivationPreambleBanner: {
        width: normalizeHeight(25),
        height: normalizeHeight(25),
        resizeMode: 'contain',
        marginRight: normalizeWidth(10),
    },
    imageModal: {
        width: normalizeWidth(210),
        height: normalizeWidth(100),
        resizeMode: 'contain',
        marginVertical: 15,
    },
    imageBackToAppButton: {
        width: normalizeWidth(7),
        height: normalizeWidth(11),
    },
    imageCheckMarkIcon: {
        height: normalizeHeight(8),
        width: normalizeHeight(8),
        resizeMode: 'contain',
    },
    imageChevronIcon: {
        height: normalizeHeight(45),
        width: normalizeHeight(45),
        resizeMode: 'contain',
    },
    imageArrowRightIcon: {
        height: normalizeWidth(10),
        width: normalizeWidth(10),
        marginLeft: normalizeWidth(6),
    },
});
