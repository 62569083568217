import * as React from 'react';
import { ApolloClient, useQuery } from '@apollo/react-hooks';

import { User } from '../../api/graphql/fragments/user';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';

export function useUser(): User | undefined {
    const queryResponse = useQuery<UserQueryResponse>(userQuery, { fetchPolicy: 'network-only' });
    const user = React.useMemo(() => queryResponse?.data?.user, [queryResponse]);
    return user;
}

export async function queryUser(apolloClient: ApolloClient<object>): Promise<User | undefined> {
    const response = await apolloClient.query<UserQueryResponse>({ query: userQuery, fetchPolicy: 'network-only' });
    return response.data?.user;
}

export function isFirstNameValid(firstName: string) {
    return firstName.length >= 2 && firstName.length <= 50;
}

export function isAgeValid(age: string) {
    const regex = /[0-9]+/;
    return regex.test(age) && Number(age) >= 16 && Number(age) <= 150;
}

export function isReferrerCodeValid(referrerCode: string) {
    const regex = /^[a-zA-Z]+$/;
    return referrerCode.length === 0 || (regex.test(referrerCode) && referrerCode.length === 6);
}
