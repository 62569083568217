import * as React from 'react';
import { StyleSheet, View, Image, Text } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';

import { MerchantOffer } from '../../../api/graphql/fragments/offers';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { containerStyles } from '../../../style/container';
import { useMerchantOffer } from '../../../lib/offers/offers';
import { AffiliateLinkLoadingTriggeringEventType } from '../../../lib/offers/affiliateLinkLoading';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import OfferButton from './OfferButton';
import FavoriteOfferButton from './FavoriteOfferButton';
import OfferCashback from './OfferCashback';
import ExpirationDate from './ExpirationDate';
import { HoverableTouchableOpacity } from '../../common/HoverableComponents';

export default function OfferComponent({ offerId }: { offerId: string }) {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const offer: MerchantOffer | undefined = useMerchantOffer(offerId);
    return (
        <HoverableTouchableOpacity
            style={styles.container}
            hoveredStyle={styles.containerHovered}
            onPress={() => {
                if (offer) {
                    logAmplitudeEvent({ name: 'Offers - Clicked Offer', properties: { offerId } });
                    logUserEventUtil(apolloClient, {
                        type: 'clickedOffer',
                        payload: { offerId, type: offer.type, from: 'webApp' },
                    });
                    history.push(`/home/offer/${offer.offerId}`);
                }
            }}>
            <OfferInfo {...{ offer }} />
            <OfferButton {...{ offer }} triggeringEventType={AffiliateLinkLoadingTriggeringEventType.offerComponent} />
        </HoverableTouchableOpacity>
    );
}

export function OfferInfo({ offer }: { offer: MerchantOffer | undefined }) {
    const bannerURL = offer?.card?.bannerURL ?? offer?.screen?.bannerURL;
    const title = offer?.card?.title ?? offer?.screen?.title;
    return (
        <View style={containerStyles.containerRowLeftCenter}>
            <View>
                {bannerURL ? <Image source={{ uri: bannerURL }} style={styles.image} /> : <View style={styles.image} />}
                <View style={styles.containerFavoriteOfferButton}>
                    <FavoriteOfferButton {...{ offer, isInSmallOfferComponent: true }} />
                </View>
            </View>
            <View style={styles.containerTexts}>
                {offer && title ? (
                    <>
                        <Text style={styles.textTitle} numberOfLines={1}>
                            {title}
                        </Text>
                        <OfferCashback {...{ offer }} />
                    </>
                ) : (
                    <>
                        <View style={[styles.containerPlaceholder, { width: 60 }]} />
                        <View style={[styles.containerPlaceholder, { width: 120 }]} />
                    </>
                )}
            </View>
            <View style={styles.containerExpirationDate}>{offer ? <ExpirationDate offer={offer} /> : null}</View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
    },
    containerHovered: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        borderRadius: 8,
    },
    containerFavoriteOfferButton: {
        position: 'absolute',
        right: 6,
        top: 6,
    },
    containerTexts: {
        flex: 1,
        marginLeft: 15,
        marginRight: 10,
    },
    containerExpirationDate: {
        position: 'absolute',
        bottom: 4,
        left: 4,
    },
    containerPlaceholder: {
        height: 14,
        marginVertical: 3,
        borderRadius: 5,
        backgroundColor: color.linkWaterLight,
    },
    image: {
        width: 160,
        height: 96,
        borderRadius: 8,
        backgroundColor: color.linkWaterLight,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emperor,
    },
});
