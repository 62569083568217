import * as React from 'react';
import Amplify from 'aws-amplify';

import { buildApolloClientAndRestoreCache } from './graphql/client';
import { ApolloClient } from './graphql/client';
import { getDevStackMode } from '../lib/common/devStackMode';

export enum RestApiUrl {
    dev = 'https://7q5kiih3hd.execute-api.eu-west-1.amazonaws.com/dev/',
    prod = 'https://vif2cunewb.execute-api.eu-west-1.amazonaws.com/dev/',
}

export function getRestApiUrl(): string {
    return getDevStackMode() ? RestApiUrl.dev : RestApiUrl.prod;
}

enum GraphQlApiUrl {
    dev = 'https://kj7lhbgnynez3nilmztwg43e7i.appsync-api.eu-west-1.amazonaws.com/graphql',
    prod = 'https://hhpmo2comjeefmdzfxmwpu3n3u.appsync-api.eu-west-1.amazonaws.com/graphql',
}

function getGraphQlApiUrl(): { url: string; region: string } {
    return {
        url: getDevStackMode() ? GraphQlApiUrl.dev : GraphQlApiUrl.prod,
        region: 'eu-west-1',
    };
}

export function getCognitoConfiguration(): {
    identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
} {
    if (getDevStackMode())
        return {
            identityPoolId: 'eu-west-1:b7b37756-edf6-4aaf-87d3-0cd950b0dac8',
            region: 'eu-west-1',
            userPoolId: 'eu-west-1_pnyjX8pBk',
            userPoolWebClientId: '113u65488gfatp3kll8cnnlpr5',
        };
    else
        return {
            identityPoolId: 'eu-west-1:115f351a-b276-4026-91f8-2aaea11c0f53',
            region: 'eu-west-1',
            userPoolId: 'eu-west-1_2IUrZPpJm',
            userPoolWebClientId: '6maadudat4g0a2rhc999rhfs7g',
        };
}

export async function getApolloClient(): Promise<ApolloClient> {
    const cognitoConfiguration = getCognitoConfiguration();
    const graphQlApiUrl = getGraphQlApiUrl();
    Amplify.configure({
        Auth: cognitoConfiguration,
    });
    const client = await buildApolloClientAndRestoreCache(graphQlApiUrl.url, graphQlApiUrl.region);
    return client;
}

export function useApolloClient() {
    const [apolloClient, setApolloClient] = React.useState<ApolloClient | undefined>(undefined);
    React.useEffect(() => {
        getApolloClient().then(setApolloClient);
    }, []);
    return apolloClient;
}
