const gql = require('graphql-tag');

export const offerBrowsingTagFragment = gql`
    fragment OfferBrowsingTag on OfferBrowsingTag {
        tagId
        displayName
        score
    }
`;

export interface OfferBrowsingTag {
    __typename: 'OfferBrowsingTag';
    tagId: string;
    displayName: string;
    score: number;
}

export const dataIdFromOfferBrowsingTag = (offerBrowsingTag: OfferBrowsingTag | { tagId: string }) => {
    return `OfferBrowsingTag:${offerBrowsingTag.tagId}`;
};
