import * as React from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import moment from 'moment';

import { getLocalizedTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { updateUser } from '../../../api/graphql/mutations/updateUser';
import { lastUserAttributeVerificationActionAtVar } from '../../../api/graphql/reactiveVariables';
import { usePrevious } from '../../../lib/common/utilHooks';
import Button from '../../../components/common/Button';

export const PHONE_NUMBER_PREFIX_INDICATOR = '33';

function UserPhoneNumberVerificationPage() {
    const texts = getLocalizedTexts().home.profile.userInfo.phoneNumberVerificationPage;
    const history = useHistory();
    const [code, setCode] = React.useState<string>('');
    const previousCode = usePrevious(code);
    const [verifyPhoneNumber, isLoading, hasSucceeded, error, clearError] = useVerifyPhoneNumber();
    const onPress = () => {
        if (isCodeValid(code)) verifyPhoneNumber(code);
    };
    React.useEffect(() => {
        if (code.length !== previousCode?.length) clearError();
    }, [code, previousCode, clearError]);
    if (!hasSucceeded)
        return (
            <View style={styles.container}>
                <Text style={styles.textTitle}>{texts.title}</Text>
                <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
                <CodeInput {...{ code, setCode, isLoading }} />
                {error ? <ErrorMessage {...{ error }} /> : null}
                <Button
                    {...{ onPress, isLoading }}
                    style={styles.containerConfirmButton}
                    textStyle={styles.textConfirmButton}
                    height={40}
                    disabled={!isCodeValid(code)}>
                    {texts.nextButton}
                </Button>
                <Button
                    onPress={() => history.goBack()}
                    style={styles.containerCancelButton}
                    textStyle={styles.textCancelButton}
                    height={40}
                    useSecondaryColor>
                    {texts.backButton}
                </Button>
            </View>
        );
    else
        return (
            <View style={styles.container}>
                <Text style={styles.textTitle}>{texts.success.text}</Text>
                <Button
                    onPress={() => history.push('/home/profile/user-info')}
                    style={styles.containerConfirmButton}
                    textStyle={styles.textConfirmButton}
                    height={40}>
                    {texts.success.button}
                </Button>
            </View>
        );
}

export default UserPhoneNumberVerificationPage;

enum VerifyPhoneNumberError {
    WrongCode = 'WrongCode',
    LimitExceeded = 'LimitExceeded',
    Other = 'Other',
}

function useVerifyPhoneNumber(): [
    (code: string) => Promise<void>,
    boolean,
    boolean,
    VerifyPhoneNumberError | undefined,
    () => void
] {
    const apolloClient = useApolloClient();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [hasSucceeded, setHasSucceeded] = React.useState<boolean>(false);
    const [error, setError] = React.useState<VerifyPhoneNumberError | undefined>();
    React.useEffect(() => {
        Auth.verifyCurrentUserAttribute('phone_number').catch((error) => {
            if (error.code === 'LimitExceededException') {
                setError(VerifyPhoneNumberError.LimitExceeded);
            } else {
                setError(VerifyPhoneNumberError.Other);
            }
        });
    }, []);
    const verifyPhoneNumber = async (code: string) => {
        if (isCodeValid(code) && !isLoading) {
            setIsLoading(true);
            const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
            try {
                await Auth.verifyUserAttributeSubmit(cognitoUser, 'phone_number', code);
                await updateUser(apolloClient, { phoneNumberStatus: 'confirmed' });
                setHasSucceeded(true);
                lastUserAttributeVerificationActionAtVar(moment.now());
            } catch (error) {
                setError(VerifyPhoneNumberError.WrongCode);
            }
            setIsLoading(false);
        }
    };
    const clearError = () => setError(undefined);
    return [verifyPhoneNumber, isLoading, hasSucceeded, error, clearError];
}

function isCodeValid(confirmationCode: string): boolean {
    const regex = /^\d{6}$/;
    return regex.test(confirmationCode);
}

export const CodeInput = ({
    code,
    setCode,
    isLoading,
}: {
    code: string;
    setCode: (code: string) => void;
    isLoading: boolean;
}) => {
    const texts = getLocalizedTexts().home.profile.userInfo.phoneNumberVerificationPage;
    return (
        <View style={[code ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                value={code}
                style={styles.textInput}
                placeholder={texts.codePlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'numeric'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                onChangeText={setCode}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
};

export const ErrorMessage = ({ error }: { error: VerifyPhoneNumberError }) => {
    const texts = getLocalizedTexts().home.profile.userInfo.phoneNumberVerificationPage.error;
    let message: string = '';
    if (error === VerifyPhoneNumberError.WrongCode) message = texts.wrongCode;
    else if (error === VerifyPhoneNumberError.LimitExceeded) message = texts.limitExceeded;
    else message = texts.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 450,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        justifyContent: 'center',
    },
    containerTextInput: {
        flexDirection: 'row',
    },
    containerErrorMessage: {
        backgroundColor: color.pippin,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: 24,
    },
    containerConfirmButton: {
        marginTop: 24,
    },
    containerCancelButton: {
        marginTop: 16,
    },
    textTitle: {
        marginBottom: 16,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textSubtitle: {
        marginHorizontal: 15,
        marginBottom: 16,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.black,
        textAlign: 'center',
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textErrorMessage: {
        color: color.flamingo,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textConfirmButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
    },
    textCancelButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.black,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
});
