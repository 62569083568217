import * as React from 'react';

export type WindowSize = { width: number | undefined; height: number | undefined };

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = React.useState<WindowSize>({ width: undefined, height: undefined });
    React.useEffect(() => {
        function handleResize() {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []); //
    return windowSize;
}
