import React from 'react';
import { View, StyleSheet, Image, TextInput, TouchableOpacity, Text } from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router';
import * as _ from 'lodash';

import { getLocalizedTexts, formatCurrencyAmount } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';
import { User } from '../../api/graphql/fragments/user';
import { useUserBalance } from '../../lib/points/points';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';

const logo = { uri: '/assets/images/logos/logo-with-title.svg' };
const searchIcon = { uri: '/assets/images/icons/search.svg' };
const crossIcon = { uri: '/assets/images/icons/cross.svg' };
const currencySymbolGreen = { uri: '/assets/images/visualIdentity/currency-symbol-green.svg' };
const currencySymbolGray = { uri: '/assets/images/visualIdentity/currency-symbol-light-gray.svg' };
const heartIconGreen = { uri: '/assets/images/icons/heart-green.svg' };
const heartIconGray = { uri: '/assets/images/icons/heart-gray.svg' };
const giftIconGreen = { uri: '/assets/images/icons/gift-green.svg' };
const giftIconGray = { uri: '/assets/images/icons/gift-gray.svg' };

function NavigationBar() {
    const history = useHistory();
    return (
        <View style={styles.containerNavigationBar}>
            <View style={styles.containerLeftBlock}>
                <TouchableOpacity onPress={() => history.push('/home')}>
                    <Image source={logo} style={styles.imageLogo} />
                </TouchableOpacity>
                <SearchBar />
            </View>
            <View style={styles.containerCenterBlock}>
                <TabButtons />
            </View>
            <View style={styles.containerRightBlock}>
                <UserBalance />
                <ProfileButton />
            </View>
        </View>
    );
}

export default NavigationBar;

function SearchBar() {
    const texts = getLocalizedTexts().home.navigationBar;
    const history = useHistory();
    const location = useLocation();
    const [inputValue, setInputValue] = React.useState<string>('');
    const setSearchPathname = (value: string) => history.push(`/home/search/${value}`);
    const debouncedSetSearchPathname = React.useRef(_.debounce(setSearchPathname, 125)).current;
    const onChangeText = (value: string) => {
        setInputValue(value);
        debouncedSetSearchPathname(value);
    };
    const onClearText = () => {
        setInputValue('');
        history.push(`/home`);
    };
    const isSearchPathname = React.useMemo(() => location.pathname?.startsWith('/home/search'), [location]);
    return (
        <View style={[styles.containerSearchBar, isSearchPathname ? styles.containerSearchBarFocused : {}]}>
            <View style={styles.containerSearchBarIcon}>
                <Image source={searchIcon} style={{ width: 15, height: 15 }} />
            </View>
            <TextInput
                value={inputValue}
                onChangeText={onChangeText}
                placeholder={texts.searchBarPlaceholder}
                style={styles.textInputSearchBar}
                placeholderTextColor={color.frenchGray}
                selectTextOnFocus={false}
                autoCorrect={false}
                onFocus={() => history.push(`/home/search/${inputValue}`)}
            />
            {isSearchPathname ? (
                <TouchableOpacity onPress={onClearText}>
                    <View style={styles.containerSearchBarIcon}>
                        <Image source={crossIcon} style={{ width: 11, height: 11 }} />
                    </View>
                </TouchableOpacity>
            ) : null}
        </View>
    );
}

function TabButtons() {
    const location = useLocation();
    const history = useHistory();
    const selectedTab: 'home' | 'favoriteOffers' | 'rewards' | undefined = React.useMemo(
        () =>
            location.pathname === '/home'
                ? 'home'
                : location.pathname === '/home/favorite-offers'
                ? 'favoriteOffers'
                : location.pathname === '/home/rewards'
                ? 'rewards'
                : undefined,
        [location]
    );
    return (
        <View style={styles.containerTabButtons}>
            <HoverableTouchableOpacity
                style={[styles.containerTabButton, selectedTab === 'home' && styles.containerTabButtonSelected]}
                hoveredStyle={styles.containerTabButtonHovered}
                onPress={() => history.push('/home')}>
                <Image
                    source={selectedTab === 'home' ? currencySymbolGreen : currencySymbolGray}
                    style={styles.imageTabIcon}
                />
            </HoverableTouchableOpacity>
            <HoverableTouchableOpacity
                style={[styles.containerTabButton, selectedTab === 'favoriteOffers' && styles.containerTabButtonSelected]}
                hoveredStyle={styles.containerTabButtonHovered}
                onPress={() => history.push('/home/favorite-offers')}>
                <Image
                    source={selectedTab === 'favoriteOffers' ? heartIconGreen : heartIconGray}
                    style={styles.imageTabIcon}
                />
            </HoverableTouchableOpacity>
            <HoverableTouchableOpacity
                style={[styles.containerTabButton, selectedTab === 'rewards' && styles.containerTabButtonSelected]}
                hoveredStyle={styles.containerTabButtonHovered}
                onPress={() => history.push('/home/rewards')}>
                <Image source={selectedTab === 'rewards' ? giftIconGreen : giftIconGray} style={styles.imageTabIcon} />
            </HoverableTouchableOpacity>
        </View>
    );
}

function UserBalance() {
    const history = useHistory();
    const userBalance = useUserBalance();
    if (userBalance !== undefined) {
        return (
            <HoverableTouchableOpacity
                style={styles.containerUserBalance}
                hoveredStyle={styles.containerUserBalanceHovered}
                onPress={() => {
                    logAmplitudeEvent({ name: 'History - Clicked Tab', properties: { tab: 'all' } });
                    history.push('/home/history');
                }}>
                <Text style={styles.textUserBalance}>{formatCurrencyAmount(userBalance)}</Text>
            </HoverableTouchableOpacity>
        );
    }
    return null;
}

function ProfileButton() {
    const history = useHistory();
    const user = useUser();
    return (
        <HoverableTouchableOpacity
            style={styles.containerProfileButton}
            hoveredStyle={{ opacity: 0.8 }}
            onPress={() => history.push('/home/profile')}>
            <Text style={styles.textProfileButton}>{user?.userInfo?.firstName?.slice(0, 1)}</Text>
        </HoverableTouchableOpacity>
    );
}

function useUser(): User | undefined {
    const queryResponse = useQuery<UserQueryResponse>(userQuery, { fetchPolicy: 'cache-and-network' });
    const user = React.useMemo(() => queryResponse?.data?.user, [queryResponse]);
    return user;
}

const styles = StyleSheet.create({
    containerNavigationBar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: NAVIGATION_BAR_HEIGHT,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        zIndex: 1,
    },
    containerLeftBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 480,
    },
    containerCenterBlock: {
        flexDirection: 'row',
    },
    containerRightBlock: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 40,
        width: 480,
    },
    containerSearchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 25,
        height: 48,
        width: 350,
        borderRadius: 40,
        backgroundColor: color.linkWaterLight,
    },
    containerSearchBarFocused: {
        borderColor: color.emerald,
        borderWidth: 1,
        shadowColor: color.emerald,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    containerSearchBarIcon: {
        paddingVertical: 5,
        paddingLeft: 5,
    },
    containerTabButtons: {
        flexDirection: 'row',
    },
    containerTabButton: {
        width: 96,
        height: NAVIGATION_BAR_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerTabButtonHovered: {
        backgroundColor: color.hintOfGreen,
    },
    containerTabButtonSelected: {
        borderBottomWidth: 4,
        borderBottomColor: color.emerald,
    },
    containerUserBalance: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
        borderRadius: 40,
        backgroundColor: color.linkWaterLight,
        paddingLeft: 13,
        paddingRight: 16,
        marginHorizontal: 16,
    },
    containerUserBalanceHovered: {
        backgroundColor: color.concrete,
    },
    containerProfileButton: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
        width: 48,
        borderRadius: 48,
        backgroundColor: color.electricViolet,
    },
    imageLogo: {
        width: 96,
        height: 32,
        resizeMode: 'contain',
        marginHorizontal: 30,
    },
    imageTabIcon: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
    },
    textInputSearchBar: {
        flex: 1,
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.black,
        marginHorizontal: 12,
        paddingVertical: 0,
        outlineWidth: 0,
    },
    textUserBalance: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.emerald,
        marginLeft: 5,
    },
    textProfileButton: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.white,
    },
});
