const gql = require('graphql-tag');

export const userBonusFragment = gql`
    fragment UserBonus on UserBonus {
        bonusId
        bonus {
            points
        }
    }
`;

export interface Bonus {
    __typename: 'Bonus';
    points: number;
}

export interface UserBonus {
    __typename: 'UserBonus';
    bonusId: string;
    bonus: Bonus;
}

export const dataIdFromUserBonus = (bonus: UserBonus | { bonusId: string }) => {
    return `UserBonus:${bonus.bonusId}`;
};
