import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import { useHistory } from 'react-router';

import { getLocalizedTexts, formatCurrencyAmount } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { Reward } from '../../../api/graphql/fragments/rewards';
import { hasPressedRedeemRewardVar } from '../../../api/graphql/reactiveVariables';
import { getEmailAndPhoneNumberStatuses } from '../../../lib/common/cognito';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { useUserBalance, useUserPointBalance } from '../../../lib/points/points';
import { Dialog, DialogState, useReward } from './RewardDetailPage';
import HorizontalFlatList from '../../../components/common/HorizontalFlatList';

const cashMachineIcon = { uri: '/assets/images/icons/cash-machine.svg' };

function BankTransferDetailPage() {
    const reward: Reward | undefined = useReward();
    const [selectedPoints, setSelectedPoints] = React.useState<number | null>(null);
    const [dialogState, setDialogState] = React.useState<DialogState>({ isVisible: false });
    const closeDialog = () => setDialogState({ ...dialogState, isVisible: false });
    if (!reward || !reward.screen)
        return (
            <View style={[styles.container, { justifyContent: 'center', alignItems: 'center' }]}>
                <ActivityIndicator color={color.frenchGray} size="large" />
            </View>
        );
    if (!dialogState.isVisible)
        return (
            <View style={styles.container}>
                <UserBalance />
                <View style={styles.containerIllustration}>
                    <Image source={cashMachineIcon} style={styles.imageIllustration} />
                </View>
                <Text style={styles.textTitle}>{reward.screen.title}</Text>
                <Text style={styles.textDescription}>{reward.screen.description}</Text>
                <AmountList {...{ reward, selectedPoints, setSelectedPoints }} />
                <RedeemButton {...{ reward, selectedPoints, setDialogState }} />
            </View>
        );
    else return <Dialog {...{ dialogState, closeDialog }} />;
}

export default BankTransferDetailPage;

function UserBalance() {
    const userBalance = useUserBalance();
    return (
        <View style={styles.containerUserBalance}>
            <Text style={styles.textUserBalance}>{formatCurrencyAmount(userBalance || 0)}</Text>
        </View>
    );
}

function AmountList({
    reward,
    selectedPoints,
    setSelectedPoints,
}: {
    reward: Reward;
    selectedPoints: number | null;
    setSelectedPoints: (points: number) => void;
}) {
    const userPointBalance = useUserPointBalance();
    const amountInfoList: AmountInfo[] = React.useMemo(
        () => computeAmountInfoList(reward, userPointBalance),
        [reward, userPointBalance]
    );
    return (
        <View style={{ flexDirection: 'column', marginHorizontal: 5, overflow: 'visible' }}>
            <HorizontalFlatList
                data={amountInfoList}
                numberOfRows={1}
                renderItem={(item) => (
                    <Amount
                        amountInfo={item}
                        selected={item.points === selectedPoints}
                        onSelect={() => !item.locked && setSelectedPoints(item.points)}
                    />
                )}
                initialNumberOfColumnsToDisplay={5}
                hideScrollIndicators
                arrowStyle={{ margin: 10, width: 30 }}
                horizontalSpacing={10}
            />
        </View>
    );
}

type AmountInfo = {
    points: number;
    amount: number;
    locked: boolean;
};

function Amount({
    amountInfo: { amount, locked },
    selected,
    onSelect,
}: {
    amountInfo: AmountInfo;
    selected: boolean;
    onSelect: () => void;
}) {
    const texts = getLocalizedTexts().home.rewards.bankTransfer;
    return (
        <TouchableOpacity
            onPress={onSelect}
            style={[
                styles.containerAmount,
                selected && styles.containerAmountSelected,
                locked && styles.containerAmountLocked,
            ]}>
            <Text style={styles.textAmount}>{texts.amount({ amount })}</Text>
        </TouchableOpacity>
    );
}

function computeAmountInfoList(reward: Reward, userPointBalance: number | undefined): AmountInfo[] {
    const { minPoints, maxPoints, pointInterval, bankTransferProperties } = reward;
    const amountInfoList: AmountInfo[] = [];
    if (
        minPoints &&
        maxPoints &&
        pointInterval &&
        bankTransferProperties &&
        bankTransferProperties.pointConversionRate &&
        userPointBalance !== undefined
    ) {
        const { pointConversionRate } = bankTransferProperties;
        for (
            let points = minPoints;
            points <= maxPoints && (amountInfoList.length <= 4 || points - userPointBalance <= pointInterval);
            points += pointInterval
        )
            amountInfoList.push({
                points,
                amount: Number((points / pointConversionRate).toFixed(2)),
                locked: points > userPointBalance,
            });
    }
    return amountInfoList;
}

function RedeemButton({
    reward,
    selectedPoints,
    setDialogState,
}: {
    reward: Reward;
    selectedPoints: number | null;
    setDialogState: (value: DialogState) => void;
}) {
    const [hasEnoughPoints, onRedeem] = useRedeemReward(reward, selectedPoints, setDialogState);
    const buttonText: string | undefined = getRedeemButtonText({ selectedPoints, hasEnoughPoints });
    return (
        <TouchableOpacity onPress={onRedeem} style={{ flexDirection: 'row' }}>
            <View
                style={[
                    styles.containerButton,
                    (!hasEnoughPoints || !selectedPoints) && { backgroundColor: color.frenchGray },
                ]}>
                <Text style={styles.textButton}>{buttonText}</Text>
            </View>
        </TouchableOpacity>
    );
}

function getRedeemButtonText({
    hasEnoughPoints,
    selectedPoints,
}: {
    hasEnoughPoints: boolean;
    selectedPoints: number | null;
}): string | undefined {
    const texts = getLocalizedTexts().home.rewards.bankTransfer;
    if (!hasEnoughPoints) return texts.redeemButton.balanceTooLow;
    else if (!selectedPoints) return texts.redeemButton.noAmountSelected;
    else return texts.redeemButton.default;
}

function useRedeemReward(
    reward: Reward,
    selectedPoints: number | null,
    setDialogState: (value: DialogState) => void
): [boolean, () => void] {
    const texts = getLocalizedTexts().home.rewards.rewardDetails;
    const history = useHistory();
    const userPointBalance = useUserPointBalance();
    const hasEnoughPoints: boolean = React.useMemo(
        () =>
            userPointBalance !== undefined &&
            userPointBalance >= (reward.minPoints || 0) &&
            userPointBalance >= (selectedPoints || 0),
        [userPointBalance, selectedPoints, reward]
    );
    const onRedeem = async () => {
        const { rewardId, type } = reward;
        if (hasEnoughPoints && selectedPoints) {
            logAmplitudeEvent({
                name: 'Rewards - Clicked Redeem',
                properties: { rewardId, type, points: selectedPoints },
            });
            hasPressedRedeemRewardVar(true);
            const { emailVerified, phoneNumberVerified } = await getEmailAndPhoneNumberStatuses();
            if (!emailVerified || !phoneNumberVerified) {
                setDialogState({
                    isVisible: true,
                    subtitle: texts.verificationNeededDialog.text({
                        phoneNumberVerified,
                        emailVerified,
                    }),
                    confirmButton: texts.verificationNeededDialog.button.next,
                    onConfirm: () => history.push('/home/profile/user-info'),
                    cancelButton: texts.verificationNeededDialog.button.back,
                });
            } else {
                history.push(`/home/rewards/bank-transfer/${rewardId}/bank-details/${selectedPoints}`);
            }
        }
    };
    return [hasEnoughPoints, onRedeem];
}

const styles = StyleSheet.create({
    container: {
        width: 600,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        justifyContent: 'center',
    },
    containerUserBalance: {
        flexDirection: 'row',
    },
    containerIllustration: {
        alignSelf: 'center',
    },
    containerAmount: {
        marginTop: 25,
        marginBottom: 40,
        marginHorizontal: 5,
        paddingHorizontal: 20,
        paddingVertical: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        borderWidth: 2,
        borderColor: color.white,
        backgroundColor: color.white,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 5,
    },
    containerAmountSelected: {
        borderColor: color.emerald,
    },
    containerAmountLocked: {
        opacity: 0.5,
    },
    containerButton: {
        flex: 1,
        height: 50,
        borderRadius: 60,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.emerald,
    },
    textUserBalance: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.emerald,
        marginLeft: 5,
    },
    textTitle: {
        marginTop: 16,
        marginHorizontal: 70,
        fontFamily: font.ambitBlack,
        fontSize: 22,
        color: color.black,
        textAlign: 'center',
    },
    textDescription: {
        marginTop: 10,
        marginHorizontal: 20,
        fontFamily: font.ambitSemiBold,
        fontSize: 15,
        color: color.manatee,
        textAlign: 'center',
    },
    textAmount: {
        marginBottom: 5,
        fontFamily: font.ambitBlack,
        fontSize: 22,
        color: color.black,
        textAlign: 'center',
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
    },
    imageIllustration: {
        width: 100,
        height: 100,
        margin: 25,
        resizeMode: 'contain',
    },
});
