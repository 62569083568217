import * as React from 'react';
import { Modal, StyleSheet, View, Text, Image, Dimensions, ActivityIndicator, TouchableWithoutFeedback } from 'react-native';
import { useParams } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { redeemIOSDonationReward } from '../../api/rest/rewards';
import Button from '../../components/common/Button';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';
import JokoLogoHeader from '../../components/public/JokoLogoHeader';

export enum DonationRedeemState {
    toConfirm = 'toConfirm',
    success = 'success',
    error = 'error',
    cancelled = 'cancelled',
}

function IOSDonationPage() {
    const { userId, token, rewardId, rewardTitle, rewardValue, stackMode, rewardLogoUrl } = useParams<{
        userId: string;
        token: string;
        rewardId: string;
        rewardTitle: string;
        rewardValue: string;
        rewardLogoUrl: string;
        stackMode?: string;
    }>();
    const [donationRedeemState, setDonationRedeemState] = React.useState<DonationRedeemState>(DonationRedeemState.toConfirm);
    const shouldUseDevStack: boolean = stackMode === 'dev';
    return (
        <Modal transparent={true}>
            <TouchableWithoutFeedback>
                <View style={styles.container}>
                    <View>
                        <JokoLogoHeader />
                        {donationRedeemState === DonationRedeemState.toConfirm ? (
                            <RedeemConfirmation
                                {...{ rewardTitle: decodeURIComponent(rewardTitle), rewardValue, rewardLogoUrl }}
                            />
                        ) : (
                            <RedeemResult {...{ donationRedeemState }} />
                        )}
                    </View>
                    {donationRedeemState === DonationRedeemState.toConfirm ? (
                        <Buttons {...{ userId, rewardId, token, shouldUseDevStack, setDonationRedeemState }} />
                    ) : null}
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

export default IOSDonationPage;

function RedeemConfirmation({
    rewardTitle,
    rewardValue,
    rewardLogoUrl,
}: {
    rewardTitle: string;
    rewardValue: string;
    rewardLogoUrl: string;
}) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem.confirmation;
    return (
        <View style={styles.containerContent}>
            <View style={styles.containerTitle}>
                <View style={styles.containerRewardLogo}>
                    <Image source={{ uri: decodeURIComponent(rewardLogoUrl) }} style={styles.imageRewardLogo} />
                </View>
                <Text style={styles.textTitle}>{rewardTitle}</Text>
            </View>
            <Text style={styles.textSubtitle}>{texts.text1({ value: Number(rewardValue), name: rewardTitle })}</Text>
            <Text style={[styles.textSubtitle, { marginTop: 8 }]}>{texts.text2}</Text>
        </View>
    );
}

function RedeemResult({ donationRedeemState }: { donationRedeemState: DonationRedeemState }) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem;
    const resultTexts =
        donationRedeemState === DonationRedeemState.success
            ? texts.success
            : donationRedeemState === DonationRedeemState.cancelled
            ? texts.cancellation
            : texts.error;
    return (
        <View style={styles.containerContent}>
            <View style={styles.containerTitle}>
                <Text style={styles.textTitle}>{resultTexts.title}</Text>
            </View>
            <Text style={styles.textSubtitle}>{resultTexts.text1}</Text>
            <Text style={[styles.textSubtitle, { marginTop: 8 }]}>{resultTexts.text2}</Text>
        </View>
    );
}

function Buttons({
    userId,
    rewardId,
    token,
    shouldUseDevStack,
    setDonationRedeemState,
}: {
    userId: string;
    rewardId: string;
    token: string;
    shouldUseDevStack: boolean;
    setDonationRedeemState: (value: DonationRedeemState) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem.confirmation;
    const [isRedeeming, setIsRedeeming] = React.useState<boolean>(false);
    const onPressCancel = () => {
        if (!isRedeeming) setDonationRedeemState(DonationRedeemState.cancelled);
    };
    const onPressConfirm = async () => {
        if (!isRedeeming) {
            setIsRedeeming(true);
            setDonationRedeemState(await redeemIOSDonationReward(userId, rewardId, token, shouldUseDevStack));
            setIsRedeeming(false);
        }
    };
    return (
        <View style={styles.containerButtons}>
            <Button
                onPress={onPressCancel}
                style={styles.containerCancelButton}
                textStyle={styles.textButton}
                useSecondaryColor
                disabled={isRedeeming}>
                {texts.cancelButton}
            </Button>
            <HoverableTouchableOpacity style={styles.containerRedeemButton} onPress={onPressConfirm}>
                {isRedeeming ? (
                    <ActivityIndicator color={color.white} />
                ) : (
                    <Text style={[styles.textButton, { color: color.white }]}>{texts.confirmButton}</Text>
                )}
            </HoverableTouchableOpacity>
        </View>
    );
}

const { height, width } = Dimensions.get('window');

const PADDING = 20;
const BUTTON_HEIGHT = 53;

const styles = StyleSheet.create({
    container: {
        height: height,
        width: width,
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerContent: {
        paddingHorizontal: PADDING,
        marginTop: 9,
        justifyContent: 'center',
    },
    containerTitle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 17,
    },
    containerRewardLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 46,
        height: 46,
        borderRadius: 46,
        backgroundColor: color.linkWaterLight,
        marginRight: 8,
    },
    containerButtons: {
        width: width - 2 * PADDING,
        marginBottom: 15,
    },
    containerCancelButton: {
        height: BUTTON_HEIGHT,
    },
    containerRedeemButton: {
        height: BUTTON_HEIGHT,
        borderRadius: 80,
        backgroundColor: color.black,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 35,
        marginTop: 10,
    },
    imageRewardLogo: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
    },
    textTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
    },
    textSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 15,
        color: color.black,
    },
    textButton: {
        fontSize: 16,
        fontFamily: font.ambitBold,
    },
});
