import * as React from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { getLocalizedTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { updateUser } from '../../../api/graphql/mutations/updateUser';
import { CognitoUserAttributeName, getCognitoUserAttribute } from '../../../lib/common/cognito';
import { usePrevious } from '../../../lib/common/utilHooks';
import Button from '../../../components/common/Button';
import {
    checkDuplicatePhoneNumberQuery,
    CheckDuplicatePhoneNumberQueryResponse,
    CheckDuplicatePhoneNumberQueryVariables,
} from '../../../api/graphql/queries/user';

export const PHONE_NUMBER_PREFIX_INDICATOR = '33';

function UserPhoneNumberPage() {
    const texts = getLocalizedTexts().home.profile.userInfo.phoneNumberPage;
    const history = useHistory();
    const [initialPhoneNumber, setInitialPhoneNumber] = React.useState<string>('');
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const previousPhoneNumber = usePrevious(phoneNumber);
    React.useEffect(() => {
        getCognitoUserAttribute(CognitoUserAttributeName.phoneNumber).then((cognitoPhoneNumberAttribute) => {
            if (cognitoPhoneNumberAttribute) {
                setInitialPhoneNumber(cognitoPhoneNumberAttribute.getValue());
                if (!phoneNumber) setPhoneNumber(cognitoPhoneNumberAttribute.getValue());
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const [updatePhoneNumber, isLoading, error, clearError] = useUpdatePhoneNumber();
    const onPress = () => {
        if (isPhoneNumberValid(phoneNumber)) updatePhoneNumber(initialPhoneNumber, phoneNumber);
    };
    React.useEffect(() => {
        if (phoneNumber.length !== previousPhoneNumber?.length) clearError();
    }, [phoneNumber, previousPhoneNumber, clearError]);
    return (
        <View style={styles.container}>
            <Text style={styles.textTitle}>{texts.title}</Text>
            <PhoneNumberInput {...{ phoneNumber, setPhoneNumber, isLoading }} />
            {error ? <ErrorMessage {...{ error }} /> : null}
            <Button
                {...{ onPress, isLoading }}
                style={styles.containerConfirmButton}
                textStyle={styles.textConfirmButton}
                height={40}
                disabled={!isPhoneNumberValid(phoneNumber)}>
                {texts.nextButton}
            </Button>
            <Button
                onPress={() => history.goBack()}
                style={styles.containerCancelButton}
                textStyle={styles.textCancelButton}
                height={40}
                useSecondaryColor>
                {texts.backButton}
            </Button>
        </View>
    );
}

export default UserPhoneNumberPage;

enum UpdatePhoneNumberError {
    AlreadyExists = 'AlreadyExists',
    Other = 'Other',
}

function useUpdatePhoneNumber(): [
    (initialPhoneNumber: string, phoneNumber: string) => Promise<void>,
    boolean,
    UpdatePhoneNumberError | undefined,
    () => void
] {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<UpdatePhoneNumberError | undefined>();
    const updatePhoneNumber = async (initialPhoneNumber: string, phoneNumber: string) => {
        if (!isLoading) {
            if (phoneNumber === undefined || phoneNumber === initialPhoneNumber) {
                history.goBack();
            } else if (isPhoneNumberValid(phoneNumber)) {
                setIsLoading(true);
                const formattedPhoneNumber = phoneNumber.replace(
                    /^0([6-7](\d{2}){4})$/,
                    `+${PHONE_NUMBER_PREFIX_INDICATOR}$1`
                );
                const response = await apolloClient.query<
                    CheckDuplicatePhoneNumberQueryResponse,
                    CheckDuplicatePhoneNumberQueryVariables
                >({
                    query: checkDuplicatePhoneNumberQuery,
                    variables: { phoneNumber: formattedPhoneNumber },
                    fetchPolicy: 'network-only',
                });
                if (response.data.checkDuplicatePhoneNumber) {
                    await updateUser(apolloClient, { phoneNumber: formattedPhoneNumber });
                    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
                    await Auth.updateUserAttributes(cognitoUser, { phone_number: formattedPhoneNumber });
                    history.push('/home/profile/user-info/phone-number/verification');
                } else {
                    setError(UpdatePhoneNumberError.AlreadyExists);
                    setIsLoading(false);
                }
            }
        }
    };
    const clearError = () => setError(undefined);
    return [updatePhoneNumber, isLoading, error, clearError];
}

function isPhoneNumberValid(phoneNumber: string): boolean {
    const regex = new RegExp(`^(0|\\+${PHONE_NUMBER_PREFIX_INDICATOR})[6-7](\\d{2}){4}$`, 'g');
    return phoneNumber !== undefined && regex.test(phoneNumber);
}

export const PhoneNumberInput = ({
    phoneNumber,
    setPhoneNumber,
    isLoading,
}: {
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    isLoading: boolean;
}) => {
    const texts = getLocalizedTexts().home.profile.userInfo.phoneNumberPage;
    return (
        <View style={[phoneNumber ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                value={phoneNumber}
                style={styles.textInput}
                placeholder={texts.phoneNumberPlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'phone-pad'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                onChangeText={setPhoneNumber}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
};

export const ErrorMessage = ({ error }: { error: UpdatePhoneNumberError }) => {
    const texts = getLocalizedTexts().home.profile.userInfo.phoneNumberPage.error;
    let message: string = '';
    if (error === UpdatePhoneNumberError.AlreadyExists) message = texts.alreadyExists;
    else message = texts.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 450,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        justifyContent: 'center',
    },
    containerTextInput: {
        flexDirection: 'row',
    },
    containerErrorMessage: {
        backgroundColor: color.pippin,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: 24,
    },
    containerConfirmButton: {
        marginTop: 24,
    },
    containerCancelButton: {
        marginTop: 16,
    },
    textTitle: {
        marginHorizontal: 25,
        marginBottom: 16,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textErrorMessage: {
        color: color.flamingo,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textConfirmButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
    },
    textCancelButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.black,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
});
