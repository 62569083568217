export class EmailAddressUnknown extends Error {
    __proto__: EmailAddressUnknown;

    constructor() {
        super('');
        this.__proto__ = EmailAddressUnknown.prototype;
    }
}

export class GeneratedPassword extends Error {
    __proto__: GeneratedPassword;

    constructor() {
        super('');
        this.__proto__ = GeneratedPassword.prototype;
    }
}

export class ConflictingEmail extends Error {
    __proto__: ConflictingEmail;
    parameters: { email?: string | undefined };

    constructor(email: string | undefined) {
        super('');

        this.__proto__ = ConflictingEmail.prototype;
        this.parameters = { email };
    }
}

export class MissingEmail extends Error {
    __proto__: MissingEmail;

    constructor() {
        super('');
        this.__proto__ = MissingEmail.prototype;
    }
}
