import * as React from 'react';
import { View, StyleSheet, Animated, Text, TouchableWithoutFeedback } from 'react-native';

import color from '../../style/color';
import { font } from '../../style/text';

export default function SwitchButton({
    isEnabled,
    onPress,
    subtitle,
    disabled,
}: {
    isEnabled: boolean;
    onPress: () => any;
    subtitle?: string;
    disabled?: boolean;
}) {
    const animatedValue = React.useRef(new Animated.Value(isEnabled ? 1 : 0)).current;
    React.useEffect(() => {
        Animated.timing(animatedValue, { toValue: isEnabled ? 1 : 0, duration: 300, useNativeDriver: true }).start();
    }, [isEnabled, animatedValue]);
    const circleLeftOffset = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [CIRCLE_MARGIN, CONTAINER_WIDTH - CIRCLE_MARGIN - CIRCLE_WIDTH],
    });
    return (
        <>
            <TouchableWithoutFeedback {...{ onPress, disabled }}>
                <View
                    style={[
                        styles.containerSwitchButton,
                        { backgroundColor: isEnabled ? color.emerald : color.mercury, opacity: disabled ? 0.5 : 1 },
                    ]}>
                    <Animated.View style={[styles.containerCircle, { left: circleLeftOffset }]}></Animated.View>
                </View>
            </TouchableWithoutFeedback>
            <Text style={styles.textSubtitle}>{subtitle}</Text>
        </>
    );
}

const CONTAINER_WIDTH = 48;
const CIRCLE_WIDTH = 20;
const CIRCLE_MARGIN = CONTAINER_WIDTH / 4 - CIRCLE_WIDTH / 2;

const styles = StyleSheet.create({
    containerSwitchButton: {
        width: CONTAINER_WIDTH,
        height: CONTAINER_WIDTH / 2,
        borderRadius: 60,
        justifyContent: 'center',
    },
    containerCircle: {
        backgroundColor: color.white,
        width: CIRCLE_WIDTH,
        height: CIRCLE_WIDTH,
        borderRadius: CIRCLE_WIDTH / 2,
    },
    textSubtitle: {
        textAlign: 'center',
        marginTop: 2,
        fontFamily: font.ambitSemiBold,
        fontSize: 10,
        color: color.manatee,
        opacity: 0.8,
    },
});
