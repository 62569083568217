import * as React from 'react';
import {
    View,
    FlatList,
    FlatListProps,
    SectionList,
    SectionListProps,
    ActivityIndicator,
    SectionListData,
} from 'react-native';

import color from '../../style/color';

export function ProgressiveFlatList<ItemT>(
    props: FlatListProps<ItemT> & {
        initialNumberOfItemsToDisplay: number;
        ListFooterComponentLoading?: JSX.Element;
        refProp?: (instance: FlatList | null) => void;
    }
) {
    const { initialNumberOfItemsToDisplay, data, ListFooterComponent, ListFooterComponentLoading, refProp } = props;
    const totalNumberOfItems = React.useMemo(() => data?.length || 0, [data]);
    const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = React.useState(
        Math.min(initialNumberOfItemsToDisplay, totalNumberOfItems)
    );
    const displayMore = () => {
        if (numberOfItemsToDisplay < totalNumberOfItems) {
            const newNumberOfItemsToDisplay = Math.min(
                numberOfItemsToDisplay + Math.ceil(initialNumberOfItemsToDisplay / 2),
                totalNumberOfItems
            );
            setNumberOfItemsToDisplay(newNumberOfItemsToDisplay);
        }
    };
    const slicedData = React.useMemo(() => data?.slice(0, numberOfItemsToDisplay), [data, numberOfItemsToDisplay]);
    return (
        <FlatList
            ref={refProp}
            {...props}
            data={slicedData}
            ListFooterComponent={
                totalNumberOfItems > numberOfItemsToDisplay
                    ? ListFooterComponentLoading || (
                          <View
                              style={{
                                  flex: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                              }}>
                              <ActivityIndicator size="large" color={color.alto} />
                          </View>
                      )
                    : ListFooterComponent || <View />
            }
            onEndReachedThreshold={0.5}
            onEndReached={() => displayMore()}
        />
    );
}

export function ProgressiveSectionList<ItemT>(
    props: SectionListProps<ItemT> & { initialNumberOfItemsToDisplay: number; ListFooterComponentLoading?: JSX.Element }
) {
    const { initialNumberOfItemsToDisplay, sections, ListFooterComponent, ListFooterComponentLoading } = props;
    const totalNumberOfItems = React.useMemo(() => getSectionsLength(sections), [sections]);
    const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = React.useState(
        Math.min(initialNumberOfItemsToDisplay, totalNumberOfItems)
    );
    const displayMore = () => {
        if (numberOfItemsToDisplay < totalNumberOfItems) {
            const newNumberOfItemsToDisplay = Math.min(
                numberOfItemsToDisplay + Math.ceil(initialNumberOfItemsToDisplay / 2),
                totalNumberOfItems
            );
            setNumberOfItemsToDisplay(newNumberOfItemsToDisplay);
        }
    };
    const slicedSections = React.useMemo(() => sliceSections(sections, numberOfItemsToDisplay), [
        sections,
        numberOfItemsToDisplay,
    ]);
    return (
        <SectionList
            {...props}
            sections={slicedSections}
            ListFooterComponent={
                totalNumberOfItems > numberOfItemsToDisplay
                    ? ListFooterComponentLoading || (
                          <View
                              style={{
                                  flex: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                              }}>
                              <ActivityIndicator size="large" color={color.alto} />
                          </View>
                      )
                    : ListFooterComponent || <View />
            }
            onEndReachedThreshold={0.5}
            onEndReached={() => displayMore()}
        />
    );
}

function getSectionsLength<ItemT>(sections: readonly SectionListData<ItemT>[]): number {
    return sections.reduce((previousValue, section) => previousValue + section.data.length, 0);
}

function sliceSections<ItemT>(sections: readonly SectionListData<ItemT>[], end: number): SectionListData<ItemT>[] {
    const slicedSections: SectionListData<ItemT>[] = [];
    let numberOfItems: number = 0;
    for (const section of sections) {
        const slicedSection = { sectionKey: section.sectionKey, data: section.data.slice(0, end - numberOfItems) };
        slicedSections.push(slicedSection);
        numberOfItems += slicedSection.data.length;
        if (numberOfItems >= end) break;
    }
    return slicedSections;
}
