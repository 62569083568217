import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory } from 'react-router';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { User } from '../../api/graphql/fragments/user';
import { updateUser } from '../../api/graphql/mutations/updateUser';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { logUserEventUtil } from '../../lib/events/userEvents';
import { GoogleTagManagerEventName, logGoogleTagManagerEvent } from '../../lib/common/tags';
import LoadingFrame from '../../components/auth/LoadingFrame';

const MIN_ANIMATION_LENGTH_MS = 3000;

export default function OnboardingEndPage() {
    const texts = getLocalizedTexts().onboarding;
    const size = useWindowSize();
    useRedirection();
    return (
        <View style={styles.container}>
            <LoadingFrame
                text={texts.loading}
                height={(size.height || 0) - 120}
                width={(size.width || 0) - 240}
                isError={false}
            />
        </View>
    );
}

export function useRedirection() {
    const mountingTimestampMs = React.useRef(Date.now()).current;
    const history = useHistory();
    const apolloClient = useApolloClient();
    const user = useUser();
    const logEventsAndRedirect = React.useCallback(
        async (user: User) => {
            if (!user.userInfo || !user.userInfo.firstName || !user.userInfo.age || !user.userInfo.gender)
                history.push('/onboarding/user-info');
            else {
                if (!user.onboardedAt) {
                    await updateUser(apolloClient, { onboardedAt: moment().unix() });
                    logAmplitudeEvent({ name: 'Registration - Onboarding Finished' });
                    logUserEventUtil(apolloClient, { type: 'finishedOnboarding' });
                    logGoogleTagManagerEvent({ event: GoogleTagManagerEventName.finishedOnboarding });
                }
                const currentTimestampMs = Date.now();
                setTimeout(
                    () => history.push('/home'),
                    Math.max(MIN_ANIMATION_LENGTH_MS - (currentTimestampMs - mountingTimestampMs), 0)
                );
            }
        },
        [apolloClient, history, mountingTimestampMs]
    );
    React.useEffect(() => {
        if (user) logEventsAndRedirect(user);
    }, [user, logEventsAndRedirect]);
}

function useUser(): User | undefined {
    const queryResponse = useQuery<UserQueryResponse>(userQuery, { fetchPolicy: 'network-only' });
    const user = React.useMemo(() => queryResponse?.data?.user, [queryResponse]);
    return user;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
});
