import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ApolloClient } from '../../api/graphql/client';
import { PointQueryResponse, pointQuery } from '../../api/graphql/queries/points';
import { userHistoryAndPointsQuery, UserHistoryAndPointsQueryResponse } from '../../api/graphql/queries/pointCollection';

export function useUserPointBalance(): number | undefined {
    const queryResponse = useQuery<PointQueryResponse, {}>(pointQuery, {
        fetchPolicy: 'cache-and-network',
    });
    const userPointBalance: number | undefined | null = React.useMemo(
        () => queryResponse.data?.user?.points?.balance,
        [queryResponse]
    );
    return userPointBalance === null ? undefined : userPointBalance;
}

export function useUserBalance(): number | undefined {
    const userPointBalance = useUserPointBalance();
    return userPointBalance !== undefined ? convertPointsToCurrencyAmount(userPointBalance) : undefined;
}

export async function refetchHistoryAndPointsWithDelay(apolloClient: ApolloClient, delay: number = 5000) {
    setTimeout(async () => {
        await apolloClient.query<UserHistoryAndPointsQueryResponse>({
            query: userHistoryAndPointsQuery,
            fetchPolicy: 'network-only',
        });
    }, delay);
}

export function convertPointsToCurrencyAmount(points: number) {
    return points / 1000;
}
