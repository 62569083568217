import { Setting } from '../fragments/settings';

const gql = require('graphql-tag');

export const settingQuery = gql`
    query ListSettings {
        settings {
            items {
                settingKey
                value
            }
            nextToken
        }
    }
`;

export interface SettingList {
    __typename: 'SettingList';
    items: Setting[];
    nextToken: string | null;
}

export type SettingQueryResponse = {
    settings: SettingList;
};
