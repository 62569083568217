import * as React from 'react';
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native';

import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { HOME_SCREEN_WIDTH, NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { UserInAppContent } from '../../api/graphql/fragments/inAppContents';
import {
    ProcessedUserInAppContent,
    ProcessedUserInAppContentItem,
    ProcessedUserInAppContentLayout,
    processUserInAppContent,
    useUserInAppContents,
} from '../../lib/inAppContents/inAppContents';
import { MinimalUserOfferMap, useSearchableUserOfferMap } from '../../lib/offers/offers';
import { ProgressiveFlatList } from '../../components/common/ProgressiveList';
import HorizontalFlatList from '../../components/common/HorizontalFlatList';
import { LargeCardItem, MediumCardItem, SmallCardItem } from '../../components/home/homeScreen/CardItem';
import LargeOfferComponent from '../../components/home/offers/LargeOfferComponent';
import MediumOfferComponent from '../../components/home/offers/MediumOfferComponent';
import SmallOfferComponent from '../../components/home/offers/SmallOfferComponent';
import OfferBrowsingTagComponent from '../../components/home/homeScreen/OfferBrowsingTagComponent';
import { BrowserExtensionBanner } from '../../components/home/homeScreen/BrowserExtensionBanner';
import Footer from '../../components/common/Footer';

function HomePage() {
    return (
        <View style={styles.container}>
            <Sections />
        </View>
    );
}

export default HomePage;

const INITIAL_NUMBER_OF_SECTIONS_TO_DISPLAY = 4;

function Sections() {
    const size = useWindowSize();
    const contents: UserInAppContent[] | undefined = useUserInAppContents();
    const searchableUserOfferMap: MinimalUserOfferMap | undefined = useSearchableUserOfferMap();
    if (!contents || !searchableUserOfferMap)
        return (
            <View>
                <ActivityIndicator size="large" color={color.alto} />
            </View>
        );
    return (
        <View>
            <ProgressiveFlatList<UserInAppContent>
                style={{ height: (size.height || 0) - NAVIGATION_BAR_HEIGHT, width: size.width }}
                data={contents}
                renderItem={({ item, index }) => <Section content={item} {...{ index, searchableUserOfferMap }} />}
                keyExtractor={(item) => `${item.inAppContentId}`}
                ListFooterComponent={<Footer />}
                ListFooterComponentLoading={
                    <View style={{ height: 150 }}>
                        <ActivityIndicator size="large" color={color.alto} />
                    </View>
                }
                initialNumberOfItemsToDisplay={INITIAL_NUMBER_OF_SECTIONS_TO_DISPLAY}
            />
        </View>
    );
}

function Section({
    content,
    index,
    searchableUserOfferMap,
}: {
    content: UserInAppContent;
    index: number;
    searchableUserOfferMap: MinimalUserOfferMap;
}) {
    const processedContent: ProcessedUserInAppContent | undefined = React.useMemo(
        () => processUserInAppContent(content, searchableUserOfferMap),
        [content, searchableUserOfferMap]
    );
    if (!processedContent) return null;
    const { title, layout, itemList } = processedContent;
    return (
        <View style={styles.containerSection}>
            <View style={index > 0 ? styles.containerSectionHeaderWithBorder : styles.containerSectionHeader}>
                {title ? <Text style={styles.textSectionTitle}>{title}</Text> : null}
            </View>
            <SectionItems {...{ layout, itemList }} />
            <View style={styles.containerSectionFooter} />
        </View>
    );
}

const INITIAL_NUMBER_OF_COLUMNS_TO_DISPLAY = 5;

function SectionItems({
    layout: { layoutType, layoutParameters },
    itemList,
}: {
    layout: ProcessedUserInAppContentLayout;
    itemList: ProcessedUserInAppContentItem[];
}) {
    if (layoutType === 'singleItem' && itemList[0])
        return (
            <View style={styles.containerSectionSingleItem}>
                <SectionItem item={itemList[0]} index={0} />
            </View>
        );
    if (layoutType === 'carrousel')
        return (
            <HorizontalFlatList
                data={itemList}
                numberOfRows={layoutParameters?.numberOfRows || 1}
                renderItem={(item, columnIndex) => <SectionItem {...{ item }} index={columnIndex} />}
                initialNumberOfColumnsToDisplay={INITIAL_NUMBER_OF_COLUMNS_TO_DISPLAY}
            />
        );
    return null;
}

function SectionItem({ item, index }: { item: ProcessedUserInAppContentItem; index: number }) {
    switch (item.itemType) {
        case 'largeCard':
            return <LargeCardItem {...item.itemParameters} />;
        case 'mediumCard':
            return <MediumCardItem {...item.itemParameters} />;
        case 'smallCard':
            return <SmallCardItem {...item.itemParameters} />;
        case 'largeOffer':
            return <LargeOfferComponent {...item.itemParameters} />;
        case 'mediumOffer':
            return <MediumOfferComponent {...item.itemParameters} />;
        case 'smallOffer':
            return <SmallOfferComponent {...item.itemParameters} />;
        case 'offerBrowsingTag':
            return <OfferBrowsingTagComponent {...item.itemParameters} index={index} />;
        case 'browserExtensionBanner':
        case 'chromeExtensionBanner': // for backwards compatibility
            return <BrowserExtensionBanner {...item.itemParameters} />;
        default:
            return null;
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    containerSection: {
        width: HOME_SCREEN_WIDTH,
        alignSelf: 'center',
    },
    containerSectionHeader: {
        paddingTop: 20,
    },
    containerSectionHeaderWithBorder: {
        paddingTop: 20,
        borderTopWidth: 2,
        borderTopColor: color.linkWaterLight,
    },
    containerSectionFooter: {
        marginHorizontal: 20,
        paddingTop: 20,
    },
    containerSectionSingleItem: {
        flex: 1,
    },
    textSectionTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.black,
        marginBottom: 15,
    },
});
