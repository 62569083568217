import { Region, Currency } from '../../lib/auth/region';

export type BankDetails = BankDetailsSEPA | BankDetailsUK | BankDetailsUS;

export type BankDetailsSEPA = {
    currency: Currency.EUR;
    firstName: string;
    lastName: string;
    iban: string;
    bic: string;
};

export type BankDetailsUK = {
    currency: Currency.GBP;
    fullName: string;
    accountNumber: string;
    sortCode: string;
};

export type BankDetailsUS = {
    currency: Currency.USD;
    fullName: string;
    routingNumber: string;
    accountNumber: string;
    accountType: AccountTypeUS;
    address: AddressDetailsUS;
};

export enum AccountTypeUS {
    checking = 'CHECKING',
    savings = 'SAVINGS',
}

type AddressDetailsUS = {
    firstLine: string;
    city: string;
    postCode: string;
    state: string;
    country: string;
};

export function buildEmptyBankDetails(userRegion: Region): BankDetails {
    switch (userRegion) {
        case Region.GB:
            return buildEmptyBankDetailsUK();
        case Region.US:
            return buildEmptyBankDetailsUS();
        case Region.FR:
            return buildEmptyBankDetailsSEPA();
    }
}

function buildEmptyBankDetailsUK(): BankDetailsUK {
    return { currency: Currency.GBP, fullName: '', accountNumber: '', sortCode: '' };
}

function buildEmptyBankDetailsUS(): BankDetailsUS {
    return {
        currency: Currency.USD,
        fullName: '',
        routingNumber: '',
        accountNumber: '',
        accountType: AccountTypeUS.checking,
        address: {
            firstLine: '',
            city: '',
            postCode: '',
            state: '',
            country: 'US',
        },
    };
}

function buildEmptyBankDetailsSEPA(): BankDetailsSEPA {
    return { currency: Currency.EUR, firstName: '', lastName: '', iban: '', bic: '' };
}
