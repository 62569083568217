import { HistoryRecord, historyRecordFragment } from '../fragments/history';
import { UserPoints, userPointsFragment } from '../fragments/points';

const gql = require('graphql-tag');

export const userHistoryAndPointsQuery = gql`
    query UserHistoryAndPointsQuery {
        user {
            history(showUncollected: true, showReferralPoints: true) {
                ...HistoryRecord
            }
            points {
                ...UserPoints
            }
            firstPointsCollectedAt
            firstOfferPointsCollectedAt
        }
    }
    ${historyRecordFragment}
    ${userPointsFragment}
`;

export interface UserHistoryAndPointsQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        history: HistoryRecord[];
        points: UserPoints;
        firstPointsCollectedAt: number | null;
        firstOfferPointsCollectedAt: number | null;
    };
}
