import TagManager, { TagManagerArgs } from 'react-gtm-module';
import * as _ from 'lodash';
import moment from 'moment';

import { getUserId } from './cognito';
import { getDeviceId } from './device';
import { TrackingId } from '../../api/graphql/fragments/trackingConsents';
import { getUserTrackingConsent } from '../../lib/common/trackingConsents';

const GOOGLE_TAG_MANAGER_ID = 'GTM-W6SGXN6';
const GOOGLE_TAG_MANAGER_AUTH_TOKEN = 'gDaaC7x2FwcRpFoj0L9T0g';
const GOOGLE_TAG_MANAGER_ENV = 'env-1';

export enum GoogleTagManagerEventName {
    finishedOnboarding = 'finishedOnboarding',
    clickedDownloadBrowserExtension = 'clickedDownloadBrowserExtension',
    clickedAffiliateLink = 'clickedAffiliateLink',
}

type GoogleTagManagerEvent =
    | {
          event: GoogleTagManagerEventName.finishedOnboarding;
      }
    | {
          event: GoogleTagManagerEventName.clickedDownloadBrowserExtension;
          fromComponent: 'browserExtensionBanner';
      }
    | {
          event: GoogleTagManagerEventName.clickedAffiliateLink;
      };

export function initializeGoogleTagManager(consentChoice?: boolean) {
    if (consentChoice === undefined) {
        const trackingConsent = getUserTrackingConsent(TrackingId.googleTagManagerMarketingAttributionWebApp);
        consentChoice = trackingConsent?.consentChoice && trackingConsent.expiresAt > moment().unix() ? true : false;
    }
    if (consentChoice) {
        const eventConfig: { [eventName: string]: string } = {};
        for (const eventName of _.values(GoogleTagManagerEventName)) eventConfig[eventName] = eventName;
        const config: TagManagerArgs = {
            gtmId: GOOGLE_TAG_MANAGER_ID,
            auth: GOOGLE_TAG_MANAGER_AUTH_TOKEN,
            preview: GOOGLE_TAG_MANAGER_ENV,
            events: eventConfig,
        };
        TagManager.initialize(config);
    }
}

export function logGoogleTagManagerEvent(event: GoogleTagManagerEvent) {
    const trackingConsent = getUserTrackingConsent(TrackingId.googleTagManagerMarketingAttributionWebApp);
    const consentChoice = trackingConsent?.consentChoice && trackingConsent.expiresAt > moment().unix() ? true : false;
    if (consentChoice) {
        const deviceId = getDeviceId();
        const userId = getUserId();
        TagManager.dataLayer({
            dataLayer: {
                ...event,
                userId,
                deviceId,
            },
        });
    }
}
