const gql = require('graphql-tag');

export const updateUserLegalUpdatesConsentMutation = gql`
    mutation UpdateUserLegalUpdatesConsent($legalUpdateIds: [String]!, $consentChoice: Boolean!) {
        updateUserLegalUpdatesConsent(legalUpdateIds: $legalUpdateIds, consentChoice: $consentChoice)
    }
`;

export type UpdateUserLegalUpdatesConsentResponse = {
    __typename: 'Mutation';
    updateUserLegalUpdatesConsent: string | null;
};

export type UpdateUserLegalUpdatesConsentVariables = {
    legalUpdateIds: string[];
    consentChoice: boolean;
};
