import * as React from 'react';
import { Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { useHistory } from 'react-router';

import color from '../../../style/color';
import { HOME_SCREEN_WIDTH } from '../../../style/size';
import { font } from '../../../style/text';

export function LargeCardItem({ image, title, redirectionUri }: { image: string; title?: string; redirectionUri?: string }) {
    const onPress = useOnPressCardItem(redirectionUri);
    return (
        <TouchableOpacity style={styles.containerLargeCardItem} disabled={!redirectionUri} {...{ onPress }}>
            <Image style={styles.imageLargeCardItem} source={{ uri: image }} />
            {title ? <Text style={styles.textCardItem}>{title}</Text> : null}
        </TouchableOpacity>
    );
}

export function MediumCardItem({
    image,
    title,
    redirectionUri,
}: {
    image: string;
    title?: string;
    redirectionUri?: string;
}) {
    const onPress = useOnPressCardItem(redirectionUri);
    return (
        <TouchableOpacity style={styles.containerMediumCardItem} disabled={!redirectionUri} {...{ onPress }}>
            <Image style={styles.imageMediumCardItem} source={{ uri: image }} />
            {title ? <Text style={styles.textCardItem}>{title}</Text> : null}
        </TouchableOpacity>
    );
}

export function SmallCardItem({ image, title, redirectionUri }: { image: string; title?: string; redirectionUri?: string }) {
    const onPress = useOnPressCardItem(redirectionUri);
    return (
        <TouchableOpacity style={styles.containerSmallCardItem} disabled={!redirectionUri} {...{ onPress }}>
            <Image style={styles.imageSmallCardItem} source={{ uri: image }} />
            {title ? <Text style={styles.textCardItem}>{title}</Text> : null}
        </TouchableOpacity>
    );
}

function useOnPressCardItem(redirectionUri: string | undefined) {
    const history = useHistory();
    const onPress = () => {
        if (redirectionUri) history.push(redirectionUri);
    };
    return onPress;
}

const styles = StyleSheet.create({
    containerLargeCardItem: {
        width: Math.floor((HOME_SCREEN_WIDTH - 20) / 2),
    },
    containerMediumCardItem: {
        width: Math.floor((HOME_SCREEN_WIDTH - 2 * 20) / 3),
    },
    containerSmallCardItem: {
        width: Math.floor((HOME_SCREEN_WIDTH - 4 * 20) / 5),
    },
    textCardItem: {
        flex: 1,
        marginTop: 14,
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.emperor,
    },
    imageLargeCardItem: {
        flex: 1,
        height: 200,
        resizeMode: 'cover',
        backgroundColor: color.linkWaterLight,
        borderRadius: 8,
    },
    imageMediumCardItem: {
        flex: 1,
        height: 150,
        resizeMode: 'cover',
        backgroundColor: color.linkWaterLight,
        borderRadius: 8,
    },
    imageSmallCardItem: {
        flex: 1,
        height: 140,
        resizeMode: 'cover',
        backgroundColor: color.linkWaterLight,
        borderRadius: 8,
    },
});
