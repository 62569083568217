import * as React from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image, ViewStyle } from 'react-native';
import { useHistory } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import { MarketingWebsitePath, getMarketingWebsiteUrl } from '../../lib/auth/region';
import color from '../../style/color';
import { font } from '../../style/text';
import { usePrevious } from '../../lib/common/utilHooks';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { useEmailSignUp, SignUpError, isEmailValid } from '../../lib/auth/emailSignUp';
import HighlightedText from '../../components/common/HighlightedText';
import Button from '../../components/common/Button';
import OnboardingProgressIndicator, { OnboardingStep } from '../../components/onboarding/OnboardingProgressIndicator';
import PasswordRequirements, { usePasswordRequirementsChecks } from '../../components/auth/PasswordRequirements';

const eyeIcon = { uri: '/assets/images/icons/eye.svg' };
const eyeSlashIcon = { uri: '/assets/images/icons/eye-slash.svg' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark.svg' };
const leftChevronIcon = { uri: '/assets/images/icons/chevron-left-gray.svg' };

function EmailSignUpPage() {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    const history = useHistory();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const previousEmail = usePrevious(email);
    const previousPassword = usePrevious(password);
    const passwordRequirementsChecks = usePasswordRequirementsChecks(password);
    const [consentedToTermsOfService, setConsentedToTermsOfService] = React.useState<boolean>(false);
    const [consentedToNewsletter, setConsentedToNewsletter] = React.useState<boolean>(false);
    const [signUp, isLoading, error, clearError] = useEmailSignUp({
        onSignUpEnd: () => {
            history.push('/onboarding');
            window.location.reload();
        },
    });
    const onPress = () => {
        signUp(email, password, passwordRequirementsChecks, consentedToTermsOfService, consentedToNewsletter);
    };
    React.useEffect(() => {
        if (email.length !== previousEmail?.length || password.length !== previousPassword?.length) clearError();
    }, [email, password, previousEmail, previousPassword, clearError]);
    return (
        <View style={styles.container}>
            <View style={styles.containerContent}>
                <OnboardingProgressIndicator step={OnboardingStep.accountCreationStep} />
                <Text style={styles.textTitle}>{texts.emailTitle}</Text>
                <EmailInput {...{ email, setEmail, isLoading }} />
                <Text style={styles.textTitle}>{texts.passwordTitle}</Text>
                <PasswordInput {...{ password, setPassword, isLoading, onPress }} />
                <View style={styles.containerPasswordRequirements}>
                    <PasswordRequirements {...{ passwordRequirementsChecks }} />
                </View>
                <View style={styles.containerCheckBoxes}>
                    <TermsOfServiceConsentCheckBox
                        isChecked={consentedToTermsOfService}
                        setIsChecked={setConsentedToTermsOfService}
                    />
                    <View style={{ height: 18 }} />
                    <NewsletterConsentCheckBox isChecked={consentedToNewsletter} setIsChecked={setConsentedToNewsletter} />
                </View>
                {error ? <SignUpErrorMessage {...{ error }} /> : null}
                <View style={styles.containerNextButton}>
                    <Button
                        {...{ onPress, isLoading }}
                        textStyle={styles.textNextButton}
                        height={40}
                        disabled={!isEmailValid(email) || !consentedToTermsOfService || !passwordRequirementsChecks.all}>
                        {texts.nextButton}
                    </Button>
                </View>
                <TouchableOpacity style={styles.containerBackButton} onPress={() => history.goBack()}>
                    <Image source={leftChevronIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                    <Text style={styles.textBackButton}>{texts.backButton}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

export default EmailSignUpPage;

export const EmailInput = ({
    email,
    setEmail,
    isLoading,
}: {
    email: string;
    setEmail: (email: string) => void;
    isLoading: boolean;
}) => {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    return (
        <View style={styles.containerTextInput}>
            <TextInput
                value={email}
                style={styles.textInput}
                placeholder={texts.emailPlaceholder}
                placeholderTextColor={color.silverChalice}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                onChangeText={setEmail}
            />
        </View>
    );
};

export const PasswordInput = ({
    password,
    setPassword,
    isLoading,
    onPress,
    style,
}: {
    password: string;
    setPassword: (password: string) => void;
    isLoading: boolean;
    onPress: () => void;
    style?: ViewStyle;
}) => {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    const [hidePassword, setHidePassword] = React.useState<boolean>(true);
    return (
        <View style={[styles.containerTextInput, style]}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.passwordPlaceholder}
                placeholderTextColor={color.silverChalice}
                autoCorrect={false}
                autoCapitalize={'none'}
                secureTextEntry={hidePassword}
                underlineColorAndroid="transparent"
                returnKeyType="go"
                editable={!isLoading}
                onChangeText={setPassword}
                onSubmitEditing={onPress}
            />
            <TouchableOpacity
                onPress={() => setHidePassword(!hidePassword)}
                activeOpacity={0.8}
                style={{ justifyContent: 'center' }}>
                {hidePassword ? (
                    <Image source={eyeIcon} style={styles.imageEyeIcon} />
                ) : (
                    <Image source={eyeSlashIcon} style={styles.imageEyeIcon} />
                )}
            </TouchableOpacity>
        </View>
    );
};

export function TermsOfServiceConsentCheckBox({
    isChecked,
    setIsChecked,
}: {
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}) {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    const viewTermsOfService = () => {
        logAmplitudeEvent({ name: 'Registration - Clicked Terms Of Service' });
        window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.termOfService }));
    };
    const viewPrivacyPolicy = () => {
        logAmplitudeEvent({ name: 'Registration - Clicked Privacy Policy' });
        window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy }));
    };
    return (
        <CheckBox {...{ isChecked, setIsChecked }}>
            <HighlightedText
                style={styles.textCheckBox}
                highlightedStyle={styles.textCheckBoxHighlighted}
                onPressHighlightedTexts={[viewTermsOfService, viewPrivacyPolicy]}>
                {texts.termsOfService}
            </HighlightedText>
        </CheckBox>
    );
}

export function NewsletterConsentCheckBox({
    isChecked,
    setIsChecked,
}: {
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}) {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    return (
        <CheckBox {...{ isChecked, setIsChecked }}>
            <Text style={styles.textCheckBox}>{texts.newsletter}</Text>
        </CheckBox>
    );
}

function CheckBox({
    children,
    isChecked,
    setIsChecked,
}: {
    children: JSX.Element;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}) {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity
                onPress={() => setIsChecked(!isChecked)}
                activeOpacity={0.7}
                style={[
                    styles.containerCheckMark,
                    isChecked ? styles.containerCheckMarkSelected : styles.containerCheckMarkUnselected,
                ]}>
                {isChecked ? (
                    <Image source={checkMarkIcon} style={{ height: 12, width: 12, resizeMode: 'contain' }} />
                ) : null}
            </TouchableOpacity>
            {children}
        </View>
    );
}

export const SignUpErrorMessage = ({ error }: { error: SignUpError }) => {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    let message: string = '';
    if (error === SignUpError.EmailNotValid) message = texts.error.emailNotValid;
    else if (error === SignUpError.PasswordNotValid) message = texts.error.passwordNotValid;
    else if (error === SignUpError.DidNotConsentToTermsOfServices) message = texts.error.didNotConsentToTermsOfServices;
    else if (error === SignUpError.AlreadyExists) message = texts.error.alreadyExists;
    else message = texts.error.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerContent: {
        marginTop: 68,
        width: 424,
    },
    containerTextInput: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: color.gallery,
        borderWidth: 2,
        borderRadius: 8,
        height: 48,
        paddingHorizontal: 15,
    },
    containerPasswordRequirements: {
        marginTop: 8,
    },
    containerCheckBoxes: {
        marginTop: 40,
    },
    containerCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        width: 24,
        borderRadius: 24,
    },
    containerCheckMarkSelected: {
        backgroundColor: color.emerald,
    },
    containerCheckMarkUnselected: {
        borderWidth: 2,
        borderColor: color.frenchGray,
    },
    containerErrorMessage: {
        backgroundColor: color.sauvignon,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: 16,
    },
    containerNextButton: {
        marginTop: 40,
    },
    containerBackButton: {
        alignSelf: 'center',
        marginTop: 16,
        flexDirection: 'row',
    },
    textTitle: {
        marginTop: 40,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.tundora,
        marginBottom: 16,
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textCheckBox: {
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 14,
        paddingLeft: 15,
    },
    textCheckBoxHighlighted: {
        fontFamily: font.ambitSemiBold,
        color: color.emerald,
    },
    textErrorMessage: {
        color: color.flamingo,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textNextButton: {
        fontSize: 14,
    },
    textBackButton: {
        fontFamily: font.ambitBlack,
        color: color.silverChalice,
        fontSize: 14,
        paddingLeft: 8,
    },
    imageEyeIcon: {
        width: 25,
        height: 25,
        resizeMode: 'contain',
    },
});
