import * as React from 'react';
import {
    ActivityIndicator,
    StyleSheet,
    View,
    TouchableOpacity,
    FlatList,
    NativeSyntheticEvent,
    NativeScrollEvent,
    LayoutChangeEvent,
    Image,
} from 'react-native';
import * as _ from 'lodash';

import color from '../../style/color';
import { ProgressiveFlatList } from '../../components/common/ProgressiveList';

const md5 = require('md5');

const chevronLeftIcon = { uri: '/assets/images/icons/chevron-left.svg' };
const chevronRightIcon = { uri: '/assets/images/icons/chevron-right.svg' };

export default function HorizontalFlatList<T>({
    data,
    numberOfRows,
    renderItem,
    initialNumberOfColumnsToDisplay,
    hideScrollIndicators,
    arrowStyle,
    horizontalSpacing = 20,
    verticalSpacing = 20,
}: {
    data: T[];
    numberOfRows: number;
    renderItem: (item: T, columnIndex: number) => JSX.Element;
    initialNumberOfColumnsToDisplay: number;
    hideScrollIndicators?: boolean;
    arrowStyle?: ArrowStyle;
    horizontalSpacing?: number;
    verticalSpacing?: number;
}) {
    const itemColumns: T[][] = React.useMemo(() => _.chunk(data, numberOfRows), [data, numberOfRows]);
    const [containerHeight, setContainerHeight] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);
    const [isContainerHovered, setIsContainerHovered] = React.useState(false);
    const flatListComponentRef = React.useRef<FlatList | null>(null);
    const [onColumnLayout, onScroll, onPressArrow, scrollIndicators] = useHorizontalScrollingManager(
        flatListComponentRef,
        containerWidth
    );
    return (
        <Container {...{ setContainerHeight, setContainerWidth, setIsContainerHovered }}>
            <ProgressiveFlatList<T[]>
                refProp={(component: any) => (flatListComponentRef.current = component)}
                data={itemColumns}
                extraData={data}
                renderItem={({ item: column, index: columnIndex }) => (
                    <View onLayout={(event) => onColumnLayout(event, columnIndex)}>
                        {column.map((item, rowIndex) => (
                            <View
                                key={hashObject(item)}
                                style={[
                                    { paddingRight: horizontalSpacing },
                                    columnIndex === itemColumns.length - 1 && { paddingRight: 0 },
                                    rowIndex > 0 && { marginTop: verticalSpacing },
                                ]}>
                                {renderItem(item, columnIndex)}
                            </View>
                        ))}
                    </View>
                )}
                keyExtractor={(item) => hashObject(item)}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                ListFooterComponentLoading={
                    <View style={{ flex: 1, width: 90, justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size="large" color={color.alto} />
                    </View>
                }
                initialNumberOfItemsToDisplay={initialNumberOfColumnsToDisplay}
                onScroll={onScroll}
            />
            <Arrows onPress={onPressArrow} height={containerHeight} visible={isContainerHovered} {...{ arrowStyle }} />
            {!hideScrollIndicators ? <ScrollIndicators items={scrollIndicators} /> : null}
        </Container>
    );
}

function Container({
    children,
    setContainerWidth,
    setContainerHeight,
    setIsContainerHovered,
}: {
    children: JSX.Element | (JSX.Element | null)[] | null;
    setContainerWidth: (width: number) => void;
    setContainerHeight: (height: number) => void;
    setIsContainerHovered: (value: boolean) => void;
}) {
    const onContainerLayout = (event: LayoutChangeEvent) => {
        setContainerHeight(event.nativeEvent.layout.height);
        setContainerWidth(event.nativeEvent.layout.width);
    };
    const onMouseEnter = () => setIsContainerHovered(true);
    const onMouseLeave = () => setIsContainerHovered(false);
    return React.cloneElement(React.Children.only(<View onLayout={onContainerLayout}>{children}</View>), {
        onMouseEnter,
        onMouseLeave,
    });
}

function hashObject(object: Object): string {
    return md5(JSON.stringify(object));
}

function useHorizontalScrollingManager(
    flatListComponentRef: React.RefObject<FlatList | null>,
    containerWidth: number
): [
    (event: LayoutChangeEvent, columnIndex: number) => void,
    (event: NativeSyntheticEvent<NativeScrollEvent>) => void,
    (type: ArrowType) => void,
    ScrollIndicatorProps[]
] {
    const columnWidthMapRef = React.useRef<{ [index: number]: number }>({});
    const onColumnLayout = (event: LayoutChangeEvent, columnIndex: number) => {
        columnWidthMapRef.current[columnIndex] = event.nativeEvent.layout.width;
        if (Object.keys(columnWidthMapRef.current).length === flatListComponentRef.current?.props.data?.length)
            debouncedUpdateScrollIndicator();
    };
    const scrollIndexRef = React.useRef<number>(0);
    const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const contentOffsetX = event.nativeEvent.contentOffset.x;
        let scrollIndex = 0;
        let isSnapped = false;
        let index = 0;
        let cumulativeWidth = 0;
        while (columnWidthMapRef.current[index] !== undefined) {
            if (contentOffsetX === cumulativeWidth) isSnapped = true;
            cumulativeWidth += columnWidthMapRef.current[index];
            index++;
            if (contentOffsetX >= cumulativeWidth - columnWidthMapRef.current[index - 1] / 2) scrollIndex = index;
        }
        if (cumulativeWidth - contentOffsetX <= containerWidth) isSnapped = true; // To handle the case where we have reached the end
        scrollIndexRef.current = scrollIndex;
        if (isSnapped) debouncedUpdateScrollIndicator();
        else debouncedScrollToIndex(scrollIndex);
    };
    const lastOnScrollEventTimestampRef = React.useRef<number | undefined>(undefined);
    const debouncedOnScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const onScrollEventTimestamp = Date.now();
        lastOnScrollEventTimestampRef.current = onScrollEventTimestamp;
        setTimeout(() => {
            if (lastOnScrollEventTimestampRef.current === onScrollEventTimestamp) onScroll(event);
        }, 150);
    };
    const scrollToIndex = (index: number) => {
        if (columnWidthMapRef.current[index]) flatListComponentRef.current?.scrollToIndex({ index });
    };
    const debouncedScrollToIndex = _.debounce(scrollToIndex, 100, { leading: true, trailing: true });
    const [scrollIndicators, setScrollIndicators] = React.useState<ScrollIndicatorProps[]>([]);
    const updateScrollIndicator = () => {
        let maxIndex = _.max(Object.keys(columnWidthMapRef.current).map(Number)) || 0;
        let cumulativeWidth = 0;
        while (maxIndex >= 0) {
            cumulativeWidth += columnWidthMapRef.current[maxIndex];
            if (cumulativeWidth + (columnWidthMapRef.current[maxIndex - 1] || 0) / 2 >= containerWidth) break;
            maxIndex--;
        }
        setScrollIndicators(getScrollIndicatorsItems(scrollIndexRef.current, maxIndex));
    };
    const debouncedUpdateScrollIndicator = _.debounce(updateScrollIndicator, 500, { leading: true, trailing: true });
    const onPressArrow = (type: ArrowType) => {
        const newIndex = scrollIndexRef.current + (type === 'left' ? -1 : 1);
        debouncedScrollToIndex(newIndex);
    };
    return [onColumnLayout, debouncedOnScroll, onPressArrow, scrollIndicators];
}

type ArrowType = 'left' | 'right';

type ArrowStyle = { width: number; margin: number };

function Arrows({
    onPress,
    height,
    visible,
    arrowStyle,
}: {
    onPress: (type: ArrowType) => void;
    height: number;
    visible: boolean;
    arrowStyle?: ArrowStyle;
}) {
    return (
        <>
            <Arrow type={'left'} onPress={() => onPress('left')} {...{ height, visible, arrowStyle }} />
            <Arrow type={'right'} onPress={() => onPress('right')} {...{ height, visible, arrowStyle }} />
        </>
    );
}

function Arrow({
    type,
    onPress,
    height,
    visible,
    arrowStyle = { width: 60, margin: 120 },
}: {
    type: ArrowType;
    onPress: () => void;
    height: number;
    visible: boolean;
    arrowStyle?: ArrowStyle;
}) {
    const [isHovered, setIsHovered] = React.useState(false);
    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);
    const { width, margin } = arrowStyle;
    const offset = width + margin;
    return React.cloneElement(
        React.Children.only(
            <TouchableOpacity
                style={[
                    styles.containerArrow,
                    { opacity: isHovered || visible ? 1 : 0, height, width: offset },
                    type === 'left' ? { left: -offset } : { right: -offset },
                ]}
                onPress={onPress}>
                <View style={[styles.containerArrowBox, isHovered && styles.containerArrowBoxHovered, { width }]}>
                    <Image source={type === 'left' ? chevronLeftIcon : chevronRightIcon} style={styles.imageArrow} />
                </View>
            </TouchableOpacity>
        ),
        { onMouseEnter, onMouseLeave }
    );
}

const SCROLL_INDICATOR_DEFAULT_SIZE = 8;

interface ScrollIndicatorProps {
    isSelected: boolean;
    size: number;
}

function getScrollIndicatorsItems(currentIndex: number, maxIndex: number): ScrollIndicatorProps[] {
    const scrollIndicatorItems: ScrollIndicatorProps[] = [];
    if (maxIndex > 0)
        for (let index = 0; index <= maxIndex; index++) {
            const indexDelta = Math.abs(index - currentIndex);
            if (indexDelta <= 3)
                scrollIndicatorItems.push({
                    isSelected: index === currentIndex,
                    size: SCROLL_INDICATOR_DEFAULT_SIZE / (indexDelta + 1),
                });
        }
    return scrollIndicatorItems;
}

function ScrollIndicators({ items }: { items: ScrollIndicatorProps[] }) {
    return (
        <View style={styles.containerScrollIndicators}>
            {items.map((item, index) => (
                <ScrollIndicator {...item} />
            ))}
        </View>
    );
}

function ScrollIndicator({ isSelected, size }: ScrollIndicatorProps) {
    return (
        <View
            style={{
                width: size,
                height: size,
                borderRadius: size,
                marginLeft: 4,
                backgroundColor: isSelected ? color.emerald : color.alto,
            }}
        />
    );
}

const styles = StyleSheet.create({
    containerArrow: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerArrowBox: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.wildSand,
        borderRadius: 8,
    },
    containerArrowBoxHovered: {
        backgroundColor: color.concrete,
    },
    containerScrollIndicators: {
        position: 'absolute',
        right: 0,
        top: -28,
        flexDirection: 'row',
        alignItems: 'center',
    },
    imageArrow: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
    },
});
