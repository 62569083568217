import { getRestApiUrl } from '../config';

export async function getPublicSetting(settingKey: string): Promise<string | undefined> {
    console.log(`API call: getting the ${settingKey} public setting...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const url = `${getRestApiUrl()}setting?settingKey=${encodeURIComponent(settingKey)}`;
    return fetch(url, {
        method: 'GET',
        headers: headers,
    })
        .then((response) => {
            console.log(`Response ${response.status} for API call 'get public setting'`);
            if (response.status === 200) {
                return response.json();
            } else throw new Error('No setting found');
        })
        .then(({ settingValue }) => settingValue)
        .catch((error) => {
            console.log(error);
            return undefined;
        });
}
