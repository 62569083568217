import { rewardFragment, Reward } from '../fragments/rewards';

const gql = require('graphql-tag');

export const allRewardsQuery = gql`
    query AllRewardsQuery($devMode: Boolean) {
        rewards(devMode: $devMode) {
            items {
                ...Reward
            }
        }
    }
    ${rewardFragment}
`;

export interface RewardList {
    __typename: 'RewardList';
    items: Reward[];
}

export interface AllRewardsQueryResponse {
    __typename: 'Query';
    rewards: RewardList;
}

export type AllRewardsQueryVariables = {
    devMode: boolean;
};

export const rewardQuery = gql`
    query RewardQuery($rewardId: String!) {
        reward(rewardId: $rewardId) {
            ...Reward
        }
    }
    ${rewardFragment}
`;

export interface RewardQueryResponse {
    __typename: 'Query';
    reward: Reward | null;
}

export interface RewardQueryVariables {
    rewardId: string;
}
