import { getRegion, Region } from './lib/auth/region';
import { enGBFormat, enGBTexts } from './locales/en-GB';
import { enUSFormat, enUSTexts } from './locales/en-US';
import { frFormat, frTexts } from './locales/fr';
import {
    LocalizedTexts,
    LocalizedFormat,
    formatNumberWithLocaleFormat,
    formatCurrencyAmountWithLocaleFormat,
} from './locales/utils';

const localizedFormatsByRegion: Record<Region, LocalizedFormat> = {
    [Region.GB]: enGBFormat,
    [Region.US]: enUSFormat,
    [Region.FR]: frFormat,
};

const localizedTextsByRegion: Record<Region, LocalizedTexts> = {
    [Region.GB]: enGBTexts,
    [Region.US]: enUSTexts,
    [Region.FR]: frTexts,
};

export function getLocalizedTexts(): LocalizedTexts {
    const userRegion = getRegion();
    return localizedTextsByRegion[userRegion];
}

export function getLocalizedFormat(): LocalizedFormat {
    const userRegion = getRegion();
    return localizedFormatsByRegion[userRegion];
}

export function formatNumber(
    number: number | string,
    {
        shouldNeverUseSingleDecimal = true,
    }: {
        shouldNeverUseSingleDecimal?: boolean;
    } = {}
): string {
    const localeFormat = getLocalizedFormat();
    return formatNumberWithLocaleFormat(number, { localeFormat, shouldNeverUseSingleDecimal });
}

export function formatCurrencyAmount(
    number: number,
    {
        shouldNeverUseThreeDecimals,
    }: {
        shouldNeverUseThreeDecimals?: boolean;
    } = {}
): string {
    const localeFormat = getLocalizedFormat();
    return formatCurrencyAmountWithLocaleFormat(number, { localeFormat, shouldNeverUseThreeDecimals });
}

export function formatPercentageAmount(number: number): string {
    return `${formatNumber(number, { shouldNeverUseSingleDecimal: false })}%`;
}
