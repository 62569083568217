import { userTrackingConsentFragment, UserTrackingConsent } from '../fragments/trackingConsents';

const gql = require('graphql-tag');

export const userTrackingConsentsQuery = gql`
    query UserTrackingConsentsQuery {
        user {
            trackingConsents(platform: "webApp") {
                ...UserTrackingConsent
            }
        }
    }
    ${userTrackingConsentFragment}
`;

export interface UserTrackingConsentsQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        trackingConsents: UserTrackingConsent[];
    };
}
