import * as React from 'react';
import { StyleSheet, View, ViewStyle, TouchableOpacity, Image, Text, ImageSourcePropType } from 'react-native';

import { getLocalizedTexts } from '../../Locales';
import { font } from '../../style/text';
import color from '../../style/color';
import { Gender } from '../../api/graphql/fragments/user';

const femaleGenderIcon = { uri: '/assets/images/icons/gender-female.svg' };
const maleGenderIcon = { uri: '/assets/images/icons/gender-male.svg' };
const otherGenderIcon = { uri: '/assets/images/icons/gender-other.svg' };

interface Props {
    gender: Gender | undefined;
    setGender: (gender: Gender | undefined) => void;
    isLoading: boolean;
    style?: ViewStyle;
    containerImageWidth?: number;
    imageWidth?: number;
    descriptionTextSize?: number;
}

export default function GenderButtons({
    gender,
    setGender,
    isLoading,
    style = {},
    containerImageWidth = 80,
    imageWidth = 42,
    descriptionTextSize = 14,
}: Props) {
    return (
        <View style={[styles.container, style]}>
            <GenderButton
                buttonGender={Gender.Female}
                selectedGender={gender}
                setSelectedGender={setGender}
                {...{ isLoading, containerImageWidth, imageWidth, descriptionTextSize }}
            />
            <GenderButton
                buttonGender={Gender.Male}
                selectedGender={gender}
                setSelectedGender={setGender}
                {...{ isLoading, containerImageWidth, imageWidth: imageWidth - 6, descriptionTextSize }}
            />
            <GenderButton
                buttonGender={Gender.Other}
                selectedGender={gender}
                setSelectedGender={setGender}
                {...{ isLoading, containerImageWidth, imageWidth, descriptionTextSize }}
            />
        </View>
    );
}

function GenderButton({
    buttonGender,
    selectedGender,
    setSelectedGender,
    isLoading,
    containerImageWidth,
    imageWidth,
    descriptionTextSize,
}: {
    buttonGender: Gender;
    selectedGender: Gender | undefined;
    setSelectedGender: (gender: Gender | undefined) => void;
    isLoading: boolean;
    containerImageWidth: number;
    imageWidth: number;
    descriptionTextSize: number;
}) {
    const texts = getLocalizedTexts().onboarding.userInfo.genderDescription;
    const isSelected = selectedGender === buttonGender;
    const onPress = () => setSelectedGender(isSelected ? undefined : buttonGender);
    const imageSource =
        buttonGender === Gender.Female ? femaleGenderIcon : buttonGender === Gender.Male ? maleGenderIcon : otherGenderIcon;
    const descriptionText =
        buttonGender === Gender.Female ? texts.female : buttonGender === Gender.Male ? texts.male : texts.other;
    return (
        <TouchableOpacity onPress={onPress} activeOpacity={0.8} disabled={isLoading} style={{ marginRight: 17 }}>
            <View
                style={[
                    styles.containerImage,
                    isSelected && styles.containerImageSelected,
                    {
                        width: containerImageWidth,
                        height: containerImageWidth,
                        borderRadius: containerImageWidth,
                    },
                ]}>
                <Image source={imageSource} style={[styles.image, { width: imageWidth, height: imageWidth }]} />
            </View>
            <Text
                style={[
                    styles.textGenderDescription,
                    { fontSize: descriptionTextSize },
                    isSelected && { color: color.emerald },
                ]}>
                {descriptionText}
            </Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    containerImage: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderColor: color.white,
        backgroundColor: color.wildSand,
    },
    containerImageSelected: {
        borderColor: color.emerald,
    },
    image: {
        resizeMode: 'contain',
    },
    textGenderDescription: {
        fontFamily: font.ambitSemiBold,
        color: color.frenchGray,
        textAlign: 'center',
        position: 'relative',
        left: 1,
        marginTop: 2,
    },
});
