import { useQuery } from '@apollo/react-hooks';
import * as React from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import { useHistory } from 'react-router';

import color from '../../style/color';
import { font } from '../../style/text';
import { OfferBrowsingTag } from '../../api/graphql/fragments/offerBrowsingTags';
import {
    offerBrowsingTagQuery,
    OfferBrowsingTagQueryResponse,
    OfferBrowsingTagQueryVariables,
} from '../../api/graphql/queries/offerBrowsingTags';
import { getRegion, Region } from '../../lib/auth/region';
import { trimOfferBrowsingTagName } from '../../lib/offers/offerBrowsingTags';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';

function OfferBrowsingTagsPage() {
    const offerBrowsingTags = useOfferBrowsingTags();
    const region = getRegion();
    if (region !== Region.FR) return null;
    return (
        <View style={styles.container}>
            <BrowsingTags {...{ offerBrowsingTags }} />
        </View>
    );
}

export default OfferBrowsingTagsPage;

function useOfferBrowsingTags(): OfferBrowsingTag[] | undefined {
    const queryResponse = useQuery<OfferBrowsingTagQueryResponse, OfferBrowsingTagQueryVariables>(offerBrowsingTagQuery, {
        fetchPolicy: 'cache-and-network',
    });
    const offerBrowsingTags: OfferBrowsingTag[] | undefined = React.useMemo(
        () => queryResponse.data?.offerBrowsingTags?.items?.slice().sort((a, b) => b.score - a.score),
        [queryResponse]
    );
    return offerBrowsingTags;
}

function BrowsingTags({ offerBrowsingTags }: { offerBrowsingTags: OfferBrowsingTag[] | undefined }) {
    if (!offerBrowsingTags) return null;
    return (
        <View style={styles.containerBrowsingTags}>
            <FlatList<OfferBrowsingTag>
                data={offerBrowsingTags}
                renderItem={({ item, index }) => <BrowsingTag offerBrowsingTag={item} {...{ index }} />}
                keyExtractor={(item) => item.tagId}
                numColumns={4}
                keyboardShouldPersistTaps={'handled'}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={<View style={{ height: 90 }} />}
            />
        </View>
    );
}

function BrowsingTag({ offerBrowsingTag, index }: { offerBrowsingTag: OfferBrowsingTag; index: number }) {
    const history = useHistory();
    const backgroundColors = [color.blueChalk, color.pippin, color.hintOfGreen, color.oysterBay];
    const textColors = [color.electricViolet, color.flamingo, color.malachite, color.easternBlue];
    const getColorIndex = (index: number) => {
        return (index - Math.floor(index / 4)) % 4;
    };
    const name = trimOfferBrowsingTagName(offerBrowsingTag.displayName);
    return (
        <HoverableTouchableOpacity
            style={[styles.containerBrowsingTag, { backgroundColor: backgroundColors[getColorIndex(index)] }]}
            hoveredStyle={styles.containerBrowsingTagHovered}
            onPress={() => history.push(`/home/tag/${offerBrowsingTag.tagId}`)}>
            <View style={[styles.containerBrowsingTagText, { backgroundColor: textColors[getColorIndex(index)] }]}>
                <Text style={styles.textBrowsingTag}>{name}</Text>
            </View>
        </HoverableTouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    containerBrowsingTags: {
        width: 715,
    },
    containerBrowsingTag: {
        margin: 12,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: 110,
        borderRadius: 8,
        padding: 15,
    },
    containerBrowsingTagHovered: {
        opacity: 0.7,
    },
    containerBrowsingTagText: {
        borderRadius: 4,
        paddingHorizontal: 6,
        paddingVertical: 3,
    },
    textBrowsingTag: {
        textAlign: 'center',
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.white,
    },
});
