import * as React from 'react';
import { View, ScrollView, StyleSheet, Text, TextStyle } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { UserTrackingConsent, TrackingId } from '../../api/graphql/fragments/trackingConsents';
import {
    TrackingConsentsMap,
    buildTrackingConsentsMap,
    updateUserTrackingConsents,
} from '../../lib/common/trackingConsents';
import ModalContainer from '../../components/common/ModalContainer';
import SwitchButton from '../../components/common/SwitchButton';
import Button from '../../components/common/Button';

export default function TrackingPersonalizationModal({
    trackingConsents,
    setIsModalVisible,
}: {
    trackingConsents: UserTrackingConsent[] | undefined;
    setIsModalVisible: (value: boolean) => void;
}) {
    const texts = getLocalizedTexts().trackingConsents.trackingPersonalization;
    const windowHeight = useWindowSize().height || 0;
    const [trackingConsentsMap, setTrackingConsentsMap] = React.useState<TrackingConsentsMap>(
        buildTrackingConsentsMap(trackingConsents)
    );
    return (
        <ModalContainer cannotClose>
            <ScrollView
                style={{
                    marginTop:
                        windowHeight < TRACKING_PERSONALIZATION_HEIGHT
                            ? 0
                            : (windowHeight - TRACKING_PERSONALIZATION_HEIGHT) / 2,
                }}>
                <View style={styles.container}>
                    <Text style={styles.textTitle}>{texts.title}</Text>
                    <View style={styles.containerDivider}></View>
                    <NecessaryTrackers />
                    <PerformanceTrackers {...{ trackingConsentsMap, setTrackingConsentsMap }} />
                    <MarketingTrackers {...{ trackingConsentsMap, setTrackingConsentsMap }} />
                    <SubmitButton
                        {...{
                            trackingConsentsMap,
                            setIsModalVisible,
                        }}
                    />
                </View>
            </ScrollView>
        </ModalContainer>
    );
}

function Section({
    title,
    subtitle,
    children,
}: {
    title: string;
    subtitle: string;
    children: null | JSX.Element | JSX.Element[];
}) {
    return (
        <View>
            <Text style={styles.textSectionTitle}>{title}</Text>
            <Text style={styles.textSectionSubtitle}>{subtitle}</Text>
            <View style={styles.containerDivider}></View>
            {children}
        </View>
    );
}

function TrackingItem({
    text,
    isEnabled,
    onPress,
    textStyle,
    buttonSubtitle,
    disabled,
}: {
    text: string;
    isEnabled: boolean;
    onPress: () => any;
    textStyle?: TextStyle;
    buttonSubtitle?: string;
    disabled?: boolean;
}) {
    return (
        <>
            <View style={styles.containerTrackingItem}>
                <Text style={textStyle ?? styles.textTrackingItem}>{text}</Text>
                <View style={{ marginRight: 10 }}>
                    <SwitchButton {...{ isEnabled, onPress, subtitle: buttonSubtitle, disabled }} />
                </View>
            </View>
            <View style={styles.containerDivider}></View>
        </>
    );
}

function NecessaryTrackers() {
    const texts = getLocalizedTexts().trackingConsents.trackingPersonalization;
    return (
        <Section title={texts.necessaryTrackers.title} subtitle={texts.necessaryTrackers.subtitle}>
            <TrackingItem
                text={texts.necessaryTrackers.functionalTrackers}
                isEnabled={true}
                onPress={() => {}}
                buttonSubtitle={texts.required}
                disabled={true}
            />
            <TrackingItem
                text={texts.necessaryTrackers.securityTrackers}
                isEnabled={true}
                onPress={() => {}}
                buttonSubtitle={texts.required}
                disabled={true}
            />
        </Section>
    );
}

function PerformanceTrackers({
    trackingConsentsMap,
    setTrackingConsentsMap,
}: {
    trackingConsentsMap: TrackingConsentsMap;
    setTrackingConsentsMap: (value: TrackingConsentsMap) => void;
}) {
    const texts = getLocalizedTexts().trackingConsents.trackingPersonalization.performanceTrackers;
    const isPerformanceTrackerAccepted = trackingConsentsMap[TrackingId.amplitudeAnalyticsWebApp];
    return (
        <Section title={texts.title} subtitle={texts.subtitle}>
            <TrackingItem
                text={texts.amplitudeTrackers}
                isEnabled={isPerformanceTrackerAccepted}
                onPress={() =>
                    setTrackingConsentsMap({
                        ...trackingConsentsMap,
                        [TrackingId.amplitudeAnalyticsWebApp]: !isPerformanceTrackerAccepted,
                    })
                }
            />
        </Section>
    );
}

function MarketingTrackers({
    trackingConsentsMap,
    setTrackingConsentsMap,
}: {
    trackingConsentsMap: TrackingConsentsMap;
    setTrackingConsentsMap: (value: TrackingConsentsMap) => void;
}) {
    const texts = getLocalizedTexts().trackingConsents.trackingPersonalization;
    const isAmplitudeTrackerAccepted = trackingConsentsMap[TrackingId.amplitudeMarketingAttributionWebApp];
    const isGoogleTagManagerTrackerAccepted = trackingConsentsMap[TrackingId.googleTagManagerMarketingAttributionWebApp];
    const areAllMarketingTrackersAccepted = isAmplitudeTrackerAccepted && isGoogleTagManagerTrackerAccepted;
    return (
        <Section title={texts.marketingTrackers.title} subtitle={texts.marketingTrackers.subtitle}>
            <TrackingItem
                text={texts.activateAll}
                textStyle={styles.textActivateAllItem}
                isEnabled={areAllMarketingTrackersAccepted}
                onPress={() =>
                    setTrackingConsentsMap({
                        ...trackingConsentsMap,
                        [TrackingId.amplitudeMarketingAttributionWebApp]: !areAllMarketingTrackersAccepted,
                        [TrackingId.googleTagManagerMarketingAttributionWebApp]: !areAllMarketingTrackersAccepted,
                    })
                }
                buttonSubtitle={areAllMarketingTrackersAccepted ? texts.allActivated : undefined}
            />
            <TrackingItem
                text={texts.marketingTrackers.amplitudeTrackers}
                isEnabled={isAmplitudeTrackerAccepted}
                onPress={() =>
                    setTrackingConsentsMap({
                        ...trackingConsentsMap,
                        [TrackingId.amplitudeMarketingAttributionWebApp]: !isAmplitudeTrackerAccepted,
                    })
                }
            />
            <TrackingItem
                text={texts.marketingTrackers.googleTagManagerTrackers}
                isEnabled={isGoogleTagManagerTrackerAccepted}
                onPress={() =>
                    setTrackingConsentsMap({
                        ...trackingConsentsMap,
                        [TrackingId.googleTagManagerMarketingAttributionWebApp]: !isGoogleTagManagerTrackerAccepted,
                    })
                }
            />
        </Section>
    );
}

function SubmitButton({
    trackingConsentsMap,
    setIsModalVisible,
}: {
    trackingConsentsMap: TrackingConsentsMap;
    setIsModalVisible: (value: boolean) => void;
}) {
    const texts = getLocalizedTexts().trackingConsents.trackingPersonalization;
    const apolloClient = useApolloClient();
    const onPress = () => {
        updateUserTrackingConsents(trackingConsentsMap, apolloClient);
        setIsModalVisible(false);
    };
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 15, paddingVertical: 5 }}>
            <View style={{ alignItems: 'center' }}>
                <Button
                    onPress={onPress}
                    textStyle={styles.textButton}
                    height={40}
                    style={{
                        backgroundColor: color.black,
                        borderColor: color.black,
                        paddingHorizontal: 20,
                    }}>
                    {texts.submitButton}
                </Button>
            </View>
        </View>
    );
}

const TRACKING_PERSONALIZATION_HEIGHT = 650;

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        width: 550,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        paddingVertical: 15,
    },
    containerDivider: {
        marginVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: color.linkWaterLight,
    },
    containerTrackingItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 20,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.black,
        textAlign: 'center',
    },
    textSectionTitle: {
        paddingHorizontal: 20,
        marginBottom: 3,
        fontFamily: font.ambitBold,
        fontSize: 16,
        color: color.black,
    },
    textSectionSubtitle: {
        paddingHorizontal: 20,
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.tundora,
    },
    textTrackingItem: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.tundora,
    },
    textActivateAllItem: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.tundora,
    },
    textButton: {
        fontSize: 14,
    },
});
