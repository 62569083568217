import React from 'react';
import { View, StyleSheet, ActivityIndicator, Image, Text, TouchableOpacity, TextStyle } from 'react-native';
import { useHistory, useParams } from 'react-router';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

import { getLocalizedTexts, formatCurrencyAmount } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { ApolloClient } from '../../../api/graphql/client';
import { Reward, RewardType } from '../../../api/graphql/fragments/rewards';
import { rewardQuery, RewardQueryResponse, RewardQueryVariables } from '../../../api/graphql/queries/rewards';
import {
    redeemRewardMutation,
    RedeemRewardMutationResponse,
    RedeemRewardMutationVariables,
} from '../../../api/graphql/mutations/redeemReward';
import { hasPressedRedeemRewardVar } from '../../../api/graphql/reactiveVariables';
import {
    refetchHistoryAndPointsWithDelay,
    useUserPointBalance,
    convertPointsToCurrencyAmount,
} from '../../../lib/points/points';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { getEmailAndPhoneNumberStatuses } from '../../../lib/common/cognito';
import { HoverableTouchableOpacity } from '../../../components/common/HoverableComponents';

function RewardDetailPage() {
    const reward: Reward | undefined = useReward();
    const [dialogState, setDialogState] = React.useState<DialogState>({ isVisible: false });
    const closeDialog = () => setDialogState({ ...dialogState, isVisible: false });
    const [isRedeeming, setIsRedeeming] = React.useState<boolean>(false);
    if (!reward)
        return (
            <View style={[styles.container, { justifyContent: 'center', alignItems: 'center' }]}>
                <ActivityIndicator color={color.frenchGray} size="large" />
            </View>
        );
    if (!dialogState.isVisible)
        return (
            <View style={styles.container}>
                <BannerImage {...{ reward }} />
                <MainInfo {...{ reward }}>
                    <RedeemButton {...{ reward, setDialogState, isRedeeming, setIsRedeeming }} />
                </MainInfo>
                <Description {...{ reward }} />
            </View>
        );
    else return <Dialog {...{ dialogState, closeDialog }} />;
}

export default RewardDetailPage;

export function useReward(): Reward | undefined {
    let { rewardId } = useParams<{ rewardId: string | undefined }>();
    const queryResponse = useQuery<RewardQueryResponse, RewardQueryVariables>(rewardQuery, {
        variables: { rewardId: rewardId || '' },
        fetchPolicy: 'cache-and-network',
    });
    const userOffer: Reward | undefined = React.useMemo(() => queryResponse.data?.reward || undefined, [queryResponse]);
    return userOffer;
}

function BannerImage({ reward }: { reward: Reward }) {
    return (
        <View style={{ flexDirection: 'row' }}>
            <Image style={styles.imageBanner} source={{ uri: reward.screen?.imageURL }} />
        </View>
    );
}

function MainInfo({ reward, children }: { reward: Reward; children: JSX.Element }) {
    return (
        <View style={styles.containerMainInfo}>
            <Logo {...{ reward }} />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <MainInfoTexts {...{ reward }} />
                {children}
            </View>
        </View>
    );
}

function Logo({ reward }: { reward: Reward }) {
    return (
        <View style={styles.containerLogo}>
            <Image style={styles.imageLogo} source={{ uri: reward.screen?.logoURL }} />
        </View>
    );
}

function MainInfoTexts({ reward }: { reward: Reward }) {
    return (
        <View style={styles.containerMainInfoTexts}>
            <Text style={styles.textTitle}>{reward.screen?.title}</Text>
            <View style={styles.containerRewardAmount}>
                <Text style={styles.textRewardAmount}>
                    {formatCurrencyAmount(convertPointsToCurrencyAmount(reward.points || 0))}
                </Text>
            </View>
        </View>
    );
}

function Description({ reward }: { reward: Reward }) {
    return (
        <View style={styles.containerDescription}>
            <Text style={styles.textDescription}>{reward.screen?.description}</Text>
        </View>
    );
}

function RedeemButton({
    reward,
    setDialogState,
    isRedeeming,
    setIsRedeeming,
}: {
    reward: Reward;
    setDialogState: (value: DialogState) => void;
    isRedeeming: boolean;
    setIsRedeeming: (value: boolean) => void;
}) {
    const [hasEnoughPoints, onRedeem] = useRedeemReward(reward, setDialogState, isRedeeming, setIsRedeeming);
    const buttonText: string | undefined = useRedeemButtonText({ reward, hasEnoughPoints });
    return (
        <HoverableTouchableOpacity
            style={[styles.containerButton, !hasEnoughPoints ? { backgroundColor: color.frenchGray } : {}]}
            hoveredStyle={[hasEnoughPoints && styles.containerButtonHovered]}
            onPress={onRedeem}>
            {isRedeeming ? <ActivityIndicator color={color.white} /> : <Text style={styles.textButton}>{buttonText}</Text>}
        </HoverableTouchableOpacity>
    );
}

function useRedeemReward(
    reward: Reward,
    setDialogState: (value: DialogState) => void,
    isRedeeming: boolean,
    setIsRedeeming: (value: boolean) => void
): [boolean, () => void, boolean] {
    const texts = getLocalizedTexts().home.rewards.rewardDetails;
    const history = useHistory();
    const apolloClient = useApolloClient();
    const userPointBalance = useUserPointBalance();
    const hasEnoughPoints: boolean = React.useMemo(
        () => userPointBalance !== undefined && userPointBalance >= (reward.points || 0),
        [userPointBalance, reward]
    );
    const onRedeem = async () => {
        const { rewardId, type } = reward;
        if (!isRedeeming && hasEnoughPoints && reward.points) {
            const points = reward.points;
            logAmplitudeEvent({
                name: 'Rewards - Clicked Redeem',
                properties: { rewardId, type, points },
            });
            hasPressedRedeemRewardVar(true);
            const { emailVerified, phoneNumberVerified } = await getEmailAndPhoneNumberStatuses();
            if (!emailVerified || !phoneNumberVerified)
                setDialogState({
                    isVisible: true,
                    subtitle: texts.verificationNeededDialog.text({
                        phoneNumberVerified,
                        emailVerified,
                    }),
                    confirmButton: texts.verificationNeededDialog.button.next,
                    cancelButton: texts.verificationNeededDialog.button.back,
                    onConfirm: () => history.push('/home/profile/user-info'),
                });
            else
                setDialogState({
                    isVisible: true,
                    title: texts.confirmationDialog.title,
                    subtitle: texts.confirmationDialog.subtitle({
                        rewardType: type,
                        value: Number((points / 1000).toFixed(0)),
                        name: reward.screen?.title,
                    }),
                    confirmButton: texts.confirmationDialog.button.yes,
                    cancelButton: texts.confirmationDialog.button.no,
                    onConfirm: async () => {
                        setIsRedeeming(true);
                        logAmplitudeEvent({
                            name: 'Rewards - Confirmed Redeem',
                            properties: { rewardId, type, points },
                        });
                        await redeemReward(apolloClient, rewardId);
                        setDialogState({
                            isVisible: true,
                            title: texts.successDialog.title,
                            subtitle: texts.successDialog.subtitle,
                            confirmButton: texts.successDialog.button,
                            onConfirm: () => history.push('/home/rewards'),
                        });
                        setIsRedeeming(false);
                        refetchHistoryAndPointsWithDelay(apolloClient, 5000);
                    },
                });
        }
    };
    return [hasEnoughPoints, onRedeem, isRedeeming];
}

async function redeemReward(apolloClient: ApolloClient, rewardId: string) {
    console.log(`AppSync: redeeming reward ${rewardId}...`);
    await apolloClient.mutate<RedeemRewardMutationResponse, RedeemRewardMutationVariables>({
        mutation: redeemRewardMutation,
        variables: { rewardId },
    });
    console.log(`AppSync: reward redeemed`);
}

function useRedeemButtonText({ reward, hasEnoughPoints }: { reward: Reward; hasEnoughPoints: boolean }): string | undefined {
    const texts = getLocalizedTexts().home.rewards.rewardDetails.button;
    if (!hasEnoughPoints) return texts.balanceTooLow;
    else {
        if (reward.type === RewardType.donation) return texts.redeem.donation;
        if (reward.type === RewardType.giftCard) return texts.redeem.giftCard;
    }
}

export type DialogState = {
    isVisible: boolean;
    title?: string;
    subtitle?: string;
    confirmButton?: string;
    cancelButton?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export function Dialog({ dialogState, closeDialog }: { dialogState: DialogState; closeDialog: () => void }) {
    return (
        <View style={styles.containerDialog}>
            {dialogState.title ? <Text style={styles.textDialogTitle}>{dialogState.title}</Text> : null}
            <Text style={styles.textDialogSubtitle}>{dialogState.subtitle}</Text>
            <TouchableOpacity
                style={styles.containerDialogConfirmButton}
                onPress={() => {
                    closeDialog();
                    if (dialogState.onConfirm) dialogState.onConfirm();
                }}>
                <Text style={styles.textDialogConfirmButton}>{dialogState.confirmButton}</Text>
            </TouchableOpacity>
            {dialogState.cancelButton ? (
                <TouchableOpacity
                    style={styles.containerDialogCancelButton}
                    onPress={() => {
                        closeDialog();
                        if (dialogState.onCancel) dialogState.onCancel();
                    }}>
                    <Text style={styles.textDialogCancelButton}>{dialogState.cancelButton}</Text>
                </TouchableOpacity>
            ) : null}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 600,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    containerMainInfo: {
        flexDirection: 'column',
        paddingHorizontal: 40,
        paddingTop: 50,
        paddingBottom: 35,
    },
    containerLogo: {
        position: 'absolute',
        top: -40,
        justifyContent: 'center',
        alignItems: 'center',
        height: 80,
        width: 80,
        borderRadius: 80,
        borderWidth: 3,
        borderColor: color.white,
        backgroundColor: color.linkWaterLight,
    },
    containerMainInfoTexts: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    containerRewardAmount: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    containerButton: {
        height: 50,
        borderRadius: 80,
        backgroundColor: color.black,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 35,
    },
    containerButtonHovered: {
        opacity: 0.7,
    },
    containerDescription: {
        marginHorizontal: 40,
        paddingTop: 25,
        paddingBottom: 40,
        borderTopColor: color.linkWaterLight,
        borderTopWidth: 2,
    },
    containerDialog: {
        width: 400,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: color.white,
        padding: 40,
        borderRadius: 16,
    },
    containerDialogConfirmButton: {
        marginTop: 24,
        paddingVertical: 11,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 60,
        backgroundColor: color.black,
    },
    containerDialogCancelButton: {
        marginTop: 16,
        paddingVertical: 11,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 60,
        backgroundColor: color.white,
        borderWidth: 1,
        borderColor: color.black,
    },
    imageBanner: {
        flex: 1,
        height: 220,
        resizeMode: 'cover',
        backgroundColor: color.linkWaterLight,
    },
    imageLogo: {
        height: 40,
        width: 40,
        resizeMode: 'contain',
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginBottom: 5,
    },
    textRewardAmount: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emerald,
        marginLeft: 3,
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.white,
    },
    textDescription: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        lineHeight: 19.5,
        color: color.manatee,
    },
    textDialogTitle: {
        marginHorizontal: 25,
        marginBottom: 16,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textDialogSubtitle: {
        marginHorizontal: 15,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.black,
        textAlign: 'center',
    },
    textDialogSubtitleRewardAmount: {
        fontFamily: font.ambitBold,
        fontSize: 16,
        color: color.emerald,
    },
    textDialogConfirmButton: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.white,
    },
    textDialogCancelButton: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.black,
    },
});
