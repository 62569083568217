import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

import { getCognitoConfiguration } from '../../api/config';

export function checkIsSignedIn(): boolean {
    const cognitoConfiguration = getCognitoConfiguration();
    for (let i = 0; 1; i++) {
        const key = localStorage.key(i);
        if (!key) break;
        else if (key.startsWith(`CognitoIdentityServiceProvider.${cognitoConfiguration.userPoolWebClientId}`)) return true;
    }
    return false;
}

export function getUserId(): string | undefined {
    const cognitoConfiguration = getCognitoConfiguration();
    for (let i = 0; 1; i++) {
        const key = localStorage.key(i);
        if (!key) break;
        else {
            const match = key.match(
                `CognitoIdentityServiceProvider\\.${cognitoConfiguration.userPoolWebClientId}\\.(.*)\\.refreshToken`
            );
            if (match?.[1]) return match[1];
        }
    }
    return undefined;
}

export enum CognitoUserAttributeName {
    email = 'email',
    emailVerified = 'email_verified',
    phoneNumber = 'phone_number',
    phoneNumberVerified = 'phone_number_verified',
    givenName = 'given_name',
    gender = 'gender',
    age = 'custom:age',
}

export async function getCognitoUserAttributes(): Promise<CognitoUserAttribute[]> {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return await Auth.userAttributes(cognitoUser);
}

export async function getCognitoUserAttribute(
    userAttributeName: CognitoUserAttributeName
): Promise<CognitoUserAttribute | undefined> {
    const userAttributes: CognitoUserAttribute[] | null = await getCognitoUserAttributes();
    if (userAttributes)
        for (const userAttribute of userAttributes) if (userAttribute.getName() === userAttributeName) return userAttribute;
    return;
}

export async function getEmailAndPhoneNumberStatuses(): Promise<{ emailVerified: boolean; phoneNumberVerified: boolean }> {
    let emailVerified: boolean = false;
    let phoneNumberVerified: boolean = false;
    const userAttributes = await getCognitoUserAttributes();
    for (const userAttribute of userAttributes) {
        if (userAttribute.getName() === CognitoUserAttributeName.emailVerified)
            emailVerified = userAttribute.getValue() === 'true';
        if (userAttribute.getName() === CognitoUserAttributeName.phoneNumberVerified)
            phoneNumberVerified = userAttribute.getValue() === 'true';
    }
    return { emailVerified, phoneNumberVerified };
}
