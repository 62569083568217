import React from 'react';
import { TouchableOpacity, Image, StyleSheet } from 'react-native';

import color from '../../style/color';

const crossIcon = { uri: '/assets/images/icons/cross-white.svg' };

export default function CloseButton({
    onPress,
    shouldCrossBeDiscrete,
}: {
    onPress: () => void;
    shouldCrossBeDiscrete?: boolean;
}) {
    return (
        <TouchableOpacity style={shouldCrossBeDiscrete ? styles.containerDiscrete : styles.container} onPress={onPress}>
            <Image source={crossIcon} style={shouldCrossBeDiscrete ? styles.imageDiscrete : styles.image} />
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 32,
        width: 32,
        borderRadius: 32,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.tundora,
    },
    containerDiscrete: {
        height: 25,
        width: 25,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.frenchGray,
    },
    image: {
        height: 12,
        width: 12,
    },
    imageDiscrete: {
        height: 10,
        width: 10,
    },
});
