import * as React from 'react';
import { StyleSheet, View, TextInput, ViewStyle } from 'react-native';

import color from '../../style/color';
import { font } from '../../style/text';

export function FirstNameInput({
    firstName,
    firstNamePlaceholder,
    setFirstName,
    isLoading,
}: {
    firstName: string;
    firstNamePlaceholder: string;
    setFirstName: (firstName: string) => void;
    isLoading: boolean;
}) {
    return (
        <View style={[firstName ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                style={styles.textInput}
                placeholder={firstNamePlaceholder}
                value={firstName}
                blurOnSubmit={false}
                autoCorrect={false}
                autoCapitalize={'words'}
                keyboardType={'default'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setFirstName}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
}

export function AgeInput({
    age,
    agePlaceholder,
    setAge,
    isLoading,
    style,
}: {
    age: string;
    agePlaceholder: string;
    setAge: (age: string) => void;
    isLoading: boolean;
    style?: ViewStyle;
}) {
    return (
        <View style={[age ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput, style]}>
            <TextInput
                style={styles.textInput}
                placeholder={agePlaceholder}
                value={String(age)}
                blurOnSubmit={false}
                autoCorrect={false}
                keyboardType={'numeric'}
                returnKeyType="next"
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setAge}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
}

export function ReferrerCodeInput({
    referrerCode,
    referrerCodePlaceholder,
    setReferrerCode,
    isLoading,
}: {
    referrerCode: string;
    referrerCodePlaceholder: string;
    setReferrerCode: (referrerCode: string) => void;
    isLoading: boolean;
}) {
    return (
        <View style={[referrerCode ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                style={styles.textInput}
                placeholder={referrerCodePlaceholder}
                value={referrerCode}
                blurOnSubmit={false}
                autoCorrect={false}
                autoCapitalize={'words'}
                keyboardType={'default'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setReferrerCode}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    containerTextInput: {
        flexDirection: 'row',
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitSemiBold,
        color: color.tundora,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.tundora,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
});
