import * as React from 'react';
import { View, StyleSheet, Text, Image, ActivityIndicator } from 'react-native';
import { useParams } from 'react-router';
// @ts-ignore
import { remove as removeDiacritics } from 'diacritics';

import { getLocalizedTexts } from '../../Locales';
import { font } from '../../style/text';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { NAVIGATION_BAR_HEIGHT } from '../../style/size';
import { containerStyles } from '../../style/container';
import { MinimalUserOffer } from '../../api/graphql/queries/enrichedOffers';
import { useOfferBrowsingTag } from '../../lib/offers/offerBrowsingTags';
import { useSearchableUserOffers } from '../../lib/offers/offers';
import { ProgressiveFlatList } from '../../components/common/ProgressiveList';
import OfferComponent from '../../components/home/offers/OfferComponent';
import Footer from '../../components/common/Footer';

const emptyImage = { uri: '/assets/images/illustrations/undraw-empty.svg' };

function OfferSearchPage() {
    const texts = getLocalizedTexts().home.offers.offerSearch;
    const { searchValue, tagId } = useParams<{ searchValue: string | undefined; tagId: string | undefined }>();
    const searchableUserOffers = useSearchableUserOffers();
    const searchResults = useSearchResults(searchableUserOffers, searchValue, tagId);
    const offerBrowsingTag = useOfferBrowsingTag(tagId, 'cache-only');
    return (
        <View style={styles.container}>
            {searchResults !== undefined ? (
                <SearchResults
                    {...{ searchResults }}
                    ListHeaderComponent={
                        <View style={styles.containerTitle}>
                            <Text style={styles.textTitle}>
                                {searchValue ? texts.searchResults({ searchValue }) : offerBrowsingTag?.displayName}
                            </Text>
                        </View>
                    }
                    ListFooterComponent={<Footer />}
                />
            ) : (
                <LoadingScreen />
            )}
        </View>
    );
}

export default OfferSearchPage;

function useSearchResults(
    searchableUserOffers: MinimalUserOffer[] | undefined,
    searchValue: string | undefined,
    offerBrowsingTagId: string | undefined
): MinimalUserOffer[] | undefined {
    const userOfferPreprocessingResult = useUserOfferPreprocessing(searchableUserOffers);
    const searchResults: MinimalUserOffer[] | undefined = React.useMemo(() => {
        if (!userOfferPreprocessingResult) return undefined;
        const { userOffersByOfferId, offerIdsByCleanedTitle } = userOfferPreprocessingResult;
        let searchRegexp: RegExp | undefined;
        try {
            searchRegexp = new RegExp(removeDiacritics(searchValue), 'i');
        } catch {}
        const searchResults: MinimalUserOffer[] = [];
        for (const cleanedTitle of Object.keys(offerIdsByCleanedTitle)) {
            if (!searchRegexp || cleanedTitle.match(searchRegexp))
                for (const offerId of offerIdsByCleanedTitle[cleanedTitle]) {
                    const userOffer = userOffersByOfferId[offerId];
                    if (
                        offerBrowsingTagId === undefined ||
                        offerBrowsingTagId === 'default' ||
                        userOffer.offer?.browsingTagIds?.includes(offerBrowsingTagId)
                    )
                        searchResults.push(userOffer);
                }
        }
        return searchResults;
    }, [userOfferPreprocessingResult, searchValue, offerBrowsingTagId]);
    return searchResults;
}

function useUserOfferPreprocessing(userOffers: MinimalUserOffer[] | undefined):
    | {
          userOffersByOfferId: { [offerId: string]: MinimalUserOffer };
          offerIdsByCleanedTitle: { [title: string]: string[] };
      }
    | undefined {
    const preprocessingResult = React.useMemo(() => {
        const userOffersByOfferId: { [offerId: string]: MinimalUserOffer } = {};
        const offerIdsByCleanedTitle: { [title: string]: string[] } = {};
        if (userOffers) {
            for (const userOffer of userOffers) {
                userOffersByOfferId[userOffer.offerId] = userOffer;
                const {
                    offer: { offerId, screen },
                } = userOffer;
                if (screen) {
                    const cleanedTitle: string = removeDiacritics(screen.title);
                    if (!offerIdsByCleanedTitle[cleanedTitle]) offerIdsByCleanedTitle[cleanedTitle] = [];
                    offerIdsByCleanedTitle[cleanedTitle].push(offerId);
                }
            }
            return { userOffersByOfferId, offerIdsByCleanedTitle };
        } else return undefined;
    }, [userOffers]);
    return preprocessingResult;
}

function LoadingScreen() {
    const size = useWindowSize();
    const height = (size.height || 0) - NAVIGATION_BAR_HEIGHT;
    return (
        <View style={{ height, justifyContent: 'center' }}>
            <ActivityIndicator size={'large'} color={color.frenchGray} />
        </View>
    );
}

function SearchResults({
    searchResults,
    ListHeaderComponent,
    ListFooterComponent,
}: {
    searchResults: MinimalUserOffer[];
    ListHeaderComponent: JSX.Element;
    ListFooterComponent: JSX.Element;
}) {
    const size = useWindowSize();
    const height = (size.height || 0) - NAVIGATION_BAR_HEIGHT;
    const width = size.width;
    if (searchResults.length > 0)
        return (
            <ProgressiveFlatList<MinimalUserOffer>
                style={{ height, width }}
                data={searchResults}
                renderItem={({ item }) => <SearchResult searchResult={item} />}
                keyExtractor={(item) => item.offerId}
                keyboardShouldPersistTaps={'handled'}
                initialNumberOfItemsToDisplay={10}
                ListHeaderComponent={ListHeaderComponent}
                ListFooterComponent={ListFooterComponent}
            />
        );
    else
        return (
            <View style={containerStyles.containerCenter}>
                {ListHeaderComponent}
                <Image source={emptyImage} style={{ width: 400, height: 400, resizeMode: 'contain' }} />
            </View>
        );
}

function SearchResult({ searchResult }: { searchResult: MinimalUserOffer }) {
    return (
        <View style={styles.containerSearchResult}>
            <OfferComponent offerId={searchResult.offerId} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    containerTitle: {
        alignSelf: 'center',
        width: 460,
        paddingTop: 55,
        paddingBottom: 25,
    },
    containerSearchResult: {
        alignSelf: 'center',
        width: 470,
        marginVertical: 7,
    },
    textTitle: {
        textAlign: 'left',
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.black,
    },
});
