import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { GetSocialIdentityCredentialsResponse, getUserId } from '../../api/rest/user';
import { EmailAddressUnknown, GeneratedPassword } from '../../api/rest/errors';
import { PasswordType } from '../../api/graphql/fragments/user';
import { usePrevious } from '../../lib/common/utilHooks';
import { getDeviceId } from '../../lib/common/device';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { isEmailValid } from '../../lib/auth/emailSignUp';
import { getRegion } from '../../lib/auth/region';
import Button from '../../components/common/Button';
import OnboardingProgressIndicator, { OnboardingStep } from '../../components/onboarding/OnboardingProgressIndicator';
import { EmailInput, TermsOfServiceConsentCheckBox, NewsletterConsentCheckBox, SignUpErrorMessage } from './EmailSignUpPage';

const leftChevronIcon = { uri: '/assets/images/icons/chevron-left-gray.svg' };

function SocialSignUpPage({
    initialEmail,
    credentials,
}: {
    initialEmail: string;
    credentials: GetSocialIdentityCredentialsResponse;
}) {
    const texts = getLocalizedTexts().auth.signUp.withEmail;
    const history = useHistory();
    const [email, setEmail] = React.useState<string>(initialEmail);
    const previousEmail = usePrevious(email);
    const [consentedToTermsOfService, setConsentedToTermsOfService] = React.useState<boolean>(false);
    const [consentedToNewsletter, setConsentedToNewsletter] = React.useState<boolean>(false);
    const [signUp, isLoading, error, clearError] = useSignUp();
    const onPress = () => {
        if (isEmailValid(email) && consentedToTermsOfService)
            signUp(email, credentials, consentedToTermsOfService, consentedToNewsletter);
    };
    React.useEffect(() => {
        if (email.length !== previousEmail?.length) clearError();
    }, [email, previousEmail, clearError]);
    return (
        <View style={styles.container}>
            <View style={styles.containerContent}>
                <OnboardingProgressIndicator step={OnboardingStep.accountCreationStep} />
                <Text style={styles.textTitle}>{texts.emailTitle}</Text>
                <EmailInput {...{ email, setEmail, isLoading }} />
                <View style={styles.containerCheckBoxes}>
                    <TermsOfServiceConsentCheckBox
                        isChecked={consentedToTermsOfService}
                        setIsChecked={setConsentedToTermsOfService}
                    />
                    <View style={{ height: 18 }} />
                    <NewsletterConsentCheckBox isChecked={consentedToNewsletter} setIsChecked={setConsentedToNewsletter} />
                </View>
                {error ? <SignUpErrorMessage {...{ error }} /> : null}
                <View style={styles.containerNextButton}>
                    <Button
                        {...{ onPress, isLoading }}
                        textStyle={styles.textNextButton}
                        height={40}
                        disabled={!isEmailValid(email) || !consentedToTermsOfService}>
                        {texts.nextButton}
                    </Button>
                </View>
                <TouchableOpacity style={styles.containerBackButton} onPress={() => history.goBack()}>
                    <Image source={leftChevronIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                    <Text style={styles.textBackButton}>{texts.backButton}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

export default SocialSignUpPage;

enum SignUpError {
    AlreadyExists = 'AlreadyExists',
    Other = 'Other',
}

function useSignUp(): [
    (
        email: string,
        credentials: GetSocialIdentityCredentialsResponse,
        consentedToTermsOfService: boolean,
        consentedToNewsletter: boolean
    ) => Promise<void>,
    boolean,
    SignUpError | undefined,
    () => void
] {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<SignUpError | undefined>();
    const signUp = async (
        email: string,
        credentials: GetSocialIdentityCredentialsResponse,
        consentedToTermsOfService: boolean,
        consentedToNewsletter: boolean
    ): Promise<void> => {
        if (isEmailValid(email)) {
            setIsLoading(true);
            logAmplitudeEvent({ name: 'Registration - Validated Email', properties: { email } });
            if (consentedToTermsOfService)
                logAmplitudeEvent({ name: 'Registration - Ticked Checkbox For Terms Of Service and Privacy Policy' });
            if (consentedToNewsletter) logAmplitudeEvent({ name: 'Registration - Ticked Checkbox For Newsletter' });
            try {
                await getUserId(email);
                setError(SignUpError.AlreadyExists);
            } catch (error) {
                if (error instanceof EmailAddressUnknown) {
                    try {
                        const region = getRegion();
                        await Auth.signUp({
                            username: credentials.userId,
                            password: credentials.secretKey,
                            attributes: {
                                email: email.toLowerCase(),
                                'custom:password_type': PasswordType.generated,
                                'custom:region': region,
                                'custom:newsletter_consent': `${consentedToNewsletter}`,
                                'custom:signup_platform': 'webApp',
                                'custom:signup_device_id': getDeviceId(),
                            },
                        });
                        Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
                        await Auth.signIn({ username: credentials.userId, password: credentials.secretKey });
                        await Auth.verifyCurrentUserAttribute('email');
                        history.push('/onboarding');
                        window.location.reload();
                    } catch (error) {
                        console.log(error);
                        setError(SignUpError.Other);
                    }
                } else if (error instanceof GeneratedPassword) setError(SignUpError.AlreadyExists);
                else setError(SignUpError.Other);
            }
            setIsLoading(false);
        }
    };
    const clearError = () => setError(undefined);
    return [signUp, isLoading, error, clearError];
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerContent: {
        marginTop: 68,
        width: 424,
    },
    containerCheckBoxes: {
        marginTop: 40,
    },
    containerNextButton: {
        marginTop: 40,
    },
    containerBackButton: {
        alignSelf: 'center',
        marginTop: 16,
        flexDirection: 'row',
    },
    textTitle: {
        marginTop: 40,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginBottom: 16,
    },
    textNextButton: {
        fontSize: 14,
    },
    textBackButton: {
        fontFamily: font.ambitBlack,
        color: color.silverChalice,
        fontSize: 14,
        paddingLeft: 8,
    },
});
