import { UserPoints, userPointsFragment } from '../fragments/points';

const gql = require('graphql-tag');

export const pointQuery = gql`
    query PointQuery {
        user {
            points {
                ...UserPoints
            }
        }
    }
    ${userPointsFragment}
`;

interface User {
    __typename: 'User';
    points: UserPoints;
}

export interface PointQueryResponse {
    __typename: 'Query';
    user: User;
}
