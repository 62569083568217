import { StyleSheet } from 'react-native';

export const containerStyles = StyleSheet.create({
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        flexDirection: 'row',
    },
    containerRowLeftCenter: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    containerRowSpaceBetween: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
