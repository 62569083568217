import * as React from 'react';
import { StyleProp, TouchableOpacity, TouchableOpacityProps, View, ViewProps, ViewStyle } from 'react-native';

export function HoverableContainer({
    children,
}: {
    children: (isHovered: boolean) => JSX.Element | (JSX.Element | null)[];
}) {
    const [isHovered, setIsHovered] = React.useState(false);
    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);
    return React.cloneElement(React.Children.only(children(isHovered)), { onMouseEnter, onMouseLeave });
}

export function HoverableView({
    children,
    style,
    hoveredStyle,
    ...props
}: {
    children: JSX.Element | (JSX.Element | null)[] | null;
    style?: StyleProp<ViewStyle>;
    hoveredStyle?: StyleProp<ViewStyle>;
} & ViewProps) {
    const [isHovered, setIsHovered] = React.useState(false);
    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);
    return React.cloneElement(
        React.Children.only(
            <View {...props} style={[style, isHovered && hoveredStyle]}>
                {children}
            </View>
        ),
        { onMouseEnter, onMouseLeave }
    );
}

export function HoverableTouchableOpacity({
    children,
    style,
    hoveredStyle,
    ...props
}: {
    children: JSX.Element | (JSX.Element | null)[] | null;
    style?: StyleProp<ViewStyle>;
    hoveredStyle?: StyleProp<ViewStyle>;
} & TouchableOpacityProps) {
    const [isHovered, setIsHovered] = React.useState(false);
    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);
    return React.cloneElement(
        React.Children.only(
            <TouchableOpacity {...props} style={[style, isHovered && hoveredStyle]}>
                {children}
            </TouchableOpacity>
        ),
        { onMouseEnter, onMouseLeave }
    );
}
