import * as React from 'react';
import {
    Modal,
    StyleSheet,
    View,
    Text,
    Image,
    TouchableOpacity,
    Dimensions,
    Platform,
    ActivityIndicator,
} from 'react-native';
import { useLocation, useParams } from 'react-router';
import qs from 'qs';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { containerStyles } from '../../style/container';
import { getPublicSetting } from '../../api/rest/settings';
import { OnlineOfferActivationInfo, fetchOnlineOfferActivationInfo } from '../../api/rest/offers';
import { logUserEventWithoutAuthentication } from '../../api/rest/events';
import { checkIsAdBlockerActivated } from '../../lib/common/adBlocker';
import { getHelpCenterUrl } from '../../lib/auth/region';
import {
    AffiliateLinkLoadingMethod,
    generateNewAffiliateLinkLoadingAttemptData,
    generateNewAffiliateLinkLoadingMechanismData,
    logClickedAffiliateLinkEventsWithoutAuthentication,
} from '../../lib/offers/affiliateLinkLoading';
import FloatingIcon from '../../components/common/FloatingIcon';
import Button from '../../components/common/Button';
import OfferCashback from '../../components/home/offers/OfferCashback';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };
const currencySymbol = { uri: '/assets/images/visualIdentity/currency-symbol-green.svg' };
const ticketIcon = { uri: '/assets/images/icons/ticket.svg' };
const giftIcon = { uri: '/assets/images/icons/gift-pink-tilted.svg' };
const checkMarkIconFilled = { uri: '/assets/images/icons/check-mark-green-filled-2.svg' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark-gray.svg' };
const errorIcon = { uri: '/assets/images/icons/error.svg' };
const cookieIcon = { uri: '/assets/images/icons/cookie.png' };

const MIN_LOADING_TIME_MS = 1500;
const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 1000;

enum OfferActivationState {
    loading = 'loading',
    succeeded = 'succeeded',
    adBlockerDetected = 'adBlockerDetected',
    cookieWarningAcknowledgementRequired = 'cookieWarningAcknowledgementRequired',
}

function OnlineOfferActivationPage() {
    const { userId, offerId, stackMode } = useParams<{ userId: string; offerId: string; stackMode?: string }>();
    const shouldUseDevStack: boolean = stackMode === 'dev';
    const { triggeringEventPlatform, triggeringEventType, triggeringEventTimestamp, redirectUrl, isTemporaryUser } =
        useQueryParameters();
    const [offerActivationInfo, setOfferActivationInfo] = React.useState<OnlineOfferActivationInfo | undefined>(undefined);
    React.useEffect(() => {
        fetchOnlineOfferActivationInfo(userId, offerId, shouldUseDevStack, redirectUrl, triggeringEventPlatform).then(
            (info) => setOfferActivationInfo(info)
        );
    }, [userId, offerId, shouldUseDevStack, redirectUrl, triggeringEventPlatform]);
    const affiliateLinkUrl = useAffiliateLinkUrl({
        userId,
        isTemporaryUser,
        shouldUseDevStack,
        offerActivationInfo,
        triggeringEventPlatform,
        triggeringEventType,
        triggeringEventTimestamp,
    });
    const offerActivationState = useOfferActivationState(offerActivationInfo, affiliateLinkUrl);
    if (offerActivationInfo && affiliateLinkUrl)
        return (
            <Container>
                <Image source={logo} style={styles.imageLogo} />
                <View style={styles.containerBody}>
                    {offerActivationState !== OfferActivationState.cookieWarningAcknowledgementRequired ? (
                        <OfferActivationStatus {...{ offerActivationState, offerActivationInfo }} />
                    ) : null}
                    {offerActivationState === OfferActivationState.adBlockerDetected ? <AdBlockerMessage /> : null}
                    {offerActivationState === OfferActivationState.adBlockerDetected && Platform.OS === 'web' ? (
                        <NavigationButtons {...{ affiliateLinkUrl, offerActivationInfo }} />
                    ) : null}
                    {offerActivationInfo.hasAcknowledgedWarningForCookieTracking &&
                    offerActivationState !== OfferActivationState.adBlockerDetected ? (
                        <CookieWarningWithoutAcknowledgmentRequired />
                    ) : null}
                    {offerActivationState === OfferActivationState.cookieWarningAcknowledgementRequired ? (
                        <CookieWarningWithAcknowledgmentRequired
                            {...{ userId, affiliateLinkUrl, offerActivationInfo, shouldUseDevStack }}
                        />
                    ) : null}
                </View>
                <View style={styles.containerFooter}>
                    {offerActivationState === OfferActivationState.adBlockerDetected && Platform.OS !== 'web' ? (
                        <NavigationButtons {...{ affiliateLinkUrl, offerActivationInfo }} />
                    ) : null}
                </View>
            </Container>
        );
    else return <LoadingAnimation />;
}

export default OnlineOfferActivationPage;

function useQueryParameters(): {
    triggeringEventPlatform: string | undefined;
    triggeringEventType: string | undefined;
    triggeringEventTimestamp: number | undefined;
    redirectUrl: string | undefined;
    isTemporaryUser: boolean;
} {
    const location = useLocation();
    return React.useMemo(() => {
        const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
        return {
            triggeringEventPlatform:
                typeof parsedQuery?.triggeringEventPlatform === 'string' ? parsedQuery.triggeringEventPlatform : undefined,
            triggeringEventType:
                typeof parsedQuery?.triggeringEventType === 'string' ? parsedQuery.triggeringEventType : undefined,
            triggeringEventTimestamp: Number.isInteger(Number(parsedQuery?.triggeringEventTimestamp))
                ? Number(parsedQuery?.triggeringEventTimestamp)
                : undefined,
            redirectUrl:
                typeof parsedQuery?.redirectUrl === 'string' && parsedQuery.redirectUrl !== ''
                    ? decodeURIComponent(parsedQuery.redirectUrl)
                    : undefined,
            isTemporaryUser:
                typeof parsedQuery?.isTemporaryUser === 'string' && parsedQuery.isTemporaryUser !== ''
                    ? parsedQuery.isTemporaryUser === 'true'
                    : false,
        };
    }, [location]);
}

function useAffiliateLinkUrl({
    userId,
    isTemporaryUser,
    shouldUseDevStack,
    offerActivationInfo,
    triggeringEventPlatform,
    triggeringEventType,
    triggeringEventTimestamp,
}: {
    userId: string;
    isTemporaryUser: boolean;
    shouldUseDevStack: boolean;
    offerActivationInfo: OnlineOfferActivationInfo | undefined;
    triggeringEventPlatform: string | undefined;
    triggeringEventType: string | undefined;
    triggeringEventTimestamp: number | undefined;
}) {
    const [affiliateLinkUrl, setAffiliateLinkUrl] = React.useState<string | undefined>(undefined);
    const getAffiliateLinkUrl = React.useCallback(
        async (offerActivationInfo: OnlineOfferActivationInfo) => {
            const affiliateLinkLoadingMechanismData = await generateNewAffiliateLinkLoadingMechanismData({
                userId,
                isTemporaryUser,
                offerActivationInfo,
                triggeringEventPlatform,
                triggeringEventType,
                triggeringEventTimestamp,
            });
            if (!affiliateLinkLoadingMechanismData) return;
            const attemptNumber = 1; // We will make only a single attempt to load the affiliate link
            // The affiliate link URL that embeds a Click ID is generated by this operation
            const affiliateLinkLoadingAttemptData = generateNewAffiliateLinkLoadingAttemptData(
                affiliateLinkLoadingMechanismData,
                AffiliateLinkLoadingMethod.directLoading,
                attemptNumber
            );
            // This step is instrumental because it will allow us to retrieve the User ID from the Click ID passed in the affiliate link
            logClickedAffiliateLinkEventsWithoutAuthentication(
                affiliateLinkLoadingAttemptData,
                offerActivationInfo.hasRedirectUrl,
                offerActivationInfo.parametersRemovedFromRedirectUrl,
                shouldUseDevStack
            );
            setAffiliateLinkUrl(affiliateLinkLoadingAttemptData.affiliateLinkUrl);
        },
        [userId, isTemporaryUser, shouldUseDevStack, triggeringEventPlatform, triggeringEventType, triggeringEventTimestamp]
    );
    React.useEffect(() => {
        if (offerActivationInfo) getAffiliateLinkUrl(offerActivationInfo);
    }, [offerActivationInfo, getAffiliateLinkUrl]);
    return affiliateLinkUrl;
}

function useOfferActivationState(
    offerActivationInfo: OnlineOfferActivationInfo | undefined,
    affiliateLinkUrl: string | undefined
): OfferActivationState {
    const shouldAcknowledgeCookieWarning: boolean = offerActivationInfo
        ? !offerActivationInfo.hasAcknowledgedWarningForCookieTracking
        : false;
    const [offerActivationState, setOfferActivationState] = React.useState<OfferActivationState>(
        OfferActivationState.loading
    );
    const onSuccess = React.useCallback(
        () =>
            shouldAcknowledgeCookieWarning
                ? setTimeout(
                      () => setOfferActivationState(OfferActivationState.cookieWarningAcknowledgementRequired),
                      MIN_LOADING_TIME_MS
                  )
                : setTimeout(
                      () => affiliateLinkUrl && redirectToAffiliateLink({ offerActivationInfo, affiliateLinkUrl }),
                      SUCCESS_MESSAGE_DISPLAY_TIME_MS
                  ),
        [shouldAcknowledgeCookieWarning, affiliateLinkUrl, offerActivationInfo]
    );
    const runChecks = React.useCallback(async () => {
        const startTimestampMs = Date.now();
        const isAdBlockerActivated = await checkIsAdBlockerActivated();
        const checkDurationMs = Date.now() - startTimestampMs;
        await sleep(Math.max(MIN_LOADING_TIME_MS - checkDurationMs, 0));
        if (isAdBlockerActivated) setOfferActivationState(OfferActivationState.adBlockerDetected);
        else {
            setOfferActivationState(OfferActivationState.succeeded);
            onSuccess();
        }
    }, [onSuccess]);
    React.useEffect(() => {
        if (offerActivationInfo) runChecks();
    }, [offerActivationInfo, runChecks]);
    return offerActivationState;
}

function redirectToAffiliateLink({
    offerActivationInfo,
    affiliateLinkUrl,
}: {
    offerActivationInfo: OnlineOfferActivationInfo | undefined;
    affiliateLinkUrl: string;
}) {
    const parsedQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const initialUrl =
        typeof parsedQuery?.redirectUrl === 'string' && parsedQuery.redirectUrl !== ''
            ? decodeURIComponent(parsedQuery.redirectUrl)
            : undefined;
    // We don't want to monitor the URL change if there is no `redirectUrl` parameter in the URL or if there is no offer activation info
    if (initialUrl && offerActivationInfo)
        postOfferActivatedInitialUrlMessageToExtension({ initialUrl, offerActivationInfo });
    window.open(affiliateLinkUrl, '_self');
}

type OfferActivatedCurrentUrlPayload = {
    initialUrl: string;
    offerId: string;
    personalizedAffiliateLink: string | undefined;
    isAutomaticRedirectUrlApplied: boolean;
};

/** This function is used in the extensions for affiliate link loading monitoring purposes */
function postOfferActivatedInitialUrlMessageToExtension({
    initialUrl,
    offerActivationInfo,
}: {
    initialUrl: string;
    offerActivationInfo: OnlineOfferActivationInfo;
}) {
    const offerId = offerActivationInfo.partialOffer.offerId;
    const personalizedAffiliateLink = offerActivationInfo.personalizedAffiliateLink;
    const payload: OfferActivatedCurrentUrlPayload = {
        initialUrl,
        offerId,
        personalizedAffiliateLink,
        isAutomaticRedirectUrlApplied: offerActivationInfo.hasRedirectUrl,
    };
    window.postMessage({ type: 'joko:saveOfferActivatedInitialUrlPayload', payload }, '*');
}

function sleep(sleepTimeMs: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, sleepTimeMs));
}

function LoadingAnimation() {
    return (
        <View style={styles.containerLoadingAnimation}>
            <ActivityIndicator color={color.frenchGray} size="large" />
        </View>
    );
}

function Container({ children }: { children: JSX.Element | (JSX.Element | null)[] | null }) {
    const size = useWindowSize();
    return (
        <Modal>
            <View style={[styles.container, { height: size.height, width: size.width }]}>{children}</View>
        </Modal>
    );
}

function OfferActivationStatus({
    offerActivationState,
    offerActivationInfo,
}: {
    offerActivationState: OfferActivationState;
    offerActivationInfo: OnlineOfferActivationInfo;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.offerActivationStatus;
    return (
        <View
            style={[
                styles.containerBox,
                offerActivationState === OfferActivationState.succeeded && styles.containerBoxGreen,
            ]}>
            <StatusIcon {...{ offerActivationState }} />
            <Text
                style={[
                    styles.textTitle,
                    offerActivationState === OfferActivationState.succeeded && styles.textTitleSucceeded,
                    offerActivationState === OfferActivationState.adBlockerDetected && styles.textTitleFailed,
                ]}>
                {offerActivationState === OfferActivationState.loading
                    ? texts.loading
                    : offerActivationState === OfferActivationState.succeeded
                    ? texts.succeeded
                    : texts.failed}
            </Text>
            <OfferDetails {...offerActivationInfo} />
        </View>
    );
}

function StatusIcon({ offerActivationState }: { offerActivationState: OfferActivationState }) {
    if (offerActivationState === OfferActivationState.loading)
        return (
            <View style={styles.containerFloatingIcons}>
                <FloatingIcon icon={currencySymbol} iconStyle={{ width: 20, height: 20 }} />
                <FloatingIcon icon={ticketIcon} offsetY={-10} />
                <FloatingIcon icon={giftIcon} />
            </View>
        );
    if (offerActivationState === OfferActivationState.succeeded)
        return <Image source={checkMarkIconFilled} style={styles.imageStatusIcon} />;
    return <Image source={errorIcon} style={styles.imageStatusIcon} />;
}

function OfferDetails(offerInfo: OnlineOfferActivationInfo | undefined) {
    if (offerInfo) {
        const partialOffer = offerInfo.partialOffer;
        return (
            <View style={styles.containerOfferDetails}>
                <View style={containerStyles.containerRowLeftCenter}>
                    <View style={styles.containerOfferLogo}>
                        <Image source={{ uri: partialOffer.card?.logoURL || '' }} style={styles.imageOfferLogo} />
                    </View>
                    <View style={styles.containerOfferTexts}>
                        <Text style={styles.textOfferTitle} numberOfLines={1}>
                            {partialOffer.card?.title}
                        </Text>
                        <OfferCashback {...{ offer: partialOffer, shouldUseValueMinCondition: true }} />
                    </View>
                </View>
            </View>
        );
    }
    return null;
}

function AdBlockerMessage() {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.adBlockerMessage;
    const subtitle1 = texts.subtitle1;
    const subtitle2 = Platform.OS === 'web' ? texts.subtitle2OnDesktop : texts.subtitle2OnMobile;
    const shouldSkipLine: boolean = isDeviceScreenLarge;
    const subtitle = `${subtitle1}${shouldSkipLine ? '\n' : ' '}${subtitle2}`;
    return (
        <View style={[styles.containerBox, styles.containerAdBlockerMessage]}>
            <Text style={[styles.textTitle, styles.textAdBlockerMessageTitle]}>{texts.title}</Text>
            <Text style={[styles.textSubtitle, styles.textAdBlockerMessageSubtitle]}>{subtitle}</Text>
            <TouchableOpacity
                onPress={async () => {
                    const settingKey: string = 'adBlockerFaqLink';
                    const adBlockerFaqArticleUrl: string =
                        (await getPublicSetting(settingKey)) || getHelpCenterUrl({ path: '' });
                    window.open(adBlockerFaqArticleUrl);
                }}>
                <Text style={styles.textAdBlockerMessageSupportLink}>{texts.supportLink}</Text>
            </TouchableOpacity>
        </View>
    );
}

function NavigationButtons({
    affiliateLinkUrl,
    offerActivationInfo,
}: {
    affiliateLinkUrl: string;
    offerActivationInfo: OnlineOfferActivationInfo;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.adBlockerMessage.navigationButtons;
    return (
        <View style={styles.containerNavigationButtons}>
            {Platform.OS === 'web' ? (
                <Button
                    onPress={() => window.location.reload()}
                    style={styles.containerRetryButton}
                    textStyle={styles.textButton}>
                    {texts.retry}
                </Button>
            ) : null}
            <Button
                onPress={() => redirectToAffiliateLink({ offerActivationInfo, affiliateLinkUrl })}
                style={styles.containerBackButton}
                textStyle={styles.textButton}
                useSecondaryColor>
                {texts.continue}
            </Button>
        </View>
    );
}

function CookieWarningWithoutAcknowledgmentRequired() {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.cookieWarning.withoutAcknowledgmentRequired;
    return (
        <View style={[styles.containerBox, styles.containerCookieWarningWithoutAcknowledgmentRequired]}>
            <Text style={[styles.textTitle, styles.textCookieWarningTitle]}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
        </View>
    );
}

function CookieWarningWithAcknowledgmentRequired({
    userId,
    affiliateLinkUrl,
    offerActivationInfo,
    shouldUseDevStack,
}: {
    userId: string;
    affiliateLinkUrl: string;
    offerActivationInfo: OnlineOfferActivationInfo;
    shouldUseDevStack: boolean;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.cookieWarning.withAcknowledgmentRequired;
    const [isCheckboxFilled, setIsCheckboxFilled] = React.useState(false);
    return (
        <View style={[styles.containerBox, styles.containerBoxBlue]}>
            <Image source={cookieIcon} style={styles.imageCookieIcon} />
            <Text style={[styles.textTitle, styles.textCookieWarningTitle]}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
            <Button
                onPress={async () => {
                    if (isCheckboxFilled) await skipFutureCookieWarningAcknowledgment(userId, shouldUseDevStack);
                    redirectToAffiliateLink({ offerActivationInfo, affiliateLinkUrl });
                }}
                style={styles.containerAcknowledgeCookieWarningButton}
                textStyle={styles.textButton}>
                {texts.acknowledgeButtonText}
            </Button>
            <TouchableOpacity
                onPress={() => setIsCheckboxFilled(!isCheckboxFilled)}
                style={styles.containerSkipCookieWarningAcknowledgment}>
                <View style={styles.containerSkipCookieWarningAcknowledgmentCheckbox}>
                    {isCheckboxFilled ? (
                        <View style={styles.containerCheckBox}>
                            <Image source={checkMarkIcon} style={styles.imageCheckBoxFilled} />
                        </View>
                    ) : (
                        <View style={styles.containerCheckBox} />
                    )}
                </View>
                <Text style={styles.textSkipCookieWarningAcknowledgment}>{texts.skipFutureAcknowledgmentText}</Text>
            </TouchableOpacity>
        </View>
    );
}

async function skipFutureCookieWarningAcknowledgment(userId: string, shouldUseDevStack: boolean) {
    await logUserEventWithoutAuthentication(userId, { type: 'acknowledgedWarningForCookieTracking' }, shouldUseDevStack);
}

const { width } = Dimensions.get('window');
const isDeviceScreenLarge = width >= 1280;

const LOGO_TOP_MARGIN = isDeviceScreenLarge ? 54 : 13;
const LOGO_HEIGHT = isDeviceScreenLarge ? 54 : 50;

const bigStyles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100vh',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerBody: {
        alignItems: 'center',
    },
    containerFooter: {
        height: LOGO_HEIGHT + LOGO_TOP_MARGIN,
        alignItems: 'flex-end',
    },
    containerBox: {
        width: 384,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: color.alabaster,
    },
    containerBoxGreen: {
        backgroundColor: color.hintOfGreen,
    },
    containerBoxBlue: {
        backgroundColor: color.tranquil,
    },
    containerAdBlockerMessage: {
        marginTop: 23,
        backgroundColor: color.sauvignon,
    },
    containerFloatingIcons: {
        marginTop: 22,
        flexDirection: 'row',
    },
    containerOfferDetails: {
        marginTop: 16,
        marginBottom: 24,
        width: 336,
        height: 72,
        padding: 8,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerOfferLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 56,
        height: 56,
        borderRadius: 56,
        backgroundColor: color.linkWaterLight,
    },
    containerOfferTexts: {
        flex: 1,
        marginLeft: 8,
        flexDirection: 'column',
    },
    containerNavigationButtons: {
        width: Platform.OS === 'web' ? 270 : 385,
        marginBottom: Platform.OS === 'web' ? 0 : 17,
    },
    containerRetryButton: {
        height: Platform.OS === 'web' ? 40 : 56,
        marginTop: 28,
    },
    containerBackButton: {
        height: Platform.OS === 'web' ? 40 : 56,
        marginTop: 9,
    },
    containerCookieWarningWithoutAcknowledgmentRequired: {
        marginTop: 23,
        backgroundColor: color.tranquil,
    },
    containerAcknowledgeCookieWarningButton: {
        height: Platform.OS === 'web' ? 40 : 56,
        width: Platform.OS === 'web' ? 241 : 384,
        marginBottom: 8,
    },
    containerSkipCookieWarningAcknowledgment: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
    },
    containerSkipCookieWarningAcknowledgmentCheckbox: {
        height: 18,
        width: 18,
        marginHorizontal: 5,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerCheckBox: {
        height: 15,
        width: 15,
        borderRadius: 15,
        borderWidth: 2,
        borderColor: color.frenchGray,
        backgroundColor: color.tranquil,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerLoadingAnimation: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
    },
    imageLogo: {
        width: 114,
        marginTop: LOGO_TOP_MARGIN,
        height: LOGO_HEIGHT,
        resizeMode: 'contain',
    },
    imageStatusIcon: {
        marginTop: 25,
        width: 40,
        height: 40,
    },
    imageOfferLogo: {
        width: 36,
        height: 36,
        resizeMode: 'contain',
    },
    imageCookieIcon: {
        marginTop: 24,
        height: 58,
        width: 58,
        borderRadius: 58,
        backgroundColor: color.whiteIce,
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageCheckBoxFilled: {
        height: 15,
        width: 15,
    },
    textTitle: {
        marginTop: 18,
        width: 336,
        fontFamily: font.ambitBlack,
        fontSize: Platform.OS === 'web' ? 24 : 20,
        color: color.black,
        textAlign: 'center',
    },
    textTitleSucceeded: {
        color: color.emerald,
    },
    textTitleFailed: {
        color: color.flamingo,
    },
    textAdBlockerMessageTitle: {
        fontSize: 18,
        color: color.flamingo,
    },
    textCookieWarningTitle: {
        fontSize: 18,
        color: color.easternBlue,
    },
    textOfferTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
    },
    textSubtitle: {
        marginTop: 16,
        marginBottom: 24,
        width: 358,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.black,
        textAlign: 'center',
    },
    textAdBlockerMessageSubtitle: {
        marginTop: 8,
        marginBottom: 10,
    },
    textAdBlockerMessageSupportLink: {
        marginBottom: 18,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.flamingo,
        textDecorationLine: 'underline',
    },
    textButton: {
        fontSize: 15,
    },
    textSkipCookieWarningAcknowledgment: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.manatee,
        textAlign: 'center',
    },
});

const smallStyles = StyleSheet.create({
    container: {
        height: '100vh',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerBody: {
        alignItems: 'center',
    },
    containerFooter: {
        height: LOGO_HEIGHT + LOGO_TOP_MARGIN,
    },
    containerBox: {
        marginTop: 9,
        width: 284,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: color.alabaster,
    },
    containerBoxGreen: {
        backgroundColor: color.hintOfGreen,
    },
    containerBoxBlue: {
        backgroundColor: color.tranquil,
    },
    containerAdBlockerMessage: {
        marginTop: 17,
        backgroundColor: color.sauvignon,
    },
    containerFloatingIcons: {
        marginTop: 17,
        flexDirection: 'row',
    },
    containerOfferDetails: {
        marginTop: 10,
        marginBottom: 17,
        width: 262,
        height: 72,
        padding: 8,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerOfferLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 46,
        height: 46,
        borderRadius: 46,
        backgroundColor: color.linkWaterLight,
    },
    containerOfferTexts: {
        flex: 1,
        marginLeft: 8,
        flexDirection: 'column',
    },
    containerNavigationButtons: {
        width: Platform.OS === 'web' ? 241 : 298,
        marginBottom: Platform.OS === 'web' ? 0 : 17,
    },
    containerRetryButton: {
        height: Platform.OS === 'web' ? 40 : 53,
        marginTop: 24,
    },
    containerBackButton: {
        height: Platform.OS === 'web' ? 40 : 53,
        marginTop: 9,
    },
    containerCookieWarningWithoutAcknowledgmentRequired: {
        marginTop: 17,
        backgroundColor: color.tranquil,
    },
    containerAcknowledgeCookieWarningButton: {
        height: Platform.OS === 'web' ? 40 : 53,
        width: Platform.OS === 'web' ? 241 : 284,
        marginBottom: 8,
    },
    containerSkipCookieWarningAcknowledgment: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
    },
    containerSkipCookieWarningAcknowledgmentCheckbox: {
        height: 18,
        width: 18,
        marginHorizontal: 5,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerCheckBox: {
        height: 15,
        width: 15,
        borderRadius: 15,
        borderWidth: 2,
        borderColor: color.frenchGray,
        backgroundColor: color.tranquil,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerLoadingAnimation: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
    },
    imageLogo: {
        width: 106,
        marginTop: LOGO_TOP_MARGIN,
        height: LOGO_HEIGHT,
        resizeMode: 'contain',
    },
    imageStatusIcon: {
        marginTop: 15,
        height: 30,
        width: 30,
    },
    imageCookieIcon: {
        marginTop: 17,
        height: 48,
        width: 48,
        borderRadius: 48,
        backgroundColor: color.whiteIce,
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageOfferLogo: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
    },
    imageCheckBoxFilled: {
        height: 10,
        width: 10,
    },
    textTitle: {
        marginTop: 13,
        width: 266,
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.black,
        textAlign: 'center',
    },
    textTitleSucceeded: {
        color: color.emerald,
    },
    textTitleFailed: {
        color: color.flamingo,
    },
    textAdBlockerMessageTitle: {
        fontSize: 16,
        color: color.flamingo,
    },
    textCookieWarningTitle: {
        fontSize: 16,
        color: color.easternBlue,
    },
    textOfferTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
    },
    textSubtitle: {
        marginTop: 12,
        marginBottom: 17,
        width: 250,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.black,
        textAlign: 'center',
    },
    textAdBlockerMessageSubtitle: {
        marginTop: 8,
        marginBottom: 10,
    },
    textAdBlockerMessageSupportLink: {
        marginBottom: 15,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.flamingo,
        textDecorationLine: 'underline',
    },
    textButton: {
        fontSize: 14,
    },
    textSkipCookieWarningAcknowledgment: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.manatee,
        textAlign: 'center',
    },
});

const styles = isDeviceScreenLarge ? bigStyles : smallStyles;
