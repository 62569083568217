import * as React from 'react';
import { StyleSheet, Animated, Easing, ImageSourcePropType, ImageStyle } from 'react-native';

export default function FloatingIcon({
    icon,
    deltaY = 5,
    offsetY = 0,
    iconStyle = {},
}: {
    icon: ImageSourcePropType;
    deltaY?: number;
    offsetY?: number;
    iconStyle?: ImageStyle;
}) {
    const animatedValue = React.useRef(new Animated.Value(-deltaY)).current;
    React.useEffect(() => {
        function animate(toValue: number) {
            Animated.timing(animatedValue, {
                toValue: toValue,
                duration: 1200,
                useNativeDriver: true,
                easing: Easing.inOut(Easing.linear),
            }).start(() => animate(-toValue));
        }
        return animate(deltaY);
    }, [animatedValue, deltaY]);
    return (
        <Animated.Image source={icon} style={[styles.image, iconStyle, { bottom: Animated.add(animatedValue, offsetY) }]} />
    );
}

const styles = StyleSheet.create({
    image: {
        width: 25,
        height: 25,
        marginHorizontal: 4,
    },
});
