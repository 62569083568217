import * as React from 'react';
import { NetworkStatus } from '@apollo/client';
import { useQuery, useApolloClient, useReactiveVar } from '@apollo/react-hooks';
import moment from 'moment';
import * as _ from 'lodash';

import { ApolloClient } from '../../api/graphql/client';
import { sessionIdVar } from '../../api/graphql/reactiveVariables';
import { UserTrackingConsent, TrackingId } from '../../api/graphql/fragments/trackingConsents';
import { SettingKey } from '../../api/graphql/fragments/settings';
import { UserTrackingConsentsQueryResponse, userTrackingConsentsQuery } from '../../api/graphql/queries/trackingConsents';
import {
    UpdateUserTrackingConsentMutationResponse,
    UpdateUserTrackingConsentMutationVariables,
    updateUserTrackingConsentMutation,
} from '../../api/graphql/mutations/updateUserTrackingConsent';
import { getPublicSetting } from '../../api/rest/settings';
import { checkIsSignedIn } from '../../lib/common/cognito';
import {
    logAmplitudeEvent,
    getSavedAmplitudeMarketingAttributionEvent,
    clearSavedAmplitudeMarketingAttributionEvent,
    setAmplitudeMarketingAttributionUserProperties,
    getSavedAmplitudeMarketingAttributionUserProperties,
    clearSavedAmplitudeMarketingAttributionUserProperties,
} from '../../lib/events/amplitudeEvents';
import { initializeGoogleTagManager } from '../../lib/common/tags';

const USER_TRACKING_CONSENTS_LOCAL_STORAGE_KEY = '@ConsentsStore:userTrackingConsents';
const DEFAULT_CONSENT_TIME_INTERVAL_MONTHS = 6;
const ALL_TRACKING_IDS = Object.values(TrackingId) as TrackingId[];

export type TrackingConsentsMap = { [trackingId: string]: boolean };

export function buildTrackingConsentsMap(trackingConsents: UserTrackingConsent[] | undefined): TrackingConsentsMap {
    const trackingConsentsMap: TrackingConsentsMap = {};
    const trackingIdToUserTrackingConsentMap: { [trackingId: string]: UserTrackingConsent } = trackingConsents
        ? _.keyBy(trackingConsents, 'trackingId')
        : {};
    for (const trackingId of ALL_TRACKING_IDS)
        trackingConsentsMap[trackingId] = trackingIdToUserTrackingConsentMap[trackingId]?.consentChoice ?? false;
    return trackingConsentsMap;
}

export function getUserTrackingConsents(): UserTrackingConsent[] | undefined {
    const localStorageItem: string | null = localStorage.getItem(USER_TRACKING_CONSENTS_LOCAL_STORAGE_KEY);
    if (!localStorageItem) return undefined;
    try {
        return JSON.parse(localStorageItem).trackingConsents;
    } catch {
        return undefined;
    }
}

export function getUserTrackingConsent(trackingId: TrackingId): UserTrackingConsent | undefined {
    const trackingConsents = getUserTrackingConsents();
    return trackingConsents
        ? trackingConsents.find((trackingConsent) => trackingConsent.trackingId === trackingId)
        : undefined;
}

export function acceptAllTracking(client: ApolloClient) {
    const trackingConsentsMap: TrackingConsentsMap = {};
    for (const trackingId of ALL_TRACKING_IDS) trackingConsentsMap[trackingId] = true;
    updateUserTrackingConsents(trackingConsentsMap, client);
}

export function rejectAllTracking(client: ApolloClient) {
    const trackingConsentsMap: TrackingConsentsMap = {};
    for (const trackingId of ALL_TRACKING_IDS) trackingConsentsMap[trackingId] = false;
    updateUserTrackingConsents(trackingConsentsMap, client);
}

export async function updateUserTrackingConsents(trackingConsentsMap: TrackingConsentsMap, client: ApolloClient) {
    updateLocalStorageTrackingConsents(trackingConsentsMap);
    if (trackingConsentsMap[TrackingId.amplitudeMarketingAttributionWebApp]) {
        /**
         * When users give their consent for Amplitude marketing attribution events,
         * we are able to log attribution events and user properties that were saved for later logging.
         */
        const savedAmplitudeMarketingAttributionUserProperties = getSavedAmplitudeMarketingAttributionUserProperties();
        const savedAmplitudeMarketingAttributionEvent = getSavedAmplitudeMarketingAttributionEvent();
        if (savedAmplitudeMarketingAttributionEvent?.pathname === window.location.pathname) {
            if (savedAmplitudeMarketingAttributionUserProperties)
                await setAmplitudeMarketingAttributionUserProperties(savedAmplitudeMarketingAttributionUserProperties, true);
            await logAmplitudeEvent(savedAmplitudeMarketingAttributionEvent.event, undefined, true);
        }
    }
    clearSavedAmplitudeMarketingAttributionUserProperties();
    clearSavedAmplitudeMarketingAttributionEvent();
    if (trackingConsentsMap[TrackingId.googleTagManagerMarketingAttributionWebApp]) initializeGoogleTagManager(true);
    if (trackingConsentsMap[TrackingId.amplitudeAnalyticsWebApp])
        logAmplitudeEvent({ name: 'Navigation - Accepted Amplitude Analytics Tracking' }, undefined, true);
    if (checkIsSignedIn())
        // TODO: Calling multiple simultaneous mutations to User Appsync API is not optimal: we should use a single mutation
        for (const trackingId of ALL_TRACKING_IDS)
            await client.mutate<UpdateUserTrackingConsentMutationResponse, UpdateUserTrackingConsentMutationVariables>({
                mutation: updateUserTrackingConsentMutation,
                variables: { trackingId, consentChoice: trackingConsentsMap[trackingId] },
                fetchPolicy: 'no-cache',
            });
}

export function useSyncLocalAndRemoteTrackingConsents(): number | undefined {
    const apolloClient = useApolloClient();
    const sessionId = useReactiveVar(sessionIdVar);
    const [remoteTrackingConsents, queryNetworkStatus] = useRemoteTrackingConsents();
    const [lastSyncingTimestamp, setLastSyncingTimestamp] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        syncLocalAndRemoteTrackingConsents(
            remoteTrackingConsents,
            queryNetworkStatus,
            sessionId,
            apolloClient,
            setLastSyncingTimestamp
        );
    }, [remoteTrackingConsents, queryNetworkStatus, sessionId, apolloClient]);
    return lastSyncingTimestamp;
}

export function shouldDisplayTrackingModal(trackingConsents: UserTrackingConsent[] | undefined): boolean {
    if (!trackingConsents) return true;
    const trackingIdToUserTrackingConsentMap = _.keyBy(trackingConsents, 'trackingId');
    for (const trackingId of ALL_TRACKING_IDS)
        if (
            !trackingIdToUserTrackingConsentMap[trackingId] ||
            trackingIdToUserTrackingConsentMap[trackingId].expiresAt < moment().unix()
        )
            return true;
    return false;
}

type LocalStorageTrackingConsents = {
    sessionId: number | null;
    trackingConsents: UserTrackingConsent[];
};

function writeLocalStorageTrackingConsents(trackingConsents: UserTrackingConsent[], sessionId: number | null) {
    const localStorageItem: LocalStorageTrackingConsents = { sessionId, trackingConsents };
    localStorage.setItem(USER_TRACKING_CONSENTS_LOCAL_STORAGE_KEY, JSON.stringify(localStorageItem));
}

async function updateLocalStorageTrackingConsents(trackingConsentsMap: TrackingConsentsMap) {
    const trackingConsents: UserTrackingConsent[] = [];
    const settingKey: string = SettingKey.askingForTrackingConsentTimeIntervalMonths;
    const askingForTrackingConsentTimeIntervalMonths: number =
        Number(await getPublicSetting(settingKey)) || DEFAULT_CONSENT_TIME_INTERVAL_MONTHS;
    for (const trackingId of ALL_TRACKING_IDS)
        trackingConsents.push({
            __typename: 'UserTrackingConsent',
            trackingId,
            consentChoice: trackingConsentsMap[trackingId],
            expiresAt: moment().add(askingForTrackingConsentTimeIntervalMonths, 'months').unix(),
        });
    writeLocalStorageTrackingConsents(trackingConsents, sessionIdVar());
}

function useRemoteTrackingConsents(): [UserTrackingConsent[] | undefined, NetworkStatus] {
    const queryResponse = useQuery<UserTrackingConsentsQueryResponse>(userTrackingConsentsQuery, {
        fetchPolicy: 'no-cache',
    });
    const trackingConsents: UserTrackingConsent[] | undefined = React.useMemo(
        () => queryResponse.data?.user?.trackingConsents || undefined,
        [queryResponse]
    );
    return [trackingConsents, queryResponse.networkStatus];
}

async function syncLocalAndRemoteTrackingConsents(
    remoteTrackingConsents: UserTrackingConsent[] | undefined,
    queryNetworkStatus: NetworkStatus,
    sessionId: number | null,
    client: ApolloClient,
    setLastSyncingTimestamp: (value: number | undefined) => void
) {
    if (queryNetworkStatus === NetworkStatus.ready && sessionId) {
        const localStorageItem: string | null = localStorage.getItem(USER_TRACKING_CONSENTS_LOCAL_STORAGE_KEY);
        let localTrackingConsents: LocalStorageTrackingConsents | null = null;
        if (localStorageItem)
            try {
                localTrackingConsents = JSON.parse(localStorageItem);
            } catch {}
        if (localTrackingConsents && localTrackingConsents.sessionId === sessionId) {
            const trackingConsentsMap = buildTrackingConsentsMap(localTrackingConsents.trackingConsents);
            // TODO: Calling multiple simultaneous mutations to User Appsync API is not optimal: we should use a single mutation
            for (const trackingId of ALL_TRACKING_IDS)
                await client.mutate<UpdateUserTrackingConsentMutationResponse, UpdateUserTrackingConsentMutationVariables>({
                    mutation: updateUserTrackingConsentMutation,
                    variables: { trackingId, consentChoice: trackingConsentsMap[trackingId] },
                    fetchPolicy: 'no-cache',
                });
            // Update the sessionId stored in the local storage to null, to avoid infinite updates of the remote data
            writeLocalStorageTrackingConsents(localTrackingConsents.trackingConsents, null);
        } else if (remoteTrackingConsents && remoteTrackingConsents.length > 0)
            writeLocalStorageTrackingConsents(remoteTrackingConsents, null);
        setLastSyncingTimestamp(moment().unix());
    } else if (queryNetworkStatus === NetworkStatus.error) setLastSyncingTimestamp(moment().unix());
}
