import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import { useHistory, useLocation } from 'react-router';

import { getLocalizedTexts } from '../../../Locales';
import { MerchantOffer, OfferType } from '../../../api/graphql/fragments/offers';
import { EnrichedUserOffer } from '../../../api/graphql/fragments/enrichedOffers';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { useUserOffer, useActivateOffer, useOpenAffiliateLink } from '../../../lib/offers/offers';
import { AffiliateLinkLoadingTriggeringEventType } from '../../../lib/offers/affiliateLinkLoading';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { HoverableContainer } from '../../common/HoverableComponents';

const checkMarkIcon = { uri: '/assets/images/icons/check-mark-green.svg' };

export default function OfferButton({
    offer,
    triggeringEventType,
}: {
    offer: MerchantOffer | undefined;
    triggeringEventType: AffiliateLinkLoadingTriggeringEventType;
}) {
    const texts = getLocalizedTexts().home.offers.offerComponent;
    const history = useHistory();
    const userOffer: EnrichedUserOffer | undefined = useUserOffer(offer?.offerId, 'cache-first');
    const [onPressOfferButton] = useOnPressOfferButton(userOffer, triggeringEventType);
    if (!offer || !userOffer) return <View style={styles.containerPlaceholder} />;
    return (
        <>
            {offer.type !== OfferType.Online && userOffer.active && userOffer.available ? (
                <ActivatedButton />
            ) : (
                <Button
                    onPress={() => {
                        onPressOfferButton();
                        history.push(`/home/offer/${offer.offerId}`);
                    }}
                    text={offer.type === OfferType.Online ? texts.button.shop : texts.button.activate}
                />
            )}
        </>
    );
}

export function useOnPressOfferButton(
    userOffer: EnrichedUserOffer | undefined,
    triggeringEventType: AffiliateLinkLoadingTriggeringEventType
): [() => void] {
    const location = useLocation();
    const activateOffer = useActivateOffer();
    const openAffiliateLink = useOpenAffiliateLink(triggeringEventType);
    const onPressOfferButton = async () => {
        if (userOffer?.available) {
            logAmplitudeEvent({
                name: 'Offers - Clicked Offer CTA',
                properties: {
                    offerId: userOffer.offerId,
                    fromComponent:
                        location.pathname === '/home'
                            ? 'Home'
                            : location.pathname.startsWith('/home/search')
                            ? 'OfferSearch'
                            : location.pathname.startsWith('/home/tag')
                            ? 'OffersByTag'
                            : location.pathname.startsWith('/home/offer')
                            ? 'OfferDetails'
                            : 'Other',
                },
            });
            if (userOffer.offer.type === OfferType.Online) openAffiliateLink(userOffer);
            else activateOffer(userOffer.offer);
        }
    };
    return [onPressOfferButton];
}

export function ActivatedButton() {
    return (
        <View style={{ alignSelf: 'center' }}>
            <View style={styles.containerActivatedOfferCheckMark}>
                <Image source={checkMarkIcon} style={{ height: 17, width: 17 }} />
            </View>
        </View>
    );
}

export function Button({ onPress, text }: { onPress: () => void; text: string }) {
    return (
        <View style={{ alignSelf: 'center' }}>
            <HoverableContainer>
                {(isHovered) => (
                    <TouchableOpacity
                        style={[styles.containerButton, isHovered && styles.containerButtonHovered]}
                        {...{ onPress }}>
                        <Text style={[styles.textButton, isHovered && styles.textButtonHovered]}>{text}</Text>
                    </TouchableOpacity>
                )}
            </HoverableContainer>
        </View>
    );
}

const styles = StyleSheet.create({
    containerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 28,
        paddingHorizontal: 16,
        borderRadius: 40,
        backgroundColor: color.hintOfGreen,
    },
    containerButtonHovered: {
        backgroundColor: color.emerald,
    },
    containerActivatedOfferCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 28,
        height: 28,
        borderRadius: 28,
        backgroundColor: color.hintOfGreen,
    },
    containerPlaceholder: {
        height: 28,
        width: 65,
        alignSelf: 'center',
        paddingHorizontal: 16,
        borderRadius: 40,
        backgroundColor: color.linkWaterLight,
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 12,
        color: color.emerald,
    },
    textButtonHovered: {
        color: color.white,
    },
});
