import * as React from 'react';
import qs from 'qs';

import { getRegion, Region } from '../auth/region';
import { logAmplitudeEventWithoutAuthentication } from '../events/amplitudeEvents';
import { logUserEventWithoutAuthentication } from '../../api/rest/events';
import { browserKind } from '../../lib/common/browser';
import { updateStoredDeviceId } from '../../lib/common/device';
import { AbcTestGroup, useAbTestWithThreeGroups, useAbTestWithTwoGroups } from './abTest';

export type UrlStep =
    | 'activate'
    | 'authorize'
    | 'authorizeWithBanner'
    | 'conclusion'
    | 'missingAllWebsitesAuthorization'
    | 'missingAllWebsitesAuthorizationAfterRetry'
    | 'missingAuthorization'
    | 'temporaryAccountReplacement'
    | 'firstWaitingStep'
    | 'secondWaitingStep'
    | 'wait'; // This url step is deprecated

export function useParseUrlParameters(location: any): {
    userId: string | undefined;
    urlStep: UrlStep;
    isDevStackMode: boolean;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    isFlowWithSystemSettings: boolean;
    deviceId: string | undefined;
    region: Region;
} {
    return React.useMemo(() => {
        const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
        return {
            userId: typeof parsedQuery?.userId === 'string' ? parsedQuery.userId : undefined,
            urlStep:
                parsedQuery?.step === 'authorize' ||
                parsedQuery?.step === 'authorizeWithBanner' ||
                parsedQuery?.step === 'conclusion' ||
                parsedQuery?.step === 'missingAllWebsitesAuthorization' ||
                parsedQuery?.step === 'missingAllWebsitesAuthorizationAfterRetry' ||
                parsedQuery?.step === 'missingAuthorization' ||
                parsedQuery?.step === 'temporaryAccountReplacement' ||
                parsedQuery?.step === 'firstWaitingStep' ||
                parsedQuery?.step === 'secondWaitingStep' ||
                parsedQuery?.step === 'wait'
                    ? parsedQuery.step
                    : ('activate' as UrlStep),
            isDevStackMode: parsedQuery?.devStack === 'true',
            isOnboarding: parsedQuery?.isOnboarding === 'true',
            isBeforeOnboarding:
                parsedQuery?.isBeforeOnboarding === 'true' ||
                ((parsedQuery?.step === 'conclusion' || parsedQuery?.step === 'authorize') &&
                    checkHasRecentlyConsentedToMobileSafariExtensionTermsOfServiceAndPrivacyPolicy()),
            isFlowWithSystemSettings: parsedQuery?.isFlowWithSystemSettings === 'true',
            deviceId: typeof parsedQuery?.deviceId === 'string' ? parsedQuery.deviceId : undefined,
            region: getRegion(),
        };
    }, [location]);
}

export enum InstallationStep {
    activateStepPreamble = 'activateStepPreamble',
    activateStep = 'activateStep',
    authorizeStepPreamble = 'authorizeStepPreamble',
    authorizeStep = 'authorizeStep',
    authorizeWithBannerStep = 'authorizeWithBannerStep',
    conclusionStep = 'conclusionStep',
    missingAllWebsitesAuthorization = 'missingAllWebsitesAuthorization',
    missingAllWebsitesAuthorizationAfterRetry = 'missingAllWebsitesAuthorizationAfterRetry',
    missingAuthorization = 'missingAuthorization',
    firstWaitingStep = 'firstWaitingStep',
    secondWaitingStep = 'secondWaitingStep',
    genericWaitingPage = 'genericWaitingPage',
}

export function useInstallationStep(
    urlStep: UrlStep,
    userId: string | undefined,
    isDevStackMode: boolean,
    activationPreamblePageTestGroup: AbcTestGroup | undefined
): [InstallationStep, React.Dispatch<React.SetStateAction<InstallationStep>>] {
    const [step, setStep] = React.useState<InstallationStep>(InstallationStep.genericWaitingPage);
    React.useEffect(() => {
        if (urlStep === 'activate') {
            if (activationPreamblePageTestGroup === AbcTestGroup.C) setStep(InstallationStep.activateStep);
            else setStep(InstallationStep.activateStepPreamble);
        } else if (urlStep === 'authorize') setStep(InstallationStep.authorizeStepPreamble);
        else if (urlStep === 'authorizeWithBanner') setStep(InstallationStep.authorizeWithBannerStep);
        else if (urlStep === 'conclusion' || urlStep === 'temporaryAccountReplacement')
            setStep(InstallationStep.conclusionStep);
        else if (urlStep === 'missingAllWebsitesAuthorization') setStep(InstallationStep.missingAllWebsitesAuthorization);
        else if (urlStep === 'missingAllWebsitesAuthorizationAfterRetry')
            setStep(InstallationStep.missingAllWebsitesAuthorizationAfterRetry);
        else if (urlStep === 'missingAuthorization') setStep(InstallationStep.missingAuthorization);
        else if (urlStep === 'firstWaitingStep') setStep(InstallationStep.firstWaitingStep);
        else if (urlStep === 'secondWaitingStep') setStep(InstallationStep.secondWaitingStep);
        else if (urlStep === 'wait') setStep(InstallationStep.genericWaitingPage); // This url step is deprecated
    }, [urlStep, userId, isDevStackMode, activationPreamblePageTestGroup]);
    return [step, setStep];
}

export function useLogEvents(
    urlStep: UrlStep,
    userId: string | undefined,
    deviceId: string | undefined,
    isDevStackMode: boolean,
    isOnboarding: boolean,
    isBeforeOnboarding: boolean,
    isFlowWithSystemSettings: boolean
) {
    React.useEffect(() => {
        // The `deviceId` is reset if needed, in order to be correctly set for future page openings
        updateStoredDeviceId(deviceId);
        if (urlStep === 'activate') {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    {
                        type: 'viewedActivationPageMobileSafariExtensionInstallationFlow',
                        payload: { isOnboarding, isBeforeOnboarding },
                    },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Activation Page',
                    properties: { browserKind, isOnboarding, isBeforeOnboarding },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'authorize' || urlStep === 'authorizeWithBanner') {
            const isFlowWithBanner = urlStep === 'authorizeWithBanner';
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    {
                        type: 'viewedAuthorizationPageMobileSafariExtension',
                        payload: { isBeforeOnboarding, isFlowWithSystemSettings, isFlowWithBanner },
                    },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Authorization Page',
                    properties: { isBeforeOnboarding, isFlowWithSystemSettings, isFlowWithBanner },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'conclusion') {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    {
                        type: 'viewedConclusionPageMobileSafariExtension',
                        payload: { isBeforeOnboarding },
                    },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Conclusion Page',
                    properties: { isBeforeOnboarding },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'missingAllWebsitesAuthorization') {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    { type: 'viewedMissingAllWebsitesAuthorizationPageMobileSafariExtension' },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Missing All-Websites Authorization Page',
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'missingAllWebsitesAuthorizationAfterRetry') {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    { type: 'viewedMissingAllWebsitesAuthorizationAfterRetryPageMobileSafariExtension' },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Missing All-Websites Authorization After Retry Page',
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'missingAuthorization') {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    { type: 'viewedMissingAuthorizationPageMobileSafariExtension' },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Missing Authorization Page',
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'firstWaitingStep' || urlStep === 'secondWaitingStep' || urlStep === 'wait')
            // We only log an Amplitude event because the waiting pages are opened by the MSE without userId information in the URL parameters
            logAmplitudeEventWithoutAuthentication(
                { name: 'Mobile Safari Extension - Viewed Waiting Page', properties: { step: urlStep } },
                {}
            );
    }, [urlStep, userId, isDevStackMode, isOnboarding, isBeforeOnboarding, isFlowWithSystemSettings, deviceId]);
}

export function getCallbacks({
    step,
    setStep,
    userId,
    deviceId,
    isDevStackMode,
    isOnboarding,
    isBeforeOnboarding,
    isFlowWithSystemSettings,
}: {
    step: InstallationStep;
    setStep: (step: InstallationStep) => void;
    userId: string | undefined;
    deviceId: string | undefined;
    isDevStackMode: boolean;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    isFlowWithSystemSettings: boolean;
}) {
    const onPressStartActivation = () => {
        setStep(InstallationStep.activateStep);
        if (userId)
            logUserEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedStartActivationMobileSafariExtensionInstallationFlow',
                    payload: { isOnboarding, isBeforeOnboarding },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Start Activation',
                properties: { isOnboarding, isBeforeOnboarding },
            },
            { userId, deviceId }
        );
    };
    const onPressStartAuthorization = () => {
        setStep(InstallationStep.authorizeStep);
        if (userId)
            logUserEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedStartAuthorizationMobileSafariExtensionInstallationFlow',
                    payload: { isBeforeOnboarding, isFlowWithSystemSettings },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Start Authorization',
                properties: { isBeforeOnboarding, isFlowWithSystemSettings },
            },
            { userId, deviceId }
        );
    };
    const onPressCloseMissingAllWebsitesAuthorizationModal = (hasUserClickedModifyAuthorization: boolean) => {
        setStep(InstallationStep.authorizeStep);
        if (hasUserClickedModifyAuthorization) {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    {
                        type: 'clickedModifyAuthorizationMobileSafariExtensionInstallationFlow',
                        payload: { from: 'missingAllWebsitesAuthorizationPopup' },
                    },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Clicked Modify Authorization',
                    properties: { from: 'missingAllWebsitesAuthorizationPopup' },
                },
                { userId, deviceId }
            );
        } else {
            if (userId)
                logUserEventWithoutAuthentication(
                    userId,
                    { type: 'closedMissingAuthorizationPopupMobileSafariExtensionInstallationFlow' },
                    isDevStackMode
                );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Closed Missing Authorization Popup',
                },
                { userId, deviceId }
            );
        }
    };
    const onPressModifyAuthorization = () => {
        setStep(InstallationStep.authorizeStep);
        if (userId)
            logUserEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedModifyAuthorizationMobileSafariExtensionInstallationFlow',
                    payload: { from: 'missingAuthorizationPage' },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Modify Authorization',
                properties: { from: 'missingAuthorizationPage' },
            },
            { userId, deviceId }
        );
    };
    const onPressReturnToApp = () => {
        const payload = { installationStep: step };
        if (userId)
            logUserEventWithoutAuthentication(userId, { type: 'clickedReturnToAppFromMobileSafariExtension', payload });
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Return To App',
                properties: payload,
            },
            { userId, deviceId }
        );
        window.location.href = 'joko://';
    };
    return {
        onPressStartActivation,
        onPressStartAuthorization,
        onPressCloseMissingAllWebsitesAuthorizationModal,
        onPressModifyAuthorization,
        onPressReturnToApp,
    };
}
const REDIRECT_TO_APP_TIMEOUT = 3000;

export function useRedirectToAppAtConclusionStepAfterTimeout({
    isOnboarding,
    isBeforeOnboarding,
    urlStep,
}: {
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    urlStep: UrlStep;
}) {
    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | undefined;
        if ((isOnboarding || isBeforeOnboarding) && urlStep === 'conclusion')
            timeoutId = setTimeout(() => window.location.replace('joko://'), REDIRECT_TO_APP_TIMEOUT);
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isOnboarding, isBeforeOnboarding, urlStep]);
}

const INSTALLATION_FLOW_AB_TEST_ID = 'test-mobile-safari-extension-installation-version-3';
const INSTALLATION_FLOW_B_TEST_GROUP_PROPORTION = 0;

export function useShouldSeeInstallationFlowVersionB({
    userId,
    deviceId,
    region,
}: {
    userId?: string;
    deviceId?: string;
    region: Region;
}) {
    return useAbTestWithTwoGroups({
        testId: INSTALLATION_FLOW_AB_TEST_ID,
        testGroupProportion: INSTALLATION_FLOW_B_TEST_GROUP_PROPORTION,
        userId,
        deviceId,
        region,
    });
}

export const MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_LOCAL_STORAGE_KEY =
    '@ConsentsStore:mobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsent';

export const MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY =
    '@ConsentsStore:mobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentTimestampMs';

export const MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_IS_DEV_STACK_MODE_LOCAL_STORAGE_KEY =
    '@ConsentsStore:mobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentIsDevStackMode';

export function setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage(isDevStackMode: boolean) {
    localStorage.setItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_LOCAL_STORAGE_KEY, 'true');
    localStorage.setItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY, JSON.stringify(Date.now()));
    localStorage.setItem(
        MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_IS_DEV_STACK_MODE_LOCAL_STORAGE_KEY,
        isDevStackMode ? 'true' : 'false'
    );
}

function checkHasRecentlyConsentedToMobileSafariExtensionTermsOfServiceAndPrivacyPolicy() {
    try {
        return (
            localStorage.getItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_LOCAL_STORAGE_KEY) === 'true' &&
            !!localStorage.getItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY) &&
            Date.now() -
                Number(localStorage.getItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY)) <
                15 * 60 * 1000
        );
    } catch {
        return false;
    }
}

// In order to ask users if they want to allow the extension, Safari displays two successive native pop-ups.
// The first one (`alwaysAllowPopup`) asks if the user wants to allow the extension permanently, for 24 hours only, or not at all.
// The second one (`allowOnAllWebsitesPopup`) asks the user if they want these permissions to apply to the current website only or to all websites.
// For UX reasons, we also introduce a state `popupClosed` which is different from the initial state `noPopup`
export enum NativeAuthorizationPopupState {
    noPopup = 'noPopup', // This is the initial state
    alwaysAllowPopup = 'alwaysAllowPopup', // This is the state after the opening of the aA menu, which leads to the 'alwaysAllowPopup'
    allowOnAllWebsitesPopup = 'allowOnAllWebsitesPopup', // This is the state after the opening of the 'allowOnAllWebsitesPopup'
    popupClosed = 'popupClosed', // This is the state after the user closes a popup and is back to the instruction page
}

export function useNativeAuthorizationPopupState({
    shouldShowNativeAuthorizationPopupBackgroundModal,
    userId,
    deviceId,
    isDevStackMode,
}: {
    shouldShowNativeAuthorizationPopupBackgroundModal?: boolean;
    userId?: string;
    deviceId?: string;
    isDevStackMode: boolean;
}): NativeAuthorizationPopupState {
    const [nativeAuthorizationPopupState, setNativeAuthorizationPopupState] = React.useState<NativeAuthorizationPopupState>(
        NativeAuthorizationPopupState.noPopup
    );
    React.useEffect(() => {
        const logStateChangeEvents = (popupState: NativeAuthorizationPopupState) => {
            if (popupState === NativeAuthorizationPopupState.noPopup) return;
            else if (popupState === NativeAuthorizationPopupState.popupClosed) {
                logAmplitudeEventWithoutAuthentication(
                    { name: 'Mobile Safari Extension - Authorization Popup Background Instructions Hidden' },
                    { userId, deviceId }
                );
                if (!!userId)
                    logUserEventWithoutAuthentication(
                        userId,
                        { type: 'mobileSafariExtensionAuthorizationPopupBackgroundInstructionsHidden' },
                        isDevStackMode
                    );
            } else {
                logAmplitudeEventWithoutAuthentication(
                    {
                        name: 'Mobile Safari Extension - Authorization Popup Background Instructions Displayed',
                        properties: { popupState, browserLanguage: window.navigator.language },
                    },
                    { userId, deviceId }
                );
                if (!!userId)
                    logUserEventWithoutAuthentication(
                        userId,
                        {
                            type: 'mobileSafariExtensionAuthorizationPopupBackgroundInstructionsDisplayed',
                            payload: { popupState, browserLanguage: window.navigator.language },
                        },
                        isDevStackMode
                    );
            }
        };
        const scrollToInstruction = (nativeAuthorizationPopupState: NativeAuthorizationPopupState) => {
            if (
                nativeAuthorizationPopupState === NativeAuthorizationPopupState.alwaysAllowPopup ||
                nativeAuthorizationPopupState === NativeAuthorizationPopupState.allowOnAllWebsitesPopup
            ) {
                const instructionElement = document.getElementById(
                    nativeAuthorizationPopupState === NativeAuthorizationPopupState.alwaysAllowPopup
                        ? 'alwaysAllowPopupInstruction'
                        : 'allowOnAllWebsitesPopupInstruction'
                );
                if (!!instructionElement) instructionElement.scrollIntoView({ behavior: 'smooth' });
            }
        };
        /** The `blur` event is emitted when the `alwaysAllowPopup` is opened, so that the instruction page is put in the background */
        const onBlurCallback = () => {
            if (
                nativeAuthorizationPopupState === NativeAuthorizationPopupState.noPopup ||
                nativeAuthorizationPopupState === NativeAuthorizationPopupState.popupClosed
            ) {
                setNativeAuthorizationPopupState(NativeAuthorizationPopupState.alwaysAllowPopup);
                if (!!shouldShowNativeAuthorizationPopupBackgroundModal) {
                    logStateChangeEvents(NativeAuthorizationPopupState.alwaysAllowPopup);
                    scrollToInstruction(NativeAuthorizationPopupState.alwaysAllowPopup);
                }
            }
        };
        /** The `focus` event is emitted when the first popup is closed and the `allowOnAllWebsitesPopup` is opened */
        const onFocusCallback = () => {
            if (nativeAuthorizationPopupState === NativeAuthorizationPopupState.alwaysAllowPopup) {
                setNativeAuthorizationPopupState(NativeAuthorizationPopupState.allowOnAllWebsitesPopup);
                if (!!shouldShowNativeAuthorizationPopupBackgroundModal) {
                    logStateChangeEvents(NativeAuthorizationPopupState.allowOnAllWebsitesPopup);
                    scrollToInstruction(NativeAuthorizationPopupState.allowOnAllWebsitesPopup);
                }
            }
        };
        /** The `touchstart` event is emitted when the user comes back to the instruction screen and touches it */
        const onTouchstartCallback = () => {
            if (
                nativeAuthorizationPopupState === NativeAuthorizationPopupState.alwaysAllowPopup ||
                nativeAuthorizationPopupState === NativeAuthorizationPopupState.allowOnAllWebsitesPopup
            ) {
                setNativeAuthorizationPopupState(NativeAuthorizationPopupState.popupClosed);
                if (!!shouldShowNativeAuthorizationPopupBackgroundModal)
                    logStateChangeEvents(NativeAuthorizationPopupState.popupClosed);
            }
        };
        window.addEventListener('blur', onBlurCallback);
        window.addEventListener('focus', onFocusCallback);
        window.addEventListener('touchstart', onTouchstartCallback);
        return () => {
            window.removeEventListener('blur', onBlurCallback);
            window.removeEventListener('focus', onFocusCallback);
            window.removeEventListener('touchstart', onTouchstartCallback);
        };
    }, [nativeAuthorizationPopupState, shouldShowNativeAuthorizationPopupBackgroundModal]);
    return nativeAuthorizationPopupState;
}

const ADAPT_NATIVE_AUTHORIZATION_POPUP_BACKGROUND_TEST_ID = 'test-adapt-native-authorization-popup-background-1';
const ADAPT_NATIVE_AUTHORIZATION_POPUP_BACKGROUND_TEST_GROUP_PROPORTION = 0;

export function useNativeAuthorizationPopupBackgroundDisplay({
    userId,
    deviceId,
    region,
    step,
    isFlowWithSystemSettings,
    isDevStackMode,
}: {
    userId?: string;
    deviceId?: string;
    region: Region;
    step: InstallationStep;
    isFlowWithSystemSettings: boolean;
    isDevStackMode: boolean;
}): {
    nativeAuthorizationPopupState: NativeAuthorizationPopupState;
    shouldShowNativeAuthorizationPopupBackgroundModal?: boolean;
} {
    // The background display is not activated in the banner flow (i.e. `authorizeWithBannerStep`), because the events on which the detection mechanism relies are not fired in this case
    const isAuthorizationStep = step === InstallationStep.authorizeStep;
    const isUserInTestGroup = useAbTestWithTwoGroups({
        testId: ADAPT_NATIVE_AUTHORIZATION_POPUP_BACKGROUND_TEST_ID,
        testGroupProportion: ADAPT_NATIVE_AUTHORIZATION_POPUP_BACKGROUND_TEST_GROUP_PROPORTION,
        userId,
        deviceId,
        region,
    });
    const shouldShowNativeAuthorizationPopupBackgroundModal =
        isAuthorizationStep && isUserInTestGroup && !isFlowWithSystemSettings;
    const nativeAuthorizationPopupState = useNativeAuthorizationPopupState({
        shouldShowNativeAuthorizationPopupBackgroundModal,
        userId,
        deviceId,
        isDevStackMode,
    });
    return { nativeAuthorizationPopupState, shouldShowNativeAuthorizationPopupBackgroundModal };
}

// After activation and authorization in the MSE setup flow, the instruction pages are opened by the MSE in the focused tab except for the following exception: in the purpose of blocking a native display, two waiting pages are opened in a new tab.
// We implement a communication mechanism to close the obsolete pages in the background (activation and first waiting page), when the second waiting page is opened.
const BROADCAST_CHANNEL_NAME = 'closeOldInstructionPagesBroadcastChannel';
const SECOND_WAITING_PAGE_OPENED_MESSAGE = 'secondWaitingPageOpened';
const ALLOWED_MESSAGE_ORIGINS = [`https://app.hellojoko.com`, `https://dev.app.hellojoko.com`];

export function useCloseObsoletePagesWhenSecondWaitingPageIsOpened(step: InstallationStep) {
    React.useEffect(() => {
        if (
            step !== InstallationStep.secondWaitingStep &&
            step !== InstallationStep.activateStepPreamble &&
            step !== InstallationStep.activateStep &&
            step !== InstallationStep.firstWaitingStep
        )
            return;
        const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
        if (step === InstallationStep.secondWaitingStep)
            setTimeout(() => broadcastChannel.postMessage(SECOND_WAITING_PAGE_OPENED_MESSAGE), 2000);
        else if (
            step === InstallationStep.activateStepPreamble ||
            step === InstallationStep.activateStep ||
            step === InstallationStep.firstWaitingStep
        )
            broadcastChannel.addEventListener('message', (message) => {
                if (
                    message.data === SECOND_WAITING_PAGE_OPENED_MESSAGE &&
                    !!message.origin &&
                    ALLOWED_MESSAGE_ORIGINS.includes(message.origin)
                )
                    window.close();
            });
        return () => broadcastChannel.close(); // This closes the broadcast channel and removes the event listener on component unmount
    }, [step]);
}

const ACTIVATION_PREAMBLE_PAGE_TEST_ID = 'test-mobile-safari-extension-activation-preamble-page-1';
const ACTIVATION_PREAMBLE_PAGE_TEST_GROUP_PROPORTIONS = { B: 0, C: 0 };

export function useActivationPreamblePageTestGroup({
    isBeforeOnboarding,
    userId,
    deviceId,
    region,
}: {
    isBeforeOnboarding: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    region: Region;
}) {
    return useAbTestWithThreeGroups({
        testId: ACTIVATION_PREAMBLE_PAGE_TEST_ID,
        testGroupProportions: ACTIVATION_PREAMBLE_PAGE_TEST_GROUP_PROPORTIONS,
        shouldForceControlGroup: isBeforeOnboarding,
        userId,
        deviceId,
        region,
    });
}
