import * as React from 'react';
import { FetchPolicy, useQuery, useReactiveVar } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';

import { ApolloClient } from '../../api/graphql/client';
import { HistoryRecord } from '../../api/graphql/fragments/history';
import { UserPoints } from '../../api/graphql/fragments/points';
import { userHistoryAndPointsQuery, UserHistoryAndPointsQueryResponse } from '../../api/graphql/queries/pointCollection';
import { collectPoints } from '../../api/graphql/mutations/collectPoints';
import { lastPointsCollectedAtVar } from '../../api/graphql/reactiveVariables';
import { logAmplitudeEvent } from '../events/amplitudeEvents';

type UserHistoryAndPoints = {
    history: HistoryRecord[];
    points: UserPoints;
    firstPointsCollectedAt: number | null;
    firstOfferPointsCollectedAt: number | null;
};

export function usePointCollectionModalManager() {
    const history = useHistory();
    const location = useLocation();
    const uncollectedHistoryAndPoints: UserHistoryAndPoints | undefined = useUncollectedHistoryAndPoints();
    const lastPointsCollectedAt = useReactiveVar(lastPointsCollectedAtVar);
    React.useEffect(() => {
        if (
            uncollectedHistoryAndPoints?.history.length &&
            location.pathname !== '/home/point-collection' &&
            location.pathname !== '/home/promotion/browser-extension' &&
            location.pathname !== '/home/legal-updates' &&
            (!lastPointsCollectedAt || moment().diff(moment.unix(lastPointsCollectedAt), 'seconds') > 30)
        )
            history.push('/home/point-collection');
    }, [uncollectedHistoryAndPoints?.history, lastPointsCollectedAt, history, location]);
}

export function useUserHistoryAndPoints(fetchPolicy?: FetchPolicy): UserHistoryAndPoints | undefined {
    const queryResponse = useQuery<UserHistoryAndPointsQueryResponse>(userHistoryAndPointsQuery, {
        fetchPolicy: fetchPolicy || 'network-only',
    });
    if (queryResponse.data?.user?.history && queryResponse.data?.user?.points)
        return {
            history: queryResponse.data.user.history,
            points: queryResponse.data.user.points,
            firstPointsCollectedAt: queryResponse.data.user.firstPointsCollectedAt,
            firstOfferPointsCollectedAt: queryResponse.data.user.firstOfferPointsCollectedAt,
        };
    return undefined;
}

export function useUncollectedHistoryAndPoints(fetchPolicy?: FetchPolicy): UserHistoryAndPoints | undefined {
    const data = useUserHistoryAndPoints(fetchPolicy);
    const uncollectedHistoryAndPoints: UserHistoryAndPoints | undefined = React.useMemo(
        () => filterUncollectedHistoryAndPoints(data),
        [data]
    );
    return uncollectedHistoryAndPoints;
}

function filterUncollectedHistoryAndPoints(data: UserHistoryAndPoints | undefined): UserHistoryAndPoints | undefined {
    if (!data) return undefined;
    const { history, points, firstPointsCollectedAt, firstOfferPointsCollectedAt } = data;
    return {
        history: history.filter((record) => record.isUncollected),
        points,
        firstPointsCollectedAt,
        firstOfferPointsCollectedAt,
    };
}

export function collectPointsAndLogEvents({
    apolloClient,
    uncollectedHistoryRecords,
    firstPointsCollectedAt,
    firstOfferPointsCollectedAt,
}: {
    apolloClient: ApolloClient;
    uncollectedHistoryRecords: HistoryRecord[];
    firstPointsCollectedAt?: number | null;
    firstOfferPointsCollectedAt?: number | null;
}) {
    collectPoints({ client: apolloClient });
    const isCollectingOfferPoints: boolean = uncollectedHistoryRecords.some(
        (record) => !!record.transaction || !!record.affiliateTransaction
    );
    logAmplitudeEvents(isCollectingOfferPoints, uncollectedHistoryRecords);
    // We don't integrate Facebook Ad tracking and Adjust tracking yet
    // if (!firstPointsCollectedAt) {
    //     logAdjustEvent(AdjustEventType.firstPointsCollected);
    //     logFacebookEvent(FacebookEventType.firstPointsCollected);
    // }
    // logAdjustEvent(AdjustEventType.pointsCollected);
    // logFacebookEvent(FacebookEventType.pointsCollected);
    // if (isCollectingOfferPoints) {
    //     if (!firstOfferPointsCollectedAt) {
    //         logAdjustEvent(AdjustEventType.firstOfferPointsCollected);
    //         logFacebookEvent(FacebookEventType.firstOfferPointsCollected);
    //     }
    //     logAdjustEvent(AdjustEventType.offerPointsCollected);
    //     logFacebookEvent(FacebookEventType.offerPointsCollected);
    // }
}

function logAmplitudeEvents(isCollectingOfferPoints: boolean, historyRecords: HistoryRecord[]) {
    const pointCollectionItems: {
        transactionIds: string[];
        affiliateTransactionIds: string[];
        offerIds: string[];
        bonusTitles: string[];
        referralPoints: { refereeUserId: string; referrerUserId: string }[];
    } = { transactionIds: [], affiliateTransactionIds: [], offerIds: [], bonusTitles: [], referralPoints: [] };
    for (const { offer, bonus, transaction, affiliateTransaction, referralPoints } of historyRecords) {
        if (!!transaction) pointCollectionItems.transactionIds.push(transaction.transactionId);
        if (!!affiliateTransaction)
            pointCollectionItems.affiliateTransactionIds.push(affiliateTransaction.affiliateTransactionId);
        if (!!offer) pointCollectionItems.offerIds.push(offer.offerId);
        if (!!bonus && !!bonus.card) pointCollectionItems.bonusTitles.push(bonus.card.title);
        if (!!referralPoints)
            pointCollectionItems.referralPoints.push({
                refereeUserId: referralPoints.refereeUserId,
                referrerUserId: referralPoints.referrerUserId,
            });
    }
    logAmplitudeEvent({
        name: 'Points - Collected Points',
        properties: {
            ...pointCollectionItems,
        },
    });
    if (isCollectingOfferPoints)
        logAmplitudeEvent({
            name: 'Points - Collected Offer Points',
            properties: {
                transactionIds: pointCollectionItems.transactionIds,
                affiliateTransactionIds: pointCollectionItems.affiliateTransactionIds,
                offerIds: pointCollectionItems.offerIds,
            },
        });
}
