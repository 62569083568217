import React from 'react';
import { View, StyleSheet, Image, ViewStyle, TouchableOpacity, Text } from 'react-native';

import { Browser, browserKind, BrowserExtensionStoreUrl } from '../../lib/common/browser';
import { containerStyles } from '../../style/container';
import { font } from '../../style/text';
import color from '../../style/color';

const jokoLogo = { uri: '/assets/images/logos/joko-title-gray.svg' };
const firefoxLogo = { uri: '/assets/images/logos/browsers/firefox.svg' };
const safariLogo = { uri: '/assets/images/logos/browsers/safari.svg' };
const chromeLogo = { uri: '/assets/images/logos/browsers/chrome.svg' };

type BrowserExtensionStoreInfo = {
    logo: { uri: string };
    link: string;
    name: string;
};

const browserExtensionStoreInfo: BrowserExtensionStoreInfo =
    browserKind === Browser.safari
        ? { logo: safariLogo, link: BrowserExtensionStoreUrl.safari, name: 'Safari app store' }
        : browserKind === Browser.firefox
        ? { logo: firefoxLogo, link: BrowserExtensionStoreUrl.firefox, name: 'Firefox add-ons' }
        : { logo: chromeLogo, link: BrowserExtensionStoreUrl.chrome, name: 'Chrome web store' };

function Footer({ style }: { style?: ViewStyle }) {
    return (
        <View style={[containerStyles.containerCenter, style]}>
            <Image source={jokoLogo} style={styles.imageJokoLogo} />
            <TouchableOpacity
                style={styles.containerBrowserExtensionStoreLink}
                onPress={() => window.open(browserExtensionStoreInfo.link)}>
                <Image source={browserExtensionStoreInfo.logo} style={styles.imageBrowserExtensionLogo} />
                <Text style={styles.textBrowserExtensionStoreName}>{browserExtensionStoreInfo.name}</Text>
            </TouchableOpacity>
        </View>
    );
}

export default Footer;

const styles = StyleSheet.create({
    containerBrowserExtensionStoreLink: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 60,
    },
    imageJokoLogo: {
        height: 28.5,
        width: 85,
        marginTop: 40,
        marginBottom: 20,
    },
    imageBrowserExtensionLogo: {
        height: 24,
        width: 24,
        marginRight: 8,
    },
    textBrowserExtensionStoreName: {
        fontSize: 14,
        fontFamily: font.ambitBold,
        color: color.frenchGray,
    },
});
