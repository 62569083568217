import * as React from 'react';

import { Region } from '../auth/region';
import { logAmplitudeEventWithoutAuthentication } from '../events/amplitudeEvents';

export function useAbTestWithTwoGroups({
    testId,
    testGroupProportion,
    shouldForceControlGroup,
    userId,
    deviceId,
    region,
}: {
    testId: string;
    testGroupProportion: number;
    shouldForceControlGroup?: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    region: Region;
}) {
    const localStorageKey = `@ABTestStore:${testId}`;
    const groupIds = {
        A: `${testId}-A`, // Control group
        B: `${testId}-B`, // Test group
    };
    const [isInTestGroup, setIsInTestGroup] = React.useState<boolean | undefined>(undefined);
    const checkIsInTestGroup = () => {
        const localStorageTestGroup = localStorage.getItem(localStorageKey);
        if (localStorageTestGroup === groupIds.A || localStorageTestGroup === groupIds.B)
            return localStorageTestGroup === groupIds.B;
        const shouldBeInTestGroup = Math.random() < testGroupProportion;
        const groupId = shouldBeInTestGroup ? groupIds.B : groupIds.A;
        localStorage.setItem(localStorageKey, groupId);
        logAmplitudeEventWithoutAuthentication(
            { name: 'Mobile Safari Extension - AB Test Group Set', properties: { testId, groupId } },
            { userId, deviceId }
        );
        return shouldBeInTestGroup;
    };
    React.useEffect(() => {
        if (shouldForceControlGroup || testGroupProportion === 0 || region !== Region.FR) {
            setIsInTestGroup(false);
            return;
        }
        setIsInTestGroup(checkIsInTestGroup());
        // eslint-disable-next-line
    }, []); // This effect should run only once, as we do not expect the shouldForceControlGroup boolean to change
    return isInTestGroup;
}

export enum AbcTestGroup {
    A = 'A',
    B = 'B',
    C = 'C',
}

export function useAbTestWithThreeGroups({
    testId,
    testGroupProportions,
    shouldForceControlGroup,
    userId,
    deviceId,
    region,
}: {
    testId: string;
    testGroupProportions: { B: number; C: number };
    shouldForceControlGroup?: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    region: Region;
}) {
    const localStorageKey = `@ABTestStore:${testId}`;
    const groupIds = {
        A: `${testId}-A`, // Control group
        B: `${testId}-B`,
        C: `${testId}-C`,
    };
    const [abcTestGroup, setAbcTestGroup] = React.useState<AbcTestGroup | undefined>(undefined);
    const checkAbcTestGroup = () => {
        const localStorageTestGroup = localStorage.getItem(localStorageKey);
        if (localStorageTestGroup === groupIds.A) return AbcTestGroup.A;
        else if (localStorageTestGroup === groupIds.B) return AbcTestGroup.B;
        else if (localStorageTestGroup === groupIds.C) return AbcTestGroup.C;
        let abcTestGroupToSet: AbcTestGroup;
        const randomNumber = Math.random();
        if (randomNumber < testGroupProportions.B) abcTestGroupToSet = AbcTestGroup.B;
        else if (randomNumber < testGroupProportions.B + testGroupProportions.C) abcTestGroupToSet = AbcTestGroup.C;
        else abcTestGroupToSet = AbcTestGroup.A;
        const groupId = groupIds[abcTestGroupToSet];
        localStorage.setItem(localStorageKey, groupId);
        logAmplitudeEventWithoutAuthentication(
            { name: 'Mobile Safari Extension - AB Test Group Set', properties: { testId, groupId } },
            { userId, deviceId }
        );
        return abcTestGroupToSet;
    };
    React.useEffect(() => {
        if (
            shouldForceControlGroup ||
            (testGroupProportions.B === 0 && testGroupProportions.C === 0) ||
            region !== Region.FR
        ) {
            setAbcTestGroup(AbcTestGroup.A);
            return;
        }
        setAbcTestGroup(checkAbcTestGroup());
        // eslint-disable-next-line
    }, []); // This effect should run only once, as we do not expect the shouldForceControlGroup boolean to change
    return abcTestGroup;
}
