import * as React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

import color from '../../style/color';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };

export default function GradientBackground({ height, width }: { height?: number; width?: number }) {
    return (
        <>
            <View style={[styles.containerBackground, { height, width }]}>
                <Svg height="100%" width="100%">
                    <Defs>
                        <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
                            <Stop offset="0" stopColor={color.linkWaterLight} stopOpacity="1" />
                            <Stop offset="1" stopColor={color.white} stopOpacity="1" />
                        </LinearGradient>
                    </Defs>
                    <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
                </Svg>
            </View>
            <View style={styles.containerLogo}>
                <Image source={logo} style={styles.imageLogo} />
            </View>
        </>
    );
}
const styles = StyleSheet.create({
    containerBackground: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 16,
        overflow: 'hidden',
    },
    containerLogo: {
        position: 'absolute',
        top: 40,
    },
    imageLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
});
