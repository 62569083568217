import * as React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { updateUser } from '../../api/graphql/mutations/updateUser';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { logUserEventUtil } from '../../lib/events/userEvents';
import { useUser, isReferrerCodeValid } from '../../lib/onboarding/userInfo';
import Button from '../../components/common/Button';
import OnboardingProgressIndicator, { OnboardingStep } from '../../components/onboarding/OnboardingProgressIndicator';
import { ReferrerCodeInput } from '../../components/onboarding/UserInfoInputs';

const leftChevronIcon = { uri: '/assets/images/icons/chevron-left-gray.svg' };

export default function OnboardingReferrerCodePage() {
    const texts = getLocalizedTexts().onboarding.referrerCode;
    const history = useHistory();
    const [referrerCode, setReferrerCode] = React.useState<string>('');
    const [updateReferrerCode, isLoading] = useUpdateReferrerCode();
    const onPress = () => {
        if (isReferrerCodeValid(referrerCode)) updateReferrerCode(referrerCode.toLocaleLowerCase());
    };
    return (
        <View style={styles.container}>
            <View style={styles.containerContent}>
                <OnboardingProgressIndicator step={OnboardingStep.referrerCodeStep} />
                <Text style={styles.textTitle}>{texts.referrerCodeTitle}</Text>
                <ReferrerCodeInput
                    {...{
                        referrerCode,
                        referrerCodePlaceholder: texts.referrerCodePlaceholder,
                        setReferrerCode,
                        isLoading,
                    }}
                />
                <View style={styles.containerNextButton}>
                    <Button
                        {...{ onPress, isLoading }}
                        textStyle={styles.textNextButton}
                        height={40}
                        disabled={!isReferrerCodeValid(referrerCode)}>
                        {texts.nextButton}
                    </Button>
                </View>
                <TouchableOpacity style={styles.containerBackButton} onPress={() => history.goBack()}>
                    <Image source={leftChevronIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                    <Text style={styles.textBackButton}>{texts.backButton}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

function useUpdateReferrerCode(): [(referrerCode: string) => Promise<void>, boolean] {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const apolloClient = useApolloClient();
    const user = useUser();
    const updateReferrerCode = async (referrerCode: string) => {
        if (!isLoading && user) {
            setIsLoading(true);
            if (referrerCode.length > 0 && !user.referrerCode) await updateUser(apolloClient, { referrerCode });
            logAmplitudeEvent(
                { name: 'Registration - Validated User Information' },
                {
                    firstName: user.userInfo?.firstName,
                    age: user.userInfo?.age,
                    gender: user.userInfo?.gender,
                    referrerCode: referrerCode.length > 0 ? referrerCode : undefined,
                    registrationDate: user.registeredAt ? moment.unix(user.registeredAt).format('YYYY-MM-DD') : undefined,
                    registrationWeek: user.registeredAt ? moment.unix(user.registeredAt).format('YYYY-[W]ww') : undefined,
                    registrationMonth: user.registeredAt ? moment.unix(user.registeredAt).format('YYYY-MM') : undefined,
                }
            );
            logUserEventUtil(apolloClient, { type: 'validatedUserInfo' });
            history.push('/onboarding/end');
            setIsLoading(false);
        }
    };
    return [updateReferrerCode, isLoading];
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerContent: {
        marginTop: 68,
        width: 424,
    },
    containerNextButton: {
        marginTop: 40,
    },
    containerBackButton: {
        alignSelf: 'center',
        marginTop: 16,
        flexDirection: 'row',
    },
    textTitle: {
        marginTop: 40,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginBottom: 16,
    },
    textNextButton: {
        fontSize: 14,
    },
    textBackButton: {
        fontFamily: font.ambitBlack,
        color: color.silverChalice,
        fontSize: 14,
        paddingLeft: 8,
    },
});
