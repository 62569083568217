import * as React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';

export enum OnboardingStep {
    accountCreationStep = 'accountCreationStep',
    userInfoStep = 'userInfoStep',
    referrerCodeStep = 'referrerCodeStep',
}

export default function OnboardingProgressIndicator({ step }: { step: OnboardingStep }) {
    const texts = getLocalizedTexts().onboarding.progressIndicator;
    return (
        <View style={styles.container}>
            <Text style={[styles.text, isAfter(step, OnboardingStep.accountCreationStep) && styles.textSelected]}>
                {texts.accountCreationStep}
            </Text>
            <Text style={[styles.text, isAfter(step, OnboardingStep.userInfoStep) && styles.textSelected]}>
                {texts.userInfoStep}
            </Text>
            <Text style={[styles.text, isAfter(step, OnboardingStep.referrerCodeStep) && styles.textSelected]}>
                {texts.referralStep}
            </Text>
        </View>
    );
}

function isAfter(onboardingStepA: OnboardingStep, onboardingStepB: OnboardingStep): boolean {
    const onboardingSteps = Object.values(OnboardingStep);
    for (const onboardingStep of onboardingSteps)
        if (onboardingStepB === onboardingStep) return true;
        else if (onboardingStepA === onboardingStep) return false;
    return false;
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    text: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.ghost,
        marginRight: 20,
    },
    textSelected: {
        color: color.black,
    },
});
