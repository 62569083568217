import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { getSocialIdentityCredentials, GetSocialIdentityCredentialsResponse, Provider } from '../../api/rest/user';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import LoadingFrame from '../../components/auth/LoadingFrame';
import IntroPanel from './IntroPanel';
import SocialSignUpPage from './SocialSignUpPage';

const JWT = require('jsonwebtoken');
const jwkToPem = require('jwk-to-pem');

const { v1: uuidv1 } = require('uuid');

const APPLE_OAUTH_URL = 'https://appleid.apple.com/auth/authorize';
const APPLE_CLIENT_ID = 'io.wylr.joko-web';

const LOCALHOST_REDIRECT_URI = 'https://hellojoko.com/apple-login';

function AppleAuthPage() {
    const texts = getLocalizedTexts().auth.signIn.withApple;
    const size = useWindowSize();
    const [signUpData, errorMessage] = useHandleRedirection();
    if (!signUpData)
        return (
            <View style={styles.container}>
                <LoadingFrame
                    text={errorMessage || texts.loading}
                    height={(size.height || 0) - 120}
                    width={(size.width || 0) - 240}
                    isError={!!errorMessage}
                />
            </View>
        );
    else
        return (
            <View style={styles.containerSignUp}>
                <View style={styles.containerSignUpColumn}>
                    <IntroPanel />
                </View>
                <View style={styles.containerSignUpColumn}>
                    <SocialSignUpPage {...signUpData} />
                </View>
            </View>
        );
}

export default AppleAuthPage;

function useHandleRedirection(): [SignUpData | undefined, string | undefined] {
    const location = useLocation();
    const redirectUri =
        window.location.host === 'localhost:3000'
            ? LOCALHOST_REDIRECT_URI
            : `https://${window.location.host}${location.pathname}`;
    const [handleOAuthResponse, signUpData, errorMessage] = useHandleOAuthResponse();
    React.useEffect(() => {
        const match = location.hash?.match(`id_token=(.+)$`);
        if (match && match[1]) {
            const idToken = match[1];
            handleOAuthResponse(idToken);
        } else {
            if (window.location.host === 'localhost:3000')
                alert(`In order to test Apple sign-in in Localhost, replace "${LOCALHOST_REDIRECT_URI}" with this page URL`);
            const appleState = uuidv1();
            window.open(
                `${APPLE_OAUTH_URL}` +
                    `?client_id=${APPLE_CLIENT_ID}` +
                    `&redirect_uri=${redirectUri}` +
                    `&response_type=code id_token` +
                    `&state=${appleState}` +
                    `&response_mode=fragment`,
                window.location.host === 'localhost:3000' ? undefined : '_self'
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return [signUpData, errorMessage];
}

interface SignUpData {
    initialEmail: string;
    credentials: GetSocialIdentityCredentialsResponse;
}

function useHandleOAuthResponse(): [(idToken: string) => void, SignUpData | undefined, string | undefined] {
    const texts = getLocalizedTexts().auth.signIn.withApple;
    const history = useHistory();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [signUpData, setSignUpData] = React.useState<SignUpData | undefined>(undefined);
    const handleOAuthResponse = async (idToken: string) => {
        try {
            const verifiedToken = await verifyAppleJWTToken(idToken);
            const credentials = await getSocialIdentityCredentials({
                provider: Provider.Apple,
                providerUserId: verifiedToken.sub,
                email: verifiedToken.email || `${verifiedToken.sub}@apple.com`,
            });
            if (!credentials.new) await signIn(credentials);
            else setSignUpData({ initialEmail: verifiedToken.email || '', credentials });
        } catch (error) {
            console.log(error);
            setErrorMessage(texts.error.default);
            logAmplitudeEvent({ name: 'Registration - Apple Login Failed' });
            setTimeout(() => history.push('/auth'), 1000);
        }
    };
    return [handleOAuthResponse, signUpData, errorMessage];
}

async function verifyAppleJWTToken(JWTToken: string): Promise<{ sub: string; email?: string }> {
    console.log('Apple Sign-in: verifying JWT token...');
    const decodedToken = JWT.decode(JWTToken, { complete: true });
    if (!decodedToken?.header?.kid) throw new Error("Apple Sign-in: missing 'kid' key in token header");
    const applePublicKey = await matchApplePublicKey(decodedToken.header.kid);
    const verifiedToken = JWT.verify(JWTToken, applePublicKey, { algorithms: ['RS256'] });
    console.log('Apple Sign-in: JWT token verified');
    if (!verifiedToken || !verifiedToken.sub || typeof verifiedToken.sub !== 'string')
        throw new Error(`Apple Sign-in: missing 'sub' key in verified JWT Token`);
    return verifiedToken;
}

async function matchApplePublicKey(keyId: string): Promise<string> {
    const matchingKey = applePublicKeys.find((key: any) => key?.kid === keyId);
    if (!matchingKey) throw new Error('Apple Sign-in: no matching public key found');
    const pemKey = jwkToPem(matchingKey);
    return pemKey;
}

// These keys are public and can be found here: https://appleid.apple.com/auth/keys
const applePublicKeys = [
    {
        kty: 'RSA',
        kid: '86D88Kf',
        use: 'sig',
        alg: 'RS256',
        n: 'iGaLqP6y-SJCCBq5Hv6pGDbG_SQ11MNjH7rWHcCFYz4hGwHC4lcSurTlV8u3avoVNM8jXevG1Iu1SY11qInqUvjJur--hghr1b56OPJu6H1iKulSxGjEIyDP6c5BdE1uwprYyr4IO9th8fOwCPygjLFrh44XEGbDIFeImwvBAGOhmMB2AD1n1KviyNsH0bEB7phQtiLk-ILjv1bORSRl8AK677-1T8isGfHKXGZ_ZGtStDe7Lu0Ihp8zoUt59kx2o9uWpROkzF56ypresiIl4WprClRCjz8x6cPZXU2qNWhu71TQvUFwvIvbkE1oYaJMb0jcOTmBRZA2QuYw-zHLwQ',
        e: 'AQAB',
    },
    {
        kty: 'RSA',
        kid: 'eXaunmL',
        use: 'sig',
        alg: 'RS256',
        n: '4dGQ7bQK8LgILOdLsYzfZjkEAoQeVC_aqyc8GC6RX7dq_KvRAQAWPvkam8VQv4GK5T4ogklEKEvj5ISBamdDNq1n52TpxQwI2EqxSk7I9fKPKhRt4F8-2yETlYvye-2s6NeWJim0KBtOVrk0gWvEDgd6WOqJl_yt5WBISvILNyVg1qAAM8JeX6dRPosahRVDjA52G2X-Tip84wqwyRpUlq2ybzcLh3zyhCitBOebiRWDQfG26EH9lTlJhll-p_Dg8vAXxJLIJ4SNLcqgFeZe4OfHLgdzMvxXZJnPp_VgmkcpUdRotazKZumj6dBPcXI_XID4Z4Z3OM1KrZPJNdUhxw',
        e: 'AQAB',
    },
    {
        kty: 'RSA',
        kid: 'YuyXoY',
        use: 'sig',
        alg: 'RS256',
        n: '1JiU4l3YCeT4o0gVmxGTEK1IXR-Ghdg5Bzka12tzmtdCxU00ChH66aV-4HRBjF1t95IsaeHeDFRgmF0lJbTDTqa6_VZo2hc0zTiUAsGLacN6slePvDcR1IMucQGtPP5tGhIbU-HKabsKOFdD4VQ5PCXifjpN9R-1qOR571BxCAl4u1kUUIePAAJcBcqGRFSI_I1j_jbN3gflK_8ZNmgnPrXA0kZXzj1I7ZHgekGbZoxmDrzYm2zmja1MsE5A_JX7itBYnlR41LOtvLRCNtw7K3EFlbfB6hkPL-Swk5XNGbWZdTROmaTNzJhV-lWT0gGm6V1qWAK2qOZoIDa_3Ud0Gw',
        e: 'AQAB',
    },
];

async function signIn(credentials: GetSocialIdentityCredentialsResponse) {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    await Auth.signIn(credentials.userId, credentials.secretKey);
    logAmplitudeEvent({ name: 'Registration - Apple Login Succeeded' });
    window.location.reload();
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerSignUp: {
        flex: 1,
        flexDirection: 'row',
    },
    containerSignUpColumn: {
        flex: 1,
    },
});
