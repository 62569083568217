import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { User } from '../../api/graphql/fragments/user';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';
import LoadingFrame from '../../components/auth/LoadingFrame';

const MIN_ANIMATION_LENGTH_MS = 3000;

export default function OnboardingStartPage() {
    const texts = getLocalizedTexts().onboarding;
    const size = useWindowSize();
    useRedirection();
    return (
        <View style={styles.container}>
            <LoadingFrame
                text={texts.loading}
                height={(size.height || 0) - 120}
                width={(size.width || 0) - 240}
                isError={false}
            />
        </View>
    );
}

export function useRedirection() {
    const mountingTimestampMs = React.useRef(Date.now()).current;
    const history = useHistory();
    const user = useUser();
    const waitAndRedirect = React.useCallback(
        (pathName: string) => {
            const currentTimestampMs = Date.now();
            setTimeout(
                () => history.push(pathName),
                Math.max(MIN_ANIMATION_LENGTH_MS - (currentTimestampMs - mountingTimestampMs), 0)
            );
        },
        [mountingTimestampMs, history]
    );
    React.useEffect(() => {
        if (user) {
            if (!user.userInfo || !user.userInfo.firstName || !user.userInfo.age || !user.userInfo.gender)
                waitAndRedirect('/onboarding/user-info');
            else if (!user.onboardedAt) waitAndRedirect('/onboarding/end');
            else waitAndRedirect('/home');
        }
    }, [user, waitAndRedirect]);
}

function useUser(): User | undefined {
    const queryResponse = useQuery<UserQueryResponse>(userQuery, { fetchPolicy: 'network-only' });
    const user = React.useMemo(() => queryResponse?.data?.user, [queryResponse]);
    return user;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
});
