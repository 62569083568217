import * as React from 'react';
import { useQuery, useReactiveVar } from '@apollo/react-hooks';
import moment from 'moment';

import {
    UserInAppContentQueryResponse,
    UserInAppContentQueryVariables,
    userInAppContentsQuery,
} from '../../api/graphql/queries/inAppContents';
import { UserInAppContent, UserInAppContentLayout, UserInAppContentItem } from '../../api/graphql/fragments/inAppContents';
import { lastUserInAppContentsQueryQueryAtVar } from '../../api/graphql/reactiveVariables';
import { MinimalUserOfferMap } from '../offers/offers';

const USER_IN_APP_CONTENTS_QUERY_REFETCH_FREQUENCY_SEC = 60;

export function useUserInAppContents(): UserInAppContent[] | undefined {
    const lastUserInAppContentsQueryQueryAt = useReactiveVar(lastUserInAppContentsQueryQueryAtVar);
    const queryResponse = useQuery<UserInAppContentQueryResponse, UserInAppContentQueryVariables>(userInAppContentsQuery, {
        fetchPolicy: 'cache-first',
        variables: {
            filterWebContents: true,
        },
    });
    React.useEffect(() => {
        if (
            !lastUserInAppContentsQueryQueryAt ||
            lastUserInAppContentsQueryQueryAt < moment().unix() - USER_IN_APP_CONTENTS_QUERY_REFETCH_FREQUENCY_SEC
        ) {
            queryResponse?.refetch();
            lastUserInAppContentsQueryQueryAtVar(moment().unix());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const userInAppContents: UserInAppContent[] | undefined = React.useMemo(
        () => queryResponse.data?.user?.inAppContents?.items || undefined,
        [queryResponse]
    );
    return userInAppContents;
}

export type ProcessedUserInAppContent = {
    inAppContentId: string;
    title?: string;
    layout: ProcessedUserInAppContentLayout;
    itemList: ProcessedUserInAppContentItem[];
};

export type ProcessedUserInAppContentLayout = {
    layoutType: 'singleItem' | 'carrousel';
    layoutParameters?: {
        numberOfRows?: number;
        minNumberOfItems?: number;
    };
};

export type ProcessedUserInAppContentItem =
    | {
          itemType: 'smallCard' | 'mediumCard' | 'largeCard';
          itemParameters: { image: string; title?: string; redirectionUri?: string };
      }
    | {
          itemType: 'smallOffer' | 'mediumOffer' | 'largeOffer';
          itemParameters: { offerId: string };
      }
    | {
          itemType: 'offerBrowsingTag';
          itemParameters: { tagId: string };
      }
    | {
          itemType: 'browserExtensionBanner' | 'chromeExtensionBanner';
          itemParameters: {
              title: string;
              subtitle: string;
              button: string;
              imageURL: string;
          };
      };

export function processUserInAppContent(
    { inAppContentId, title, layout, itemList }: UserInAppContent,
    searchableUserOfferMap: MinimalUserOfferMap
): ProcessedUserInAppContent | undefined {
    if (!layout) return undefined;
    const processedLayout = processUserInAppContentLayout(layout);
    if (!processedLayout) return undefined;
    if (!itemList) return undefined;
    const processedItemList: ProcessedUserInAppContentItem[] = [];
    for (const item of itemList) {
        const processedItem = processUserInAppContentItem(item, searchableUserOfferMap);
        if (processedItem) processedItemList.push(processedItem);
    }
    if (
        processedItemList.length === 0 ||
        (processedLayout.layoutParameters?.minNumberOfItems &&
            processedItemList.length < processedLayout.layoutParameters?.minNumberOfItems)
    )
        return undefined;
    return {
        inAppContentId,
        title: title || undefined,
        layout: processedLayout,
        itemList: processedItemList,
    };
}

function processUserInAppContentLayout({
    layoutType,
    layoutParameters,
}: UserInAppContentLayout): ProcessedUserInAppContentLayout | undefined {
    if (layoutType !== 'singleItem' && layoutType !== 'carrousel') return undefined;
    let parsedLayoutParameters = parseJSONObject(layoutParameters);
    const processedLayoutParameters: {
        numberOfRows?: number;
        minNumberOfItems?: number;
    } = {};
    if (typeof parsedLayoutParameters['numberOfRows'] === 'number')
        processedLayoutParameters.numberOfRows = parsedLayoutParameters['numberOfRows'];
    if (typeof parsedLayoutParameters['minNumberOfItems'] === 'number')
        processedLayoutParameters.minNumberOfItems = parsedLayoutParameters['minNumberOfItems'];
    return { layoutType, layoutParameters: processedLayoutParameters };
}

function processUserInAppContentItem(
    { itemType, itemParameters }: UserInAppContentItem,
    searchableUserOfferMap: MinimalUserOfferMap
): ProcessedUserInAppContentItem | undefined {
    let parsedItemParameters = parseJSONObject(itemParameters);
    switch (itemType) {
        case 'smallCard':
        case 'mediumCard':
        case 'largeCard':
            if (
                typeof parsedItemParameters['image'] === 'string' &&
                (typeof parsedItemParameters['title'] === 'string' ||
                    typeof parsedItemParameters['title'] === 'undefined') &&
                (typeof parsedItemParameters['redirectionUri'] === 'string' ||
                    typeof parsedItemParameters['redirectionUri'] === 'undefined')
            ) {
                return {
                    itemType,
                    itemParameters: {
                        image: parsedItemParameters['image'],
                        title: parsedItemParameters['title'],
                        redirectionUri: parsedItemParameters['redirectionUri'],
                    },
                };
            } else return undefined;
        case 'smallOffer':
        case 'mediumOffer':
        case 'largeOffer':
            if (
                typeof parsedItemParameters['offerId'] === 'string' &&
                searchableUserOfferMap[parsedItemParameters['offerId']]
            ) {
                return {
                    itemType,
                    itemParameters: {
                        offerId: parsedItemParameters['offerId'],
                    },
                };
            } else return undefined;
        case 'offerBrowsingTag':
            if (typeof parsedItemParameters['tagId'] === 'string') {
                return {
                    itemType,
                    itemParameters: {
                        tagId: parsedItemParameters['tagId'],
                    },
                };
            } else return undefined;
        case 'browserExtensionBanner':
        case 'chromeExtensionBanner': // for backwards compatibility
            if (
                typeof parsedItemParameters['title'] === 'string' &&
                typeof parsedItemParameters['subtitle'] === 'string' &&
                typeof parsedItemParameters['button'] === 'string' &&
                typeof parsedItemParameters['imageURL'] === 'string'
            )
                return {
                    itemType,
                    itemParameters: {
                        title: parsedItemParameters['title'],
                        subtitle: parsedItemParameters['subtitle'],
                        button: parsedItemParameters['button'],
                        imageURL: parsedItemParameters['imageURL'],
                    },
                };
            else return undefined;
        default:
            return undefined;
    }
}

function parseJSONObject(input: string | null): { [key: string]: unknown } {
    if (input) {
        try {
            const parsedInput: unknown = JSON.parse(input);
            if (typeof parsedInput === 'object' && parsedInput !== null) return parsedInput as { [key: string]: unknown };
        } catch {}
    }
    return {};
}
