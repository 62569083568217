import * as React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router';

import color from '../../../style/color';
import { font } from '../../../style/text';
import { trimOfferBrowsingTagName, useOfferBrowsingTag } from '../../../lib/offers/offerBrowsingTags';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { HoverableTouchableOpacity } from '../../common/HoverableComponents';

const backgroundColors = [color.blueChalk, color.pippin, color.hintOfGreen, color.oysterBay];
const textColors = [color.electricViolet, color.flamingo, color.malachite, color.easternBlue];

export default function OfferBrowsingTagComponent({ tagId, index }: { tagId: string; index: number }) {
    const history = useHistory();
    const offerBrowsingTag = useOfferBrowsingTag(tagId);
    if (!offerBrowsingTag) return null;
    const name = trimOfferBrowsingTagName(offerBrowsingTag.displayName);
    const onPress = () => {
        logAmplitudeEvent({ name: 'Offers - Clicked Browsing Tag', properties: { tagId } });
        history.push(`/home/tag/${offerBrowsingTag.tagId}`);
    };
    return (
        <HoverableTouchableOpacity
            style={[styles.container, { backgroundColor: backgroundColors[index % 4] }]}
            hoveredStyle={styles.containerHovered}
            {...{ onPress }}>
            <View style={[styles.containerText, { backgroundColor: textColors[index % 4] }]}>
                <Text style={styles.text}>{name}</Text>
            </View>
        </HoverableTouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 160,
        height: 110,
        borderRadius: 8,
        padding: 15,
    },
    containerHovered: {
        opacity: 0.7,
    },
    containerText: {
        borderRadius: 4,
        paddingHorizontal: 6,
        paddingVertical: 3,
    },
    text: {
        textAlign: 'center',
        fontFamily: font.ambitBlack,
        fontSize: 18,
        color: color.white,
    },
});
