import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { FetchPolicy, useApolloClient, useQuery, useReactiveVar } from '@apollo/react-hooks';

import { lastLegalUpdateConsentedAtVar } from '../../api/graphql/reactiveVariables';
import { LegalUpdatesToBeReviewed } from '../../api/graphql/fragments/legalUpdates';
import {
    legalUpdatesToBeReviewedQuery,
    LegalUpdatesToBeReviewedQueryResponse,
} from '../../api/graphql/queries/legalUpdates';
import {
    updateUserLegalUpdatesConsentMutation,
    UpdateUserLegalUpdatesConsentResponse,
    UpdateUserLegalUpdatesConsentVariables,
} from '../../api/graphql/mutations/updateUserLegalUpdatesConsent';
import { logUserEventUtil } from '../events/userEvents';
import { logAmplitudeEvent } from '../events/amplitudeEvents';
import { useDeleteUser } from './user';

export function useLegalUpdatesModalManager() {
    const history = useHistory();
    const location = useLocation();
    const legalUpdatesToBeReviewed = useLegalUpdatesToBeReviewed('network-only');
    const lastLegalUpdateConsentedAt = useReactiveVar(lastLegalUpdateConsentedAtVar);
    const checkAndDisplayLegalUpdatesModal = () => {
        if (
            location.pathname !== '/home/legal-updates' &&
            location.pathname !== '/home/promotion/browser-extension' &&
            location.pathname !== '/home/point-collection'
        ) {
            const hasRecentlyConsentedToLegalUpdate =
                !!lastLegalUpdateConsentedAt && moment.unix(lastLegalUpdateConsentedAt).isAfter(moment().subtract(1, 'h'));
            if (legalUpdatesToBeReviewed && !hasRecentlyConsentedToLegalUpdate) history.push('/home/legal-updates');
        }
    };
    React.useEffect(() => {
        checkAndDisplayLegalUpdatesModal();
    }, [legalUpdatesToBeReviewed, location]);
}

export function useLegalUpdatesToBeReviewed(fetchPolicy: FetchPolicy): LegalUpdatesToBeReviewed | undefined | null {
    const queryResponse = useQuery<LegalUpdatesToBeReviewedQueryResponse>(legalUpdatesToBeReviewedQuery, { fetchPolicy });
    return queryResponse.data?.user?.legalUpdatesToBeReviewed;
}

export function useSubmitLegalUpdatesConsentChoice(): [
    (parameters: { legalUpdateIds: string[]; consentChoice: boolean }) => Promise<void>,
    boolean
] {
    const client = useApolloClient();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const deleteUser = useDeleteUser();
    const submitLegalUpdatesConsentChoice = async ({
        legalUpdateIds,
        consentChoice,
    }: {
        legalUpdateIds: string[];
        consentChoice: boolean;
    }) => {
        setIsLoading(true);
        await client.mutate<UpdateUserLegalUpdatesConsentResponse, UpdateUserLegalUpdatesConsentVariables>({
            mutation: updateUserLegalUpdatesConsentMutation,
            variables: { legalUpdateIds, consentChoice },
        });
        lastLegalUpdateConsentedAtVar(moment().unix());
        logUserEventUtil(client, {
            type: 'submittedLegalUpdatesConsentChoice',
            payload: { legalUpdateIds, consentChoice },
        });
        logAmplitudeEvent({
            name: 'Legal - Submitted Legal Updates Consent Choice',
            properties: { legalUpdateIds, consentChoice },
        });
        if (consentChoice === false) await deleteUser();
        else
            setTimeout(() => {
                client.query({ query: legalUpdatesToBeReviewedQuery, fetchPolicy: 'network-only' }); // To avoid being redirected again after one hour on the legal updates page
            }, 5000);
        setIsLoading(false);
    };
    return [submitLegalUpdatesConsentChoice, isLoading];
}
