import { getDeviceId } from '../../lib/common/device';
import { UserEvent } from '../../lib/events/userEvents';
import { RestApiUrl, getRestApiUrl } from '../config';

export async function logUserEventWithoutAuthentication(userId: string, event: UserEvent, shouldUseDevStack?: boolean) {
    console.log(`API call: logging ${event.type} event...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const timestamp = Number((Date.now() / 1000).toFixed(3));
    const active = true;
    const restApiUrl =
        shouldUseDevStack !== undefined ? (shouldUseDevStack ? RestApiUrl.dev : RestApiUrl.prod) : getRestApiUrl();
    const payload = { ...(event.payload ?? {}), platform: 'webApp', deviceId: getDeviceId() };
    const url =
        `${restApiUrl}user/events` +
        `?userId=${encodeURIComponent(userId)}&type=${event.type}&timestamp=${timestamp}&active=${active}` +
        `&payload=${encodeURIComponent(JSON.stringify(payload))}`;
    await fetch(url, { method: 'POST', headers: headers })
        .then((response) => console.log(`Response ${response.status} for API call 'log ${event.type} event'`))
        .catch((error) => console.log(error));
}
