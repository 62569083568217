import { merchantOfferFragment, MerchantOffer } from '../fragments/offers';

const gql = require('graphql-tag');

export const merchantOfferQuery = gql`
    query MerchantOfferQuery($offerId: String!) {
        offer(offerId: $offerId) {
            ...MerchantOffer
        }
    }
    ${merchantOfferFragment}
`;

export interface MerchantOfferQueryResponse {
    __typename: 'Query';
    offer: MerchantOffer | null;
}

export interface MerchantOfferQueryVariables {
    offerId: string;
}
