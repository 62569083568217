import React from 'react';
import { TouchableWithoutFeedback, View, StyleSheet, Modal } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Location } from 'history';
import { useWindowSize } from '../../style/utils';
import CloseButton from './CloseButton';

export default function ModalContainer({
    children,
    cannotClose,
    onClose,
    shouldCrossBeDiscrete,
}: {
    children: JSX.Element;
    cannotClose?: boolean;
    onClose?: () => void;
    shouldCrossBeDiscrete?: boolean;
}) {
    const history = useHistory();
    const size = useWindowSize();
    const [isVisible, setIsVisible] = React.useState(false);
    React.useEffect(() => {
        setTimeout(setIsVisible(true), 200);
    }, []);
    return (
        <Modal transparent={true}>
            <TouchableWithoutFeedback onPress={() => !cannotClose && history.goBack()}>
                <View
                    style={[
                        styles.containerModalBackground,
                        { height: size.height, width: size.width, opacity: isVisible ? 1 : 0 },
                    ]}>
                    {cannotClose ? (
                        children
                    ) : (
                        <TouchableWithoutFeedback>
                            <View>
                                <View style={styles.containerCloseButton}>
                                    <CloseButton
                                        onPress={() => {
                                            onClose ? onClose() : history.goBack();
                                        }}
                                        shouldCrossBeDiscrete={shouldCrossBeDiscrete}
                                    />
                                </View>
                                {children}
                            </View>
                        </TouchableWithoutFeedback>
                    )}
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

export function useModalLocationManager(modalPathPrefixes: string[]): [boolean, Location | undefined] {
    const location = useLocation();
    let { path } = useRouteMatch();
    const isModalLocation: boolean = React.useMemo(
        () => modalPathPrefixes.some((modalPathPrefix) => location.pathname.startsWith(`${path}${modalPathPrefix}`)),
        [location, path, modalPathPrefixes]
    );
    const [lastNonModalLocation, setLastNonModalLocation] = React.useState<Location | undefined>(undefined);
    React.useEffect(() => {
        if (!isModalLocation) setLastNonModalLocation(location);
    }, [location, isModalLocation]);
    return [isModalLocation, lastNonModalLocation];
}

const styles = StyleSheet.create({
    containerModalBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 10,
    },
    containerCloseButton: {
        position: 'absolute',
        right: 15,
        top: 15,
        zIndex: 15,
        opacity: 0.75,
    },
});
