import * as React from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, ActivityIndicator, Image, ScrollView } from 'react-native';
import { useHistory, useParams } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../../Locales';
import { ApolloClient } from '../../../api/graphql/client';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { Reward } from '../../../api/graphql/fragments/rewards';
import { UserInfo, UserBankDetails } from '../../../api/graphql/fragments/user';
import { userQuery, UserQueryResponse } from '../../../api/graphql/queries/user';
import { updateUser } from '../../../api/graphql/mutations/updateUser';
import {
    RedeemRewardMutationResponse,
    redeemRewardMutation,
    redeemBankTransferUKMutation,
    redeemBankTransferUSMutation,
    RedeemRewardMutationVariables,
} from '../../../api/graphql/mutations/redeemReward';
import { Currency, getRegion } from '../../../lib/auth/region';
import {
    BankDetails,
    BankDetailsSEPA,
    BankDetailsUK,
    BankDetailsUS,
    AccountTypeUS,
    buildEmptyBankDetails,
} from '../../../lib/bank/bankDetails';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { refetchHistoryAndPointsWithDelay, useUserPointBalance } from '../../../lib/points/points';
import { getEmailAndPhoneNumberStatuses } from '../../../lib/common/cognito';
import { Dialog, DialogState, useReward } from './RewardDetailPage';

const IBAN = require('iban');

const leftChevronIcon = { uri: '/assets/images/icons/chevron-left-gray.svg' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark.svg' };

export default UserBankDetailsPage;

function UserBankDetailsPage() {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage;
    const reward: Reward | undefined = useReward();
    const { pointsString } = useParams<{ pointsString: string | undefined }>();
    const points = React.useMemo(() => (pointsString ? Number(pointsString) : undefined), [pointsString]);
    const userRegion = getRegion();
    const { userInfo, userBankDetails } = useUserInfo();
    const [bankDetails, setBankDetails] = React.useState<BankDetails>(buildEmptyBankDetails(userRegion));
    React.useEffect(() => {
        if (bankDetails.currency === Currency.EUR) {
            setBankDetails({
                currency: Currency.EUR,
                firstName: userInfo?.firstName ?? bankDetails.firstName,
                lastName: userInfo?.lastName ?? bankDetails.lastName,
                iban: userBankDetails?.iban ? formatCompleteIban(userBankDetails.iban) : bankDetails.iban,
                bic: userBankDetails?.bic ?? bankDetails.bic,
            });
        }
    }, [userInfo, userBankDetails]);
    const [shouldSaveData, setShouldSaveData] = React.useState(!reward);
    const [dialogState, setDialogState] = React.useState<DialogState>({ isVisible: false });
    const closeDialog = () => setDialogState({ ...dialogState, isVisible: false });
    if (!dialogState.isVisible)
        return (
            <ScrollView style={styles.container}>
                <View>
                    <Text style={styles.textTitle}>{texts.title}</Text>
                    <Text style={styles.textDescription}>
                        {texts.subtitle({ terms: reward?.bankTransferProperties?.terms || '' })}
                    </Text>
                </View>
                <BankDetailsInput bankDetails={bankDetails} setBankDetails={setBankDetails} />
                {reward && bankDetails.currency === Currency.EUR ? (
                    <SaveOptionToggle {...{ shouldSaveData, setShouldSaveData }} />
                ) : (
                    <View />
                )}
                <ValidateButton
                    reward={reward}
                    points={points}
                    userBankDetails={userBankDetails}
                    bankDetails={bankDetails}
                    shouldSaveData={shouldSaveData}
                    setDialogState={setDialogState}
                />
                <BackButton />
            </ScrollView>
        );
    else return <Dialog {...{ dialogState, closeDialog }} />;
}

function useUserInfo(): { userInfo: UserInfo | null; userBankDetails: UserBankDetails | null } {
    const userQueryResponse = useQuery<UserQueryResponse, {}>(userQuery, { fetchPolicy: 'cache-and-network' });
    const userInfo: UserInfo | null = React.useMemo(
        () => userQueryResponse.data?.user?.userInfo || null,
        [userQueryResponse]
    );
    const userBankDetails: UserBankDetails | null = React.useMemo(
        () => userQueryResponse.data?.user?.userBankDetails || null,
        [userQueryResponse]
    );
    return { userInfo, userBankDetails };
}

function formatCompleteIban(iban: string): string {
    return iban
        .replace(/ /g, '')
        .replace(/([a-zA-Z\d]{4})/g, '$1 ')
        .trim();
}

function BankDetailsInput({
    bankDetails,
    setBankDetails,
}: {
    bankDetails: BankDetails;
    setBankDetails: (value: BankDetails) => void;
}) {
    switch (bankDetails.currency) {
        case Currency.EUR:
            return <BankDetailsInputSEPA {...{ bankDetails, setBankDetails }} />;
        case Currency.GBP:
            return <BankDetailsInputUK {...{ bankDetails, setBankDetails }} />;
        case Currency.USD:
            return <BankDetailsInputUS {...{ bankDetails, setBankDetails }} />;
    }
}

function BankDetailsInputSEPA({
    bankDetails,
    setBankDetails,
}: {
    bankDetails: BankDetailsSEPA;
    setBankDetails: (value: BankDetails) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage.bankDetails;
    if (texts.currency !== Currency.EUR) return null;
    return (
        <>
            <View style={{ flexDirection: 'row' }}>
                <TextInputWithLabel
                    label={texts.firstName.label}
                    input={bankDetails.firstName}
                    setInput={(value) => setBankDetails({ ...bankDetails, firstName: value })}
                    placeholder={texts.firstName.placeholder}
                />
                <View style={{ width: 15 }} />
                <TextInputWithLabel
                    label={texts.lastName.label}
                    input={bankDetails.lastName}
                    setInput={(value) => setBankDetails({ ...bankDetails, lastName: value })}
                    placeholder={texts.lastName.placeholder}
                />
            </View>
            <View style={{ flexDirection: 'row' }}>
                <TextInputWithLabel
                    label={texts.iban.label}
                    input={bankDetails.iban}
                    setInput={(value) => {
                        if (value.length > bankDetails.iban.length)
                            setBankDetails({ ...bankDetails, iban: formatIncompleteIban(value) });
                        else setBankDetails({ ...bankDetails, iban: value });
                    }}
                    placeholder={texts.iban.placeholder}
                />
            </View>
            <View style={{ flexDirection: 'row' }}>
                <TextInputWithLabel
                    label={texts.bic.label}
                    input={bankDetails.bic}
                    setInput={(value) => setBankDetails({ ...bankDetails, bic: value })}
                    placeholder={texts.bic.placeholder}
                />
            </View>
        </>
    );
}

function formatIncompleteIban(iban: string): string {
    const regex = /((?: |^)[a-zA-Z\d]{4})([a-zA-Z\d]$)/g;
    if (!!regex.exec(iban)) return iban.replace(regex, `$1 $2`);
    else return iban;
}

function BankDetailsInputUK({
    bankDetails,
    setBankDetails,
}: {
    bankDetails: BankDetailsUK;
    setBankDetails: (value: BankDetails) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage.bankDetails;
    if (texts.currency !== Currency.GBP) return null;
    return (
        <>
            <TextInputWithLabel
                label={texts.fullName.label}
                input={bankDetails.fullName}
                setInput={(value) => setBankDetails({ ...bankDetails, fullName: value })}
                placeholder={texts.fullName.placeholder}
            />
            <TextInputWithLabel
                label={texts.accountNumber.label}
                input={bankDetails.accountNumber}
                setInput={(value) => setBankDetails({ ...bankDetails, accountNumber: value })}
                placeholder={texts.accountNumber.placeholder}
            />
            <TextInputWithLabel
                label={texts.sortCode.label}
                input={bankDetails.sortCode}
                setInput={(value) => setBankDetails({ ...bankDetails, sortCode: value })}
                placeholder={texts.sortCode.placeholder}
            />
        </>
    );
}

function BankDetailsInputUS({
    bankDetails,
    setBankDetails,
}: {
    bankDetails: BankDetailsUS;
    setBankDetails: (value: BankDetails) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage.bankDetails;
    if (texts.currency !== Currency.USD) return null;
    return (
        <>
            <TextInputWithLabel
                label={texts.fullName.label}
                input={bankDetails.fullName}
                setInput={(value) => setBankDetails({ ...bankDetails, fullName: value })}
                placeholder={texts.fullName.placeholder}
            />
            <TextInputWithLabel
                label={texts.routingNumber.label}
                input={bankDetails.routingNumber}
                setInput={(value) => setBankDetails({ ...bankDetails, routingNumber: value })}
                placeholder={texts.routingNumber.placeholder}
            />
            <TextInputWithLabel
                label={texts.accountNumber.label}
                input={bankDetails.accountNumber}
                setInput={(value) => setBankDetails({ ...bankDetails, accountNumber: value })}
                placeholder={texts.accountNumber.placeholder}
            />
            <TextInputWithLabel
                label={texts.accountType.label}
                input={bankDetails.accountType}
                setInput={(value) => setBankDetails({ ...bankDetails, accountType: value.toUpperCase() as AccountTypeUS })}
                placeholder={texts.accountType.placeholder}
            />
            <TextInputWithLabel
                label={texts.address.firstLine.label}
                input={bankDetails.address.firstLine}
                setInput={(value) =>
                    setBankDetails({ ...bankDetails, address: { ...bankDetails.address, firstLine: value } })
                }
                placeholder={texts.address.firstLine.placeholder}
            />
            <TextInputWithLabel
                label={texts.address.city.label}
                input={bankDetails.address.city}
                setInput={(value) => setBankDetails({ ...bankDetails, address: { ...bankDetails.address, city: value } })}
                placeholder={texts.address.city.placeholder}
            />
            <TextInputWithLabel
                label={texts.address.postCode.label}
                input={bankDetails.address.postCode}
                setInput={(value) =>
                    setBankDetails({ ...bankDetails, address: { ...bankDetails.address, postCode: value } })
                }
                placeholder={texts.address.postCode.placeholder}
                maxLength={5}
            />
            <TextInputWithLabel
                label={texts.address.state.label}
                input={bankDetails.address.state}
                setInput={(value) =>
                    setBankDetails({ ...bankDetails, address: { ...bankDetails.address, state: value.toUpperCase() } })
                }
                placeholder={texts.address.state.placeholder}
                maxLength={2}
            />
        </>
    );
}

function BackButton() {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage;
    const history = useHistory();
    return (
        <TouchableOpacity style={styles.containerBackButton} onPress={() => history.goBack()}>
            <Image source={leftChevronIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
            <Text style={styles.textBackButton}>{texts.backButton}</Text>
        </TouchableOpacity>
    );
}

function TextInputWithLabel({
    label,
    placeholder,
    input,
    setInput,
    maxLength,
}: {
    label: string;
    placeholder: string;
    input: string;
    setInput: (value: string) => void;
    maxLength?: number;
}) {
    return (
        <View style={{ flex: 1 }}>
            <Text style={styles.textLabel}>{label}</Text>
            <View style={[input ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
                <TextInput
                    style={styles.textInput}
                    placeholder={placeholder}
                    value={input}
                    blurOnSubmit={false}
                    autoCorrect={false}
                    autoCapitalize={'words'}
                    keyboardType={'default'}
                    returnKeyType="next"
                    underlineColorAndroid="transparent"
                    onChangeText={setInput}
                    placeholderTextColor={color.frenchGray}
                    maxLength={maxLength}
                />
            </View>
        </View>
    );
}

function SaveOptionToggle({
    shouldSaveData,
    setShouldSaveData,
}: {
    shouldSaveData: boolean;
    setShouldSaveData: (value: boolean) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage;
    return (
        <TouchableOpacity
            style={styles.containerSaveOptionToggle}
            onPress={() => setShouldSaveData(!shouldSaveData)}
            activeOpacity={0.7}>
            <View
                style={[
                    styles.containerCheckMark,
                    shouldSaveData ? styles.containerCheckMarkSelected : styles.containerCheckMarkUnselected,
                ]}>
                {shouldSaveData ? (
                    <Image source={checkMarkIcon} style={{ height: 12, width: 12, resizeMode: 'contain' }} />
                ) : null}
            </View>
            <Text style={styles.textSaveOption}>{texts.saveOption}</Text>
        </TouchableOpacity>
    );
}

function ValidateButton({
    reward,
    points,
    userBankDetails,
    bankDetails,
    shouldSaveData,
    setDialogState,
}: {
    reward: Reward | undefined;
    points: number | undefined;
    userBankDetails: UserBankDetails | null;
    bankDetails: BankDetails;
    shouldSaveData: boolean;
    setDialogState: (value: DialogState) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage;
    const [validateInfo, isLoading] = useValidateInfo(reward, points, userBankDetails, setDialogState);
    return (
        <TouchableOpacity onPress={() => validateInfo({ bankDetails, shouldSaveData })} style={{ flexDirection: 'row' }}>
            <View
                style={[
                    styles.containerButton,
                    shouldDisableValidateButton(bankDetails) ? { backgroundColor: color.frenchGray } : {},
                ]}>
                {isLoading ? (
                    <ActivityIndicator color={color.white} />
                ) : (
                    <Text style={styles.textButton}>{texts.button}</Text>
                )}
            </View>
        </TouchableOpacity>
    );
}

function shouldDisableValidateButton(bankDetails: BankDetails): boolean {
    switch (bankDetails.currency) {
        case Currency.EUR:
            return !bankDetails.firstName || !bankDetails.lastName || !bankDetails.iban || !bankDetails.bic;
        case Currency.GBP:
            return !bankDetails.fullName || !bankDetails.accountNumber || !bankDetails.sortCode;
        case Currency.USD:
            const { fullName, routingNumber, accountNumber, accountType, address } = bankDetails;
            const { firstLine, city, postCode, state } = address;
            const isAddressValid = !!firstLine && !!city && !!postCode && !!state;
            return !fullName || !routingNumber || !accountNumber || !accountType || !isAddressValid;
    }
}

type ValidateInfoParams = {
    bankDetails: BankDetails;
    shouldSaveData: boolean;
};

function useValidateInfo(
    reward: Reward | undefined,
    points: number | undefined,
    userBankDetails: UserBankDetails | null,
    setDialogState: (value: DialogState) => void
): [(params: ValidateInfoParams) => void, boolean] {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage;
    const history = useHistory();
    const apolloClient = useApolloClient();
    const [isLoading, setIsLoading] = React.useState(false);
    const userPointBalance = useUserPointBalance();
    const setErrorMessage = (message: string) => setDialogState({ isVisible: true, subtitle: message, confirmButton: 'OK' });
    const validateInfo = async ({ bankDetails, shouldSaveData }: ValidateInfoParams) => {
        if (!isLoading) {
            if (bankDetails.currency === Currency.EUR)
                bankDetails = {
                    ...bankDetails,
                    iban: formatBicAndIban(bankDetails.iban),
                    bic: formatBicAndIban(bankDetails.bic),
                };
            if (!checkBankDetailsAndSetErrorMessages(bankDetails, setErrorMessage)) return;
            setIsLoading(true);
            logUserEventUtil(apolloClient, { type: 'confirmedBankDetails', payload: { save: shouldSaveData } });
            if (shouldSaveData && bankDetails.currency === Currency.EUR) {
                if (userBankDetails === null) await updateUser(apolloClient, {}); // Needed to initialize the 'userBankDetails' if it is empty
                await updateUser(apolloClient, bankDetails);
            }
            if (reward && points && reward.bankTransferProperties && reward.bankTransferProperties.bankCountryWhitelist) {
                const hasEnoughPoints =
                    userPointBalance !== undefined &&
                    userPointBalance >= (reward.minPoints || 0) &&
                    userPointBalance >= (points || 0);
                const { emailVerified, phoneNumberVerified } = await getEmailAndPhoneNumberStatuses();
                if (
                    bankDetails.currency === Currency.EUR &&
                    reward.bankTransferProperties.bankCountryWhitelist &&
                    !reward.bankTransferProperties.bankCountryWhitelist.includes(bankDetails.iban.substring(0, 2))
                ) {
                    setErrorMessage(
                        texts.error.bankDetails.currency === Currency.EUR ? texts.error.bankDetails.unauthorizedCountry : ''
                    );
                    setIsLoading(false);
                } else if (!hasEnoughPoints) {
                    setErrorMessage(texts.error.balanceTooLow);
                    setIsLoading(false);
                } else if (!emailVerified || !phoneNumberVerified) {
                    setErrorMessage(texts.error.verificationNeeded);
                    setIsLoading(false);
                } else if (
                    !reward.minPoints ||
                    !reward.maxPoints ||
                    !reward.pointInterval ||
                    points < reward.minPoints ||
                    points > reward.maxPoints ||
                    (points - reward.minPoints) % reward.pointInterval !== 0
                ) {
                    setErrorMessage(texts.error.incorrectAmount);
                    setIsLoading(false);
                } else {
                    logAmplitudeEvent({
                        name: 'Rewards - Confirmed Redeem',
                        properties: { type: reward.type, rewardId: reward.rewardId, points },
                    });
                    await redeemBankTransfer({ apolloClient, rewardId: reward.rewardId, points, bankDetails });
                    refetchHistoryAndPointsWithDelay(apolloClient, 5000);
                    history.push(`/home/rewards/bank-transfer/${reward.rewardId}/success`);
                    setIsLoading(false);
                }
            } else history.goBack();
        }
    };
    return [validateInfo, isLoading];
}

function formatBicAndIban(input: string): string {
    return input.replace(' ', '').toUpperCase();
}

function checkBankDetailsAndSetErrorMessages(bankDetails: BankDetails, setErrorMessage: (value: string) => void) {
    const texts = getLocalizedTexts().home.rewards.bankDetailsPage.error;
    if (bankDetails.currency === Currency.EUR && texts.bankDetails.currency === Currency.EUR) {
        if (bankDetails.firstName.length === 0) {
            setErrorMessage(texts.bankDetails.noFirstName);
            return false;
        }
        if (bankDetails.lastName.length === 0) {
            setErrorMessage(texts.bankDetails.noLastName);
            return false;
        }
        if (bankDetails.iban.length === 0) {
            setErrorMessage(texts.bankDetails.noIban);
            return false;
        }
        if (!IBAN.isValid(bankDetails.iban)) {
            setErrorMessage(texts.bankDetails.incorrectIban);
            return false;
        }
        if (bankDetails.bic.length !== 8 && bankDetails.bic.length !== 11) {
            setErrorMessage(texts.bankDetails.incorrectBic);
            return false;
        }
        return true;
    } else if (bankDetails.currency === Currency.GBP && texts.bankDetails.currency === Currency.GBP) {
        if (bankDetails.fullName.length === 0) {
            setErrorMessage(texts.bankDetails.noFullName);
            return false;
        }
        if (bankDetails.accountNumber.length === 0) {
            setErrorMessage(texts.bankDetails.noAccountNumber);
            return false;
        }
        if (bankDetails.sortCode.length === 0) {
            setErrorMessage(texts.bankDetails.noSortCode);
            return false;
        }
        return true;
    } else if (bankDetails.currency === Currency.USD && texts.bankDetails.currency === Currency.USD) {
        if (bankDetails.fullName.length === 0) {
            setErrorMessage(texts.bankDetails.noFullName);
            return false;
        }
        if (bankDetails.routingNumber.length === 0) {
            setErrorMessage(texts.bankDetails.noRoutingNumber);
            return false;
        }
        if (bankDetails.accountNumber.length === 0) {
            setErrorMessage(texts.bankDetails.noAccountNumber);
            return false;
        }
        if (bankDetails.accountType.length === 0) {
            setErrorMessage(texts.bankDetails.noAccountType);
            return false;
        }
        if (!Object.values(AccountTypeUS).includes(bankDetails.accountType)) {
            setErrorMessage(texts.bankDetails.invalidAccountType);
            return false;
        }
        if (bankDetails.address.firstLine.length === 0) {
            setErrorMessage(texts.bankDetails.address.noFirstLine);
            return false;
        }
        if (bankDetails.address.city.length === 0) {
            setErrorMessage(texts.bankDetails.address.noCity);
            return false;
        }
        if (bankDetails.address.postCode.length === 0) {
            setErrorMessage(texts.bankDetails.address.noPostCode);
            return false;
        }
        if (bankDetails.address.state.length === 0) {
            setErrorMessage(texts.bankDetails.address.noState);
            return false;
        }
        return true;
    }
    setErrorMessage(texts.unknown);
    return false;
}

async function redeemBankTransfer({
    apolloClient,
    rewardId,
    points,
    bankDetails,
}: {
    apolloClient: ApolloClient;
    rewardId: string;
    points: number;
    bankDetails: BankDetails;
}) {
    switch (bankDetails.currency) {
        case Currency.EUR:
            await apolloClient.mutate<RedeemRewardMutationResponse, RedeemRewardMutationVariables>({
                mutation: redeemRewardMutation,
                variables: { rewardId, points, userBankDetails: bankDetails },
            });
            break;
        case Currency.GBP:
            await apolloClient.mutate<RedeemRewardMutationResponse, RedeemRewardMutationVariables>({
                mutation: redeemBankTransferUKMutation,
                variables: { rewardId, points, userBankDetails: bankDetails },
            });
            break;
        case Currency.USD:
            await apolloClient.mutate<RedeemRewardMutationResponse, RedeemRewardMutationVariables>({
                mutation: redeemBankTransferUSMutation,
                variables: { rewardId, points, userBankDetails: bankDetails },
            });
            break;
    }
}

const styles = StyleSheet.create({
    container: {
        width: 600,
        height: 600,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        marginTop: 50,
        marginBottom: 50,
    },
    containerTextInput: {
        flexDirection: 'row',
        marginTop: 10,
    },
    containerSaveOptionToggle: {
        marginTop: 24,
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        width: 24,
        borderRadius: 24,
    },
    containerCheckMarkSelected: {
        backgroundColor: color.emerald,
    },
    containerCheckMarkUnselected: {
        borderWidth: 2,
        borderColor: color.frenchGray,
    },
    containerButton: {
        marginTop: 40,
        flex: 1,
        height: 50,
        borderRadius: 60,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.black,
    },
    containerBackButton: {
        alignSelf: 'center',
        marginTop: 16,
        flexDirection: 'row',
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.black,
    },
    textDescription: {
        marginTop: 10,
        marginBottom: 24,
        fontFamily: font.ambitSemiBold,
        fontSize: 15,
        color: color.manatee,
    },
    textLabel: {
        marginTop: 16,
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.black,
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textSaveOption: {
        flex: 1,
        marginLeft: 15,
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
    },
    textBackButton: {
        fontFamily: font.ambitBlack,
        color: color.frenchGray,
        fontSize: 14,
        paddingLeft: 8,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
    icon: {
        flex: 1,
        maxHeight: 100,
        maxWidth: 100,
    },
});
