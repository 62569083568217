export const font = {
    regular: 'AvenirNext-Regular',
    medium: 'AvenirNext-Medium',
    demiBold: 'AvenirNext-DemiBold',
    bold: 'AvenirNext-Bold',
    montserratRegular: 'Montserrat-Regular',
    montserratMedium: 'Montserrat-Medium',
    montserratSemiBold: 'Montserrat-SemiBold',
    montserratBold: 'Montserrat-Bold',
    montserratExtraBold: 'Montserrat-ExtraBold',
    montserratBlack: 'Montserrat-Black',
    robotoThin: 'Roboto-Thin',
    robotoLight: 'Roboto-Light',
    robotoRegular: 'Roboto-Regular',
    robotoMedium: 'Roboto-Medium',
    robotoBold: 'Roboto-Bold',
    robotoBlack: 'Roboto-Black',
    museoSansRounded100: 'MuseoSansRounded-100',
    museoSansRounded300: 'MuseoSansRounded-300',
    museoSansRounded500: 'MuseoSansRounded-500',
    museoSansRounded700: 'MuseoSansRounded-700',
    museoSansRounded900: 'MuseoSansRounded-900',
    museoSansRounded1000: 'MuseoSansRounded-1000',
    ambitThin: 'Ambit-Thin',
    ambitExtraLight: 'Ambit-ExtraLight',
    ambitLight: 'Ambit-Light',
    ambitRegular: 'Ambit-Regular',
    ambitSemiBold: 'Ambit-SemiBold',
    ambitBold: 'Ambit-Bold',
    ambitBlack: 'Ambit-Black',
};
