import * as React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';

import { getLocalizedTexts } from '../../Locales';
import { font } from '../../style/text';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { getDevStackMode } from '../../lib/common/devStackMode';

const logo = { uri: '/assets/images/logos/logo-with-title-white.svg' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark.svg' };

function IntroPanel() {
    const texts = getLocalizedTexts().auth.intro;
    const size = useWindowSize();
    const devStackMode = getDevStackMode();
    return (
        <View style={[styles.container, devStackMode ? { backgroundColor: color.fairPink } : {}]}>
            <View style={{ height: size.height }}>
                <View style={styles.containerContent}>
                    <View style={styles.containerLogo}>
                        <Image source={logo} style={styles.imageLogo} />
                    </View>
                    <View style={styles.containerParagraphs}>
                        <Paragraph title={texts.paragraph1.title} message={texts.paragraph1.message} />
                        <Paragraph title={texts.paragraph2.title} message={texts.paragraph2.message} />
                        <Paragraph title={texts.paragraph3.title} message={texts.paragraph3.message} />
                    </View>
                </View>
            </View>
        </View>
    );
}

export default IntroPanel;

function Paragraph({ title, message }: { title: string; message: string }) {
    return (
        <View style={styles.containerParagraph}>
            <View style={styles.containerCheckMarkIcon}>
                <Image source={checkMarkIcon} style={styles.imageCheckMark} />
            </View>
            <View style={styles.containerText}>
                <Text style={styles.textTitle}>{title}</Text>
                <Text style={styles.textMessage}>{message}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: color.screamingGreen,
    },
    containerContent: {
        position: 'absolute',
        top: 60,
        right: 80,
        width: 300,
    },
    containerLogo: {
        marginLeft: 15,
    },
    containerParagraphs: {
        marginTop: 40,
    },
    containerParagraph: {
        flexDirection: 'row',
    },
    containerText: {
        flex: 1,
        marginLeft: 12,
    },
    containerCheckMarkIcon: {
        top: 2,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.black,
        marginBottom: 8,
    },
    textMessage: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
        marginBottom: 25,
    },
    imageLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
    imageCheckMark: {
        width: 16,
        height: 16,
    },
});
