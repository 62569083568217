import {
    transactionEligibilityConditionsFragment,
    offerCardFragment,
    offerScreenFragment,
    TransactionEligibilityConditions,
    OfferType,
    OfferScreen,
    OfferCard,
    RateForDisplay,
    rateForDisplayFragment,
} from './offers';

const gql = require('graphql-tag');

export const enrichedOfferConditionsFragment = gql`
    fragment EnrichedOfferConditions on EnrichedOfferConditions {
        description
        transactionEligibility {
            ...TransactionEligibilityConditions
        }
        conditions {
            standardConditionId
            summary
            description
        }
    }
    ${transactionEligibilityConditionsFragment}
`;

export const enrichedMerchantOfferFragment = gql`
    fragment EnrichedMerchantOffer on EnrichedMerchantOffer {
        offerId
        merchantId
        type
        points
        fixedPoints
        nonBoostedPoints
        nonBoostedFixedPoints
        multipleRatesForDisplay {
            ...RateForDisplay
        }
        logoURL
        enrichedConditions {
            ...EnrichedOfferConditions
        }
        card {
            ...OfferCard
        }
        screen {
            ...OfferScreen
        }
        matchToAffiliateTransaction
        affiliateLink
        website
        personalizedAffiliateLink
        browsingTagIds
    }
    ${rateForDisplayFragment}
    ${enrichedOfferConditionsFragment}
    ${offerCardFragment}
    ${offerScreenFragment}
`;

export interface EnrichedCondition {
    __typename: 'EnrichedCondition';
    standardConditionId: string | null;
    summary: string;
    description: string | null;
}

export interface EnrichedOfferConditions {
    __typename: 'EnrichedOfferConditions';
    description: string | null;
    transactionEligibility: TransactionEligibilityConditions | null;
    conditions: EnrichedCondition[];
}

export interface EnrichedMerchantOffer {
    __typename: 'EnrichedMerchantOffer';
    offerId: string;
    merchantId: string;
    type: OfferType;
    points: number;
    fixedPoints: number;
    nonBoostedPoints: number | null;
    nonBoostedFixedPoints: number | null;
    multipleRatesForDisplay: RateForDisplay[] | null;
    logoURL: string | null;
    enrichedConditions: EnrichedOfferConditions | null;
    card: OfferCard | null;
    screen: OfferScreen;
    matchToAffiliateTransaction: boolean | null;
    affiliateLink: string | null;
    website: string | null;
    personalizedAffiliateLink: string | null;
    browsingTagIds: string[] | null;
}

export function dataIdFromEnrichedMerchantOffer(offer: EnrichedMerchantOffer | { offerId: string }): string {
    return `EnrichedMerchantOffer:${offer.offerId}`;
}

export const enrichedUserOfferFragment = gql`
    fragment EnrichedUserOffer on EnrichedUserOffer {
        offerId
        offer {
            ...EnrichedMerchantOffer
        }
        active
        activatedAt
        points
        fixedPoints
        eligible
        favorited
        available
    }
    ${enrichedMerchantOfferFragment}
`;

export interface EnrichedUserOffer {
    __typename: 'EnrichedUserOffer';
    offerId: string;
    offer: EnrichedMerchantOffer;
    active: boolean;
    activatedAt: string | null;
    eligible: boolean;
    points: number;
    fixedPoints: number;
    favorited: boolean | null;
    available: boolean;
}

export function dataIdFromEnrichedUserOffer(userOffer: EnrichedUserOffer | { offerId: string }): string {
    return `EnrichedUserOffer:${userOffer.offerId}`;
}
