import * as React from 'react';
import { View, StyleSheet, Text, FlatList, Image } from 'react-native';
import { useHistory } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { font } from '../../style/text';
import GradientBackground from '../../components/common/GradientBackground';
import Button from '../../components/common/Button';
import HighlightedText from '../../components/common/HighlightedText';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';
import { MarketingWebsitePath, getMarketingWebsiteUrl } from '../../lib/auth/region';
import { LegalUpdate, LegalUpdatesToBeReviewed, LegalUpdateType } from '../../api/graphql/fragments/legalUpdates';
import { useLegalUpdatesToBeReviewed, useSubmitLegalUpdatesConsentChoice } from '../../lib/common/legalUpdates';

const warningSignEmoji = { uri: '/assets/images/icons/apple-warning-sign.png' };

function LegalUpdatesPage() {
    const history = useHistory();
    const size = useWindowSize();
    const legalUpdatesToBeReviewed = useLegalUpdatesToBeReviewed('cache-first');
    const [isRefusalSectionVisible, setIsRefusalSectionVisible] = React.useState<boolean>(false);
    const gradientBackgroundSize = { height: (size.height || 0) - 120, width: (size.width || 0) - 240 };
    React.useEffect(() => {
        if (!legalUpdatesToBeReviewed) history.push('/home');
    }, [legalUpdatesToBeReviewed]);
    if (!legalUpdatesToBeReviewed) return null;
    return (
        <View style={[styles.container, { height: size.height, width: size.width }]}>
            <View style={[gradientBackgroundSize, { alignItems: 'center' }]}>
                <GradientBackground {...gradientBackgroundSize} />
            </View>
            {!isRefusalSectionVisible ? (
                <MainSection showRefusalSection={() => setIsRefusalSectionVisible(true)} {...{ legalUpdatesToBeReviewed }} />
            ) : (
                <RefusalSection
                    hideRefusalSection={() => setIsRefusalSectionVisible(false)}
                    {...{ legalUpdatesToBeReviewed }}
                />
            )}
        </View>
    );
}

function MainSection({
    legalUpdatesToBeReviewed,
    showRefusalSection,
}: {
    legalUpdatesToBeReviewed: LegalUpdatesToBeReviewed;
    showRefusalSection: () => void;
}) {
    const texts = getLocalizedTexts().home.legalUpdates;
    return (
        <View style={styles.containerContent}>
            <Text style={styles.textTitle}>{texts.title({ legalUpdateType: legalUpdatesToBeReviewed.type })}</Text>
            <View style={{ flex: 1 }}>
                <FlatList<LegalUpdate>
                    style={{ flexGrow: 0 }}
                    data={legalUpdatesToBeReviewed.legalUpdates}
                    keyExtractor={({ legalUpdateId }) => legalUpdateId}
                    renderItem={({ item }) => (
                        <View style={styles.containerSummaryOfChanges}>
                            <Text style={styles.textSummaryOfChanges}>{item.summaryOfChanges}</Text>
                        </View>
                    )}
                />
                <Links {...{ legalUpdatesToBeReviewed }} />
            </View>
            <Buttons {...{ legalUpdatesToBeReviewed, showRefusalSection }} />
        </View>
    );
}

function Links({ legalUpdatesToBeReviewed }: { legalUpdatesToBeReviewed: LegalUpdatesToBeReviewed }) {
    const texts = getLocalizedTexts().home.legalUpdates.links;
    return (
        <View style={{ marginTop: 15 }}>
            {legalUpdatesToBeReviewed.type !== LegalUpdateType.privacyPolicy ? (
                <Link onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.termOfService }))}>
                    {texts.termsOfServiceLink}
                </Link>
            ) : null}
            {legalUpdatesToBeReviewed.type !== LegalUpdateType.termsOfService ? (
                <Link onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy }))}>
                    {texts.privacyPolicyLink}
                </Link>
            ) : null}
        </View>
    );
}

function Link({ children, onPress }: { children: string; onPress: () => void }) {
    return (
        <HoverableTouchableOpacity onPress={onPress}>
            <Text style={styles.textLink}>{children}</Text>
        </HoverableTouchableOpacity>
    );
}

function Buttons({
    legalUpdatesToBeReviewed,
    showRefusalSection,
}: {
    legalUpdatesToBeReviewed: LegalUpdatesToBeReviewed;
    showRefusalSection: () => void;
}) {
    const texts = getLocalizedTexts().home.legalUpdates;
    const history = useHistory();
    const [submitLegalUpdatesConsentChoice, isLoading] = useSubmitLegalUpdatesConsentChoice();
    const legalUpdateIds = React.useMemo(
        () => legalUpdatesToBeReviewed.legalUpdates.map(({ legalUpdateId }) => legalUpdateId),
        [legalUpdatesToBeReviewed]
    );
    const onAccept = async () => {
        if (!isLoading) {
            await submitLegalUpdatesConsentChoice({ legalUpdateIds, consentChoice: true });
            history.push('/home');
        }
    };
    return (
        <View style={{ marginBottom: 10, marginTop: 15 }}>
            {legalUpdatesToBeReviewed.requiresConsent ? (
                <Button
                    onPress={showRefusalSection}
                    style={{ marginBottom: 10 }}
                    useSecondaryColor
                    {...{ isLoading }}
                    height={45}>
                    {texts.refuseButton}
                </Button>
            ) : null}
            <Button onPress={onAccept} {...{ isLoading }} height={45}>
                {legalUpdatesToBeReviewed.requiresConsent
                    ? texts.acceptButton.withConsent
                    : texts.acceptButton.withoutConsent}
            </Button>
        </View>
    );
}

function RefusalSection({
    hideRefusalSection,
    legalUpdatesToBeReviewed,
}: {
    hideRefusalSection: () => void;
    legalUpdatesToBeReviewed: LegalUpdatesToBeReviewed;
}) {
    const texts = getLocalizedTexts().home.legalUpdates;
    const [submitLegalUpdatesConsentChoice, isLoading] = useSubmitLegalUpdatesConsentChoice();
    const onPressRefuse = async () => {
        if (!isLoading) {
            if (window.confirm(texts.refusalConfirmationModalText)) {
                await submitLegalUpdatesConsentChoice({
                    legalUpdateIds: legalUpdatesToBeReviewed.legalUpdates.map(({ legalUpdateId }) => legalUpdateId),
                    consentChoice: false,
                });
            }
            hideRefusalSection();
        }
    };
    return (
        <View style={[styles.containerContent, { height: 480, width: 450 }]}>
            <View style={styles.containerRefusalSection}>
                <Image style={styles.imageRefusalSection} source={warningSignEmoji} />
                <Text style={styles.textRefusalSectionTitle}>{texts.refusalSection.title}</Text>
                <Text style={[styles.textRefusalSection]}>
                    {texts.refusalSection.text1({ legalUpdateType: legalUpdatesToBeReviewed.type })}
                </Text>
                <HighlightedText style={styles.textRefusalSection}>{texts.refusalSection.text2}</HighlightedText>
                <Button
                    height={45}
                    onPress={onPressRefuse}
                    textStyle={{ color: color.radicalRed }}
                    style={styles.containerRefusalSectionConfirmButton}
                    activityIndicatorColor={color.radicalRed}
                    {...{ isLoading }}>
                    {texts.refusalSection.confirmButton}
                </Button>
                <Button height={45} onPress={() => !isLoading && hideRefusalSection()} {...{ isLoading }}>
                    {texts.refusalSection.cancelButton}
                </Button>
            </View>
        </View>
    );
}

export default LegalUpdatesPage;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color.white,
    },
    containerContent: {
        position: 'absolute',
        marginTop: 100,
        height: 550,
        width: 500,
        backgroundColor: color.white,
        borderRadius: 10,
        boxShadow: `${color.mercury} 0px 3px 15px`,
        padding: 20,
    },
    containerSummaryOfChanges: {
        flex: 1,
        backgroundColor: color.wildSand,
        padding: 14,
        borderRadius: 10,
        marginVertical: 5,
    },
    containerRefusalSection: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: color.white,
        paddingHorizontal: 15,
        paddingBottom: 20,
        borderRadius: 10,
    },
    containerRefusalSectionConfirmButton: {
        borderColor: color.radicalRed,
        backgroundColor: color.white,
        marginBottom: 10,
        marginTop: 10,
    },
    textTitle: {
        fontSize: 24,
        fontFamily: font.ambitBlack,
        color: color.tundora,
        marginBottom: 15,
    },
    textLink: {
        fontSize: 16,
        fontFamily: font.ambitSemiBold,
        color: color.caribbeanGreen,
        marginBottom: 10,
        textDecorationLine: 'underline',
    },
    textSummaryOfChanges: {
        fontSize: 16,
        fontFamily: font.ambitSemiBold,
        color: color.tundora,
    },
    textRefusalSectionTitle: {
        fontSize: 18,
        fontFamily: font.ambitBlack,
        color: color.tundora,
        marginBottom: 20,
        textAlign: 'center',
    },
    textRefusalSection: {
        fontSize: 15,
        fontFamily: font.ambitSemiBold,
        color: color.tundora,
        marginBottom: 10,
        textAlign: 'center',
        paddingHorizontal: 10,
        marginTop: 10,
    },
    imageRefusalSection: {
        width: 45,
        height: 45,
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 15,
    },
});
