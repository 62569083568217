import * as React from 'react';
import { StyleSheet, View, Image, Dimensions } from 'react-native';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };

export default function JokoLogoHeader() {
    return (
        <View style={styles.container}>
            <Image source={logo} style={styles.imageJokoLogo} />
        </View>
    );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
    container: {
        width,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
    },
    imageJokoLogo: {
        width: 70,
        marginVertical: 12,
        height: 30,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
});
