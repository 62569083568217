import * as React from 'react';
import { StyleSheet, Text, View, ImageSourcePropType, Image } from 'react-native';
import { useHistory } from 'react-router';

import { getLocalizedTexts } from '../../../Locales';
import { font } from '../../../style/text';
import color from '../../../style/color';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { useSignOut } from '../../../lib/common/user';
import { MarketingWebsitePath, getMarketingWebsiteUrl } from '../../../lib/auth/region';
import { HoverableTouchableOpacity } from '../../../components/common/HoverableComponents';
import { getHelpCenterUrl } from '../../../lib/auth/region';

const profileIcon = { uri: '/assets/images/icons/profile-blue.svg' };
const bookIcon = { uri: '/assets/images/icons/book-green.svg' };
const lockIcon = { uri: '/assets/images/icons/lock-yellow.svg' };
const exitIcon = { uri: '/assets/images/icons/exit-blue.svg' };
const shareIcon = { uri: '/assets/images/icons/share-gray.svg' };
const helpIcon = { uri: '/assets/images/icons/help-black.svg' };

function ProfilePanel() {
    const texts = getLocalizedTexts().home.profile.menu;
    const history = useHistory();
    const [signOut] = useSignOut();
    return (
        <View style={styles.container}>
            <View style={styles.containerMenu}>
                <Text style={styles.textSectionTitle}>{texts.settingsSection.title.toUpperCase()}</Text>
                <MenuItem
                    title={texts.settingsSection.userInfo}
                    image={profileIcon}
                    onPress={() => history.push('/home/profile/user-info')}
                    isSelected
                />
                <MenuItem
                    title={texts.settingsSection.termsOfService}
                    image={bookIcon}
                    onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.termOfService }))}
                    showShareIcon
                />
                <MenuItem
                    title={texts.settingsSection.privacyPolicy}
                    image={lockIcon}
                    onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy }))}
                    showShareIcon
                />
                <MenuItem
                    title={texts.settingsSection.signOut}
                    image={exitIcon}
                    onPress={() => {
                        signOut();
                        logAmplitudeEvent({ name: 'Settings - Clicked Logout' });
                    }}
                />
            </View>
            <View style={styles.containerMenu}>
                <Text style={styles.textSectionTitle}>{texts.supportSection.title.toUpperCase()}</Text>
                <MenuItem
                    title={texts.supportSection.faq}
                    image={helpIcon}
                    onPress={() => {
                        window.open(getHelpCenterUrl({ path: '' }));
                        logAmplitudeEvent({ name: 'Profile - Clicked FAQ' });
                    }}
                    showShareIcon
                />
            </View>
        </View>
    );
}

export default ProfilePanel;

function MenuItem({
    title,
    image,
    onPress,
    isSelected,
    showShareIcon,
}: {
    title: string;
    image: ImageSourcePropType;
    onPress: () => void;
    isSelected?: boolean;
    showShareIcon?: boolean;
}) {
    return (
        <HoverableTouchableOpacity
            style={[styles.containerMenuItem, isSelected && styles.containerMenuItemSelected]}
            hoveredStyle={styles.containerMenuItemHovered}
            onPress={onPress}>
            <Image source={image} style={styles.imageMenuItemIcon} />
            <Text style={styles.textMenuItem}>{title}</Text>
            {showShareIcon ? <Image source={shareIcon} style={styles.imageShareIcon} /> : null}
        </HoverableTouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: color.athensGray,
        paddingTop: 16,
        paddingHorizontal: 71,
        alignItems: 'flex-end',
    },
    containerMenu: {
        marginTop: 32,
        width: 345,
    },
    containerMenuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 13,
        paddingHorizontal: 19,
        borderRadius: 8,
    },
    containerMenuItemHovered: {
        backgroundColor: color.concrete,
    },
    containerMenuItemSelected: {
        backgroundColor: color.athensGrayDark,
    },
    textSectionTitle: {
        fontSize: 12,
        fontFamily: font.ambitBlack,
        color: color.frenchGray,
        marginHorizontal: 19,
        marginBottom: 16,
    },
    textMenuItem: {
        flex: 1,
        marginLeft: 20,
        fontSize: 14,
        fontFamily: font.ambitBlack,
        color: color.black,
    },
    imageMenuItemIcon: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
    },
    imageShareIcon: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
    },
});
