import { userFragment, User } from '../fragments/user';

const gql = require('graphql-tag');

export const userQuery = gql`
    query GetUser {
        user {
            ...User
        }
    }
    ${userFragment}
`;

export type UserQueryResponse = {
    __typename: 'Query';
    user: User;
};

export const checkDuplicatePhoneNumberQuery = gql`
    query CheckDuplicatePhoneNumber($phoneNumber: String!) {
        checkDuplicatePhoneNumber(phoneNumber: $phoneNumber)
    }
`;

export interface CheckDuplicatePhoneNumberQueryResponse {
    __typename: 'Query';
    checkDuplicatePhoneNumber: boolean;
}

export interface CheckDuplicatePhoneNumberQueryVariables {
    phoneNumber: string;
}
