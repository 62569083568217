const DETECT_AD_BLOCKER_SCRIPT_URL = 'https://joko-ad-blocker-detection.s3-eu-west-1.amazonaws.com/abtest_ab.js';

const AD_BLOCKER_SCRIPT_ELEMENT_ID = 'tYkNHezSDKGT'; // The distant script creates a div with such id

export function checkIsAdBlockerActivated(): Promise<boolean> {
    return new Promise((resolve) => {
        var script = document.createElement('script');
        script.src = DETECT_AD_BLOCKER_SCRIPT_URL; // Due to its filename, this script will be imported only if there is no ad blocker activated
        script.onload = () => {
            const isAdBlockerActivated = !document.getElementById(AD_BLOCKER_SCRIPT_ELEMENT_ID);
            resolve(isAdBlockerActivated);
        };
        script.onerror = () => {
            const isAdBlockerActivated = true;
            resolve(isAdBlockerActivated);
        };
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
    });
}
