import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import LoadingFrame from '../../components/auth/LoadingFrame';

/**
 * This page is opened by the browser extension when it is installed
 * and is closed as soon as the extension retrieves the web app login data using its content script
 */
function BrowserExtensionAutoLoginPage() {
    const texts = getLocalizedTexts().auth.browserExtensionAutoLogin;
    const size = useWindowSize();
    return (
        <View style={styles.container}>
            <LoadingFrame
                text={texts.loading}
                height={(size.height || 0) - 120}
                width={(size.width || 0) - 240}
                isError={false}
            />
        </View>
    );
}

export default BrowserExtensionAutoLoginPage;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
});
