import { dataIdFromEnrichedUserOffer } from '../fragments/enrichedOffers';
import { OfferType } from '../fragments/offers';
import { ApolloClient } from '../client';

const gql = require('graphql-tag');
const moment = require('moment');

const activateUserOfferMutation = gql`
    mutation ActivateUserOffer($offerId: String!, $offerType: String!) {
        activateUserOffer(offerId: $offerId, offerType: $offerType) {
            offerId
            active
            activatedAt
        }
    }
`;

interface ActivateUserOfferMutationResponse {
    __typename: 'Mutation';
    activateUserOffer: {
        __typename: 'UserOffer';
        offerId: string;
        active: boolean;
        activatedAt: string | undefined;
    } | null;
}

interface ActivateUserOfferMutationVariables {
    offerId: string;
    offerType: OfferType;
}

export function activateUserOffer(client: ApolloClient, offerId: string, offerType: OfferType) {
    client.writeFragment<{
        __typename: 'EnrichedUserOffer';
        offerId: string;
        active: boolean;
        activatedAt: string | undefined;
    }>({
        id: dataIdFromEnrichedUserOffer({ offerId }),
        fragment: gql`
            fragment ActivatedEnrichedUserOffer on EnrichedUserOffer {
                offerId
                active
                activatedAt
            }
        `,
        data: {
            __typename: 'EnrichedUserOffer',
            offerId: offerId,
            active: true,
            activatedAt: moment().format('YYYY-MM-DD'),
        },
    });
    client.mutate<ActivateUserOfferMutationResponse, ActivateUserOfferMutationVariables>({
        mutation: activateUserOfferMutation,
        fetchPolicy: 'no-cache',
        variables: { offerId, offerType },
    });
}
