import * as React from 'react';

/**
 * FloaIframePage is used from mobile for the Floa iframe payment collection
 * Mobile will inject JS code required to render the iframe (including Floa's 3rd party JS library)
 * */
function FloaIframePage() {
    return (
        <>
            <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
            <div id="iframeDiv"></div>
        </>
    );
}

export default FloaIframePage;
