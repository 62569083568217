const gql = require('graphql-tag');

export const userTrackingConsentFragment = gql`
    fragment UserTrackingConsent on UserTrackingConsent {
        trackingId
        consentChoice
        expiresAt
    }
`;

export enum TrackingId {
    amplitudeAnalyticsWebApp = 'amplitude-analytics-web-app',
    amplitudeMarketingAttributionWebApp = 'amplitude-marketing-attribution-web-app',
    googleTagManagerMarketingAttributionWebApp = 'google-tag-manager-marketing-attribution-web-app',
}

export interface UserTrackingConsent {
    __typename: 'UserTrackingConsent';
    trackingId: TrackingId;
    consentChoice: boolean;
    expiresAt: number;
}

export const dataIdFromUserTrackingConsent = (trackingConsent: UserTrackingConsent) => {
    return `UserTrackingConsent:${trackingConsent.trackingId}`;
};
