import * as React from 'react';
import { useHistory } from 'react-router';
import { StyleSheet, View, Text } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { Gender } from '../../api/graphql/fragments/user';
import { updateUser } from '../../api/graphql/mutations/updateUser';
import { CognitoUserAttributeName } from '../../lib/common/cognito';
import { isFirstNameValid, isAgeValid } from '../../lib/onboarding/userInfo';
import HighlightedText from '../../components/common/HighlightedText';
import Button from '../../components/common/Button';
import OnboardingProgressIndicator, { OnboardingStep } from '../../components/onboarding/OnboardingProgressIndicator';
import { FirstNameInput, AgeInput } from '../../components/onboarding/UserInfoInputs';
import GenderButtons from '../../components/onboarding/GenderButtons';

export default function OnboardingUserInfoPage() {
    const texts = getLocalizedTexts().onboarding.userInfo;
    const [firstName, setFirstName] = React.useState<string>('');
    const [age, setAge] = React.useState<string>('');
    const [gender, setGender] = React.useState<Gender | undefined>();
    const [updateUserInfo, isLoading] = useUpdateUserInfo();
    const onPress = () => {
        if (isFirstNameValid(firstName) && isAgeValid(age)) updateUserInfo(firstName, age, gender);
    };
    return (
        <View style={styles.container}>
            <View style={styles.containerContent}>
                <OnboardingProgressIndicator step={OnboardingStep.userInfoStep} />
                <Text style={styles.textTitle}>{texts.firstNameTitle}</Text>
                <FirstNameInput
                    {...{
                        firstName,
                        firstNamePlaceholder: texts.firstNamePlaceholder,
                        setFirstName,
                        isLoading,
                    }}
                />
                <Text style={styles.textTitle}>{texts.ageTitle}</Text>
                <AgeInput {...{ age, agePlaceholder: texts.ageTitle, setAge, isLoading }} />
                <Text style={styles.textTitle}>{texts.genderTitle}</Text>
                <HighlightedText style={styles.textSubtitle} highlightedStyle={styles.textSubtitleHighlighted}>
                    {texts.genderSubtitle}
                </HighlightedText>
                <GenderButtons {...{ gender, setGender, isLoading }} style={{ marginTop: 13 }} />
                <View style={styles.containerNextButton}>
                    <Button
                        {...{ onPress, isLoading }}
                        textStyle={styles.textNextButton}
                        height={40}
                        disabled={!isFirstNameValid(firstName) || !isAgeValid(age)}>
                        {texts.nextButton}
                    </Button>
                </View>
            </View>
        </View>
    );
}

function useUpdateUserInfo(): [(firstName: string, age: string, gender: Gender | undefined) => Promise<void>, boolean] {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const apolloClient = useApolloClient();
    const updateUserInfo = async (firstName: string, age: string, gender: Gender | undefined = Gender.Other) => {
        if (!isLoading) {
            setIsLoading(true);
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(cognitoUser, {
                [CognitoUserAttributeName.givenName]: firstName,
                [CognitoUserAttributeName.age]: age,
                [CognitoUserAttributeName.gender]: gender,
            });
            await updateUser(apolloClient, {
                firstName,
                age: Number(age),
                gender,
            });
            history.push('/onboarding/referrer-code');
            setIsLoading(false);
        }
    };
    return [updateUserInfo, isLoading];
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerContent: {
        marginTop: 68,
        width: 424,
    },
    containerNextButton: {
        marginTop: 40,
    },
    textTitle: {
        marginTop: 40,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginBottom: 16,
    },
    textSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.silverChalice,
    },
    textSubtitleHighlighted: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.silverChalice,
    },
    textNextButton: {
        fontSize: 14,
    },
});
