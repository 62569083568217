import * as React from 'react';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/react-hooks';

import { OfferBrowsingTag } from '../../api/graphql/fragments/offerBrowsingTags';
import {
    offerBrowsingTagQuery,
    OfferBrowsingTagQueryResponse,
    OfferBrowsingTagQueryVariables,
} from '../../api/graphql/queries/offerBrowsingTags';

export function useOfferBrowsingTag(
    tagId: string | undefined,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
    devMode: boolean = false
): OfferBrowsingTag | undefined {
    const queryResponse = useQuery<OfferBrowsingTagQueryResponse, OfferBrowsingTagQueryVariables>(offerBrowsingTagQuery, {
        variables: { devMode },
        fetchPolicy,
    });
    const offerBrowsingTag = React.useMemo(
        () => queryResponse.data?.offerBrowsingTags?.items?.find((tag) => tag.tagId === tagId) || undefined,
        [queryResponse, tagId]
    );
    return offerBrowsingTag;
}

export function trimOfferBrowsingTagName(name: string): string {
    return name
        .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            ''
        )
        .replace(/^(\u200D|\u0020|\ufe0f)+|(\u200D|\u0020|\ufe0f)+$/g, '')
        .trim();
}
