import * as React from 'react';
import { Text, Image, StyleSheet, View, TextStyle } from 'react-native';
import { useApolloClient } from '@apollo/client';

import color from '../../../style/color';
import { HOME_SCREEN_WIDTH } from '../../../style/size';
import { font } from '../../../style/text';
import { HoverableTouchableOpacity } from '../../common/HoverableComponents';
import { browserExtensionStoreUrl } from '../../../lib/common/browser';
import { GoogleTagManagerEventName, logGoogleTagManagerEvent } from '../../../lib/common/tags';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';

const currencySymbolGreen = { uri: '/assets/images/visualIdentity/currency-symbol-green.svg' };

export function BrowserExtensionBanner({
    title,
    subtitle,
    button,
    imageURL,
}: {
    title: string;
    subtitle: string;
    button: string;
    imageURL: string;
}) {
    const apolloClient = useApolloClient();
    const onPress = () => {
        logGoogleTagManagerEvent({
            event: GoogleTagManagerEventName.clickedDownloadBrowserExtension,
            fromComponent: 'browserExtensionBanner',
        });
        logUserEventUtil(apolloClient, {
            type: 'clickedDownloadBrowserExtension',
            payload: {
                fromComponent: 'browserExtensionBanner',
            },
        });
        logAmplitudeEvent({
            name: 'Clicked Download Browser Extension',
            properties: {
                fromComponent: 'browserExtensionBanner',
            },
        });
        window.open(browserExtensionStoreUrl);
    };
    return (
        <HoverableTouchableOpacity style={styles.container} hoveredStyle={{ opacity: 0.9 }} {...{ onPress }}>
            <Image source={{ uri: imageURL }} style={styles.image} />
            <View style={styles.containerTexts}>
                <Text style={styles.textTitle}>{title}</Text>
                <TextWithPoints style={styles.textSubtitle}>{subtitle}</TextWithPoints>
            </View>
            <HoverableTouchableOpacity style={styles.containerButton} hoveredStyle={{ opacity: 0.8 }} {...{ onPress }}>
                <Text style={styles.textButton}>{button}</Text>
            </HoverableTouchableOpacity>
        </HoverableTouchableOpacity>
    );
}

function TextWithPoints({ style, children }: { style: TextStyle; children: string | undefined }) {
    const regexpSearch: RegExpExecArray | null = React.useMemo(
        () => /(^.*)(\s\d+)( PTS)(.*$)/.exec(children || ''),
        [children]
    );
    if (!regexpSearch || regexpSearch.length < 5) return <Text style={style}>{children}</Text>;
    else
        return (
            <Text style={style}>
                <Text>{regexpSearch[1]} </Text>
                <Image source={currencySymbolGreen} style={{ top: 3, width: 15, height: 15 }} />
                <Text style={styles.textPoints}>{regexpSearch[2]}</Text>
                <Text>{regexpSearch[4]}</Text>
            </Text>
        );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        width: HOME_SCREEN_WIDTH,
        height: 88,
        backgroundColor: color.hintOfGreen,
        borderRadius: 16,
    },
    containerTexts: {
        marginLeft: 16,
        flex: 1,
    },
    containerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 32,
        paddingVertical: 14,
        paddingHorizontal: 24,
        backgroundColor: color.emerald,
        borderRadius: 52,
    },
    image: {
        marginLeft: 24,
        width: 88,
        height: 88,
        resizeMode: 'contain',
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.black,
    },
    textSubtitle: {
        marginTop: 5,
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
    },
    textPoints: {
        color: color.emerald,
        fontFamily: font.ambitBlack,
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.white,
    },
});
