import * as React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';

import { getLocalizedTexts } from '../../../Locales';
import { MerchantOffer } from '../../../api/graphql/fragments/offers';
import { EnrichedMerchantOffer } from '../../../api/graphql/fragments/enrichedOffers';
import color from '../../../style/color';
import { font } from '../../../style/text';

const clockIcon = { uri: '/assets/images/icons/clock-purple.svg' };

const moment = require('moment');

export default function ExpirationDate({ offer }: { offer: EnrichedMerchantOffer | MerchantOffer }) {
    const texts = getLocalizedTexts().home.offers.offerComponent;
    const dateMax =
        'enrichedConditions' in offer
            ? offer.enrichedConditions?.transactionEligibility?.dateMax
            : offer.conditions?.transactionEligibility?.dateMax;
    const remainingTime: { quantity: number; unit: 'hours' | 'days' } | undefined = React.useMemo(() => {
        if (!dateMax) return undefined;
        const remainingHours = moment(dateMax, 'YYYY-MM-DD').endOf('day').diff(moment(), 'hours');
        if (remainingHours < 24) return { quantity: remainingHours, unit: 'hours' };
        const remainingDays = moment(dateMax, 'YYYY-MM-DD').endOf('day').diff(moment(), 'days');
        return { quantity: remainingDays, unit: 'days' };
    }, [dateMax]);
    if (remainingTime)
        return (
            <View style={styles.containerExpirationDate}>
                <Image source={clockIcon} style={{ height: 12, width: 12 }} />
                <Text style={styles.textExpirationDate}>{texts.expirationDate(remainingTime)}</Text>
            </View>
        );
    return null;
}

const styles = StyleSheet.create({
    containerExpirationDate: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 3,
        height: 16,
        borderRadius: 30,
        backgroundColor: color.magnolia,
    },
    textExpirationDate: {
        marginLeft: 3,
        fontFamily: font.ambitBlack,
        fontSize: 12,
        color: color.electricViolet,
    },
});
