import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';

import { useHomeRedirection } from '../../lib/onboarding/navigation';
import IntroPanel from '../../pages/auth/IntroPanel';
import OnboardingStartPage from '../../pages/onboarding/OnboardingStartPage';
import OnboardingUserInfoPage from '../../pages/onboarding/OnboardingUserInfoPage';
import OnboardingReferrerCodePage from '../../pages/onboarding/OnboardingReferrerCodePage';
import OnboardingEndPage from '../../pages/onboarding/OnboardingEndPage';

function OnboardingNavigator() {
    let { path } = useRouteMatch();
    useHomeRedirection();
    return (
        <Switch>
            <Route exact path={path}>
                <OnboardingStartPage />
            </Route>
            <Route exact path={`${path}/user-info`}>
                <View style={styles.container}>
                    <View style={styles.containerColumn}>
                        <IntroPanel />
                    </View>
                    <View style={styles.containerColumn}>
                        <OnboardingUserInfoPage />
                    </View>
                </View>
            </Route>
            <Route exact path={`${path}/referrer-code`}>
                <View style={styles.container}>
                    <View style={styles.containerColumn}>
                        <IntroPanel />
                    </View>
                    <View style={styles.containerColumn}>
                        <OnboardingReferrerCodePage />
                    </View>
                </View>
            </Route>
            <Route exact path={`${path}/end`}>
                <OnboardingEndPage />
            </Route>
            <Route path="*">
                <Redirect to={{ pathname: path, search: window.location.search }} />
            </Route>
        </Switch>
    );
}

export default OnboardingNavigator;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    containerColumn: {
        flex: 1,
    },
});
