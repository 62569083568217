const gql = require('graphql-tag');

export const userInAppContentFragment = gql`
    fragment UserInAppContent on UserInAppContent {
        inAppContentId
        title
        layout {
            layoutType
            layoutParameters
        }
        itemList {
            itemType
            itemParameters
        }
    }
`;

export type UserInAppContent = {
    __typename: 'UserInAppContent';
    inAppContentId: string;
    title: string | null;
    layout: UserInAppContentLayout | null;
    itemList: UserInAppContentItem[] | null;
};

export type UserInAppContentLayout = {
    __typename: 'UserInAppContentLayout';
    layoutType: string | null;
    layoutParameters: string | null;
};

export type UserInAppContentItem = {
    __typename: 'UserInAppContentItem';
    itemType: string | null;
    itemParameters: string | null;
};

export const dataIdFromUserInAppContent = (userInAppContent: UserInAppContent | { inAppContentId: string }) => {
    return `UserInAppContent:${userInAppContent.inAppContentId}`;
};
