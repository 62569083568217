import * as React from 'react';
import { View, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../Locales';
import { HistoryItem } from './HistoryPage';
import color from '../../style/color';
import { font } from '../../style/text';
import { HistoryRecord } from '../../api/graphql/fragments/history';
import { collectPointsAndLogEvents, useUncollectedHistoryAndPoints } from '../../lib/points/pointCollection';
import { ProgressiveFlatList } from '../../components/common/ProgressiveList';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';

function PointCollectionPage() {
    const texts = getLocalizedTexts().home.pointCollection;
    const history = useHistory();
    const apolloClient = useApolloClient();
    const uncollectedHistoryAndPoints = useUncollectedHistoryAndPoints('cache-first');
    React.useEffect(() => {
        if (uncollectedHistoryAndPoints !== undefined && uncollectedHistoryAndPoints.history.length === 0)
            history.push('/home');
    }, [uncollectedHistoryAndPoints, history]);
    const onPress = () => {
        if (uncollectedHistoryAndPoints)
            collectPointsAndLogEvents({
                apolloClient,
                uncollectedHistoryRecords: uncollectedHistoryAndPoints.history,
                firstPointsCollectedAt: uncollectedHistoryAndPoints.firstPointsCollectedAt,
                firstOfferPointsCollectedAt: uncollectedHistoryAndPoints.firstOfferPointsCollectedAt,
            });
        history.push('/home');
    };
    return (
        <View style={styles.container}>
            <Text style={styles.textTitle}>{texts.title}</Text>
            {uncollectedHistoryAndPoints ? (
                <View
                    style={[
                        styles.containerHistoryItems,
                        { height: Math.min(75 * uncollectedHistoryAndPoints.history.length + 100, 500) },
                    ]}>
                    <ProgressiveFlatList<HistoryRecord>
                        data={uncollectedHistoryAndPoints.history}
                        keyExtractor={(item) => item.recordId}
                        renderItem={({ item }) => <HistoryItem record={item} style={styles.containerHistoryItem} />}
                        initialNumberOfItemsToDisplay={10}
                    />
                </View>
            ) : (
                <View style={styles.containerLoadingIndicator}>
                    <ActivityIndicator color={color.grayLight} size={'large'} />
                </View>
            )}
            <HoverableTouchableOpacity
                style={styles.containerButton}
                hoveredStyle={styles.containerButtonHovered}
                onPress={onPress}>
                <Text style={styles.textButton}>{texts.button}</Text>
            </HoverableTouchableOpacity>
        </View>
    );
}

export default PointCollectionPage;

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 0,
        right: 50,
        width: 420,
        minHeight: 275,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: 'white',
        overflow: 'hidden',
        alignItems: 'center',
    },
    containerHistoryItems: {
        flexDirection: 'row',
        marginTop: 40,
    },
    containerHistoryItem: {
        width: 370,
    },
    containerButton: {
        position: 'absolute',
        bottom: 40,
        height: 56,
        width: 370,
        borderRadius: 60,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.black,
    },
    containerButtonHovered: {
        opacity: 0.7,
    },
    containerLoadingIndicator: {
        marginTop: 40,
    },
    textTitle: {
        marginTop: 30,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
    },
    textButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
        textAlign: 'center',
    },
});
