import { ApolloClient } from '../../api/graphql/client';
import { OfferType } from '../../api/graphql/fragments/offers';
import { RewardType } from '../../api/graphql/fragments/rewards';
import { logUserEvent, LogUserEventInput } from '../../api/graphql/mutations/logUserEvent';
import { sessionIdVar } from '../../api/graphql/reactiveVariables';
import { Browser } from '../common/browser';
import { checkIsSignedIn } from '../common/cognito';
import { getDeviceId } from '../common/device';
import { updateLastEventTimestampMs } from '../common/sessions';
import { InstallationStep } from '../mobileSafariExtension/mobileSafariExtension';
import { AffiliateLinkLoadingMethod } from '../offers/affiliateLinkLoading';

const SAVED_USER_EVENTS_LOCAL_STORAGE_KEY = '@EventStore:savedUserEvents';

interface BaseUserEvent<T = string> {
    type: T;
    active?: boolean;
    payload?: undefined;
}

interface BaseUserEventWithProperties<T = string, S = { [key: string]: any }> {
    type: T;
    active?: boolean;
    payload?: S;
}

type BrowserExtensionPlatform = 'chromeExtension' | 'firefoxExtension' | 'safariExtension';
type Platform = 'mobileApp' | BrowserExtensionPlatform | 'webApp';

type NavigationUserEvent =
    // Logged when the app is launched
    | BaseUserEvent<'launchedApp'>
    // Logged when a new session starts
    // Note that we consider that a new session starts when the page is initially loaded or when the tab have become visible
    // if no event was logged in the last 5 minutes
    | BaseUserEventWithProperties<'sessionStarted', { sessionId: string }>
    // Logged when we capture new attribution parameters for the user
    // Note that we run attribution check every time a new session starts
    // About UTM parameters: https://en.wikipedia.org/wiki/UTM_parameters
    // About the Google Click Id (GCLID): https://support.google.com/google-ads/answer/9744275?hl=en
    | BaseUserEventWithProperties<
          'attributionCaptured',
          {
              utmSource?: string;
              utmMedium?: string;
              utmCampaign?: string;
              utmTerm?: string;
              utmContent?: string;
              googleClickId?: string;
              facebookClickId?: string;
              referrer?: string;
              referringDomain?: string;
          }
      >;

type RegistrationUserEvent =
    // Logged when the user validates his/her information (first name, age, gender, referral code)
    | BaseUserEvent<'validatedUserInfo'>
    // Logged when the onboarding process is finished
    | BaseUserEvent<'finishedOnboarding'>;

type OffersUserEvent =
    // Logged when the user clicks on an offer component (everywhere in the app)
    | BaseUserEventWithProperties<
          'clickedOffer',
          {
              type: OfferType;
              offerId: string;
              from: Platform;
          }
      >
    // This event is logged at each affiliate link loading attempt
    // An affiliate link loading attempt can be triggered by different events (indicated in the `triggeringEventType` field)
    // Note that some loading attempts made in the universal offer activation page of the web app might come from other platforms (indicated in the `triggeringEventPlatform` field)
    // For instance, if the user comes from the mobile app, we will have `triggeringEventPlatform = "mobileApp"`
    // Each loading attempt uses a different affiliate link with a different "Sub ID", which is a custom ID passed to the affiliate platform to track each attempt
    // This Sub ID contains a Click ID that is generated before each attempt and that is logged in the payload of the present event
    // Note that this event is used to retrieve the User ID corresponding to a given Click ID when an affiliate transaction is retrieved
    | BaseUserEventWithProperties<
          'clickedAffiliateLink',
          {
              offerId: string;
              merchantId: string;
              clickId: string; // This field is instrumental since it will be used to match affiliate transactions to users
              affiliateLinkUrl: string;
              triggeringEventId: string;
              triggeringEventType?: string;
              triggeringEventPlatform?: string;
              triggeringEventTimestamp?: number;
              affiliateLinkLoadingMethod: AffiliateLinkLoadingMethod;
              affiliateLinkLoadingTimestamp: number;
              attemptNumber: number;
              hasRedirectUrl: boolean;
              parametersRemovedFromRedirectUrl: string[];
              /** @deprecated replaced by triggeringEventPlatform */
              from?: string;
          }
      >
    // Logged when an attempt to append a redirection URL to the affiliate link fails
    // Note: `affiliateLink` is the original affiliate link without a redirection URL
    | BaseUserEventWithProperties<'addRedirectUrlToAffiliateLinkFailed', { offerId: string }>
    // Logged when users view the "offer details" page
    // Note: this event is logged every time users view the offer details page, no matter where they clicked to open it
    | BaseUserEventWithProperties<
          'viewedOfferDetails',
          {
              offerType: OfferType;
              offerId: string;
              from: Platform;
          }
      >;

type RewardsUserEvent =
    // Logged when the user clicks on a reward on the Rewards page
    | BaseUserEventWithProperties<
          'clickedReward',
          {
              type: RewardType;
              rewardId: string;
          }
      >
    // Logged when the users confirm their bank details (when requesting a bank transfer or in the "user info" menu)
    // The "save" property in the payload is "true" if users want to save their bank details for the next transfer
    | BaseUserEventWithProperties<
          'confirmedBankDetails',
          {
              save: boolean;
          }
      >
    // Logged when users add an offer to their favorites by clicking on the favorite button (either on the card or from the offer details page)
    | BaseUserEventWithProperties<'favoritedOffer', { type: OfferType; offerId: string }>
    // Logged when users remove an offer from their favorites by clicking on the favorite button (either on the card or from the offer details page)
    | BaseUserEventWithProperties<'unfavoritedOffer', { type: OfferType; offerId: string }>
    // Logged when users confirm the activation of the auto donation of their cashback
    // Note: this event is logged from the web app on iOS (because the confirmation happens on an external browser due to Apple guidelines)
    // The 'autoDonationSettings' user state will be updated by this event
    | BaseUserEventWithProperties<'confirmedAutoDonationSettings', { rewardId?: string; rewardTitle?: string }>
    // Logged when users cancel the confirmation of the auto donation activation
    // Note: this event is logged from the web app on iOS (because the confirmation happens on an external browser due to Apple guidelines)
    | BaseUserEvent<'cancelledAutoDonationSettings'>;

// Logged when the user clicks on an option on the profile page
type ProfileUserEvent = BaseUserEventWithProperties<
    'updatedEmail',
    {
        oldEmail: string;
        newEmail: string;
    }
>;

type BrowserExtensionUserEvent =
    // Logged when the user clicks on the "download browser extension" CTA on the promotion page or on the home screen banner
    BaseUserEventWithProperties<
        'clickedDownloadBrowserExtension',
        {
            fromComponent: 'browserExtensionBanner';
        }
    >;

type LegalUpdateUserEvent =
    // Logged when the legal update page is displayed
    | BaseUserEventWithProperties<'legalUpdatesDisplayed', { legalUpdateIds: string[] }>
    // Logged when the user submits their legal update consent choice (being either true or false)
    | BaseUserEventWithProperties<'submittedLegalUpdatesConsentChoice', { legalUpdateIds: string[]; consentChoice: boolean }>
    // Logged when the user ticks the box to skip future cookie warnings before being redirected to a merchant's site
    | BaseUserEventWithProperties<'acknowledgedWarningForCookieTracking'>;

/**
 * In the following events, payload fields are included only if they are relevant.
 * For example, the extension is not able to detect when the flow is done during the onboarding, such that isOnboarding is only relevant for the activation step triggered by the mobile app.
 */
type MobileSafariExtensionInstallationUserEvent =
    // Logged when the user views the first page of the mobile Safari extension installation flow
    | BaseUserEventWithProperties<
          'viewedActivationPageMobileSafariExtensionInstallationFlow',
          { isOnboarding?: boolean; isBeforeOnboarding?: boolean }
      >
    // Logged when the user clicks on the start activation CTA of the mobile Safari extension installation flow, which shows indications that explain how to activate the extension
    | BaseUserEventWithProperties<
          'clickedStartActivationMobileSafariExtensionInstallationFlow',
          { isOnboarding?: boolean; isBeforeOnboarding?: boolean }
      >
    // Logged when the user clicks on the help activate CTA of the mobile Safari extension installation flow
    | BaseUserEventWithProperties<'clickedHelpActivationMobileSafariExtensionInstallationFlow'>
    // Logged when the user views the authorization page of the mobile Safari extension installation flow
    // This page is automatically opened when the user activates the extension: this consists in going to the "manage extensions" Safari menu and activating the Joko extension
    | BaseUserEventWithProperties<
          'viewedAuthorizationPageMobileSafariExtension',
          { isBeforeOnboarding?: boolean; isFlowWithSystemSettings: boolean; isFlowWithBanner: boolean }
      >
    // Logged when the user clicks on the start authorization CTA of the mobile Safari extension installation flow, which shows indications that explain how to authorize the extension
    | BaseUserEventWithProperties<
          'clickedStartAuthorizationMobileSafariExtensionInstallationFlow',
          { isBeforeOnboarding?: boolean; isFlowWithSystemSettings?: boolean }
      >
    // Logged when special instructions are displayed behind the Safari authorization popups. The popup state can be either `alwaysAllowPopup` or `allowOnAllWebsitesPopup`.
    // The first popup asks if the user wants to allow the extension permanently, for 24 hours only, or not at all.
    // The second one asks the user if they want these permissions to apply to the current website only or to all websites.
    | BaseUserEventWithProperties<
          'mobileSafariExtensionAuthorizationPopupBackgroundInstructionsDisplayed',
          { popupState?: 'alwaysAllowPopup' | 'allowOnAllWebsitesPopup'; browserLanguage?: string }
      >
    // Logged when the special authorization popup instructions are removed, after the popups are closed, such that the user sees the usual authorization instructions again.
    | BaseUserEventWithProperties<'mobileSafariExtensionAuthorizationPopupBackgroundInstructionsHidden'>
    // Logged when the user clicks on the missing authorization banner CTA, which takes the user to the regular authorization instruction page
    | BaseUserEventWithProperties<'clickedMissingMobileSafariExtensionAuthorizationBannerButton'>
    // Logged when the user clicks on the CTA to open the system settings, in the authorization step of the mobile Safari extension installation flow.
    // Note that this first sends the user to the mobile app, from where the user is redirected to the system settings. This is done because we are unable to open the system settings directly from Safari.
    | BaseUserEvent<'clickedOpenSystemSettingsMobileSafariExtensionInstallationFlow'>
    // Logged when the user grants authorization to the extension and views the conclusion page of the mobile Safari extension installation flow
    | BaseUserEventWithProperties<
          'viewedConclusionPageMobileSafariExtension',
          { isOnAllWebsites?: boolean; isBeforeOnboarding?: boolean }
      >
    // Logged when the user clicks on the start test CTA of the mobile Safari extension installation flow
    | BaseUserEventWithProperties<'clickedStartTestMobileSafariExtensionInstallationFlow'>
    // Logged when the user views the missing "all websites" authorization page, where they were redirected after the extension detected the missing authorization
    | BaseUserEventWithProperties<'viewedMissingAllWebsitesAuthorizationPageMobileSafariExtension'>
    // Logged when the user clicks on the modify authorization CTA on a missing authorization page
    // The from field in the payload indicates whether it was done in the missing "all websites" authorization popup, or in the missing authorization page
    | BaseUserEventWithProperties<
          'clickedModifyAuthorizationMobileSafariExtensionInstallationFlow',
          { from: 'missingAllWebsitesAuthorizationPopup' | 'missingAuthorizationPage' }
      >
    // Logged when the user clicks on the cross to close the popup of the missing "all websites" authorization page
    | BaseUserEventWithProperties<'closedMissingAuthorizationPopupMobileSafariExtensionInstallationFlow'>
    // Logged when the user views the second missing "all websites" authorization page, after having tried and failed twice to give the correct authorization
    | BaseUserEventWithProperties<'viewedMissingAllWebsitesAuthorizationAfterRetryPageMobileSafariExtension'>
    // Logged when the user views the missing authorization page, opened by the extension in a new tab in order to encourage the user to give the correct authorization
    // Note that this page can be opened days/weeks after the initial activation of the extension
    | BaseUserEventWithProperties<'viewedMissingAuthorizationPageMobileSafariExtension'>
    // Logged when the user clicks on the return to app CTA
    | BaseUserEventWithProperties<'clickedReturnToAppFromMobileSafariExtension', { installationStep: InstallationStep }>
    // Logged when the user is not in Safari and views the wrong browser page
    | BaseUserEventWithProperties<
          'viewedWrongBrowserPageMobileSafariExtension',
          { browserKind?: Browser; isOnboarding?: boolean; isBeforeOnboarding?: boolean }
      >;

// Logged when the user is assigned a test group (when it cannot be done from the backend - typically for AB tests on the mobile Safari extension installation page)
type ABTestUserEvent = BaseUserEventWithProperties<'splitIntoABTestGroup', { testId: string; groupId: string }>;

export type UserEvent =
    | NavigationUserEvent
    | RegistrationUserEvent
    | OffersUserEvent
    | RewardsUserEvent
    | ProfileUserEvent
    | BrowserExtensionUserEvent
    | LegalUpdateUserEvent
    | MobileSafariExtensionInstallationUserEvent
    | ABTestUserEvent;

export async function logUserEventUtil(client: ApolloClient, event: UserEvent) {
    updateLastEventTimestampMs();
    const sessionId = sessionIdVar() || undefined;
    const deviceId = getDeviceId();
    const timestamp = Number((Date.now() / 1000).toFixed(3));
    const payload: { [key: string]: any } = {
        ...event.payload,
        platform: 'webApp',
        sessionId,
        deviceId,
    };
    const stringifiedPayload: string = JSON.stringify(payload);
    const logUserEventInput: LogUserEventInput = {
        ...event,
        payload: stringifiedPayload,
        active: event.active !== undefined ? event.active : true,
        timestamp,
    };
    if (checkIsSignedIn()) {
        console.log(`User Event: ${event.type} (payload = ${stringifiedPayload})`);
        await logUserEvent(client, logUserEventInput);
        await logSavedUserEvents(client);
    } else {
        console.log(`User Event: ${event.type} failed (not logged in)`);
        pushToSavedUserEvents(logUserEventInput);
    }
}

function pushToSavedUserEvents(logUserEventInput: LogUserEventInput): void {
    const savedUserEvents: LogUserEventInput[] = getSavedUserEvents();
    savedUserEvents.push(logUserEventInput);
    setSavedUserEvents(savedUserEvents);
    console.log(`User Event: event saved for later logging`);
}

async function logSavedUserEvents(client: ApolloClient): Promise<void> {
    const savedUserEvents: LogUserEventInput[] = getSavedUserEvents();
    if (savedUserEvents.length > 0) {
        setSavedUserEvents([]);
        for (const logUserEventInput of savedUserEvents) await logUserEvent(client, logUserEventInput);
        console.log(`User Event: ${savedUserEvents.length} saved events logged`);
    }
}

function getSavedUserEvents(): LogUserEventInput[] {
    const savedUserEventsString = localStorage.getItem(SAVED_USER_EVENTS_LOCAL_STORAGE_KEY);
    const savedUserEvents: LogUserEventInput[] = savedUserEventsString ? JSON.parse(savedUserEventsString) : [];
    return savedUserEvents;
}

function setSavedUserEvents(logUserEventInputs: LogUserEventInput[]): void {
    localStorage.setItem(SAVED_USER_EVENTS_LOCAL_STORAGE_KEY, JSON.stringify(logUserEventInputs));
}
