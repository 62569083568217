import * as React from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { useHistory, useLocation } from 'react-router';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { getUserId } from '../../api/rest/user';
import { EmailAddressUnknown, GeneratedPassword } from '../../api/rest/errors';
import Button from '../../components/common/Button';
import PasswordRequirements, {
    PasswordRequirementsChecks,
    usePasswordRequirementsChecks,
} from '../../components/auth/PasswordRequirements';
import { IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE } from './SignInPage';

const md5 = require('md5');

const eyeIcon = { uri: '/assets/images/icons/eye.svg' };
const eyeSlashIcon = { uri: '/assets/images/icons/eye-slash.svg' };
const jokoLogo = { uri: '/assets/images/logos/logo-with-title.svg' };

function ResetPasswordPage() {
    const [userId, setUserId] = React.useState<string | undefined>();
    return (
        <View style={styles.container}>
            {!userId ? <EmailPage {...{ setUserId }} /> : <NewPasswordPage {...{ userId }} />}
        </View>
    );
}

export default ResetPasswordPage;

function EmailPage({ setUserId }: { setUserId: (userId: string) => void }) {
    const texts = getLocalizedTexts().auth.resetPassword.emailPage;
    const location = useLocation();
    const [email, setEmail] = React.useState<string>(new URLSearchParams(location.search).get('email') || '');
    const [resetPassword, isLoading, error, clearError] = useResetPassword(setUserId);
    const onPress = () => {
        if (isEmailValid(email)) {
            clearError();
            resetPassword(email);
        }
    };
    return (
        <View style={styles.containerPage}>
            <View style={styles.containerPageContent}>
                <JokoLogo />
                <View>
                    <Text style={styles.textTitle}>{texts.title}</Text>
                    <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
                </View>
                {error ? <ResetPasswordErrorMessage {...{ error }} /> : null}
                <EmailInput {...{ email, setEmail, isLoading }} />
                <NextButton {...{ email, onPress, isLoading }} />
                <BackButton />
            </View>
        </View>
    );
}

function JokoLogo() {
    return (
        <View style={styles.containerLogo}>
            <Image source={jokoLogo} style={styles.imageLogo} />
        </View>
    );
}

function EmailInput({
    email,
    setEmail,
    isLoading,
}: {
    email: string;
    setEmail: (email: string) => void;
    isLoading: boolean;
}) {
    const texts = getLocalizedTexts().auth.resetPassword.emailPage;
    return (
        <View style={styles.containerTextInput}>
            <TextInput
                value={email}
                style={[styles.textInput, { marginRight: 16 }]}
                placeholder={texts.emailPlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                onChangeText={setEmail}
                placeholderTextColor={color.silverChalice}
            />
        </View>
    );
}

enum ResetPasswordError {
    EmailAddressUnknown = 'EmailAddressUnknown',
    GeneratedPassword = 'GeneratedPassword',
    UnverifiedEmail = 'UnverifiedEmail',
    LimitExceeded = 'LimitExceeded',
    Other = 'Other',
}

function ResetPasswordErrorMessage({ error }: { error: ResetPasswordError }) {
    const texts = getLocalizedTexts().auth.resetPassword.emailPage;
    let message: string = '';
    if (error === ResetPasswordError.EmailAddressUnknown) message = texts.error.unknownEmail;
    else if (error === ResetPasswordError.GeneratedPassword) message = texts.error.generatedPassword;
    else if (error === ResetPasswordError.UnverifiedEmail) message = texts.error.unverifiedEmail;
    else if (error === ResetPasswordError.LimitExceeded) message = texts.error.limitExceeded;
    else message = texts.error.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
}

function NextButton({ email, onPress, isLoading }: { email: string; onPress: () => void; isLoading: boolean }) {
    const texts = getLocalizedTexts().auth.resetPassword.emailPage;
    return (
        <View style={{ marginVertical: 20 }}>
            <Button
                {...{ onPress, isLoading }}
                textStyle={styles.textNextButton}
                height={44}
                disabled={!isEmailValid(email)}>
                {texts.nextButton}
            </Button>
        </View>
    );
}

function BackButton() {
    const history = useHistory();
    const texts = getLocalizedTexts().auth.resetPassword.emailPage;
    return (
        <TouchableOpacity style={styles.containerBackButton} onPress={() => history.goBack()}>
            <Text style={styles.textBackButton}>{texts.backButton}</Text>
        </TouchableOpacity>
    );
}

function useResetPassword(
    setUserId: (userId: string) => void
): [(email: string) => void, boolean, ResetPasswordError | undefined, () => void] {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<ResetPasswordError | undefined>();
    const resetPassword = async (email: string) => {
        if (email) {
            setIsLoading(true);
            try {
                const userId = await getUserId(email);
                await Auth.forgotPassword(userId);
                setIsLoading(false);
                setUserId(userId);
            } catch (error) {
                if (error instanceof EmailAddressUnknown) setError(ResetPasswordError.EmailAddressUnknown);
                else if (error instanceof GeneratedPassword) setError(ResetPasswordError.GeneratedPassword);
                else if (error.code === 'InvalidParameterException') setError(ResetPasswordError.UnverifiedEmail);
                else if (error.code === 'LimitExceededException') setError(ResetPasswordError.LimitExceeded);
                else setError(ResetPasswordError.Other);
                setIsLoading(false);
            }
        }
    };
    const clearError = () => setError(undefined);
    return [resetPassword, isLoading, error, clearError];
}

function isEmailValid(email: string): boolean {
    const regex =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return regex.test(email.toLowerCase());
}

function NewPasswordPage({ userId }: { userId: string }) {
    const [code, setCode] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [changePassword, isLoading, hasSucceeded, error, clearError] = useChangePassword(userId);
    const passwordRequirementsChecks = usePasswordRequirementsChecks(password);
    const onPress = () => {
        if (code && passwordRequirementsChecks.all) changePassword(password, code);
    };
    return (
        <>
            {!hasSucceeded ? (
                <ResetPasswordDialog
                    {...{ code, setCode, setPassword, onPress, isLoading, error, passwordRequirementsChecks }}
                />
            ) : (
                <NewPasswordSuccessfullySet />
            )}
        </>
    );
}

function ResetPasswordDialog({
    code,
    setCode,
    setPassword,
    onPress,
    isLoading,
    error,
    passwordRequirementsChecks,
}: {
    code: string;
    setCode: (code: string) => void;
    setPassword: (password: string) => void;
    onPress: () => void;
    isLoading: boolean;
    error: ChangePasswordError | undefined;
    passwordRequirementsChecks: PasswordRequirementsChecks;
}) {
    const texts = getLocalizedTexts().auth.resetPassword.newPasswordPage;
    return (
        <View style={styles.containerPage}>
            <View style={styles.containerPageContent}>
                <JokoLogo />

                <View>
                    <Text style={styles.textTitle}>{texts.title}</Text>
                    <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
                </View>
                {error ? <ChangePasswordErrorMessage {...{ error }} /> : null}
                <View>
                    <CodeInput {...{ code, setCode, isLoading }} />
                    <PasswordInput {...{ setPassword, isLoading, onPress }} />
                    <PasswordRequirements {...{ passwordRequirementsChecks, style: { marginLeft: 4 } }} />
                </View>
                <View style={{ marginTop: 20, marginBottom: 24 }}>
                    <Button
                        {...{ onPress, isLoading }}
                        textStyle={styles.textNextButton}
                        height={40}
                        disabled={code.length === 0 || !passwordRequirementsChecks.all}>
                        {texts.nextButton}
                    </Button>
                </View>
            </View>
        </View>
    );
}

function CodeInput({ code, setCode, isLoading }: { code: string; setCode: (code: string) => void; isLoading: boolean }) {
    const texts = getLocalizedTexts().auth.resetPassword.newPasswordPage;
    return (
        <View style={[styles.containerTextInput, { marginBottom: 10 }]}>
            <TextInput
                style={[styles.textInput, { marginRight: 16 }]}
                placeholder={texts.codePlaceholder}
                value={code}
                blurOnSubmit={false}
                autoCorrect={false}
                autoCapitalize={'words'}
                keyboardType={'default'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setCode}
                placeholderTextColor={color.silverChalice}
            />
        </View>
    );
}

function PasswordInput({
    setPassword,
    isLoading,
    onPress,
}: {
    setPassword: (password: string) => void;
    isLoading: boolean;
    onPress: () => void;
}) {
    const texts = getLocalizedTexts().auth.resetPassword.newPasswordPage;
    const [hidePassword, setHidePassword] = React.useState<boolean>(true);
    return (
        <View style={[styles.containerTextInput, { marginBottom: 8, alignItems: 'center', justifyContent: 'center' }]}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.passwordPlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                secureTextEntry={hidePassword}
                underlineColorAndroid="transparent"
                returnKeyType="go"
                editable={!isLoading}
                onChangeText={setPassword}
                onSubmitEditing={onPress}
                placeholderTextColor={color.silverChalice}
            />
            <TouchableOpacity
                onPress={() => setHidePassword(!hidePassword)}
                activeOpacity={0.8}
                style={styles.containerEyeIcon}>
                {hidePassword ? (
                    <Image source={eyeIcon} style={styles.imageEyeIcon} />
                ) : (
                    <Image source={eyeSlashIcon} style={styles.imageEyeSlashIcon} />
                )}
            </TouchableOpacity>
        </View>
    );
}

enum ChangePasswordError {
    CodeMismatchException = 'CodeMismatchException',
    ExpiredCodeException = 'ExpiredCodeException',
    LimitExceededException = 'LimitExceededException',
    Other = 'Other',
}

function ChangePasswordErrorMessage({ error }: { error: ChangePasswordError }) {
    const texts = getLocalizedTexts().auth.resetPassword.newPasswordPage;
    let message: string = '';
    if (error === ChangePasswordError.CodeMismatchException) message = texts.error.wrongCode;
    else if (error === ChangePasswordError.ExpiredCodeException) message = texts.error.expiredCode;
    else if (error === ChangePasswordError.LimitExceededException) message = texts.error.limitExceeded;
    else message = texts.error.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
}

function NewPasswordSuccessfullySet() {
    const history = useHistory();
    const isUserLoggingInFromWebApp = useLocation().state === IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE;
    const texts = getLocalizedTexts().auth.resetPassword.newPasswordPage;
    return (
        <View style={{ width: 480, alignItems: 'center' }}>
            <Text style={styles.textNewPasswordTitle}>{texts.successMessage.title}</Text>
            <Text style={[styles.textSubtitle, { color: color.silverChalice, textAlign: 'center' }]}>
                {texts.successMessage.text}
            </Text>
            <Button
                onPress={() =>
                    history.push({
                        pathname: '/auth/sign-in',
                        state: isUserLoggingInFromWebApp ? IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE : undefined,
                    })
                }
                style={{ height: 44, width: 350 }}
                textStyle={styles.textNextButton}>
                {texts.successMessage.button}
            </Button>
        </View>
    );
}

function useChangePassword(
    userId: string
): [(password: string, code: string) => void, boolean, boolean, ChangePasswordError | undefined, () => void] {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [hasSucceeded, setHasSucceeded] = React.useState<boolean>(false);
    const [error, setError] = React.useState<ChangePasswordError | undefined>();
    const changePassword = async (password: string, code: string) => {
        if (isLoading || !userId) return;
        setIsLoading(true);
        const passwordMd5: string = md5(password);
        try {
            await Auth.forgotPasswordSubmit(userId, code, passwordMd5);
            setHasSucceeded(true);
        } catch (error) {
            if (error.code === 'CodeMismatchException') setError(ChangePasswordError.CodeMismatchException);
            else if (error.code === 'ExpiredCodeException') setError(ChangePasswordError.ExpiredCodeException);
            else if (error.code === 'LimitExceededException') setError(ChangePasswordError.LimitExceededException);
            else setError(ChangePasswordError.Other);
            setIsLoading(false);
        }
    };
    const clearError = () => setError(undefined);
    return [changePassword, isLoading, hasSucceeded, error, clearError];
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerPage: {
        width: 390,
        paddingHorizontal: 20,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        elevation: 5,
        borderRadius: 16,
        backgroundColor: color.white,
    },
    containerPageContent: {
        flex: 1,
    },
    containerLogo: {
        alignItems: 'center',
        marginTop: 24,
    },
    containerTextInput: {
        flexDirection: 'row',
        height: 44,
        alignItems: 'center',
        borderWidth: 2,
        borderColor: color.gallery,
        borderRadius: 8,
    },
    containerEyeIcon: {
        height: 24,
        width: 24,
        marginLeft: 8,
        marginRight: 10,
        justifyContent: 'center',
    },
    containerErrorMessage: {
        backgroundColor: color.fairPink,
        paddingVertical: 8,
        paddingHorizontal: 25,
        marginTop: -8,
        marginBottom: 16,
        borderRadius: 10,
    },
    containerBackButton: {
        alignSelf: 'center',
        marginBottom: 24,
    },
    containerResetPasswordDialog: {
        flex: 1,
    },
    textTitle: {
        marginTop: 24,
        marginBottom: 8,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        fontWeight: '900',
        lineHeight: 29,
        color: color.black,
    },
    textSubtitle: {
        marginBottom: 24,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        fontWeight: '600',
        lineHeight: 19,
        color: color.black,
    },
    textInput: {
        height: 24,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
        marginLeft: 16,
        color: color.black,
    },
    textErrorMessage: {
        color: color.radicalRed,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textNextButton: {
        fontFamily: font.ambitBlack,
        fontWeight: '900',
        fontSize: 14,
    },
    textBackButton: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    textNewPasswordTitle: {
        marginBottom: 21,
        fontFamily: font.ambitBlack,
        fontSize: 22,
        fontWeight: '900',
        lineHeight: 27,
        color: color.black,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.tundora,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
    imageLogo: {
        width: 91,
        height: 33,
        resizeMode: 'contain',
    },
    imageEyeIcon: {
        width: 19,
        height: 14,
        resizeMode: 'contain',
    },
    imageEyeSlashIcon: {
        width: 19,
        height: 17,
        resizeMode: 'contain',
    },
});
