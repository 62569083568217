import React from 'react';
import { BrowserRouter as Router, Redirect, Route, useRouteMatch } from 'react-router-dom';

import LandingPage from '../../pages/landing/LandingPage';

function LandingNavigator() {
    let { path } = useRouteMatch();
    return (
        <Router>
            <Route exact path={path}>
                <Redirect to={{ pathname: `${path}/cashback`, search: window.location.search }} />
            </Route>
            {/* Here we use the word 'cashback' for SEO */}
            <Route exact path={`${path}/cashback`}>
                <LandingPage />
            </Route>
        </Router>
    );
}

export default LandingNavigator;
